// @ts-nocheck
import { z } from 'zod';
import { Prisma } from '@prisma/client';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// JSON
//------------------------------------------------------

export type NullableJsonInput = Prisma.JsonValue | null | 'JsonNull' | 'DbNull' | Prisma.NullTypes.DbNull | Prisma.NullTypes.JsonNull;

export const transformJsonNull = (v?: NullableJsonInput) => {
  if (!v || v === 'DbNull') return Prisma.DbNull;
  if (v === 'JsonNull') return Prisma.JsonNull;
  return v;
};

export const JsonValueSchema: z.ZodType<Prisma.JsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.literal(null),
    z.record(z.lazy(() => JsonValueSchema.optional())),
    z.array(z.lazy(() => JsonValueSchema)),
  ])
);

export type JsonValueType = z.infer<typeof JsonValueSchema>;

export const NullableJsonValue = z
  .union([JsonValueSchema, z.literal('DbNull'), z.literal('JsonNull')])
  .nullable()
  .transform((v) => transformJsonNull(v));

export type NullableJsonValueType = z.infer<typeof NullableJsonValue>;

export const InputJsonValueSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.object({ toJSON: z.function(z.tuple([]), z.any()) }),
    z.record(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
    z.array(z.lazy(() => z.union([InputJsonValueSchema, z.literal(null)]))),
  ])
);

export type InputJsonValueType = z.infer<typeof InputJsonValueSchema>;


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const AssetScalarFieldEnumSchema = z.enum(['id','customerId','companyId','caseId','address','zipcode','city','inseeCode','country','surface','value','rentalValue','sellValue','assetType','usageType','ownershipType','companyType','createdAt','updatedAt']);

export const AssetJointOwnerScalarFieldEnumSchema = z.enum(['id','assetId','firstname','lastname','sharePercentage']);

export const AssetShareholderScalarFieldEnumSchema = z.enum(['id','assetId','firstname','lastname','type','sharePercentage']);

export const AssistantResponseScalarFieldEnumSchema = z.enum(['id','fileHash','assistantId','response','createdAt']);

export const AccountScalarFieldEnumSchema = z.enum(['id','userId','type','provider','providerAccountId','refresh_token','access_token','expires_at','token_type','scope','id_token','session_state']);

export const SessionScalarFieldEnumSchema = z.enum(['id','sessionToken','userId','expires']);

export const VerificationTokenScalarFieldEnumSchema = z.enum(['identifier','token','expires']);

export const BankScalarFieldEnumSchema = z.enum(['id','name','products','minDealAmount','assetTypes','acceptedLocalisation','disabledAt','createdAt','updatedAt']);

export const CaseScalarFieldEnumSchema = z.enum(['id','referenceNo','userId','customerId','coCustomerId','companyId','attio_record_id','purpose','description','requestedAmount','caseType','status','priority','createdAt','updatedAt']);

export const CaseCollaboratorScalarFieldEnumSchema = z.enum(['id','caseId','collaboratorId','createdAt','updatedAt']);

export const CaseTraceScalarFieldEnumSchema = z.enum(['id','caseId','userId','affiliateId','answersInput','contactInput','dbCreateOutput','dbCreateError','createdAt','updatedAt']);

export const CaseMessageScalarFieldEnumSchema = z.enum(['id','senderRole','model','modelData','status','caseId','senderId','createdAt','updatedAt']);

export const CompanyScalarFieldEnumSchema = z.enum(['id','type','userId','caseId','name','siret','address','zipcode','city','inseeCode','country','taxType','createdAt','updatedAt']);

export const CompanyRealEstateLoanChargeScalarFieldEnumSchema = z.enum(['id','companyId','caseId','assetId','monthlyCharge','guarantee','rate','endDate','loanAmount','type','createdAt','updatedAt']);

export const CompanyRentIncomeScalarFieldEnumSchema = z.enum(['id','companyId','assetId','monthlyIncome','createdAt','updatedAt']);

export const CompanyFinancialDataScalarFieldEnumSchema = z.enum(['id','companyId','year','exploitationResult','depreciationCharge','netProfit','loanInterest','revenue','equity','balanceSheetSize','liquidity','accountValue']);

export const CustomerScalarFieldEnumSchema = z.enum(['id','age','childrenSupportedCount','address','zipcode','city','country','maritalStatus','marriagetype','jobType','housingStatus','createdAt','updatedAt']);

export const CustomerEmployeeScalarFieldEnumSchema = z.enum(['id','customerId','type','isTrialing','monthlyIncome']);

export const CustomerRetiredScalarFieldEnumSchema = z.enum(['id','customerId','monthlyPensionIncome']);

export const CustomerSelfEmployedScalarFieldEnumSchema = z.enum(['id','customerId','lastYearAverageMonthlyIncome','hasEnoughAnnualReviews']);

export const CustomerInvestmentScalarFieldEnumSchema = z.enum(['id','customerId','type','amount']);

export const CustomerAdditionalIncomeScalarFieldEnumSchema = z.enum(['id','customerId','type','monthlyIncome']);

export const CustomerRentIncomeScalarFieldEnumSchema = z.enum(['id','customerId','assetId','monthlyIncome']);

export const CustomerRentChargeScalarFieldEnumSchema = z.enum(['id','customerId','monthlyCharge']);

export const CustomerConsumerLoanChargeScalarFieldEnumSchema = z.enum(['id','customerId','monthlyCharge','endDate','rate']);

export const CustomerRealEstateLoanChargeScalarFieldEnumSchema = z.enum(['id','customerId','assetId','monthlyCharge','guarantee','endDate','rate','loanAmount','type']);

export const CustomerAdditionalChargeScalarFieldEnumSchema = z.enum(['id','customerId','monthlyCharge','type']);

export const NotificationScalarFieldEnumSchema = z.enum(['id','receiverId','senderId','model','modelData','status','createdAt','updatedAt']);

export const CompanyPappersScalarFieldEnumSchema = z.enum(['id','companyId','partnerId','formatedSiret','formatedSiren','creationDate','isActive','legalStatus','tvaNumber','rcsNumber','capital','primaryActivity','nafCode','nafCodeLabel','activity','exerciseForm','workForce','workForceYear','financialYearEndDate','createdAt','updatedAt']);

export const PappersRepresentativeScalarFieldEnumSchema = z.enum(['id','companyPappersId','status','firstName','lastName','age','hasOngoingSanction']);

export const PappersBeneficiaryScalarFieldEnumSchema = z.enum(['id','companyPappersId','status','firstName','lastName','sharesPercentage','votePercentage','hasOngoingSanction']);

export const PappersCollectiveConventionScalarFieldEnumSchema = z.enum(['id','companyPappersId','name','idcc']);

export const PappersCollectiveProcedureScalarFieldEnumSchema = z.enum(['id','companyPappersId','type','startDate','endDate']);

export const PappersFinancialDataScalarFieldEnumSchema = z.enum(['id','companyPappersId','year','revenue','operatingResults','liquidity','charges','repaymentCapacity','equity','netProfit']);

export const PappersSanctionScalarFieldEnumSchema = z.enum(['id','pappersRepresentativeId','pappersBeneficiaryId','description','autority','isOngoing','startDate','endDate']);

export const PartnerScalarFieldEnumSchema = z.enum(['id','userId','affiliateId','companyName','status','profession','otherProfession','loanVolume','clientTypes','usageCases','siret','companySize','orias','attio_record_id','createdAt','updatedAt']);

export const PartnerCollaboratorScalarFieldEnumSchema = z.enum(['id','partnerId','collaboratorId','createdAt','updatedAt']);

export const PartnerRateScalarFieldEnumSchema = z.enum(['id','rate','partnerId','createdBy','createdAt']);

export const CasePartnerScalarFieldEnumSchema = z.enum(['id','partnerId','rateId','caseId','createdAt']);

export const PartnerPendingCaseScalarFieldEnumSchema = z.enum(['id','partnerId','firstname','lastname','email','phone','patrimony','product','amount','urgency','rating','description','createdAt','updatedAt']);

export const PendingCaseScalarFieldEnumSchema = z.enum(['id','requestedAmount','description','type','firstname','lastname','siret','companyName','companyType','taxType','phone','email','createdAt']);

export const SimulationScalarFieldEnumSchema = z.enum(['id','caseId','authorId','loanType','borrowedCapital','remainingMonths','rate','expertFees','mandate','bankFees','guaranteeFees','loansAmountTakenOver','monthlyChargesLoansTakenOver','status','rejectionMotive','rejectionReason','statusUpdatedAt','contribution','createdAt','updatedAt']);

export const SubmissionScalarFieldEnumSchema = z.enum(['id','caseId','authorId','bankId','product','reason','comment','status','createdAt','updatedAt']);

export const SuggestionScalarFieldEnumSchema = z.enum(['id','caseId','field','value','status','source','createdAt','updatedAt']);

export const SynthesisScalarFieldEnumSchema = z.enum(['id','caseId','content','updatedAt','createdAt','userId']);

export const UserScalarFieldEnumSchema = z.enum(['id','customerId','firstname','lastname','email','password','role','phone','emailVerified','image','calendarLink','gender','createdAt','updatedAt','passwordResetToken','passwordResetTokenExpiration','emailVerificationToken','emailVerificationTokenExpiration','passwordCreationToken','disabledAt']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const JsonNullValueInputSchema = z.enum(['JsonNull',]).transform((value) => (value === 'JsonNull' ? Prisma.JsonNull : value));

export const NullableJsonNullValueInputSchema = z.enum(['DbNull','JsonNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.DbNull : value);

export const QueryModeSchema = z.enum(['default','insensitive']);

export const NullsOrderSchema = z.enum(['first','last']);

export const JsonNullValueFilterSchema = z.enum(['DbNull','JsonNull','AnyNull',]).transform((value) => value === 'JsonNull' ? Prisma.JsonNull : value === 'DbNull' ? Prisma.JsonNull : value === 'AnyNull' ? Prisma.AnyNull : value);

export const AssetTypeSchema = z.enum(['residential_property','industrial_property','commercial_property']);

export type AssetTypeType = `${z.infer<typeof AssetTypeSchema>}`

export const AssetUsageTypeSchema = z.enum(['main_residence','secondary_residence','rental']);

export type AssetUsageTypeType = `${z.infer<typeof AssetUsageTypeSchema>}`

export const AssetOwnershipTypeSchema = z.enum(['propre','indivision','company','other']);

export type AssetOwnershipTypeType = `${z.infer<typeof AssetOwnershipTypeSchema>}`

export const AssetCompanyTypeSchema = z.enum(['sci_ir','sci_is','sarl_ir','sarl_is','other']);

export type AssetCompanyTypeType = `${z.infer<typeof AssetCompanyTypeSchema>}`

export const AssetShareholderTypeSchema = z.enum(['moral_entity','physical_entity']);

export type AssetShareholderTypeType = `${z.infer<typeof AssetShareholderTypeSchema>}`

export const ProductSchema = z.enum(['coInvest','loan','rac','obo','viagerLoan','dismemberment']);

export type ProductType = `${z.infer<typeof ProductSchema>}`

export const BankLocalisationSchema = z.enum(['TIER_1','TIER_2','TIER_3']);

export type BankLocalisationType = `${z.infer<typeof BankLocalisationSchema>}`

export const CasePurposeSchema = z.enum(['debt_reduction','investment','liquidity','renovate','purchase_new_asset','repay_current_account','associated_current_account_contribution','finance_acquisition','finance_self_sale']);

export type CasePurposeType = `${z.infer<typeof CasePurposeSchema>}`

export const CaseTypeSchema = z.enum(['senior','standard','patrimonial','obo']);

export type CaseTypeType = `${z.infer<typeof CaseTypeSchema>}`

export const CaseStatusSchema = z.enum(['new_prospect','to_be_processed','back_office','pieces_bo','bank_send','supplement_bank','pre_offer_bank','expertise_well','edited_offer','offer_returned_bank','funds_unlocking','commissioned_file','unqualified','duplicate','no_follow_up','bank_refusal','customer_withdrawal','signed_mandate']);

export type CaseStatusType = `${z.infer<typeof CaseStatusSchema>}`

export const CasePrioritySchema = z.enum(['low','average','high']);

export type CasePriorityType = `${z.infer<typeof CasePrioritySchema>}`

export const CaseMessageModelSchema = z.enum(['text','file_attachment','notification']);

export type CaseMessageModelType = `${z.infer<typeof CaseMessageModelSchema>}`

export const CaseMessageStatusSchema = z.enum(['unread','unread_notified','read']);

export type CaseMessageStatusType = `${z.infer<typeof CaseMessageStatusSchema>}`

export const CompanyTypeSchema = z.enum(['patrimony','commercial']);

export type CompanyTypeType = `${z.infer<typeof CompanyTypeSchema>}`

export const TaxTypeSchema = z.enum(['ir','is']);

export type TaxTypeType = `${z.infer<typeof TaxTypeSchema>}`

export const JobTypeSchema = z.enum(['employee','retired','self_employed','unemployed']);

export type JobTypeType = `${z.infer<typeof JobTypeSchema>}`

export const MaritalStatusSchema = z.enum(['single','married','cohabitation','pacs']);

export type MaritalStatusType = `${z.infer<typeof MaritalStatusSchema>}`

export const MarriageTypeSchema = z.enum(['communaute_legale','separation_de_biens','communaute_de_biens_universelle','participation_aux_acquets','autre']);

export type MarriageTypeType = `${z.infer<typeof MarriageTypeSchema>}`

export const HousingStatusSchema = z.enum(['tenant','landlord']);

export type HousingStatusType = `${z.infer<typeof HousingStatusSchema>}`

export const CustomerEmployeeTypeSchema = z.enum(['CDD','CDI']);

export type CustomerEmployeeTypeType = `${z.infer<typeof CustomerEmployeeTypeSchema>}`

export const CustomerAdditionalIncomeTypeSchema = z.enum(['other_rent','investment','pension','variable','caf']);

export type CustomerAdditionalIncomeTypeType = `${z.infer<typeof CustomerAdditionalIncomeTypeSchema>}`

export const CustomerAdditionalChargeTypeSchema = z.enum(['alimony','other']);

export type CustomerAdditionalChargeTypeType = `${z.infer<typeof CustomerAdditionalChargeTypeSchema>}`

export const LoanGuaranteeSchema = z.enum(['hypotec','caution','collateral']);

export type LoanGuaranteeType = `${z.infer<typeof LoanGuaranteeSchema>}`

export const LoanTypeSchema = z.enum(['depreciable','in_fine']);

export type LoanTypeType = `${z.infer<typeof LoanTypeSchema>}`

export const NotificationModelSchema = z.enum(['collab_to_user_new_simulation','user_to_collab_simulation_decision','user_to_collab_document_upload','collab_to_client_case_status_update','collab_got_new_case_messages','client_got_new_case_messages']);

export type NotificationModelType = `${z.infer<typeof NotificationModelSchema>}`

export const NotificationStatusSchema = z.enum(['unread','read']);

export type NotificationStatusType = `${z.infer<typeof NotificationStatusSchema>}`

export const PartnerStatusSchema = z.enum(['active','disabled']);

export type PartnerStatusType = `${z.infer<typeof PartnerStatusSchema>}`

export const PartnerProfessionSchema = z.enum(['lawyer','notary','management_advisor','tax_expert','accounting_expert','broker','real_estate_agent','other']);

export type PartnerProfessionType = `${z.infer<typeof PartnerProfessionSchema>}`

export const PartnerLoanVolumeSchema = z.enum(['below_1M','between_1M_10M','between_10M_50M','above_50M']);

export type PartnerLoanVolumeType = `${z.infer<typeof PartnerLoanVolumeSchema>}`

export const PartnerClientTypeSchema = z.enum(['entrepreneur','employees','real_estate_professional','retirees']);

export type PartnerClientTypeType = `${z.infer<typeof PartnerClientTypeSchema>}`

export const PartnerUsageCasesSchema = z.enum(['investment','pay_compensation','inheritance_transfer','purchase_real_estate','additional_retirement','treasury','fund_home_improvements','fund_business_project','leverage','refinance_real_estate']);

export type PartnerUsageCasesType = `${z.infer<typeof PartnerUsageCasesSchema>}`

export const PartnerCompanySizeSchema = z.enum(['size_1_5','size_6_10','size_11_20','size_21_50','size_51_100','size_100_plus']);

export type PartnerCompanySizeType = `${z.infer<typeof PartnerCompanySizeSchema>}`

export const ProspectPatrimonySchema = z.enum(['between_0_1M','between_1_2M','between_2_3M','between_3_4M','between_4_5M','between_5_10M','over_10M']);

export type ProspectPatrimonyType = `${z.infer<typeof ProspectPatrimonySchema>}`

export const ProspectProductSchema = z.enum(['cash_loan','real_estate_loan','professional_loan','finance_self_sale','bare_property_sale']);

export type ProspectProductType = `${z.infer<typeof ProspectProductSchema>}`

export const ProspectAmountSchema = z.enum(['between_0_500k','between_500k_1M','between_1_2M','between_2_3M','between_3_4M','between_4_5M','between_5_10M','over_10M']);

export type ProspectAmountType = `${z.infer<typeof ProspectAmountSchema>}`

export const ProspectUrgencySchema = z.enum(['next_weeks','before_3_months','before_6_months','between_6_12_months','over_1_year']);

export type ProspectUrgencyType = `${z.infer<typeof ProspectUrgencySchema>}`

export const ProspectRatingSchema = z.enum(['very_low','low','medium','high','very_high']);

export type ProspectRatingType = `${z.infer<typeof ProspectRatingSchema>}`

export const PendingCaseTypeSchema = z.enum(['customer','company']);

export type PendingCaseTypeType = `${z.infer<typeof PendingCaseTypeSchema>}`

export const SimulationStatusSchema = z.enum(['pending','accepted','rejected']);

export type SimulationStatusType = `${z.infer<typeof SimulationStatusSchema>}`

export const RejectionMotiveSchema = z.enum(['rate','duration','loanType','amount','monthlyPayment','other']);

export type RejectionMotiveType = `${z.infer<typeof RejectionMotiveSchema>}`

export const SubmissionStatusSchema = z.enum(['sent','additional_info','pre_offer','property_expertise','edited_offer','signed_offer','funds_released','rejected']);

export type SubmissionStatusType = `${z.infer<typeof SubmissionStatusSchema>}`

export const SuggestionFieldSchema = z.enum(['firstname','lastname','address','children_supported_count','monthly_income_customer','monthly_income_co_customer','movable_capital_income','customer_retired_pension']);

export type SuggestionFieldType = `${z.infer<typeof SuggestionFieldSchema>}`

export const SuggestionStatusSchema = z.enum(['pending','approved','outdated','rejected']);

export type SuggestionStatusType = `${z.infer<typeof SuggestionStatusSchema>}`

export const UserRoleSchema = z.enum(['user','admin','collaborator','developer','partner']);

export type UserRoleType = `${z.infer<typeof UserRoleSchema>}`

export const GenderSchema = z.enum(['Male','Female']);

export type GenderType = `${z.infer<typeof GenderSchema>}`

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// ASSET SCHEMA
/////////////////////////////////////////

export const AssetSchema = z.object({
  assetType: AssetTypeSchema.nullable(),
  usageType: AssetUsageTypeSchema,
  ownershipType: AssetOwnershipTypeSchema,
  companyType: AssetCompanyTypeSchema.nullable(),
  id: z.string(),
  customerId: z.string().nullable(),
  companyId: z.string().nullable(),
  caseId: z.string().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().nullable(),
  sellValue: z.number().int().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Asset = z.infer<typeof AssetSchema>

/////////////////////////////////////////
// ASSET JOINT OWNER SCHEMA
/////////////////////////////////////////

export const AssetJointOwnerSchema = z.object({
  id: z.string(),
  assetId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int(),
})

export type AssetJointOwner = z.infer<typeof AssetJointOwnerSchema>

/////////////////////////////////////////
// ASSET SHAREHOLDER SCHEMA
/////////////////////////////////////////

export const AssetShareholderSchema = z.object({
  type: AssetShareholderTypeSchema,
  id: z.string(),
  assetId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int(),
})

export type AssetShareholder = z.infer<typeof AssetShareholderSchema>

/////////////////////////////////////////
// ASSISTANT RESPONSE SCHEMA
/////////////////////////////////////////

export const AssistantResponseSchema = z.object({
  id: z.string(),
  fileHash: z.string(),
  assistantId: z.string(),
  response: JsonValueSchema,
  createdAt: z.coerce.date(),
})

export type AssistantResponse = z.infer<typeof AssistantResponseSchema>

/////////////////////////////////////////
// ACCOUNT SCHEMA
/////////////////////////////////////////

export const AccountSchema = z.object({
  id: z.string(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().nullable(),
  access_token: z.string().nullable(),
  expires_at: z.number().int().nullable(),
  token_type: z.string().nullable(),
  scope: z.string().nullable(),
  id_token: z.string().nullable(),
  session_state: z.string().nullable(),
})

export type Account = z.infer<typeof AccountSchema>

/////////////////////////////////////////
// SESSION SCHEMA
/////////////////////////////////////////

export const SessionSchema = z.object({
  id: z.string(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date(),
})

export type Session = z.infer<typeof SessionSchema>

/////////////////////////////////////////
// VERIFICATION TOKEN SCHEMA
/////////////////////////////////////////

export const VerificationTokenSchema = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date(),
})

export type VerificationToken = z.infer<typeof VerificationTokenSchema>

/////////////////////////////////////////
// BANK SCHEMA
/////////////////////////////////////////

export const BankSchema = z.object({
  products: ProductSchema.array(),
  assetTypes: AssetTypeSchema.array(),
  acceptedLocalisation: BankLocalisationSchema,
  id: z.string(),
  name: z.string(),
  minDealAmount: z.number().int(),
  disabledAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Bank = z.infer<typeof BankSchema>

/////////////////////////////////////////
// CASE SCHEMA
/////////////////////////////////////////

export const CaseSchema = z.object({
  purpose: CasePurposeSchema,
  caseType: CaseTypeSchema,
  status: CaseStatusSchema,
  priority: CasePrioritySchema,
  id: z.string(),
  referenceNo: z.number().int(),
  userId: z.string(),
  customerId: z.string().nullable(),
  coCustomerId: z.string().nullable(),
  companyId: z.string().nullable(),
  attio_record_id: z.string().nullable(),
  description: z.string(),
  requestedAmount: z.number().int().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Case = z.infer<typeof CaseSchema>

/////////////////////////////////////////
// CASE COLLABORATOR SCHEMA
/////////////////////////////////////////

export const CaseCollaboratorSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CaseCollaborator = z.infer<typeof CaseCollaboratorSchema>

/////////////////////////////////////////
// CASE TRACE SCHEMA
/////////////////////////////////////////

export const CaseTraceSchema = z.object({
  id: z.string(),
  caseId: z.string().nullable(),
  userId: z.string().nullable(),
  affiliateId: z.string().nullable(),
  answersInput: JsonValueSchema,
  contactInput: JsonValueSchema.nullable(),
  dbCreateOutput: JsonValueSchema.nullable(),
  dbCreateError: JsonValueSchema.nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CaseTrace = z.infer<typeof CaseTraceSchema>

/////////////////////////////////////////
// CASE MESSAGE SCHEMA
/////////////////////////////////////////

export const CaseMessageSchema = z.object({
  senderRole: UserRoleSchema,
  model: CaseMessageModelSchema,
  status: CaseMessageStatusSchema,
  id: z.string(),
  modelData: JsonValueSchema,
  caseId: z.string(),
  senderId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CaseMessage = z.infer<typeof CaseMessageSchema>

/////////////////////////////////////////
// COMPANY SCHEMA
/////////////////////////////////////////

export const CompanySchema = z.object({
  type: CompanyTypeSchema,
  taxType: TaxTypeSchema.nullable(),
  id: z.string(),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().nullable(),
  country: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Company = z.infer<typeof CompanySchema>

/////////////////////////////////////////
// COMPANY REAL ESTATE LOAN CHARGE SCHEMA
/////////////////////////////////////////

export const CompanyRealEstateLoanChargeSchema = z.object({
  guarantee: LoanGuaranteeSchema,
  type: LoanTypeSchema,
  id: z.string(),
  companyId: z.string(),
  caseId: z.string(),
  assetId: z.string(),
  monthlyCharge: z.number().int().nullable(),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CompanyRealEstateLoanCharge = z.infer<typeof CompanyRealEstateLoanChargeSchema>

/////////////////////////////////////////
// COMPANY RENT INCOME SCHEMA
/////////////////////////////////////////

export const CompanyRentIncomeSchema = z.object({
  id: z.string(),
  companyId: z.string(),
  assetId: z.string(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CompanyRentIncome = z.infer<typeof CompanyRentIncomeSchema>

/////////////////////////////////////////
// COMPANY FINANCIAL DATA SCHEMA
/////////////////////////////////////////

export const CompanyFinancialDataSchema = z.object({
  id: z.string(),
  companyId: z.string(),
  year: z.number().int(),
  exploitationResult: z.number().nullable(),
  depreciationCharge: z.number().nullable(),
  netProfit: z.number().nullable(),
  loanInterest: z.number().nullable(),
  revenue: z.number().nullable(),
  equity: z.number().nullable(),
  balanceSheetSize: z.number().nullable(),
  liquidity: z.number().nullable(),
  accountValue: z.number().nullable(),
})

export type CompanyFinancialData = z.infer<typeof CompanyFinancialDataSchema>

/////////////////////////////////////////
// CUSTOMER SCHEMA
/////////////////////////////////////////

export const CustomerSchema = z.object({
  maritalStatus: MaritalStatusSchema.nullable(),
  marriagetype: MarriageTypeSchema.nullable(),
  jobType: JobTypeSchema,
  housingStatus: HousingStatusSchema.nullable(),
  id: z.string(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().nullable(),
  address: z.string().nullable(),
  zipcode: z.string().nullable(),
  city: z.string().nullable(),
  country: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Customer = z.infer<typeof CustomerSchema>

/////////////////////////////////////////
// CUSTOMER EMPLOYEE SCHEMA
/////////////////////////////////////////

export const CustomerEmployeeSchema = z.object({
  type: CustomerEmployeeTypeSchema,
  id: z.string(),
  customerId: z.string(),
  isTrialing: z.boolean().nullable(),
  monthlyIncome: z.number().int(),
})

export type CustomerEmployee = z.infer<typeof CustomerEmployeeSchema>

/////////////////////////////////////////
// CUSTOMER RETIRED SCHEMA
/////////////////////////////////////////

export const CustomerRetiredSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  monthlyPensionIncome: z.number().int(),
})

export type CustomerRetired = z.infer<typeof CustomerRetiredSchema>

/////////////////////////////////////////
// CUSTOMER SELF EMPLOYED SCHEMA
/////////////////////////////////////////

export const CustomerSelfEmployedSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  lastYearAverageMonthlyIncome: z.number().int(),
  hasEnoughAnnualReviews: z.boolean(),
})

export type CustomerSelfEmployed = z.infer<typeof CustomerSelfEmployedSchema>

/////////////////////////////////////////
// CUSTOMER INVESTMENT SCHEMA
/////////////////////////////////////////

export const CustomerInvestmentSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  type: z.string(),
  amount: z.number().int(),
})

export type CustomerInvestment = z.infer<typeof CustomerInvestmentSchema>

/////////////////////////////////////////
// CUSTOMER ADDITIONAL INCOME SCHEMA
/////////////////////////////////////////

export const CustomerAdditionalIncomeSchema = z.object({
  type: CustomerAdditionalIncomeTypeSchema,
  id: z.string(),
  customerId: z.string(),
  monthlyIncome: z.number().int(),
})

export type CustomerAdditionalIncome = z.infer<typeof CustomerAdditionalIncomeSchema>

/////////////////////////////////////////
// CUSTOMER RENT INCOME SCHEMA
/////////////////////////////////////////

export const CustomerRentIncomeSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  assetId: z.string(),
  monthlyIncome: z.number().int(),
})

export type CustomerRentIncome = z.infer<typeof CustomerRentIncomeSchema>

/////////////////////////////////////////
// CUSTOMER RENT CHARGE SCHEMA
/////////////////////////////////////////

export const CustomerRentChargeSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
})

export type CustomerRentCharge = z.infer<typeof CustomerRentChargeSchema>

/////////////////////////////////////////
// CUSTOMER CONSUMER LOAN CHARGE SCHEMA
/////////////////////////////////////////

export const CustomerConsumerLoanChargeSchema = z.object({
  id: z.string(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number(),
})

export type CustomerConsumerLoanCharge = z.infer<typeof CustomerConsumerLoanChargeSchema>

/////////////////////////////////////////
// CUSTOMER REAL ESTATE LOAN CHARGE SCHEMA
/////////////////////////////////////////

export const CustomerRealEstateLoanChargeSchema = z.object({
  guarantee: LoanGuaranteeSchema,
  type: LoanTypeSchema,
  id: z.string(),
  customerId: z.string(),
  assetId: z.string().nullable(),
  monthlyCharge: z.number().int().nullable(),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().nullable(),
})

export type CustomerRealEstateLoanCharge = z.infer<typeof CustomerRealEstateLoanChargeSchema>

/////////////////////////////////////////
// CUSTOMER ADDITIONAL CHARGE SCHEMA
/////////////////////////////////////////

export const CustomerAdditionalChargeSchema = z.object({
  type: CustomerAdditionalChargeTypeSchema,
  id: z.string(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
})

export type CustomerAdditionalCharge = z.infer<typeof CustomerAdditionalChargeSchema>

/////////////////////////////////////////
// NOTIFICATION SCHEMA
/////////////////////////////////////////

export const NotificationSchema = z.object({
  model: NotificationModelSchema,
  status: NotificationStatusSchema,
  id: z.string(),
  receiverId: z.string(),
  senderId: z.string(),
  modelData: JsonValueSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Notification = z.infer<typeof NotificationSchema>

/////////////////////////////////////////
// COMPANY PAPPERS SCHEMA
/////////////////////////////////////////

export const CompanyPappersSchema = z.object({
  id: z.string(),
  companyId: z.string().nullable(),
  partnerId: z.string().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().nullable(),
  capital: z.number().nullable(),
  primaryActivity: z.string().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().nullable(),
  exerciseForm: z.string().nullable(),
  workForce: z.string().nullable(),
  workForceYear: z.number().int().nullable(),
  financialYearEndDate: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type CompanyPappers = z.infer<typeof CompanyPappersSchema>

/////////////////////////////////////////
// PAPPERS REPRESENTATIVE SCHEMA
/////////////////////////////////////////

export const PappersRepresentativeSchema = z.object({
  id: z.string(),
  companyPappersId: z.string(),
  status: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  age: z.number().int().nullable(),
  hasOngoingSanction: z.boolean().nullable(),
})

export type PappersRepresentative = z.infer<typeof PappersRepresentativeSchema>

/////////////////////////////////////////
// PAPPERS BENEFICIARY SCHEMA
/////////////////////////////////////////

export const PappersBeneficiarySchema = z.object({
  id: z.string(),
  companyPappersId: z.string(),
  status: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  sharesPercentage: z.number().int().nullable(),
  votePercentage: z.number().int().nullable(),
  hasOngoingSanction: z.boolean().nullable(),
})

export type PappersBeneficiary = z.infer<typeof PappersBeneficiarySchema>

/////////////////////////////////////////
// PAPPERS COLLECTIVE CONVENTION SCHEMA
/////////////////////////////////////////

export const PappersCollectiveConventionSchema = z.object({
  id: z.string(),
  companyPappersId: z.string(),
  name: z.string().nullable(),
  idcc: z.number().int().nullable(),
})

export type PappersCollectiveConvention = z.infer<typeof PappersCollectiveConventionSchema>

/////////////////////////////////////////
// PAPPERS COLLECTIVE PROCEDURE SCHEMA
/////////////////////////////////////////

export const PappersCollectiveProcedureSchema = z.object({
  id: z.string(),
  companyPappersId: z.string(),
  type: z.string().nullable(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
})

export type PappersCollectiveProcedure = z.infer<typeof PappersCollectiveProcedureSchema>

/////////////////////////////////////////
// PAPPERS FINANCIAL DATA SCHEMA
/////////////////////////////////////////

export const PappersFinancialDataSchema = z.object({
  id: z.string(),
  companyPappersId: z.string(),
  year: z.number().int().nullable(),
  revenue: z.number().nullable(),
  operatingResults: z.number().nullable(),
  liquidity: z.number().nullable(),
  charges: z.number().nullable(),
  repaymentCapacity: z.number().nullable(),
  equity: z.number().nullable(),
  netProfit: z.number().nullable(),
})

export type PappersFinancialData = z.infer<typeof PappersFinancialDataSchema>

/////////////////////////////////////////
// PAPPERS SANCTION SCHEMA
/////////////////////////////////////////

export const PappersSanctionSchema = z.object({
  id: z.string(),
  pappersRepresentativeId: z.string().nullable(),
  pappersBeneficiaryId: z.string().nullable(),
  description: z.string().nullable(),
  autority: z.string().nullable(),
  isOngoing: z.boolean().nullable(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
})

export type PappersSanction = z.infer<typeof PappersSanctionSchema>

/////////////////////////////////////////
// PARTNER SCHEMA
/////////////////////////////////////////

export const PartnerSchema = z.object({
  status: PartnerStatusSchema,
  profession: PartnerProfessionSchema,
  loanVolume: PartnerLoanVolumeSchema,
  clientTypes: PartnerClientTypeSchema.array(),
  usageCases: PartnerUsageCasesSchema.array(),
  companySize: PartnerCompanySizeSchema,
  id: z.string(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  otherProfession: z.string().nullable(),
  siret: z.string(),
  orias: z.string().nullable(),
  attio_record_id: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Partner = z.infer<typeof PartnerSchema>

/////////////////////////////////////////
// PARTNER COLLABORATOR SCHEMA
/////////////////////////////////////////

export const PartnerCollaboratorSchema = z.object({
  id: z.string(),
  partnerId: z.string(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type PartnerCollaborator = z.infer<typeof PartnerCollaboratorSchema>

/////////////////////////////////////////
// PARTNER RATE SCHEMA
/////////////////////////////////////////

export const PartnerRateSchema = z.object({
  id: z.string(),
  rate: z.number(),
  partnerId: z.string(),
  createdBy: z.string(),
  createdAt: z.coerce.date(),
})

export type PartnerRate = z.infer<typeof PartnerRateSchema>

/////////////////////////////////////////
// CASE PARTNER SCHEMA
/////////////////////////////////////////

export const CasePartnerSchema = z.object({
  id: z.string(),
  partnerId: z.string(),
  rateId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date(),
})

export type CasePartner = z.infer<typeof CasePartnerSchema>

/////////////////////////////////////////
// PARTNER PENDING CASE SCHEMA
/////////////////////////////////////////

export const PartnerPendingCaseSchema = z.object({
  patrimony: ProspectPatrimonySchema,
  product: ProspectProductSchema,
  amount: ProspectAmountSchema,
  urgency: ProspectUrgencySchema,
  rating: ProspectRatingSchema,
  id: z.string(),
  partnerId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  description: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type PartnerPendingCase = z.infer<typeof PartnerPendingCaseSchema>

/////////////////////////////////////////
// PENDING CASE SCHEMA
/////////////////////////////////////////

export const PendingCaseSchema = z.object({
  type: PendingCaseTypeSchema,
  companyType: CompanyTypeSchema.nullable(),
  taxType: TaxTypeSchema.nullable(),
  id: z.string(),
  requestedAmount: z.number().int(),
  description: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  siret: z.string().nullable(),
  companyName: z.string().nullable(),
  phone: z.string(),
  email: z.string(),
  createdAt: z.coerce.date(),
})

export type PendingCase = z.infer<typeof PendingCaseSchema>

/////////////////////////////////////////
// SIMULATION SCHEMA
/////////////////////////////////////////

export const SimulationSchema = z.object({
  loanType: LoanTypeSchema,
  status: SimulationStatusSchema,
  rejectionMotive: RejectionMotiveSchema.nullable(),
  id: z.string(),
  caseId: z.string(),
  authorId: z.string(),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number(),
  rejectionReason: z.string().nullable(),
  statusUpdatedAt: z.coerce.date().nullable(),
  contribution: z.number(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Simulation = z.infer<typeof SimulationSchema>

/////////////////////////////////////////
// SUBMISSION SCHEMA
/////////////////////////////////////////

export const SubmissionSchema = z.object({
  product: ProductSchema,
  status: SubmissionStatusSchema,
  id: z.string(),
  caseId: z.string(),
  authorId: z.string(),
  bankId: z.string(),
  reason: z.string(),
  comment: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Submission = z.infer<typeof SubmissionSchema>

/////////////////////////////////////////
// SUGGESTION SCHEMA
/////////////////////////////////////////

export const SuggestionSchema = z.object({
  field: SuggestionFieldSchema,
  status: SuggestionStatusSchema,
  id: z.string(),
  caseId: z.string(),
  value: z.string(),
  source: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export type Suggestion = z.infer<typeof SuggestionSchema>

/////////////////////////////////////////
// SYNTHESIS SCHEMA
/////////////////////////////////////////

export const SynthesisSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  content: z.string(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  userId: z.string(),
})

export type Synthesis = z.infer<typeof SynthesisSchema>

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  role: UserRoleSchema,
  gender: GenderSchema.nullable(),
  id: z.string(),
  customerId: z.string().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().nullable(),
  phone: z.string().nullable(),
  emailVerified: z.coerce.date().nullable(),
  image: z.string().nullable(),
  calendarLink: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  passwordResetToken: z.string().nullable(),
  passwordResetTokenExpiration: z.coerce.date().nullable(),
  emailVerificationToken: z.string().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().nullable(),
  passwordCreationToken: z.string().nullable(),
  disabledAt: z.coerce.date().nullable(),
})

export type User = z.infer<typeof UserSchema>

/////////////////////////////////////////
// SELECT & INCLUDE
/////////////////////////////////////////

// ASSET
//------------------------------------------------------

export const AssetIncludeSchema: z.ZodType<Prisma.AssetInclude> = z.object({
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  assetJointOwners: z.union([z.boolean(),z.lazy(() => AssetJointOwnerFindManyArgsSchema)]).optional(),
  assetShareholders: z.union([z.boolean(),z.lazy(() => AssetShareholderFindManyArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  customerRealEstateLoanCharges: z.union([z.boolean(),z.lazy(() => CustomerRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  customerRentIncome: z.union([z.boolean(),z.lazy(() => CustomerRentIncomeArgsSchema)]).optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  companyRealEstateLoanCharges: z.union([z.boolean(),z.lazy(() => CompanyRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  companyRentIncome: z.union([z.boolean(),z.lazy(() => CompanyRentIncomeArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => AssetCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const AssetArgsSchema: z.ZodType<Prisma.AssetDefaultArgs> = z.object({
  select: z.lazy(() => AssetSelectSchema).optional(),
  include: z.lazy(() => AssetIncludeSchema).optional(),
}).strict();

export const AssetCountOutputTypeArgsSchema: z.ZodType<Prisma.AssetCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => AssetCountOutputTypeSelectSchema).nullish(),
}).strict();

export const AssetCountOutputTypeSelectSchema: z.ZodType<Prisma.AssetCountOutputTypeSelect> = z.object({
  assetJointOwners: z.boolean().optional(),
  assetShareholders: z.boolean().optional(),
  customerRealEstateLoanCharges: z.boolean().optional(),
  companyRealEstateLoanCharges: z.boolean().optional(),
}).strict();

export const AssetSelectSchema: z.ZodType<Prisma.AssetSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  companyId: z.boolean().optional(),
  caseId: z.boolean().optional(),
  address: z.boolean().optional(),
  zipcode: z.boolean().optional(),
  city: z.boolean().optional(),
  inseeCode: z.boolean().optional(),
  country: z.boolean().optional(),
  surface: z.boolean().optional(),
  value: z.boolean().optional(),
  rentalValue: z.boolean().optional(),
  sellValue: z.boolean().optional(),
  assetType: z.boolean().optional(),
  usageType: z.boolean().optional(),
  ownershipType: z.boolean().optional(),
  companyType: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  assetJointOwners: z.union([z.boolean(),z.lazy(() => AssetJointOwnerFindManyArgsSchema)]).optional(),
  assetShareholders: z.union([z.boolean(),z.lazy(() => AssetShareholderFindManyArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  customerRealEstateLoanCharges: z.union([z.boolean(),z.lazy(() => CustomerRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  customerRentIncome: z.union([z.boolean(),z.lazy(() => CustomerRentIncomeArgsSchema)]).optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  companyRealEstateLoanCharges: z.union([z.boolean(),z.lazy(() => CompanyRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  companyRentIncome: z.union([z.boolean(),z.lazy(() => CompanyRentIncomeArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => AssetCountOutputTypeArgsSchema)]).optional(),
}).strict()

// ASSET JOINT OWNER
//------------------------------------------------------

export const AssetJointOwnerIncludeSchema: z.ZodType<Prisma.AssetJointOwnerInclude> = z.object({
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
}).strict()

export const AssetJointOwnerArgsSchema: z.ZodType<Prisma.AssetJointOwnerDefaultArgs> = z.object({
  select: z.lazy(() => AssetJointOwnerSelectSchema).optional(),
  include: z.lazy(() => AssetJointOwnerIncludeSchema).optional(),
}).strict();

export const AssetJointOwnerSelectSchema: z.ZodType<Prisma.AssetJointOwnerSelect> = z.object({
  id: z.boolean().optional(),
  assetId: z.boolean().optional(),
  firstname: z.boolean().optional(),
  lastname: z.boolean().optional(),
  sharePercentage: z.boolean().optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
}).strict()

// ASSET SHAREHOLDER
//------------------------------------------------------

export const AssetShareholderIncludeSchema: z.ZodType<Prisma.AssetShareholderInclude> = z.object({
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
}).strict()

export const AssetShareholderArgsSchema: z.ZodType<Prisma.AssetShareholderDefaultArgs> = z.object({
  select: z.lazy(() => AssetShareholderSelectSchema).optional(),
  include: z.lazy(() => AssetShareholderIncludeSchema).optional(),
}).strict();

export const AssetShareholderSelectSchema: z.ZodType<Prisma.AssetShareholderSelect> = z.object({
  id: z.boolean().optional(),
  assetId: z.boolean().optional(),
  firstname: z.boolean().optional(),
  lastname: z.boolean().optional(),
  type: z.boolean().optional(),
  sharePercentage: z.boolean().optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
}).strict()

// ASSISTANT RESPONSE
//------------------------------------------------------

export const AssistantResponseSelectSchema: z.ZodType<Prisma.AssistantResponseSelect> = z.object({
  id: z.boolean().optional(),
  fileHash: z.boolean().optional(),
  assistantId: z.boolean().optional(),
  response: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// ACCOUNT
//------------------------------------------------------

export const AccountIncludeSchema: z.ZodType<Prisma.AccountInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const AccountArgsSchema: z.ZodType<Prisma.AccountDefaultArgs> = z.object({
  select: z.lazy(() => AccountSelectSchema).optional(),
  include: z.lazy(() => AccountIncludeSchema).optional(),
}).strict();

export const AccountSelectSchema: z.ZodType<Prisma.AccountSelect> = z.object({
  id: z.boolean().optional(),
  userId: z.boolean().optional(),
  type: z.boolean().optional(),
  provider: z.boolean().optional(),
  providerAccountId: z.boolean().optional(),
  refresh_token: z.boolean().optional(),
  access_token: z.boolean().optional(),
  expires_at: z.boolean().optional(),
  token_type: z.boolean().optional(),
  scope: z.boolean().optional(),
  id_token: z.boolean().optional(),
  session_state: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// SESSION
//------------------------------------------------------

export const SessionIncludeSchema: z.ZodType<Prisma.SessionInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const SessionArgsSchema: z.ZodType<Prisma.SessionDefaultArgs> = z.object({
  select: z.lazy(() => SessionSelectSchema).optional(),
  include: z.lazy(() => SessionIncludeSchema).optional(),
}).strict();

export const SessionSelectSchema: z.ZodType<Prisma.SessionSelect> = z.object({
  id: z.boolean().optional(),
  sessionToken: z.boolean().optional(),
  userId: z.boolean().optional(),
  expires: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// VERIFICATION TOKEN
//------------------------------------------------------

export const VerificationTokenSelectSchema: z.ZodType<Prisma.VerificationTokenSelect> = z.object({
  identifier: z.boolean().optional(),
  token: z.boolean().optional(),
  expires: z.boolean().optional(),
}).strict()

// BANK
//------------------------------------------------------

export const BankIncludeSchema: z.ZodType<Prisma.BankInclude> = z.object({
  submissions: z.union([z.boolean(),z.lazy(() => SubmissionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => BankCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const BankArgsSchema: z.ZodType<Prisma.BankDefaultArgs> = z.object({
  select: z.lazy(() => BankSelectSchema).optional(),
  include: z.lazy(() => BankIncludeSchema).optional(),
}).strict();

export const BankCountOutputTypeArgsSchema: z.ZodType<Prisma.BankCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => BankCountOutputTypeSelectSchema).nullish(),
}).strict();

export const BankCountOutputTypeSelectSchema: z.ZodType<Prisma.BankCountOutputTypeSelect> = z.object({
  submissions: z.boolean().optional(),
}).strict();

export const BankSelectSchema: z.ZodType<Prisma.BankSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  products: z.boolean().optional(),
  minDealAmount: z.boolean().optional(),
  assetTypes: z.boolean().optional(),
  acceptedLocalisation: z.boolean().optional(),
  disabledAt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  submissions: z.union([z.boolean(),z.lazy(() => SubmissionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => BankCountOutputTypeArgsSchema)]).optional(),
}).strict()

// CASE
//------------------------------------------------------

export const CaseIncludeSchema: z.ZodType<Prisma.CaseInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  coCustomer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  assets: z.union([z.boolean(),z.lazy(() => AssetFindManyArgsSchema)]).optional(),
  caseTrace: z.union([z.boolean(),z.lazy(() => CaseTraceArgsSchema)]).optional(),
  caseCollaborators: z.union([z.boolean(),z.lazy(() => CaseCollaboratorFindManyArgsSchema)]).optional(),
  submissions: z.union([z.boolean(),z.lazy(() => SubmissionFindManyArgsSchema)]).optional(),
  synthesis: z.union([z.boolean(),z.lazy(() => SynthesisArgsSchema)]).optional(),
  suggestions: z.union([z.boolean(),z.lazy(() => SuggestionFindManyArgsSchema)]).optional(),
  Simulation: z.union([z.boolean(),z.lazy(() => SimulationFindManyArgsSchema)]).optional(),
  caseMessages: z.union([z.boolean(),z.lazy(() => CaseMessageFindManyArgsSchema)]).optional(),
  casePartner: z.union([z.boolean(),z.lazy(() => CasePartnerFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CaseCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CaseArgsSchema: z.ZodType<Prisma.CaseDefaultArgs> = z.object({
  select: z.lazy(() => CaseSelectSchema).optional(),
  include: z.lazy(() => CaseIncludeSchema).optional(),
}).strict();

export const CaseCountOutputTypeArgsSchema: z.ZodType<Prisma.CaseCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => CaseCountOutputTypeSelectSchema).nullish(),
}).strict();

export const CaseCountOutputTypeSelectSchema: z.ZodType<Prisma.CaseCountOutputTypeSelect> = z.object({
  assets: z.boolean().optional(),
  caseCollaborators: z.boolean().optional(),
  submissions: z.boolean().optional(),
  suggestions: z.boolean().optional(),
  Simulation: z.boolean().optional(),
  caseMessages: z.boolean().optional(),
  casePartner: z.boolean().optional(),
}).strict();

export const CaseSelectSchema: z.ZodType<Prisma.CaseSelect> = z.object({
  id: z.boolean().optional(),
  referenceNo: z.boolean().optional(),
  userId: z.boolean().optional(),
  customerId: z.boolean().optional(),
  coCustomerId: z.boolean().optional(),
  companyId: z.boolean().optional(),
  attio_record_id: z.boolean().optional(),
  purpose: z.boolean().optional(),
  description: z.boolean().optional(),
  requestedAmount: z.boolean().optional(),
  caseType: z.boolean().optional(),
  status: z.boolean().optional(),
  priority: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  coCustomer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  assets: z.union([z.boolean(),z.lazy(() => AssetFindManyArgsSchema)]).optional(),
  caseTrace: z.union([z.boolean(),z.lazy(() => CaseTraceArgsSchema)]).optional(),
  caseCollaborators: z.union([z.boolean(),z.lazy(() => CaseCollaboratorFindManyArgsSchema)]).optional(),
  submissions: z.union([z.boolean(),z.lazy(() => SubmissionFindManyArgsSchema)]).optional(),
  synthesis: z.union([z.boolean(),z.lazy(() => SynthesisArgsSchema)]).optional(),
  suggestions: z.union([z.boolean(),z.lazy(() => SuggestionFindManyArgsSchema)]).optional(),
  Simulation: z.union([z.boolean(),z.lazy(() => SimulationFindManyArgsSchema)]).optional(),
  caseMessages: z.union([z.boolean(),z.lazy(() => CaseMessageFindManyArgsSchema)]).optional(),
  casePartner: z.union([z.boolean(),z.lazy(() => CasePartnerFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CaseCountOutputTypeArgsSchema)]).optional(),
}).strict()

// CASE COLLABORATOR
//------------------------------------------------------

export const CaseCollaboratorIncludeSchema: z.ZodType<Prisma.CaseCollaboratorInclude> = z.object({
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  collaborator: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const CaseCollaboratorArgsSchema: z.ZodType<Prisma.CaseCollaboratorDefaultArgs> = z.object({
  select: z.lazy(() => CaseCollaboratorSelectSchema).optional(),
  include: z.lazy(() => CaseCollaboratorIncludeSchema).optional(),
}).strict();

export const CaseCollaboratorSelectSchema: z.ZodType<Prisma.CaseCollaboratorSelect> = z.object({
  id: z.boolean().optional(),
  caseId: z.boolean().optional(),
  collaboratorId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  collaborator: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// CASE TRACE
//------------------------------------------------------

export const CaseTraceIncludeSchema: z.ZodType<Prisma.CaseTraceInclude> = z.object({
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const CaseTraceArgsSchema: z.ZodType<Prisma.CaseTraceDefaultArgs> = z.object({
  select: z.lazy(() => CaseTraceSelectSchema).optional(),
  include: z.lazy(() => CaseTraceIncludeSchema).optional(),
}).strict();

export const CaseTraceSelectSchema: z.ZodType<Prisma.CaseTraceSelect> = z.object({
  id: z.boolean().optional(),
  caseId: z.boolean().optional(),
  userId: z.boolean().optional(),
  affiliateId: z.boolean().optional(),
  answersInput: z.boolean().optional(),
  contactInput: z.boolean().optional(),
  dbCreateOutput: z.boolean().optional(),
  dbCreateError: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// CASE MESSAGE
//------------------------------------------------------

export const CaseMessageIncludeSchema: z.ZodType<Prisma.CaseMessageInclude> = z.object({
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  sender: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const CaseMessageArgsSchema: z.ZodType<Prisma.CaseMessageDefaultArgs> = z.object({
  select: z.lazy(() => CaseMessageSelectSchema).optional(),
  include: z.lazy(() => CaseMessageIncludeSchema).optional(),
}).strict();

export const CaseMessageSelectSchema: z.ZodType<Prisma.CaseMessageSelect> = z.object({
  id: z.boolean().optional(),
  senderRole: z.boolean().optional(),
  model: z.boolean().optional(),
  modelData: z.boolean().optional(),
  status: z.boolean().optional(),
  caseId: z.boolean().optional(),
  senderId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  sender: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// COMPANY
//------------------------------------------------------

export const CompanyIncludeSchema: z.ZodType<Prisma.CompanyInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  caseAsCompany: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  assets: z.union([z.boolean(),z.lazy(() => AssetFindManyArgsSchema)]).optional(),
  companyRealEstateLoanCharge: z.union([z.boolean(),z.lazy(() => CompanyRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  companyRentIncome: z.union([z.boolean(),z.lazy(() => CompanyRentIncomeFindManyArgsSchema)]).optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  companyFinancialDatas: z.union([z.boolean(),z.lazy(() => CompanyFinancialDataFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CompanyCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CompanyArgsSchema: z.ZodType<Prisma.CompanyDefaultArgs> = z.object({
  select: z.lazy(() => CompanySelectSchema).optional(),
  include: z.lazy(() => CompanyIncludeSchema).optional(),
}).strict();

export const CompanyCountOutputTypeArgsSchema: z.ZodType<Prisma.CompanyCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => CompanyCountOutputTypeSelectSchema).nullish(),
}).strict();

export const CompanyCountOutputTypeSelectSchema: z.ZodType<Prisma.CompanyCountOutputTypeSelect> = z.object({
  caseAsCompany: z.boolean().optional(),
  assets: z.boolean().optional(),
  companyRealEstateLoanCharge: z.boolean().optional(),
  companyRentIncome: z.boolean().optional(),
  companyFinancialDatas: z.boolean().optional(),
}).strict();

export const CompanySelectSchema: z.ZodType<Prisma.CompanySelect> = z.object({
  id: z.boolean().optional(),
  type: z.boolean().optional(),
  userId: z.boolean().optional(),
  caseId: z.boolean().optional(),
  name: z.boolean().optional(),
  siret: z.boolean().optional(),
  address: z.boolean().optional(),
  zipcode: z.boolean().optional(),
  city: z.boolean().optional(),
  inseeCode: z.boolean().optional(),
  country: z.boolean().optional(),
  taxType: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  caseAsCompany: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  assets: z.union([z.boolean(),z.lazy(() => AssetFindManyArgsSchema)]).optional(),
  companyRealEstateLoanCharge: z.union([z.boolean(),z.lazy(() => CompanyRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  companyRentIncome: z.union([z.boolean(),z.lazy(() => CompanyRentIncomeFindManyArgsSchema)]).optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  companyFinancialDatas: z.union([z.boolean(),z.lazy(() => CompanyFinancialDataFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CompanyCountOutputTypeArgsSchema)]).optional(),
}).strict()

// COMPANY REAL ESTATE LOAN CHARGE
//------------------------------------------------------

export const CompanyRealEstateLoanChargeIncludeSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeInclude> = z.object({
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
}).strict()

export const CompanyRealEstateLoanChargeArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeDefaultArgs> = z.object({
  select: z.lazy(() => CompanyRealEstateLoanChargeSelectSchema).optional(),
  include: z.lazy(() => CompanyRealEstateLoanChargeIncludeSchema).optional(),
}).strict();

export const CompanyRealEstateLoanChargeSelectSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeSelect> = z.object({
  id: z.boolean().optional(),
  companyId: z.boolean().optional(),
  caseId: z.boolean().optional(),
  assetId: z.boolean().optional(),
  monthlyCharge: z.boolean().optional(),
  guarantee: z.boolean().optional(),
  rate: z.boolean().optional(),
  endDate: z.boolean().optional(),
  loanAmount: z.boolean().optional(),
  type: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
}).strict()

// COMPANY RENT INCOME
//------------------------------------------------------

export const CompanyRentIncomeIncludeSchema: z.ZodType<Prisma.CompanyRentIncomeInclude> = z.object({
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
}).strict()

export const CompanyRentIncomeArgsSchema: z.ZodType<Prisma.CompanyRentIncomeDefaultArgs> = z.object({
  select: z.lazy(() => CompanyRentIncomeSelectSchema).optional(),
  include: z.lazy(() => CompanyRentIncomeIncludeSchema).optional(),
}).strict();

export const CompanyRentIncomeSelectSchema: z.ZodType<Prisma.CompanyRentIncomeSelect> = z.object({
  id: z.boolean().optional(),
  companyId: z.boolean().optional(),
  assetId: z.boolean().optional(),
  monthlyIncome: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
}).strict()

// COMPANY FINANCIAL DATA
//------------------------------------------------------

export const CompanyFinancialDataIncludeSchema: z.ZodType<Prisma.CompanyFinancialDataInclude> = z.object({
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
}).strict()

export const CompanyFinancialDataArgsSchema: z.ZodType<Prisma.CompanyFinancialDataDefaultArgs> = z.object({
  select: z.lazy(() => CompanyFinancialDataSelectSchema).optional(),
  include: z.lazy(() => CompanyFinancialDataIncludeSchema).optional(),
}).strict();

export const CompanyFinancialDataSelectSchema: z.ZodType<Prisma.CompanyFinancialDataSelect> = z.object({
  id: z.boolean().optional(),
  companyId: z.boolean().optional(),
  year: z.boolean().optional(),
  exploitationResult: z.boolean().optional(),
  depreciationCharge: z.boolean().optional(),
  netProfit: z.boolean().optional(),
  loanInterest: z.boolean().optional(),
  revenue: z.boolean().optional(),
  equity: z.boolean().optional(),
  balanceSheetSize: z.boolean().optional(),
  liquidity: z.boolean().optional(),
  accountValue: z.boolean().optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
}).strict()

// CUSTOMER
//------------------------------------------------------

export const CustomerIncludeSchema: z.ZodType<Prisma.CustomerInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  casesAsMainCustomer: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  casesAsCoCustomer: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  customerEmployee: z.union([z.boolean(),z.lazy(() => CustomerEmployeeArgsSchema)]).optional(),
  customerRetired: z.union([z.boolean(),z.lazy(() => CustomerRetiredArgsSchema)]).optional(),
  customerSelfEmployed: z.union([z.boolean(),z.lazy(() => CustomerSelfEmployedArgsSchema)]).optional(),
  customerInvestments: z.union([z.boolean(),z.lazy(() => CustomerInvestmentFindManyArgsSchema)]).optional(),
  customerAdditionalIncomes: z.union([z.boolean(),z.lazy(() => CustomerAdditionalIncomeFindManyArgsSchema)]).optional(),
  customerRentIncomes: z.union([z.boolean(),z.lazy(() => CustomerRentIncomeFindManyArgsSchema)]).optional(),
  assets: z.union([z.boolean(),z.lazy(() => AssetFindManyArgsSchema)]).optional(),
  realEstateLoanCharges: z.union([z.boolean(),z.lazy(() => CustomerRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  customerConsumerLoanCharges: z.union([z.boolean(),z.lazy(() => CustomerConsumerLoanChargeFindManyArgsSchema)]).optional(),
  customerRentCharges: z.union([z.boolean(),z.lazy(() => CustomerRentChargeFindManyArgsSchema)]).optional(),
  customerAdditionalCharges: z.union([z.boolean(),z.lazy(() => CustomerAdditionalChargeFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CustomerCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CustomerArgsSchema: z.ZodType<Prisma.CustomerDefaultArgs> = z.object({
  select: z.lazy(() => CustomerSelectSchema).optional(),
  include: z.lazy(() => CustomerIncludeSchema).optional(),
}).strict();

export const CustomerCountOutputTypeArgsSchema: z.ZodType<Prisma.CustomerCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerCountOutputTypeSelectSchema).nullish(),
}).strict();

export const CustomerCountOutputTypeSelectSchema: z.ZodType<Prisma.CustomerCountOutputTypeSelect> = z.object({
  casesAsMainCustomer: z.boolean().optional(),
  casesAsCoCustomer: z.boolean().optional(),
  customerInvestments: z.boolean().optional(),
  customerAdditionalIncomes: z.boolean().optional(),
  customerRentIncomes: z.boolean().optional(),
  assets: z.boolean().optional(),
  realEstateLoanCharges: z.boolean().optional(),
  customerConsumerLoanCharges: z.boolean().optional(),
  customerRentCharges: z.boolean().optional(),
  customerAdditionalCharges: z.boolean().optional(),
}).strict();

export const CustomerSelectSchema: z.ZodType<Prisma.CustomerSelect> = z.object({
  id: z.boolean().optional(),
  age: z.boolean().optional(),
  childrenSupportedCount: z.boolean().optional(),
  address: z.boolean().optional(),
  zipcode: z.boolean().optional(),
  city: z.boolean().optional(),
  country: z.boolean().optional(),
  maritalStatus: z.boolean().optional(),
  marriagetype: z.boolean().optional(),
  jobType: z.boolean().optional(),
  housingStatus: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  casesAsMainCustomer: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  casesAsCoCustomer: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  customerEmployee: z.union([z.boolean(),z.lazy(() => CustomerEmployeeArgsSchema)]).optional(),
  customerRetired: z.union([z.boolean(),z.lazy(() => CustomerRetiredArgsSchema)]).optional(),
  customerSelfEmployed: z.union([z.boolean(),z.lazy(() => CustomerSelfEmployedArgsSchema)]).optional(),
  customerInvestments: z.union([z.boolean(),z.lazy(() => CustomerInvestmentFindManyArgsSchema)]).optional(),
  customerAdditionalIncomes: z.union([z.boolean(),z.lazy(() => CustomerAdditionalIncomeFindManyArgsSchema)]).optional(),
  customerRentIncomes: z.union([z.boolean(),z.lazy(() => CustomerRentIncomeFindManyArgsSchema)]).optional(),
  assets: z.union([z.boolean(),z.lazy(() => AssetFindManyArgsSchema)]).optional(),
  realEstateLoanCharges: z.union([z.boolean(),z.lazy(() => CustomerRealEstateLoanChargeFindManyArgsSchema)]).optional(),
  customerConsumerLoanCharges: z.union([z.boolean(),z.lazy(() => CustomerConsumerLoanChargeFindManyArgsSchema)]).optional(),
  customerRentCharges: z.union([z.boolean(),z.lazy(() => CustomerRentChargeFindManyArgsSchema)]).optional(),
  customerAdditionalCharges: z.union([z.boolean(),z.lazy(() => CustomerAdditionalChargeFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CustomerCountOutputTypeArgsSchema)]).optional(),
}).strict()

// CUSTOMER EMPLOYEE
//------------------------------------------------------

export const CustomerEmployeeIncludeSchema: z.ZodType<Prisma.CustomerEmployeeInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerEmployeeArgsSchema: z.ZodType<Prisma.CustomerEmployeeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerEmployeeSelectSchema).optional(),
  include: z.lazy(() => CustomerEmployeeIncludeSchema).optional(),
}).strict();

export const CustomerEmployeeSelectSchema: z.ZodType<Prisma.CustomerEmployeeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  type: z.boolean().optional(),
  isTrialing: z.boolean().optional(),
  monthlyIncome: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER RETIRED
//------------------------------------------------------

export const CustomerRetiredIncludeSchema: z.ZodType<Prisma.CustomerRetiredInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerRetiredArgsSchema: z.ZodType<Prisma.CustomerRetiredDefaultArgs> = z.object({
  select: z.lazy(() => CustomerRetiredSelectSchema).optional(),
  include: z.lazy(() => CustomerRetiredIncludeSchema).optional(),
}).strict();

export const CustomerRetiredSelectSchema: z.ZodType<Prisma.CustomerRetiredSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  monthlyPensionIncome: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER SELF EMPLOYED
//------------------------------------------------------

export const CustomerSelfEmployedIncludeSchema: z.ZodType<Prisma.CustomerSelfEmployedInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerSelfEmployedArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedDefaultArgs> = z.object({
  select: z.lazy(() => CustomerSelfEmployedSelectSchema).optional(),
  include: z.lazy(() => CustomerSelfEmployedIncludeSchema).optional(),
}).strict();

export const CustomerSelfEmployedSelectSchema: z.ZodType<Prisma.CustomerSelfEmployedSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  lastYearAverageMonthlyIncome: z.boolean().optional(),
  hasEnoughAnnualReviews: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER INVESTMENT
//------------------------------------------------------

export const CustomerInvestmentIncludeSchema: z.ZodType<Prisma.CustomerInvestmentInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerInvestmentArgsSchema: z.ZodType<Prisma.CustomerInvestmentDefaultArgs> = z.object({
  select: z.lazy(() => CustomerInvestmentSelectSchema).optional(),
  include: z.lazy(() => CustomerInvestmentIncludeSchema).optional(),
}).strict();

export const CustomerInvestmentSelectSchema: z.ZodType<Prisma.CustomerInvestmentSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  type: z.boolean().optional(),
  amount: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER ADDITIONAL INCOME
//------------------------------------------------------

export const CustomerAdditionalIncomeIncludeSchema: z.ZodType<Prisma.CustomerAdditionalIncomeInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerAdditionalIncomeArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerAdditionalIncomeSelectSchema).optional(),
  include: z.lazy(() => CustomerAdditionalIncomeIncludeSchema).optional(),
}).strict();

export const CustomerAdditionalIncomeSelectSchema: z.ZodType<Prisma.CustomerAdditionalIncomeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  type: z.boolean().optional(),
  monthlyIncome: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER RENT INCOME
//------------------------------------------------------

export const CustomerRentIncomeIncludeSchema: z.ZodType<Prisma.CustomerRentIncomeInclude> = z.object({
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerRentIncomeArgsSchema: z.ZodType<Prisma.CustomerRentIncomeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerRentIncomeSelectSchema).optional(),
  include: z.lazy(() => CustomerRentIncomeIncludeSchema).optional(),
}).strict();

export const CustomerRentIncomeSelectSchema: z.ZodType<Prisma.CustomerRentIncomeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  assetId: z.boolean().optional(),
  monthlyIncome: z.boolean().optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER RENT CHARGE
//------------------------------------------------------

export const CustomerRentChargeIncludeSchema: z.ZodType<Prisma.CustomerRentChargeInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerRentChargeArgsSchema: z.ZodType<Prisma.CustomerRentChargeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerRentChargeSelectSchema).optional(),
  include: z.lazy(() => CustomerRentChargeIncludeSchema).optional(),
}).strict();

export const CustomerRentChargeSelectSchema: z.ZodType<Prisma.CustomerRentChargeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  monthlyCharge: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER CONSUMER LOAN CHARGE
//------------------------------------------------------

export const CustomerConsumerLoanChargeIncludeSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeInclude> = z.object({
  Customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerConsumerLoanChargeArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerConsumerLoanChargeSelectSchema).optional(),
  include: z.lazy(() => CustomerConsumerLoanChargeIncludeSchema).optional(),
}).strict();

export const CustomerConsumerLoanChargeSelectSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  monthlyCharge: z.boolean().optional(),
  endDate: z.boolean().optional(),
  rate: z.boolean().optional(),
  Customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER REAL ESTATE LOAN CHARGE
//------------------------------------------------------

export const CustomerRealEstateLoanChargeIncludeSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeInclude> = z.object({
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerRealEstateLoanChargeArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerRealEstateLoanChargeSelectSchema).optional(),
  include: z.lazy(() => CustomerRealEstateLoanChargeIncludeSchema).optional(),
}).strict();

export const CustomerRealEstateLoanChargeSelectSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  assetId: z.boolean().optional(),
  monthlyCharge: z.boolean().optional(),
  guarantee: z.boolean().optional(),
  endDate: z.boolean().optional(),
  rate: z.boolean().optional(),
  loanAmount: z.boolean().optional(),
  type: z.boolean().optional(),
  asset: z.union([z.boolean(),z.lazy(() => AssetArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// CUSTOMER ADDITIONAL CHARGE
//------------------------------------------------------

export const CustomerAdditionalChargeIncludeSchema: z.ZodType<Prisma.CustomerAdditionalChargeInclude> = z.object({
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

export const CustomerAdditionalChargeArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeDefaultArgs> = z.object({
  select: z.lazy(() => CustomerAdditionalChargeSelectSchema).optional(),
  include: z.lazy(() => CustomerAdditionalChargeIncludeSchema).optional(),
}).strict();

export const CustomerAdditionalChargeSelectSchema: z.ZodType<Prisma.CustomerAdditionalChargeSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  monthlyCharge: z.boolean().optional(),
  type: z.boolean().optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
}).strict()

// NOTIFICATION
//------------------------------------------------------

export const NotificationIncludeSchema: z.ZodType<Prisma.NotificationInclude> = z.object({
  receiver: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  sender: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const NotificationArgsSchema: z.ZodType<Prisma.NotificationDefaultArgs> = z.object({
  select: z.lazy(() => NotificationSelectSchema).optional(),
  include: z.lazy(() => NotificationIncludeSchema).optional(),
}).strict();

export const NotificationSelectSchema: z.ZodType<Prisma.NotificationSelect> = z.object({
  id: z.boolean().optional(),
  receiverId: z.boolean().optional(),
  senderId: z.boolean().optional(),
  model: z.boolean().optional(),
  modelData: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  receiver: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  sender: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// COMPANY PAPPERS
//------------------------------------------------------

export const CompanyPappersIncludeSchema: z.ZodType<Prisma.CompanyPappersInclude> = z.object({
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  representatives: z.union([z.boolean(),z.lazy(() => PappersRepresentativeFindManyArgsSchema)]).optional(),
  beneficiaries: z.union([z.boolean(),z.lazy(() => PappersBeneficiaryFindManyArgsSchema)]).optional(),
  collectiveConvention: z.union([z.boolean(),z.lazy(() => PappersCollectiveConventionFindManyArgsSchema)]).optional(),
  collectiveProcedure: z.union([z.boolean(),z.lazy(() => PappersCollectiveProcedureFindManyArgsSchema)]).optional(),
  financialData: z.union([z.boolean(),z.lazy(() => PappersFinancialDataFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CompanyPappersCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const CompanyPappersArgsSchema: z.ZodType<Prisma.CompanyPappersDefaultArgs> = z.object({
  select: z.lazy(() => CompanyPappersSelectSchema).optional(),
  include: z.lazy(() => CompanyPappersIncludeSchema).optional(),
}).strict();

export const CompanyPappersCountOutputTypeArgsSchema: z.ZodType<Prisma.CompanyPappersCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => CompanyPappersCountOutputTypeSelectSchema).nullish(),
}).strict();

export const CompanyPappersCountOutputTypeSelectSchema: z.ZodType<Prisma.CompanyPappersCountOutputTypeSelect> = z.object({
  representatives: z.boolean().optional(),
  beneficiaries: z.boolean().optional(),
  collectiveConvention: z.boolean().optional(),
  collectiveProcedure: z.boolean().optional(),
  financialData: z.boolean().optional(),
}).strict();

export const CompanyPappersSelectSchema: z.ZodType<Prisma.CompanyPappersSelect> = z.object({
  id: z.boolean().optional(),
  companyId: z.boolean().optional(),
  partnerId: z.boolean().optional(),
  formatedSiret: z.boolean().optional(),
  formatedSiren: z.boolean().optional(),
  creationDate: z.boolean().optional(),
  isActive: z.boolean().optional(),
  legalStatus: z.boolean().optional(),
  tvaNumber: z.boolean().optional(),
  rcsNumber: z.boolean().optional(),
  capital: z.boolean().optional(),
  primaryActivity: z.boolean().optional(),
  nafCode: z.boolean().optional(),
  nafCodeLabel: z.boolean().optional(),
  activity: z.boolean().optional(),
  exerciseForm: z.boolean().optional(),
  workForce: z.boolean().optional(),
  workForceYear: z.boolean().optional(),
  financialYearEndDate: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  company: z.union([z.boolean(),z.lazy(() => CompanyArgsSchema)]).optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  representatives: z.union([z.boolean(),z.lazy(() => PappersRepresentativeFindManyArgsSchema)]).optional(),
  beneficiaries: z.union([z.boolean(),z.lazy(() => PappersBeneficiaryFindManyArgsSchema)]).optional(),
  collectiveConvention: z.union([z.boolean(),z.lazy(() => PappersCollectiveConventionFindManyArgsSchema)]).optional(),
  collectiveProcedure: z.union([z.boolean(),z.lazy(() => PappersCollectiveProcedureFindManyArgsSchema)]).optional(),
  financialData: z.union([z.boolean(),z.lazy(() => PappersFinancialDataFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => CompanyPappersCountOutputTypeArgsSchema)]).optional(),
}).strict()

// PAPPERS REPRESENTATIVE
//------------------------------------------------------

export const PappersRepresentativeIncludeSchema: z.ZodType<Prisma.PappersRepresentativeInclude> = z.object({
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  sanction: z.union([z.boolean(),z.lazy(() => PappersSanctionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PappersRepresentativeCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const PappersRepresentativeArgsSchema: z.ZodType<Prisma.PappersRepresentativeDefaultArgs> = z.object({
  select: z.lazy(() => PappersRepresentativeSelectSchema).optional(),
  include: z.lazy(() => PappersRepresentativeIncludeSchema).optional(),
}).strict();

export const PappersRepresentativeCountOutputTypeArgsSchema: z.ZodType<Prisma.PappersRepresentativeCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => PappersRepresentativeCountOutputTypeSelectSchema).nullish(),
}).strict();

export const PappersRepresentativeCountOutputTypeSelectSchema: z.ZodType<Prisma.PappersRepresentativeCountOutputTypeSelect> = z.object({
  sanction: z.boolean().optional(),
}).strict();

export const PappersRepresentativeSelectSchema: z.ZodType<Prisma.PappersRepresentativeSelect> = z.object({
  id: z.boolean().optional(),
  companyPappersId: z.boolean().optional(),
  status: z.boolean().optional(),
  firstName: z.boolean().optional(),
  lastName: z.boolean().optional(),
  age: z.boolean().optional(),
  hasOngoingSanction: z.boolean().optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  sanction: z.union([z.boolean(),z.lazy(() => PappersSanctionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PappersRepresentativeCountOutputTypeArgsSchema)]).optional(),
}).strict()

// PAPPERS BENEFICIARY
//------------------------------------------------------

export const PappersBeneficiaryIncludeSchema: z.ZodType<Prisma.PappersBeneficiaryInclude> = z.object({
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  sanction: z.union([z.boolean(),z.lazy(() => PappersSanctionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PappersBeneficiaryCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const PappersBeneficiaryArgsSchema: z.ZodType<Prisma.PappersBeneficiaryDefaultArgs> = z.object({
  select: z.lazy(() => PappersBeneficiarySelectSchema).optional(),
  include: z.lazy(() => PappersBeneficiaryIncludeSchema).optional(),
}).strict();

export const PappersBeneficiaryCountOutputTypeArgsSchema: z.ZodType<Prisma.PappersBeneficiaryCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => PappersBeneficiaryCountOutputTypeSelectSchema).nullish(),
}).strict();

export const PappersBeneficiaryCountOutputTypeSelectSchema: z.ZodType<Prisma.PappersBeneficiaryCountOutputTypeSelect> = z.object({
  sanction: z.boolean().optional(),
}).strict();

export const PappersBeneficiarySelectSchema: z.ZodType<Prisma.PappersBeneficiarySelect> = z.object({
  id: z.boolean().optional(),
  companyPappersId: z.boolean().optional(),
  status: z.boolean().optional(),
  firstName: z.boolean().optional(),
  lastName: z.boolean().optional(),
  sharesPercentage: z.boolean().optional(),
  votePercentage: z.boolean().optional(),
  hasOngoingSanction: z.boolean().optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  sanction: z.union([z.boolean(),z.lazy(() => PappersSanctionFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PappersBeneficiaryCountOutputTypeArgsSchema)]).optional(),
}).strict()

// PAPPERS COLLECTIVE CONVENTION
//------------------------------------------------------

export const PappersCollectiveConventionIncludeSchema: z.ZodType<Prisma.PappersCollectiveConventionInclude> = z.object({
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
}).strict()

export const PappersCollectiveConventionArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionDefaultArgs> = z.object({
  select: z.lazy(() => PappersCollectiveConventionSelectSchema).optional(),
  include: z.lazy(() => PappersCollectiveConventionIncludeSchema).optional(),
}).strict();

export const PappersCollectiveConventionSelectSchema: z.ZodType<Prisma.PappersCollectiveConventionSelect> = z.object({
  id: z.boolean().optional(),
  companyPappersId: z.boolean().optional(),
  name: z.boolean().optional(),
  idcc: z.boolean().optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
}).strict()

// PAPPERS COLLECTIVE PROCEDURE
//------------------------------------------------------

export const PappersCollectiveProcedureIncludeSchema: z.ZodType<Prisma.PappersCollectiveProcedureInclude> = z.object({
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
}).strict()

export const PappersCollectiveProcedureArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureDefaultArgs> = z.object({
  select: z.lazy(() => PappersCollectiveProcedureSelectSchema).optional(),
  include: z.lazy(() => PappersCollectiveProcedureIncludeSchema).optional(),
}).strict();

export const PappersCollectiveProcedureSelectSchema: z.ZodType<Prisma.PappersCollectiveProcedureSelect> = z.object({
  id: z.boolean().optional(),
  companyPappersId: z.boolean().optional(),
  type: z.boolean().optional(),
  startDate: z.boolean().optional(),
  endDate: z.boolean().optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
}).strict()

// PAPPERS FINANCIAL DATA
//------------------------------------------------------

export const PappersFinancialDataIncludeSchema: z.ZodType<Prisma.PappersFinancialDataInclude> = z.object({
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
}).strict()

export const PappersFinancialDataArgsSchema: z.ZodType<Prisma.PappersFinancialDataDefaultArgs> = z.object({
  select: z.lazy(() => PappersFinancialDataSelectSchema).optional(),
  include: z.lazy(() => PappersFinancialDataIncludeSchema).optional(),
}).strict();

export const PappersFinancialDataSelectSchema: z.ZodType<Prisma.PappersFinancialDataSelect> = z.object({
  id: z.boolean().optional(),
  companyPappersId: z.boolean().optional(),
  year: z.boolean().optional(),
  revenue: z.boolean().optional(),
  operatingResults: z.boolean().optional(),
  liquidity: z.boolean().optional(),
  charges: z.boolean().optional(),
  repaymentCapacity: z.boolean().optional(),
  equity: z.boolean().optional(),
  netProfit: z.boolean().optional(),
  companyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
}).strict()

// PAPPERS SANCTION
//------------------------------------------------------

export const PappersSanctionIncludeSchema: z.ZodType<Prisma.PappersSanctionInclude> = z.object({
  pappersRepresentative: z.union([z.boolean(),z.lazy(() => PappersRepresentativeArgsSchema)]).optional(),
  pappersBeneficiary: z.union([z.boolean(),z.lazy(() => PappersBeneficiaryArgsSchema)]).optional(),
}).strict()

export const PappersSanctionArgsSchema: z.ZodType<Prisma.PappersSanctionDefaultArgs> = z.object({
  select: z.lazy(() => PappersSanctionSelectSchema).optional(),
  include: z.lazy(() => PappersSanctionIncludeSchema).optional(),
}).strict();

export const PappersSanctionSelectSchema: z.ZodType<Prisma.PappersSanctionSelect> = z.object({
  id: z.boolean().optional(),
  pappersRepresentativeId: z.boolean().optional(),
  pappersBeneficiaryId: z.boolean().optional(),
  description: z.boolean().optional(),
  autority: z.boolean().optional(),
  isOngoing: z.boolean().optional(),
  startDate: z.boolean().optional(),
  endDate: z.boolean().optional(),
  pappersRepresentative: z.union([z.boolean(),z.lazy(() => PappersRepresentativeArgsSchema)]).optional(),
  pappersBeneficiary: z.union([z.boolean(),z.lazy(() => PappersBeneficiaryArgsSchema)]).optional(),
}).strict()

// PARTNER
//------------------------------------------------------

export const PartnerIncludeSchema: z.ZodType<Prisma.PartnerInclude> = z.object({
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  partnerCollaborators: z.union([z.boolean(),z.lazy(() => PartnerCollaboratorFindManyArgsSchema)]).optional(),
  partnerRates: z.union([z.boolean(),z.lazy(() => PartnerRateFindManyArgsSchema)]).optional(),
  partnerCases: z.union([z.boolean(),z.lazy(() => CasePartnerFindManyArgsSchema)]).optional(),
  partnerCompanyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  partnerPendingCases: z.union([z.boolean(),z.lazy(() => PartnerPendingCaseFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PartnerCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const PartnerArgsSchema: z.ZodType<Prisma.PartnerDefaultArgs> = z.object({
  select: z.lazy(() => PartnerSelectSchema).optional(),
  include: z.lazy(() => PartnerIncludeSchema).optional(),
}).strict();

export const PartnerCountOutputTypeArgsSchema: z.ZodType<Prisma.PartnerCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => PartnerCountOutputTypeSelectSchema).nullish(),
}).strict();

export const PartnerCountOutputTypeSelectSchema: z.ZodType<Prisma.PartnerCountOutputTypeSelect> = z.object({
  partnerCollaborators: z.boolean().optional(),
  partnerRates: z.boolean().optional(),
  partnerCases: z.boolean().optional(),
  partnerPendingCases: z.boolean().optional(),
}).strict();

export const PartnerSelectSchema: z.ZodType<Prisma.PartnerSelect> = z.object({
  id: z.boolean().optional(),
  userId: z.boolean().optional(),
  affiliateId: z.boolean().optional(),
  companyName: z.boolean().optional(),
  status: z.boolean().optional(),
  profession: z.boolean().optional(),
  otherProfession: z.boolean().optional(),
  loanVolume: z.boolean().optional(),
  clientTypes: z.boolean().optional(),
  usageCases: z.boolean().optional(),
  siret: z.boolean().optional(),
  companySize: z.boolean().optional(),
  orias: z.boolean().optional(),
  attio_record_id: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  partnerCollaborators: z.union([z.boolean(),z.lazy(() => PartnerCollaboratorFindManyArgsSchema)]).optional(),
  partnerRates: z.union([z.boolean(),z.lazy(() => PartnerRateFindManyArgsSchema)]).optional(),
  partnerCases: z.union([z.boolean(),z.lazy(() => CasePartnerFindManyArgsSchema)]).optional(),
  partnerCompanyPappers: z.union([z.boolean(),z.lazy(() => CompanyPappersArgsSchema)]).optional(),
  partnerPendingCases: z.union([z.boolean(),z.lazy(() => PartnerPendingCaseFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PartnerCountOutputTypeArgsSchema)]).optional(),
}).strict()

// PARTNER COLLABORATOR
//------------------------------------------------------

export const PartnerCollaboratorIncludeSchema: z.ZodType<Prisma.PartnerCollaboratorInclude> = z.object({
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  collaborator: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const PartnerCollaboratorArgsSchema: z.ZodType<Prisma.PartnerCollaboratorDefaultArgs> = z.object({
  select: z.lazy(() => PartnerCollaboratorSelectSchema).optional(),
  include: z.lazy(() => PartnerCollaboratorIncludeSchema).optional(),
}).strict();

export const PartnerCollaboratorSelectSchema: z.ZodType<Prisma.PartnerCollaboratorSelect> = z.object({
  id: z.boolean().optional(),
  partnerId: z.boolean().optional(),
  collaboratorId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  collaborator: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// PARTNER RATE
//------------------------------------------------------

export const PartnerRateIncludeSchema: z.ZodType<Prisma.PartnerRateInclude> = z.object({
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  casePartner: z.union([z.boolean(),z.lazy(() => CasePartnerFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PartnerRateCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const PartnerRateArgsSchema: z.ZodType<Prisma.PartnerRateDefaultArgs> = z.object({
  select: z.lazy(() => PartnerRateSelectSchema).optional(),
  include: z.lazy(() => PartnerRateIncludeSchema).optional(),
}).strict();

export const PartnerRateCountOutputTypeArgsSchema: z.ZodType<Prisma.PartnerRateCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => PartnerRateCountOutputTypeSelectSchema).nullish(),
}).strict();

export const PartnerRateCountOutputTypeSelectSchema: z.ZodType<Prisma.PartnerRateCountOutputTypeSelect> = z.object({
  casePartner: z.boolean().optional(),
}).strict();

export const PartnerRateSelectSchema: z.ZodType<Prisma.PartnerRateSelect> = z.object({
  id: z.boolean().optional(),
  rate: z.boolean().optional(),
  partnerId: z.boolean().optional(),
  createdBy: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  casePartner: z.union([z.boolean(),z.lazy(() => CasePartnerFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => PartnerRateCountOutputTypeArgsSchema)]).optional(),
}).strict()

// CASE PARTNER
//------------------------------------------------------

export const CasePartnerIncludeSchema: z.ZodType<Prisma.CasePartnerInclude> = z.object({
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  rate: z.union([z.boolean(),z.lazy(() => PartnerRateArgsSchema)]).optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
}).strict()

export const CasePartnerArgsSchema: z.ZodType<Prisma.CasePartnerDefaultArgs> = z.object({
  select: z.lazy(() => CasePartnerSelectSchema).optional(),
  include: z.lazy(() => CasePartnerIncludeSchema).optional(),
}).strict();

export const CasePartnerSelectSchema: z.ZodType<Prisma.CasePartnerSelect> = z.object({
  id: z.boolean().optional(),
  partnerId: z.boolean().optional(),
  rateId: z.boolean().optional(),
  caseId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  rate: z.union([z.boolean(),z.lazy(() => PartnerRateArgsSchema)]).optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
}).strict()

// PARTNER PENDING CASE
//------------------------------------------------------

export const PartnerPendingCaseIncludeSchema: z.ZodType<Prisma.PartnerPendingCaseInclude> = z.object({
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
}).strict()

export const PartnerPendingCaseArgsSchema: z.ZodType<Prisma.PartnerPendingCaseDefaultArgs> = z.object({
  select: z.lazy(() => PartnerPendingCaseSelectSchema).optional(),
  include: z.lazy(() => PartnerPendingCaseIncludeSchema).optional(),
}).strict();

export const PartnerPendingCaseSelectSchema: z.ZodType<Prisma.PartnerPendingCaseSelect> = z.object({
  id: z.boolean().optional(),
  partnerId: z.boolean().optional(),
  firstname: z.boolean().optional(),
  lastname: z.boolean().optional(),
  email: z.boolean().optional(),
  phone: z.boolean().optional(),
  patrimony: z.boolean().optional(),
  product: z.boolean().optional(),
  amount: z.boolean().optional(),
  urgency: z.boolean().optional(),
  rating: z.boolean().optional(),
  description: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
}).strict()

// PENDING CASE
//------------------------------------------------------

export const PendingCaseSelectSchema: z.ZodType<Prisma.PendingCaseSelect> = z.object({
  id: z.boolean().optional(),
  requestedAmount: z.boolean().optional(),
  description: z.boolean().optional(),
  type: z.boolean().optional(),
  firstname: z.boolean().optional(),
  lastname: z.boolean().optional(),
  siret: z.boolean().optional(),
  companyName: z.boolean().optional(),
  companyType: z.boolean().optional(),
  taxType: z.boolean().optional(),
  phone: z.boolean().optional(),
  email: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// SIMULATION
//------------------------------------------------------

export const SimulationIncludeSchema: z.ZodType<Prisma.SimulationInclude> = z.object({
  author: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
}).strict()

export const SimulationArgsSchema: z.ZodType<Prisma.SimulationDefaultArgs> = z.object({
  select: z.lazy(() => SimulationSelectSchema).optional(),
  include: z.lazy(() => SimulationIncludeSchema).optional(),
}).strict();

export const SimulationSelectSchema: z.ZodType<Prisma.SimulationSelect> = z.object({
  id: z.boolean().optional(),
  caseId: z.boolean().optional(),
  authorId: z.boolean().optional(),
  loanType: z.boolean().optional(),
  borrowedCapital: z.boolean().optional(),
  remainingMonths: z.boolean().optional(),
  rate: z.boolean().optional(),
  expertFees: z.boolean().optional(),
  mandate: z.boolean().optional(),
  bankFees: z.boolean().optional(),
  guaranteeFees: z.boolean().optional(),
  loansAmountTakenOver: z.boolean().optional(),
  monthlyChargesLoansTakenOver: z.boolean().optional(),
  status: z.boolean().optional(),
  rejectionMotive: z.boolean().optional(),
  rejectionReason: z.boolean().optional(),
  statusUpdatedAt: z.boolean().optional(),
  contribution: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  author: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
}).strict()

// SUBMISSION
//------------------------------------------------------

export const SubmissionIncludeSchema: z.ZodType<Prisma.SubmissionInclude> = z.object({
  bank: z.union([z.boolean(),z.lazy(() => BankArgsSchema)]).optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const SubmissionArgsSchema: z.ZodType<Prisma.SubmissionDefaultArgs> = z.object({
  select: z.lazy(() => SubmissionSelectSchema).optional(),
  include: z.lazy(() => SubmissionIncludeSchema).optional(),
}).strict();

export const SubmissionSelectSchema: z.ZodType<Prisma.SubmissionSelect> = z.object({
  id: z.boolean().optional(),
  caseId: z.boolean().optional(),
  authorId: z.boolean().optional(),
  bankId: z.boolean().optional(),
  product: z.boolean().optional(),
  reason: z.boolean().optional(),
  comment: z.boolean().optional(),
  status: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  bank: z.union([z.boolean(),z.lazy(() => BankArgsSchema)]).optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// SUGGESTION
//------------------------------------------------------

export const SuggestionIncludeSchema: z.ZodType<Prisma.SuggestionInclude> = z.object({
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
}).strict()

export const SuggestionArgsSchema: z.ZodType<Prisma.SuggestionDefaultArgs> = z.object({
  select: z.lazy(() => SuggestionSelectSchema).optional(),
  include: z.lazy(() => SuggestionIncludeSchema).optional(),
}).strict();

export const SuggestionSelectSchema: z.ZodType<Prisma.SuggestionSelect> = z.object({
  id: z.boolean().optional(),
  caseId: z.boolean().optional(),
  field: z.boolean().optional(),
  value: z.boolean().optional(),
  status: z.boolean().optional(),
  source: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
}).strict()

// SYNTHESIS
//------------------------------------------------------

export const SynthesisIncludeSchema: z.ZodType<Prisma.SynthesisInclude> = z.object({
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

export const SynthesisArgsSchema: z.ZodType<Prisma.SynthesisDefaultArgs> = z.object({
  select: z.lazy(() => SynthesisSelectSchema).optional(),
  include: z.lazy(() => SynthesisIncludeSchema).optional(),
}).strict();

export const SynthesisSelectSchema: z.ZodType<Prisma.SynthesisSelect> = z.object({
  id: z.boolean().optional(),
  caseId: z.boolean().optional(),
  content: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  userId: z.boolean().optional(),
  case: z.union([z.boolean(),z.lazy(() => CaseArgsSchema)]).optional(),
  user: z.union([z.boolean(),z.lazy(() => UserArgsSchema)]).optional(),
}).strict()

// USER
//------------------------------------------------------

export const UserIncludeSchema: z.ZodType<Prisma.UserInclude> = z.object({
  accounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  caseTraces: z.union([z.boolean(),z.lazy(() => CaseTraceFindManyArgsSchema)]).optional(),
  companies: z.union([z.boolean(),z.lazy(() => CompanyFindManyArgsSchema)]).optional(),
  cases: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  casesAsCollaborator: z.union([z.boolean(),z.lazy(() => CaseCollaboratorFindManyArgsSchema)]).optional(),
  Simulation: z.union([z.boolean(),z.lazy(() => SimulationFindManyArgsSchema)]).optional(),
  submissions: z.union([z.boolean(),z.lazy(() => SubmissionFindManyArgsSchema)]).optional(),
  synthesis: z.union([z.boolean(),z.lazy(() => SynthesisFindManyArgsSchema)]).optional(),
  sentNotifications: z.union([z.boolean(),z.lazy(() => NotificationFindManyArgsSchema)]).optional(),
  receivedNotifications: z.union([z.boolean(),z.lazy(() => NotificationFindManyArgsSchema)]).optional(),
  sentCaseMessages: z.union([z.boolean(),z.lazy(() => CaseMessageFindManyArgsSchema)]).optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  partnerCollaborators: z.union([z.boolean(),z.lazy(() => PartnerCollaboratorFindManyArgsSchema)]).optional(),
  partnerRateCreatedBy: z.union([z.boolean(),z.lazy(() => PartnerRateFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()

export const UserArgsSchema: z.ZodType<Prisma.UserDefaultArgs> = z.object({
  select: z.lazy(() => UserSelectSchema).optional(),
  include: z.lazy(() => UserIncludeSchema).optional(),
}).strict();

export const UserCountOutputTypeArgsSchema: z.ZodType<Prisma.UserCountOutputTypeDefaultArgs> = z.object({
  select: z.lazy(() => UserCountOutputTypeSelectSchema).nullish(),
}).strict();

export const UserCountOutputTypeSelectSchema: z.ZodType<Prisma.UserCountOutputTypeSelect> = z.object({
  accounts: z.boolean().optional(),
  sessions: z.boolean().optional(),
  caseTraces: z.boolean().optional(),
  companies: z.boolean().optional(),
  cases: z.boolean().optional(),
  casesAsCollaborator: z.boolean().optional(),
  Simulation: z.boolean().optional(),
  submissions: z.boolean().optional(),
  synthesis: z.boolean().optional(),
  sentNotifications: z.boolean().optional(),
  receivedNotifications: z.boolean().optional(),
  sentCaseMessages: z.boolean().optional(),
  partnerCollaborators: z.boolean().optional(),
  partnerRateCreatedBy: z.boolean().optional(),
}).strict();

export const UserSelectSchema: z.ZodType<Prisma.UserSelect> = z.object({
  id: z.boolean().optional(),
  customerId: z.boolean().optional(),
  firstname: z.boolean().optional(),
  lastname: z.boolean().optional(),
  email: z.boolean().optional(),
  password: z.boolean().optional(),
  role: z.boolean().optional(),
  phone: z.boolean().optional(),
  emailVerified: z.boolean().optional(),
  image: z.boolean().optional(),
  calendarLink: z.boolean().optional(),
  gender: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  passwordResetToken: z.boolean().optional(),
  passwordResetTokenExpiration: z.boolean().optional(),
  emailVerificationToken: z.boolean().optional(),
  emailVerificationTokenExpiration: z.boolean().optional(),
  passwordCreationToken: z.boolean().optional(),
  disabledAt: z.boolean().optional(),
  accounts: z.union([z.boolean(),z.lazy(() => AccountFindManyArgsSchema)]).optional(),
  sessions: z.union([z.boolean(),z.lazy(() => SessionFindManyArgsSchema)]).optional(),
  customer: z.union([z.boolean(),z.lazy(() => CustomerArgsSchema)]).optional(),
  caseTraces: z.union([z.boolean(),z.lazy(() => CaseTraceFindManyArgsSchema)]).optional(),
  companies: z.union([z.boolean(),z.lazy(() => CompanyFindManyArgsSchema)]).optional(),
  cases: z.union([z.boolean(),z.lazy(() => CaseFindManyArgsSchema)]).optional(),
  casesAsCollaborator: z.union([z.boolean(),z.lazy(() => CaseCollaboratorFindManyArgsSchema)]).optional(),
  Simulation: z.union([z.boolean(),z.lazy(() => SimulationFindManyArgsSchema)]).optional(),
  submissions: z.union([z.boolean(),z.lazy(() => SubmissionFindManyArgsSchema)]).optional(),
  synthesis: z.union([z.boolean(),z.lazy(() => SynthesisFindManyArgsSchema)]).optional(),
  sentNotifications: z.union([z.boolean(),z.lazy(() => NotificationFindManyArgsSchema)]).optional(),
  receivedNotifications: z.union([z.boolean(),z.lazy(() => NotificationFindManyArgsSchema)]).optional(),
  sentCaseMessages: z.union([z.boolean(),z.lazy(() => CaseMessageFindManyArgsSchema)]).optional(),
  partner: z.union([z.boolean(),z.lazy(() => PartnerArgsSchema)]).optional(),
  partnerCollaborators: z.union([z.boolean(),z.lazy(() => PartnerCollaboratorFindManyArgsSchema)]).optional(),
  partnerRateCreatedBy: z.union([z.boolean(),z.lazy(() => PartnerRateFindManyArgsSchema)]).optional(),
  _count: z.union([z.boolean(),z.lazy(() => UserCountOutputTypeArgsSchema)]).optional(),
}).strict()


/////////////////////////////////////////
// INPUT TYPES
/////////////////////////////////////////

export const AssetWhereInputSchema: z.ZodType<Prisma.AssetWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssetWhereInputSchema),z.lazy(() => AssetWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetWhereInputSchema),z.lazy(() => AssetWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  caseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  surface: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  value: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  rentalValue: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  sellValue: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => EnumAssetTypeNullableFilterSchema),z.lazy(() => AssetTypeSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => EnumAssetUsageTypeFilterSchema),z.lazy(() => AssetUsageTypeSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => EnumAssetOwnershipTypeFilterSchema),z.lazy(() => AssetOwnershipTypeSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => EnumAssetCompanyTypeNullableFilterSchema),z.lazy(() => AssetCompanyTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseNullableRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional().nullable(),
  assetJointOwners: z.lazy(() => AssetJointOwnerListRelationFilterSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderListRelationFilterSchema).optional(),
  customer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeListRelationFilterSchema).optional(),
  customerRentIncome: z.union([ z.lazy(() => CustomerRentIncomeNullableRelationFilterSchema),z.lazy(() => CustomerRentIncomeWhereInputSchema) ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyNullableRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional().nullable(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeListRelationFilterSchema).optional(),
  companyRentIncome: z.union([ z.lazy(() => CompanyRentIncomeNullableRelationFilterSchema),z.lazy(() => CompanyRentIncomeWhereInputSchema) ]).optional().nullable(),
}).strict();

export const AssetOrderByWithRelationInputSchema: z.ZodType<Prisma.AssetOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  caseId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  sellValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  assetType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  usageType: z.lazy(() => SortOrderSchema).optional(),
  ownershipType: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerOrderByRelationAggregateInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderOrderByRelationAggregateInputSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeOrderByRelationAggregateInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeOrderByWithRelationInputSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeOrderByRelationAggregateInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeOrderByWithRelationInputSchema).optional()
}).strict();

export const AssetWhereUniqueInputSchema: z.ZodType<Prisma.AssetWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => AssetWhereInputSchema),z.lazy(() => AssetWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetWhereInputSchema),z.lazy(() => AssetWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  caseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  surface: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  value: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  rentalValue: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  sellValue: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => EnumAssetTypeNullableFilterSchema),z.lazy(() => AssetTypeSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => EnumAssetUsageTypeFilterSchema),z.lazy(() => AssetUsageTypeSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => EnumAssetOwnershipTypeFilterSchema),z.lazy(() => AssetOwnershipTypeSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => EnumAssetCompanyTypeNullableFilterSchema),z.lazy(() => AssetCompanyTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseNullableRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional().nullable(),
  assetJointOwners: z.lazy(() => AssetJointOwnerListRelationFilterSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderListRelationFilterSchema).optional(),
  customer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeListRelationFilterSchema).optional(),
  customerRentIncome: z.union([ z.lazy(() => CustomerRentIncomeNullableRelationFilterSchema),z.lazy(() => CustomerRentIncomeWhereInputSchema) ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyNullableRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional().nullable(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeListRelationFilterSchema).optional(),
  companyRentIncome: z.union([ z.lazy(() => CompanyRentIncomeNullableRelationFilterSchema),z.lazy(() => CompanyRentIncomeWhereInputSchema) ]).optional().nullable(),
}).strict());

export const AssetOrderByWithAggregationInputSchema: z.ZodType<Prisma.AssetOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  caseId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  sellValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  assetType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  usageType: z.lazy(() => SortOrderSchema).optional(),
  ownershipType: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AssetCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AssetAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AssetMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AssetMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AssetSumOrderByAggregateInputSchema).optional()
}).strict();

export const AssetScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AssetScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AssetScalarWhereWithAggregatesInputSchema),z.lazy(() => AssetScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetScalarWhereWithAggregatesInputSchema),z.lazy(() => AssetScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  caseId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  surface: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  value: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  rentalValue: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  sellValue: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => EnumAssetTypeNullableWithAggregatesFilterSchema),z.lazy(() => AssetTypeSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => EnumAssetUsageTypeWithAggregatesFilterSchema),z.lazy(() => AssetUsageTypeSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => EnumAssetOwnershipTypeWithAggregatesFilterSchema),z.lazy(() => AssetOwnershipTypeSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => EnumAssetCompanyTypeNullableWithAggregatesFilterSchema),z.lazy(() => AssetCompanyTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const AssetJointOwnerWhereInputSchema: z.ZodType<Prisma.AssetJointOwnerWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssetJointOwnerWhereInputSchema),z.lazy(() => AssetJointOwnerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetJointOwnerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetJointOwnerWhereInputSchema),z.lazy(() => AssetJointOwnerWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  asset: z.union([ z.lazy(() => AssetRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerOrderByWithRelationInputSchema: z.ZodType<Prisma.AssetJointOwnerOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional(),
  asset: z.lazy(() => AssetOrderByWithRelationInputSchema).optional()
}).strict();

export const AssetJointOwnerWhereUniqueInputSchema: z.ZodType<Prisma.AssetJointOwnerWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => AssetJointOwnerWhereInputSchema),z.lazy(() => AssetJointOwnerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetJointOwnerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetJointOwnerWhereInputSchema),z.lazy(() => AssetJointOwnerWhereInputSchema).array() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  asset: z.union([ z.lazy(() => AssetRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional(),
}).strict());

export const AssetJointOwnerOrderByWithAggregationInputSchema: z.ZodType<Prisma.AssetJointOwnerOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AssetJointOwnerCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AssetJointOwnerAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AssetJointOwnerMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AssetJointOwnerMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AssetJointOwnerSumOrderByAggregateInputSchema).optional()
}).strict();

export const AssetJointOwnerScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AssetJointOwnerScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AssetJointOwnerScalarWhereWithAggregatesInputSchema),z.lazy(() => AssetJointOwnerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetJointOwnerScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetJointOwnerScalarWhereWithAggregatesInputSchema),z.lazy(() => AssetJointOwnerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const AssetShareholderWhereInputSchema: z.ZodType<Prisma.AssetShareholderWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssetShareholderWhereInputSchema),z.lazy(() => AssetShareholderWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetShareholderWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetShareholderWhereInputSchema),z.lazy(() => AssetShareholderWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumAssetShareholderTypeFilterSchema),z.lazy(() => AssetShareholderTypeSchema) ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  asset: z.union([ z.lazy(() => AssetRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional(),
}).strict();

export const AssetShareholderOrderByWithRelationInputSchema: z.ZodType<Prisma.AssetShareholderOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional(),
  asset: z.lazy(() => AssetOrderByWithRelationInputSchema).optional()
}).strict();

export const AssetShareholderWhereUniqueInputSchema: z.ZodType<Prisma.AssetShareholderWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => AssetShareholderWhereInputSchema),z.lazy(() => AssetShareholderWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetShareholderWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetShareholderWhereInputSchema),z.lazy(() => AssetShareholderWhereInputSchema).array() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumAssetShareholderTypeFilterSchema),z.lazy(() => AssetShareholderTypeSchema) ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  asset: z.union([ z.lazy(() => AssetRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional(),
}).strict());

export const AssetShareholderOrderByWithAggregationInputSchema: z.ZodType<Prisma.AssetShareholderOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AssetShareholderCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AssetShareholderAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AssetShareholderMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AssetShareholderMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AssetShareholderSumOrderByAggregateInputSchema).optional()
}).strict();

export const AssetShareholderScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AssetShareholderScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AssetShareholderScalarWhereWithAggregatesInputSchema),z.lazy(() => AssetShareholderScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetShareholderScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetShareholderScalarWhereWithAggregatesInputSchema),z.lazy(() => AssetShareholderScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumAssetShareholderTypeWithAggregatesFilterSchema),z.lazy(() => AssetShareholderTypeSchema) ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const AssistantResponseWhereInputSchema: z.ZodType<Prisma.AssistantResponseWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssistantResponseWhereInputSchema),z.lazy(() => AssistantResponseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssistantResponseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssistantResponseWhereInputSchema),z.lazy(() => AssistantResponseWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  fileHash: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assistantId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  response: z.lazy(() => JsonFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const AssistantResponseOrderByWithRelationInputSchema: z.ZodType<Prisma.AssistantResponseOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fileHash: z.lazy(() => SortOrderSchema).optional(),
  assistantId: z.lazy(() => SortOrderSchema).optional(),
  response: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssistantResponseWhereUniqueInputSchema: z.ZodType<Prisma.AssistantResponseWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    fileHash: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    fileHash: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  fileHash: z.string().optional(),
  AND: z.union([ z.lazy(() => AssistantResponseWhereInputSchema),z.lazy(() => AssistantResponseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssistantResponseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssistantResponseWhereInputSchema),z.lazy(() => AssistantResponseWhereInputSchema).array() ]).optional(),
  assistantId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  response: z.lazy(() => JsonFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict());

export const AssistantResponseOrderByWithAggregationInputSchema: z.ZodType<Prisma.AssistantResponseOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fileHash: z.lazy(() => SortOrderSchema).optional(),
  assistantId: z.lazy(() => SortOrderSchema).optional(),
  response: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AssistantResponseCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AssistantResponseMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AssistantResponseMinOrderByAggregateInputSchema).optional()
}).strict();

export const AssistantResponseScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AssistantResponseScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AssistantResponseScalarWhereWithAggregatesInputSchema),z.lazy(() => AssistantResponseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssistantResponseScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssistantResponseScalarWhereWithAggregatesInputSchema),z.lazy(() => AssistantResponseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  fileHash: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assistantId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  response: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const AccountWhereInputSchema: z.ZodType<Prisma.AccountWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const AccountOrderByWithRelationInputSchema: z.ZodType<Prisma.AccountOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  access_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  expires_at: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  token_type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  scope: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  id_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  session_state: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const AccountWhereUniqueInputSchema: z.ZodType<Prisma.AccountWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema)
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema),
  }),
])
.and(z.object({
  id: z.string().optional(),
  provider_providerAccountId: z.lazy(() => AccountProviderProviderAccountIdCompoundUniqueInputSchema).optional(),
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const AccountOrderByWithAggregationInputSchema: z.ZodType<Prisma.AccountOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  access_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  expires_at: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  token_type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  scope: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  id_token: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  session_state: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => AccountCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AccountAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AccountMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AccountMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AccountSumOrderByAggregateInputSchema).optional()
}).strict();

export const AccountScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AccountScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SessionWhereInputSchema: z.ZodType<Prisma.SessionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const SessionOrderByWithRelationInputSchema: z.ZodType<Prisma.SessionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const SessionWhereUniqueInputSchema: z.ZodType<Prisma.SessionWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    sessionToken: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    sessionToken: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  sessionToken: z.string().optional(),
  AND: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionWhereInputSchema),z.lazy(() => SessionWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const SessionOrderByWithAggregationInputSchema: z.ZodType<Prisma.SessionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SessionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SessionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SessionMinOrderByAggregateInputSchema).optional()
}).strict();

export const SessionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SessionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereWithAggregatesInputSchema),z.lazy(() => SessionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const VerificationTokenWhereInputSchema: z.ZodType<Prisma.VerificationTokenWhereInput> = z.object({
  AND: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const VerificationTokenOrderByWithRelationInputSchema: z.ZodType<Prisma.VerificationTokenOrderByWithRelationInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenWhereUniqueInputSchema: z.ZodType<Prisma.VerificationTokenWhereUniqueInput> = z.union([
  z.object({
    token: z.string(),
    identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema)
  }),
  z.object({
    token: z.string(),
  }),
  z.object({
    identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema),
  }),
])
.and(z.object({
  token: z.string().optional(),
  identifier_token: z.lazy(() => VerificationTokenIdentifierTokenCompoundUniqueInputSchema).optional(),
  AND: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenWhereInputSchema),z.lazy(() => VerificationTokenWhereInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict());

export const VerificationTokenOrderByWithAggregationInputSchema: z.ZodType<Prisma.VerificationTokenOrderByWithAggregationInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => VerificationTokenCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => VerificationTokenMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => VerificationTokenMinOrderByAggregateInputSchema).optional()
}).strict();

export const VerificationTokenScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.VerificationTokenScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema),z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema),z.lazy(() => VerificationTokenScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  identifier: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  token: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const BankWhereInputSchema: z.ZodType<Prisma.BankWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BankWhereInputSchema),z.lazy(() => BankWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BankWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BankWhereInputSchema),z.lazy(() => BankWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  products: z.lazy(() => EnumProductNullableListFilterSchema).optional(),
  minDealAmount: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  assetTypes: z.lazy(() => EnumAssetTypeNullableListFilterSchema).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => EnumBankLocalisationFilterSchema),z.lazy(() => BankLocalisationSchema) ]).optional(),
  disabledAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  submissions: z.lazy(() => SubmissionListRelationFilterSchema).optional()
}).strict();

export const BankOrderByWithRelationInputSchema: z.ZodType<Prisma.BankOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  products: z.lazy(() => SortOrderSchema).optional(),
  minDealAmount: z.lazy(() => SortOrderSchema).optional(),
  assetTypes: z.lazy(() => SortOrderSchema).optional(),
  acceptedLocalisation: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  submissions: z.lazy(() => SubmissionOrderByRelationAggregateInputSchema).optional()
}).strict();

export const BankWhereUniqueInputSchema: z.ZodType<Prisma.BankWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => BankWhereInputSchema),z.lazy(() => BankWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BankWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BankWhereInputSchema),z.lazy(() => BankWhereInputSchema).array() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  products: z.lazy(() => EnumProductNullableListFilterSchema).optional(),
  minDealAmount: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  assetTypes: z.lazy(() => EnumAssetTypeNullableListFilterSchema).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => EnumBankLocalisationFilterSchema),z.lazy(() => BankLocalisationSchema) ]).optional(),
  disabledAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  submissions: z.lazy(() => SubmissionListRelationFilterSchema).optional()
}).strict());

export const BankOrderByWithAggregationInputSchema: z.ZodType<Prisma.BankOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  products: z.lazy(() => SortOrderSchema).optional(),
  minDealAmount: z.lazy(() => SortOrderSchema).optional(),
  assetTypes: z.lazy(() => SortOrderSchema).optional(),
  acceptedLocalisation: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BankCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => BankAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BankMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BankMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => BankSumOrderByAggregateInputSchema).optional()
}).strict();

export const BankScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BankScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BankScalarWhereWithAggregatesInputSchema),z.lazy(() => BankScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BankScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BankScalarWhereWithAggregatesInputSchema),z.lazy(() => BankScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  products: z.lazy(() => EnumProductNullableListFilterSchema).optional(),
  minDealAmount: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  assetTypes: z.lazy(() => EnumAssetTypeNullableListFilterSchema).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => EnumBankLocalisationWithAggregatesFilterSchema),z.lazy(() => BankLocalisationSchema) ]).optional(),
  disabledAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseWhereInputSchema: z.ZodType<Prisma.CaseWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseWhereInputSchema),z.lazy(() => CaseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseWhereInputSchema),z.lazy(() => CaseWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  referenceNo: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  coCustomerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => EnumCasePurposeFilterSchema),z.lazy(() => CasePurposeSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => EnumCaseTypeFilterSchema),z.lazy(() => CaseTypeSchema) ]).optional(),
  status: z.union([ z.lazy(() => EnumCaseStatusFilterSchema),z.lazy(() => CaseStatusSchema) ]).optional(),
  priority: z.union([ z.lazy(() => EnumCasePriorityFilterSchema),z.lazy(() => CasePrioritySchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  customer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  coCustomer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyNullableRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional().nullable(),
  assets: z.lazy(() => AssetListRelationFilterSchema).optional(),
  caseTrace: z.union([ z.lazy(() => CaseTraceNullableRelationFilterSchema),z.lazy(() => CaseTraceWhereInputSchema) ]).optional().nullable(),
  caseCollaborators: z.lazy(() => CaseCollaboratorListRelationFilterSchema).optional(),
  submissions: z.lazy(() => SubmissionListRelationFilterSchema).optional(),
  synthesis: z.union([ z.lazy(() => SynthesisNullableRelationFilterSchema),z.lazy(() => SynthesisWhereInputSchema) ]).optional().nullable(),
  suggestions: z.lazy(() => SuggestionListRelationFilterSchema).optional(),
  Simulation: z.lazy(() => SimulationListRelationFilterSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageListRelationFilterSchema).optional(),
  casePartner: z.lazy(() => CasePartnerListRelationFilterSchema).optional()
}).strict();

export const CaseOrderByWithRelationInputSchema: z.ZodType<Prisma.CaseOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  coCustomerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  attio_record_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  purpose: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  caseType: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional(),
  assets: z.lazy(() => AssetOrderByRelationAggregateInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceOrderByWithRelationInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorOrderByRelationAggregateInputSchema).optional(),
  submissions: z.lazy(() => SubmissionOrderByRelationAggregateInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisOrderByWithRelationInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionOrderByRelationAggregateInputSchema).optional(),
  Simulation: z.lazy(() => SimulationOrderByRelationAggregateInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageOrderByRelationAggregateInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerOrderByRelationAggregateInputSchema).optional()
}).strict();

export const CaseWhereUniqueInputSchema: z.ZodType<Prisma.CaseWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    referenceNo: z.number().int()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    referenceNo: z.number().int(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  AND: z.union([ z.lazy(() => CaseWhereInputSchema),z.lazy(() => CaseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseWhereInputSchema),z.lazy(() => CaseWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  coCustomerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => EnumCasePurposeFilterSchema),z.lazy(() => CasePurposeSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => EnumCaseTypeFilterSchema),z.lazy(() => CaseTypeSchema) ]).optional(),
  status: z.union([ z.lazy(() => EnumCaseStatusFilterSchema),z.lazy(() => CaseStatusSchema) ]).optional(),
  priority: z.union([ z.lazy(() => EnumCasePriorityFilterSchema),z.lazy(() => CasePrioritySchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  customer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  coCustomer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyNullableRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional().nullable(),
  assets: z.lazy(() => AssetListRelationFilterSchema).optional(),
  caseTrace: z.union([ z.lazy(() => CaseTraceNullableRelationFilterSchema),z.lazy(() => CaseTraceWhereInputSchema) ]).optional().nullable(),
  caseCollaborators: z.lazy(() => CaseCollaboratorListRelationFilterSchema).optional(),
  submissions: z.lazy(() => SubmissionListRelationFilterSchema).optional(),
  synthesis: z.union([ z.lazy(() => SynthesisNullableRelationFilterSchema),z.lazy(() => SynthesisWhereInputSchema) ]).optional().nullable(),
  suggestions: z.lazy(() => SuggestionListRelationFilterSchema).optional(),
  Simulation: z.lazy(() => SimulationListRelationFilterSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageListRelationFilterSchema).optional(),
  casePartner: z.lazy(() => CasePartnerListRelationFilterSchema).optional()
}).strict());

export const CaseOrderByWithAggregationInputSchema: z.ZodType<Prisma.CaseOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  coCustomerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  attio_record_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  purpose: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  caseType: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CaseCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CaseAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CaseMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CaseMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CaseSumOrderByAggregateInputSchema).optional()
}).strict();

export const CaseScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CaseScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CaseScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  referenceNo: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  coCustomerId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => EnumCasePurposeWithAggregatesFilterSchema),z.lazy(() => CasePurposeSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => EnumCaseTypeWithAggregatesFilterSchema),z.lazy(() => CaseTypeSchema) ]).optional(),
  status: z.union([ z.lazy(() => EnumCaseStatusWithAggregatesFilterSchema),z.lazy(() => CaseStatusSchema) ]).optional(),
  priority: z.union([ z.lazy(() => EnumCasePriorityWithAggregatesFilterSchema),z.lazy(() => CasePrioritySchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseCollaboratorWhereInputSchema: z.ZodType<Prisma.CaseCollaboratorWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseCollaboratorWhereInputSchema),z.lazy(() => CaseCollaboratorWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseCollaboratorWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseCollaboratorWhereInputSchema),z.lazy(() => CaseCollaboratorWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  collaborator: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorOrderByWithRelationInputSchema: z.ZodType<Prisma.CaseCollaboratorOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional(),
  collaborator: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const CaseCollaboratorWhereUniqueInputSchema: z.ZodType<Prisma.CaseCollaboratorWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CaseCollaboratorWhereInputSchema),z.lazy(() => CaseCollaboratorWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseCollaboratorWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseCollaboratorWhereInputSchema),z.lazy(() => CaseCollaboratorWhereInputSchema).array() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  collaborator: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const CaseCollaboratorOrderByWithAggregationInputSchema: z.ZodType<Prisma.CaseCollaboratorOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CaseCollaboratorCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CaseCollaboratorMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CaseCollaboratorMinOrderByAggregateInputSchema).optional()
}).strict();

export const CaseCollaboratorScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CaseCollaboratorScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CaseCollaboratorScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseCollaboratorScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseCollaboratorScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseCollaboratorScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseCollaboratorScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseTraceWhereInputSchema: z.ZodType<Prisma.CaseTraceWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseTraceWhereInputSchema),z.lazy(() => CaseTraceWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseTraceWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseTraceWhereInputSchema),z.lazy(() => CaseTraceWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  affiliateId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  answersInput: z.lazy(() => JsonFilterSchema).optional(),
  contactInput: z.lazy(() => JsonNullableFilterSchema).optional(),
  dbCreateOutput: z.lazy(() => JsonNullableFilterSchema).optional(),
  dbCreateError: z.lazy(() => JsonNullableFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseNullableRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserNullableRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
}).strict();

export const CaseTraceOrderByWithRelationInputSchema: z.ZodType<Prisma.CaseTraceOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  userId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  affiliateId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  answersInput: z.lazy(() => SortOrderSchema).optional(),
  contactInput: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dbCreateError: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const CaseTraceWhereUniqueInputSchema: z.ZodType<Prisma.CaseTraceWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    caseId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    caseId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  caseId: z.string().optional(),
  AND: z.union([ z.lazy(() => CaseTraceWhereInputSchema),z.lazy(() => CaseTraceWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseTraceWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseTraceWhereInputSchema),z.lazy(() => CaseTraceWhereInputSchema).array() ]).optional(),
  userId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  affiliateId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  answersInput: z.lazy(() => JsonFilterSchema).optional(),
  contactInput: z.lazy(() => JsonNullableFilterSchema).optional(),
  dbCreateOutput: z.lazy(() => JsonNullableFilterSchema).optional(),
  dbCreateError: z.lazy(() => JsonNullableFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseNullableRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional().nullable(),
  user: z.union([ z.lazy(() => UserNullableRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
}).strict());

export const CaseTraceOrderByWithAggregationInputSchema: z.ZodType<Prisma.CaseTraceOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  userId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  affiliateId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  answersInput: z.lazy(() => SortOrderSchema).optional(),
  contactInput: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  dbCreateError: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CaseTraceCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CaseTraceMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CaseTraceMinOrderByAggregateInputSchema).optional()
}).strict();

export const CaseTraceScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CaseTraceScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CaseTraceScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseTraceScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseTraceScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseTraceScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseTraceScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  affiliateId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  answersInput: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  contactInput: z.lazy(() => JsonNullableWithAggregatesFilterSchema).optional(),
  dbCreateOutput: z.lazy(() => JsonNullableWithAggregatesFilterSchema).optional(),
  dbCreateError: z.lazy(() => JsonNullableWithAggregatesFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseMessageWhereInputSchema: z.ZodType<Prisma.CaseMessageWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseMessageWhereInputSchema),z.lazy(() => CaseMessageWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseMessageWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseMessageWhereInputSchema),z.lazy(() => CaseMessageWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderRole: z.union([ z.lazy(() => EnumUserRoleFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  model: z.union([ z.lazy(() => EnumCaseMessageModelFilterSchema),z.lazy(() => CaseMessageModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumCaseMessageStatusFilterSchema),z.lazy(() => CaseMessageStatusSchema) ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  sender: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const CaseMessageOrderByWithRelationInputSchema: z.ZodType<Prisma.CaseMessageOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  senderRole: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  modelData: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional(),
  sender: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const CaseMessageWhereUniqueInputSchema: z.ZodType<Prisma.CaseMessageWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CaseMessageWhereInputSchema),z.lazy(() => CaseMessageWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseMessageWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseMessageWhereInputSchema),z.lazy(() => CaseMessageWhereInputSchema).array() ]).optional(),
  senderRole: z.union([ z.lazy(() => EnumUserRoleFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  model: z.union([ z.lazy(() => EnumCaseMessageModelFilterSchema),z.lazy(() => CaseMessageModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumCaseMessageStatusFilterSchema),z.lazy(() => CaseMessageStatusSchema) ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  sender: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const CaseMessageOrderByWithAggregationInputSchema: z.ZodType<Prisma.CaseMessageOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  senderRole: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  modelData: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CaseMessageCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CaseMessageMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CaseMessageMinOrderByAggregateInputSchema).optional()
}).strict();

export const CaseMessageScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CaseMessageScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CaseMessageScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseMessageScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseMessageScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseMessageScalarWhereWithAggregatesInputSchema),z.lazy(() => CaseMessageScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  senderRole: z.union([ z.lazy(() => EnumUserRoleWithAggregatesFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  model: z.union([ z.lazy(() => EnumCaseMessageModelWithAggregatesFilterSchema),z.lazy(() => CaseMessageModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumCaseMessageStatusWithAggregatesFilterSchema),z.lazy(() => CaseMessageStatusSchema) ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyWhereInputSchema: z.ZodType<Prisma.CompanyWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCompanyTypeFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  siret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseListRelationFilterSchema).optional(),
  assets: z.lazy(() => AssetListRelationFilterSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeListRelationFilterSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeListRelationFilterSchema).optional(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataListRelationFilterSchema).optional()
}).strict();

export const CompanyOrderByWithRelationInputSchema: z.ZodType<Prisma.CompanyOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseOrderByRelationAggregateInputSchema).optional(),
  assets: z.lazy(() => AssetOrderByRelationAggregateInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeOrderByRelationAggregateInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeOrderByRelationAggregateInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataOrderByRelationAggregateInputSchema).optional()
}).strict();

export const CompanyWhereUniqueInputSchema: z.ZodType<Prisma.CompanyWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyWhereInputSchema),z.lazy(() => CompanyWhereInputSchema).array() ]).optional(),
  type: z.union([ z.lazy(() => EnumCompanyTypeFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  siret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseListRelationFilterSchema).optional(),
  assets: z.lazy(() => AssetListRelationFilterSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeListRelationFilterSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeListRelationFilterSchema).optional(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataListRelationFilterSchema).optional()
}).strict());

export const CompanyOrderByWithAggregationInputSchema: z.ZodType<Prisma.CompanyOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CompanyCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CompanyMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CompanyMinOrderByAggregateInputSchema).optional()
}).strict();

export const CompanyScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CompanyScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCompanyTypeWithAggregatesFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  siret: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableWithAggregatesFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeWhereInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  asset: z.union([ z.lazy(() => AssetRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeOrderByWithRelationInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional(),
  asset: z.lazy(() => AssetOrderByWithRelationInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeWhereUniqueInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  asset: z.union([ z.lazy(() => AssetRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional(),
}).strict());

export const CompanyRealEstateLoanChargeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CompanyRealEstateLoanChargeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CompanyRealEstateLoanChargeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CompanyRealEstateLoanChargeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CompanyRealEstateLoanChargeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CompanyRealEstateLoanChargeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeWithAggregatesFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  rate: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeWithAggregatesFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyRentIncomeWhereInputSchema: z.ZodType<Prisma.CompanyRentIncomeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyRentIncomeWhereInputSchema),z.lazy(() => CompanyRentIncomeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRentIncomeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRentIncomeWhereInputSchema),z.lazy(() => CompanyRentIncomeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  asset: z.union([ z.lazy(() => AssetNullableRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
}).strict();

export const CompanyRentIncomeOrderByWithRelationInputSchema: z.ZodType<Prisma.CompanyRentIncomeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  asset: z.lazy(() => AssetOrderByWithRelationInputSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional()
}).strict();

export const CompanyRentIncomeWhereUniqueInputSchema: z.ZodType<Prisma.CompanyRentIncomeWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    assetId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    assetId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  assetId: z.string().optional(),
  AND: z.union([ z.lazy(() => CompanyRentIncomeWhereInputSchema),z.lazy(() => CompanyRentIncomeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRentIncomeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRentIncomeWhereInputSchema),z.lazy(() => CompanyRentIncomeWhereInputSchema).array() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  asset: z.union([ z.lazy(() => AssetNullableRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
}).strict());

export const CompanyRentIncomeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CompanyRentIncomeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CompanyRentIncomeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CompanyRentIncomeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CompanyRentIncomeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CompanyRentIncomeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CompanyRentIncomeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CompanyRentIncomeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CompanyRentIncomeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyRentIncomeScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyRentIncomeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRentIncomeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRentIncomeScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyRentIncomeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyFinancialDataWhereInputSchema: z.ZodType<Prisma.CompanyFinancialDataWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyFinancialDataWhereInputSchema),z.lazy(() => CompanyFinancialDataWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyFinancialDataWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyFinancialDataWhereInputSchema),z.lazy(() => CompanyFinancialDataWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  exploitationResult: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  depreciationCharge: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  loanInterest: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  balanceSheetSize: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  accountValue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
}).strict();

export const CompanyFinancialDataOrderByWithRelationInputSchema: z.ZodType<Prisma.CompanyFinancialDataOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  depreciationCharge: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  netProfit: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  loanInterest: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  revenue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  equity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  balanceSheetSize: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  liquidity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  accountValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional()
}).strict();

export const CompanyFinancialDataWhereUniqueInputSchema: z.ZodType<Prisma.CompanyFinancialDataWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CompanyFinancialDataWhereInputSchema),z.lazy(() => CompanyFinancialDataWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyFinancialDataWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyFinancialDataWhereInputSchema),z.lazy(() => CompanyFinancialDataWhereInputSchema).array() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  exploitationResult: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  depreciationCharge: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  loanInterest: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  balanceSheetSize: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  accountValue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  company: z.union([ z.lazy(() => CompanyRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
}).strict());

export const CompanyFinancialDataOrderByWithAggregationInputSchema: z.ZodType<Prisma.CompanyFinancialDataOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  depreciationCharge: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  netProfit: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  loanInterest: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  revenue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  equity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  balanceSheetSize: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  liquidity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  accountValue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => CompanyFinancialDataCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CompanyFinancialDataAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CompanyFinancialDataMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CompanyFinancialDataMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CompanyFinancialDataSumOrderByAggregateInputSchema).optional()
}).strict();

export const CompanyFinancialDataScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CompanyFinancialDataScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyFinancialDataScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyFinancialDataScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyFinancialDataScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyFinancialDataScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyFinancialDataScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  exploitationResult: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  depreciationCharge: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  loanInterest: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  balanceSheetSize: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  accountValue: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const CustomerWhereInputSchema: z.ZodType<Prisma.CustomerWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerWhereInputSchema),z.lazy(() => CustomerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerWhereInputSchema),z.lazy(() => CustomerWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  age: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  childrenSupportedCount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  zipcode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  city: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => EnumMaritalStatusNullableFilterSchema),z.lazy(() => MaritalStatusSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => EnumMarriageTypeNullableFilterSchema),z.lazy(() => MarriageTypeSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => EnumJobTypeFilterSchema),z.lazy(() => JobTypeSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => EnumHousingStatusNullableFilterSchema),z.lazy(() => HousingStatusSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserNullableRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  casesAsMainCustomer: z.lazy(() => CaseListRelationFilterSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseListRelationFilterSchema).optional(),
  customerEmployee: z.union([ z.lazy(() => CustomerEmployeeNullableRelationFilterSchema),z.lazy(() => CustomerEmployeeWhereInputSchema) ]).optional().nullable(),
  customerRetired: z.union([ z.lazy(() => CustomerRetiredNullableRelationFilterSchema),z.lazy(() => CustomerRetiredWhereInputSchema) ]).optional().nullable(),
  customerSelfEmployed: z.union([ z.lazy(() => CustomerSelfEmployedNullableRelationFilterSchema),z.lazy(() => CustomerSelfEmployedWhereInputSchema) ]).optional().nullable(),
  customerInvestments: z.lazy(() => CustomerInvestmentListRelationFilterSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeListRelationFilterSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeListRelationFilterSchema).optional(),
  assets: z.lazy(() => AssetListRelationFilterSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeListRelationFilterSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeListRelationFilterSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeListRelationFilterSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeListRelationFilterSchema).optional()
}).strict();

export const CustomerOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  address: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  zipcode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  city: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  country: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  maritalStatus: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  marriagetype: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  jobType: z.lazy(() => SortOrderSchema).optional(),
  housingStatus: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseOrderByRelationAggregateInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseOrderByRelationAggregateInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeOrderByWithRelationInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredOrderByWithRelationInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedOrderByWithRelationInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentOrderByRelationAggregateInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeOrderByRelationAggregateInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeOrderByRelationAggregateInputSchema).optional(),
  assets: z.lazy(() => AssetOrderByRelationAggregateInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeOrderByRelationAggregateInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeOrderByRelationAggregateInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeOrderByRelationAggregateInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeOrderByRelationAggregateInputSchema).optional()
}).strict();

export const CustomerWhereUniqueInputSchema: z.ZodType<Prisma.CustomerWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerWhereInputSchema),z.lazy(() => CustomerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerWhereInputSchema),z.lazy(() => CustomerWhereInputSchema).array() ]).optional(),
  age: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  childrenSupportedCount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  zipcode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  city: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => EnumMaritalStatusNullableFilterSchema),z.lazy(() => MaritalStatusSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => EnumMarriageTypeNullableFilterSchema),z.lazy(() => MarriageTypeSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => EnumJobTypeFilterSchema),z.lazy(() => JobTypeSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => EnumHousingStatusNullableFilterSchema),z.lazy(() => HousingStatusSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserNullableRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional().nullable(),
  casesAsMainCustomer: z.lazy(() => CaseListRelationFilterSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseListRelationFilterSchema).optional(),
  customerEmployee: z.union([ z.lazy(() => CustomerEmployeeNullableRelationFilterSchema),z.lazy(() => CustomerEmployeeWhereInputSchema) ]).optional().nullable(),
  customerRetired: z.union([ z.lazy(() => CustomerRetiredNullableRelationFilterSchema),z.lazy(() => CustomerRetiredWhereInputSchema) ]).optional().nullable(),
  customerSelfEmployed: z.union([ z.lazy(() => CustomerSelfEmployedNullableRelationFilterSchema),z.lazy(() => CustomerSelfEmployedWhereInputSchema) ]).optional().nullable(),
  customerInvestments: z.lazy(() => CustomerInvestmentListRelationFilterSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeListRelationFilterSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeListRelationFilterSchema).optional(),
  assets: z.lazy(() => AssetListRelationFilterSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeListRelationFilterSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeListRelationFilterSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeListRelationFilterSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeListRelationFilterSchema).optional()
}).strict());

export const CustomerOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  address: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  zipcode: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  city: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  country: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  maritalStatus: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  marriagetype: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  jobType: z.lazy(() => SortOrderSchema).optional(),
  housingStatus: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  age: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  childrenSupportedCount: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  zipcode: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  city: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => EnumMaritalStatusNullableWithAggregatesFilterSchema),z.lazy(() => MaritalStatusSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => EnumMarriageTypeNullableWithAggregatesFilterSchema),z.lazy(() => MarriageTypeSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => EnumJobTypeWithAggregatesFilterSchema),z.lazy(() => JobTypeSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => EnumHousingStatusNullableWithAggregatesFilterSchema),z.lazy(() => HousingStatusSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CustomerEmployeeWhereInputSchema: z.ZodType<Prisma.CustomerEmployeeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerEmployeeWhereInputSchema),z.lazy(() => CustomerEmployeeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerEmployeeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerEmployeeWhereInputSchema),z.lazy(() => CustomerEmployeeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerEmployeeTypeFilterSchema),z.lazy(() => CustomerEmployeeTypeSchema) ]).optional(),
  isTrialing: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerEmployeeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerEmployeeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  isTrialing: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerEmployeeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerEmployeeWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    customerId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    customerId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  customerId: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerEmployeeWhereInputSchema),z.lazy(() => CustomerEmployeeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerEmployeeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerEmployeeWhereInputSchema),z.lazy(() => CustomerEmployeeWhereInputSchema).array() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerEmployeeTypeFilterSchema),z.lazy(() => CustomerEmployeeTypeSchema) ]).optional(),
  isTrialing: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerEmployeeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerEmployeeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  isTrialing: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerEmployeeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerEmployeeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerEmployeeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerEmployeeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerEmployeeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerEmployeeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerEmployeeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerEmployeeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerEmployeeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerEmployeeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerEmployeeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerEmployeeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerEmployeeTypeWithAggregatesFilterSchema),z.lazy(() => CustomerEmployeeTypeSchema) ]).optional(),
  isTrialing: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
  monthlyIncome: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerRetiredWhereInputSchema: z.ZodType<Prisma.CustomerRetiredWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRetiredWhereInputSchema),z.lazy(() => CustomerRetiredWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRetiredWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRetiredWhereInputSchema),z.lazy(() => CustomerRetiredWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyPensionIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerRetiredOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerRetiredWhereUniqueInputSchema: z.ZodType<Prisma.CustomerRetiredWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    customerId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    customerId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  customerId: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerRetiredWhereInputSchema),z.lazy(() => CustomerRetiredWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRetiredWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRetiredWhereInputSchema),z.lazy(() => CustomerRetiredWhereInputSchema).array() ]).optional(),
  monthlyPensionIncome: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerRetiredOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerRetiredOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerRetiredCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerRetiredAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerRetiredMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerRetiredMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerRetiredSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerRetiredScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerRetiredScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRetiredScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRetiredScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRetiredScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRetiredScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRetiredScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyPensionIncome: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerSelfEmployedWhereInputSchema: z.ZodType<Prisma.CustomerSelfEmployedWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerSelfEmployedWhereInputSchema),z.lazy(() => CustomerSelfEmployedWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerSelfEmployedWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerSelfEmployedWhereInputSchema),z.lazy(() => CustomerSelfEmployedWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerSelfEmployedOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  hasEnoughAnnualReviews: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerSelfEmployedWhereUniqueInputSchema: z.ZodType<Prisma.CustomerSelfEmployedWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    customerId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    customerId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  customerId: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerSelfEmployedWhereInputSchema),z.lazy(() => CustomerSelfEmployedWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerSelfEmployedWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerSelfEmployedWhereInputSchema),z.lazy(() => CustomerSelfEmployedWhereInputSchema).array() ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerSelfEmployedOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerSelfEmployedOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  hasEnoughAnnualReviews: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerSelfEmployedCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerSelfEmployedAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerSelfEmployedMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerSelfEmployedMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerSelfEmployedSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerSelfEmployedScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerSelfEmployedScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerSelfEmployedScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerSelfEmployedScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerSelfEmployedScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerSelfEmployedScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerSelfEmployedScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
}).strict();

export const CustomerInvestmentWhereInputSchema: z.ZodType<Prisma.CustomerInvestmentWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerInvestmentWhereInputSchema),z.lazy(() => CustomerInvestmentWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerInvestmentWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerInvestmentWhereInputSchema),z.lazy(() => CustomerInvestmentWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  amount: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerInvestmentOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerInvestmentWhereUniqueInputSchema: z.ZodType<Prisma.CustomerInvestmentWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerInvestmentWhereInputSchema),z.lazy(() => CustomerInvestmentWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerInvestmentWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerInvestmentWhereInputSchema),z.lazy(() => CustomerInvestmentWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  amount: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerInvestmentOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerInvestmentOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerInvestmentCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerInvestmentAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerInvestmentMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerInvestmentMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerInvestmentSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerInvestmentScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerInvestmentScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerInvestmentScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerInvestmentScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerInvestmentScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerInvestmentScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerInvestmentScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  amount: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerAdditionalIncomeWhereInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalIncomeTypeFilterSchema),z.lazy(() => CustomerAdditionalIncomeTypeSchema) ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerAdditionalIncomeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalIncomeTypeFilterSchema),z.lazy(() => CustomerAdditionalIncomeTypeSchema) ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerAdditionalIncomeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerAdditionalIncomeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerAdditionalIncomeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerAdditionalIncomeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerAdditionalIncomeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerAdditionalIncomeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalIncomeTypeWithAggregatesFilterSchema),z.lazy(() => CustomerAdditionalIncomeTypeSchema) ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerRentIncomeWhereInputSchema: z.ZodType<Prisma.CustomerRentIncomeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRentIncomeWhereInputSchema),z.lazy(() => CustomerRentIncomeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentIncomeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentIncomeWhereInputSchema),z.lazy(() => CustomerRentIncomeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  asset: z.union([ z.lazy(() => AssetNullableRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional().nullable(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerRentIncomeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  asset: z.lazy(() => AssetOrderByWithRelationInputSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerRentIncomeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerRentIncomeWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    assetId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    assetId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  assetId: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerRentIncomeWhereInputSchema),z.lazy(() => CustomerRentIncomeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentIncomeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentIncomeWhereInputSchema),z.lazy(() => CustomerRentIncomeWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  asset: z.union([ z.lazy(() => AssetNullableRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional().nullable(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerRentIncomeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerRentIncomeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerRentIncomeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerRentIncomeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerRentIncomeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerRentIncomeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerRentIncomeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerRentIncomeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerRentIncomeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRentIncomeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRentIncomeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentIncomeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentIncomeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRentIncomeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerRentChargeWhereInputSchema: z.ZodType<Prisma.CustomerRentChargeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRentChargeWhereInputSchema),z.lazy(() => CustomerRentChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentChargeWhereInputSchema),z.lazy(() => CustomerRentChargeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerRentChargeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerRentChargeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerRentChargeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerRentChargeWhereInputSchema),z.lazy(() => CustomerRentChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentChargeWhereInputSchema),z.lazy(() => CustomerRentChargeWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerRentChargeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerRentChargeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerRentChargeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerRentChargeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerRentChargeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerRentChargeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerRentChargeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerRentChargeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerRentChargeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRentChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRentChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentChargeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRentChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeWhereInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  Customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  Customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  Customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerConsumerLoanChargeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerConsumerLoanChargeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerConsumerLoanChargeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerConsumerLoanChargeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerConsumerLoanChargeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerConsumerLoanChargeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeWhereInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  asset: z.union([ z.lazy(() => AssetNullableRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional().nullable(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  asset: z.lazy(() => AssetOrderByWithRelationInputSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  asset: z.union([ z.lazy(() => AssetNullableRelationFilterSchema),z.lazy(() => AssetWhereInputSchema) ]).optional().nullable(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerRealEstateLoanChargeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerRealEstateLoanChargeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerRealEstateLoanChargeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerRealEstateLoanChargeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerRealEstateLoanChargeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerRealEstateLoanChargeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeWithAggregatesFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeWithAggregatesFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeWhereInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerAdditionalChargeWhereInputSchema),z.lazy(() => CustomerAdditionalChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalChargeWhereInputSchema),z.lazy(() => CustomerAdditionalChargeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalChargeTypeFilterSchema),z.lazy(() => CustomerAdditionalChargeTypeSchema) ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeOrderByWithRelationInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional()
}).strict();

export const CustomerAdditionalChargeWhereUniqueInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => CustomerAdditionalChargeWhereInputSchema),z.lazy(() => CustomerAdditionalChargeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalChargeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalChargeWhereInputSchema),z.lazy(() => CustomerAdditionalChargeWhereInputSchema).array() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalChargeTypeFilterSchema),z.lazy(() => CustomerAdditionalChargeTypeSchema) ]).optional(),
  customer: z.union([ z.lazy(() => CustomerRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
}).strict());

export const CustomerAdditionalChargeOrderByWithAggregationInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CustomerAdditionalChargeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CustomerAdditionalChargeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CustomerAdditionalChargeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CustomerAdditionalChargeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CustomerAdditionalChargeSumOrderByAggregateInputSchema).optional()
}).strict();

export const CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema),z.lazy(() => CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalChargeTypeWithAggregatesFilterSchema),z.lazy(() => CustomerAdditionalChargeTypeSchema) ]).optional(),
}).strict();

export const NotificationWhereInputSchema: z.ZodType<Prisma.NotificationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => NotificationWhereInputSchema),z.lazy(() => NotificationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => NotificationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => NotificationWhereInputSchema),z.lazy(() => NotificationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  receiverId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  model: z.union([ z.lazy(() => EnumNotificationModelFilterSchema),z.lazy(() => NotificationModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumNotificationStatusFilterSchema),z.lazy(() => NotificationStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  receiver: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  sender: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const NotificationOrderByWithRelationInputSchema: z.ZodType<Prisma.NotificationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  receiverId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  modelData: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  receiver: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  sender: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const NotificationWhereUniqueInputSchema: z.ZodType<Prisma.NotificationWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => NotificationWhereInputSchema),z.lazy(() => NotificationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => NotificationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => NotificationWhereInputSchema),z.lazy(() => NotificationWhereInputSchema).array() ]).optional(),
  receiverId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  model: z.union([ z.lazy(() => EnumNotificationModelFilterSchema),z.lazy(() => NotificationModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumNotificationStatusFilterSchema),z.lazy(() => NotificationStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  receiver: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  sender: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const NotificationOrderByWithAggregationInputSchema: z.ZodType<Prisma.NotificationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  receiverId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  modelData: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => NotificationCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => NotificationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => NotificationMinOrderByAggregateInputSchema).optional()
}).strict();

export const NotificationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.NotificationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => NotificationScalarWhereWithAggregatesInputSchema),z.lazy(() => NotificationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => NotificationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => NotificationScalarWhereWithAggregatesInputSchema),z.lazy(() => NotificationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  receiverId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  model: z.union([ z.lazy(() => EnumNotificationModelWithAggregatesFilterSchema),z.lazy(() => NotificationModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonWithAggregatesFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumNotificationStatusWithAggregatesFilterSchema),z.lazy(() => NotificationStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyPappersWhereInputSchema: z.ZodType<Prisma.CompanyPappersWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyPappersWhereInputSchema),z.lazy(() => CompanyPappersWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyPappersWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyPappersWhereInputSchema),z.lazy(() => CompanyPappersWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  partnerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  formatedSiret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  formatedSiren: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  creationDate: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  isActive: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  legalStatus: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  tvaNumber: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rcsNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  capital: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  primaryActivity: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  nafCode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  nafCodeLabel: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  activity: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  exerciseForm: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workForce: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workForceYear: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  financialYearEndDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyNullableRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional().nullable(),
  partner: z.union([ z.lazy(() => PartnerNullableRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional().nullable(),
  representatives: z.lazy(() => PappersRepresentativeListRelationFilterSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryListRelationFilterSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionListRelationFilterSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureListRelationFilterSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataListRelationFilterSchema).optional()
}).strict();

export const CompanyPappersOrderByWithRelationInputSchema: z.ZodType<Prisma.CompanyPappersOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  partnerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  formatedSiret: z.lazy(() => SortOrderSchema).optional(),
  formatedSiren: z.lazy(() => SortOrderSchema).optional(),
  creationDate: z.lazy(() => SortOrderSchema).optional(),
  isActive: z.lazy(() => SortOrderSchema).optional(),
  legalStatus: z.lazy(() => SortOrderSchema).optional(),
  tvaNumber: z.lazy(() => SortOrderSchema).optional(),
  rcsNumber: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  capital: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  primaryActivity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  nafCode: z.lazy(() => SortOrderSchema).optional(),
  nafCodeLabel: z.lazy(() => SortOrderSchema).optional(),
  activity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  exerciseForm: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  workForce: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  workForceYear: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  financialYearEndDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  company: z.lazy(() => CompanyOrderByWithRelationInputSchema).optional(),
  partner: z.lazy(() => PartnerOrderByWithRelationInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeOrderByRelationAggregateInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryOrderByRelationAggregateInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionOrderByRelationAggregateInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureOrderByRelationAggregateInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataOrderByRelationAggregateInputSchema).optional()
}).strict();

export const CompanyPappersWhereUniqueInputSchema: z.ZodType<Prisma.CompanyPappersWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    companyId: z.string(),
    partnerId: z.string()
  }),
  z.object({
    id: z.string(),
    companyId: z.string(),
  }),
  z.object({
    id: z.string(),
    partnerId: z.string(),
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    companyId: z.string(),
    partnerId: z.string(),
  }),
  z.object({
    companyId: z.string(),
  }),
  z.object({
    partnerId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  companyId: z.string().optional(),
  partnerId: z.string().optional(),
  AND: z.union([ z.lazy(() => CompanyPappersWhereInputSchema),z.lazy(() => CompanyPappersWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyPappersWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyPappersWhereInputSchema),z.lazy(() => CompanyPappersWhereInputSchema).array() ]).optional(),
  formatedSiret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  formatedSiren: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  creationDate: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  isActive: z.union([ z.lazy(() => BoolFilterSchema),z.boolean() ]).optional(),
  legalStatus: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  tvaNumber: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rcsNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  capital: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  primaryActivity: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  nafCode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  nafCodeLabel: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  activity: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  exerciseForm: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workForce: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workForceYear: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  financialYearEndDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  company: z.union([ z.lazy(() => CompanyNullableRelationFilterSchema),z.lazy(() => CompanyWhereInputSchema) ]).optional().nullable(),
  partner: z.union([ z.lazy(() => PartnerNullableRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional().nullable(),
  representatives: z.lazy(() => PappersRepresentativeListRelationFilterSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryListRelationFilterSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionListRelationFilterSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureListRelationFilterSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataListRelationFilterSchema).optional()
}).strict());

export const CompanyPappersOrderByWithAggregationInputSchema: z.ZodType<Prisma.CompanyPappersOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  partnerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  formatedSiret: z.lazy(() => SortOrderSchema).optional(),
  formatedSiren: z.lazy(() => SortOrderSchema).optional(),
  creationDate: z.lazy(() => SortOrderSchema).optional(),
  isActive: z.lazy(() => SortOrderSchema).optional(),
  legalStatus: z.lazy(() => SortOrderSchema).optional(),
  tvaNumber: z.lazy(() => SortOrderSchema).optional(),
  rcsNumber: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  capital: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  primaryActivity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  nafCode: z.lazy(() => SortOrderSchema).optional(),
  nafCodeLabel: z.lazy(() => SortOrderSchema).optional(),
  activity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  exerciseForm: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  workForce: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  workForceYear: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  financialYearEndDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CompanyPappersCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => CompanyPappersAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CompanyPappersMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CompanyPappersMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => CompanyPappersSumOrderByAggregateInputSchema).optional()
}).strict();

export const CompanyPappersScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CompanyPappersScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyPappersScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyPappersScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyPappersScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyPappersScalarWhereWithAggregatesInputSchema),z.lazy(() => CompanyPappersScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  partnerId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  formatedSiret: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  formatedSiren: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  creationDate: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  isActive: z.union([ z.lazy(() => BoolWithAggregatesFilterSchema),z.boolean() ]).optional(),
  legalStatus: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  tvaNumber: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  rcsNumber: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  capital: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  primaryActivity: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  nafCode: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  nafCodeLabel: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  activity: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  exerciseForm: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workForce: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workForceYear: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  financialYearEndDate: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PappersRepresentativeWhereInputSchema: z.ZodType<Prisma.PappersRepresentativeWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersRepresentativeWhereInputSchema),z.lazy(() => PappersRepresentativeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersRepresentativeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersRepresentativeWhereInputSchema),z.lazy(() => PappersRepresentativeWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  age: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionListRelationFilterSchema).optional()
}).strict();

export const PappersRepresentativeOrderByWithRelationInputSchema: z.ZodType<Prisma.PappersRepresentativeOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  firstName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  lastName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  age: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  hasOngoingSanction: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional(),
  sanction: z.lazy(() => PappersSanctionOrderByRelationAggregateInputSchema).optional()
}).strict();

export const PappersRepresentativeWhereUniqueInputSchema: z.ZodType<Prisma.PappersRepresentativeWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PappersRepresentativeWhereInputSchema),z.lazy(() => PappersRepresentativeWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersRepresentativeWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersRepresentativeWhereInputSchema),z.lazy(() => PappersRepresentativeWhereInputSchema).array() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  age: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionListRelationFilterSchema).optional()
}).strict());

export const PappersRepresentativeOrderByWithAggregationInputSchema: z.ZodType<Prisma.PappersRepresentativeOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  firstName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  lastName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  age: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  hasOngoingSanction: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => PappersRepresentativeCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PappersRepresentativeAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PappersRepresentativeMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PappersRepresentativeMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PappersRepresentativeSumOrderByAggregateInputSchema).optional()
}).strict();

export const PappersRepresentativeScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PappersRepresentativeScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PappersRepresentativeScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersRepresentativeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersRepresentativeScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersRepresentativeScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersRepresentativeScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  age: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
}).strict();

export const PappersBeneficiaryWhereInputSchema: z.ZodType<Prisma.PappersBeneficiaryWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersBeneficiaryWhereInputSchema),z.lazy(() => PappersBeneficiaryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersBeneficiaryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersBeneficiaryWhereInputSchema),z.lazy(() => PappersBeneficiaryWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  sharesPercentage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  votePercentage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionListRelationFilterSchema).optional()
}).strict();

export const PappersBeneficiaryOrderByWithRelationInputSchema: z.ZodType<Prisma.PappersBeneficiaryOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  firstName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  lastName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  sharesPercentage: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  votePercentage: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  hasOngoingSanction: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional(),
  sanction: z.lazy(() => PappersSanctionOrderByRelationAggregateInputSchema).optional()
}).strict();

export const PappersBeneficiaryWhereUniqueInputSchema: z.ZodType<Prisma.PappersBeneficiaryWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PappersBeneficiaryWhereInputSchema),z.lazy(() => PappersBeneficiaryWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersBeneficiaryWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersBeneficiaryWhereInputSchema),z.lazy(() => PappersBeneficiaryWhereInputSchema).array() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  sharesPercentage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  votePercentage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionListRelationFilterSchema).optional()
}).strict());

export const PappersBeneficiaryOrderByWithAggregationInputSchema: z.ZodType<Prisma.PappersBeneficiaryOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  firstName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  lastName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  sharesPercentage: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  votePercentage: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  hasOngoingSanction: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => PappersBeneficiaryCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PappersBeneficiaryAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PappersBeneficiaryMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PappersBeneficiaryMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PappersBeneficiarySumOrderByAggregateInputSchema).optional()
}).strict();

export const PappersBeneficiaryScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PappersBeneficiaryScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PappersBeneficiaryScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersBeneficiaryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersBeneficiaryScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersBeneficiaryScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersBeneficiaryScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  sharesPercentage: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  votePercentage: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionWhereInputSchema: z.ZodType<Prisma.PappersCollectiveConventionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersCollectiveConventionWhereInputSchema),z.lazy(() => PappersCollectiveConventionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveConventionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveConventionWhereInputSchema),z.lazy(() => PappersCollectiveConventionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  idcc: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionOrderByWithRelationInputSchema: z.ZodType<Prisma.PappersCollectiveConventionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  idcc: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional()
}).strict();

export const PappersCollectiveConventionWhereUniqueInputSchema: z.ZodType<Prisma.PappersCollectiveConventionWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PappersCollectiveConventionWhereInputSchema),z.lazy(() => PappersCollectiveConventionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveConventionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveConventionWhereInputSchema),z.lazy(() => PappersCollectiveConventionWhereInputSchema).array() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  idcc: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
}).strict());

export const PappersCollectiveConventionOrderByWithAggregationInputSchema: z.ZodType<Prisma.PappersCollectiveConventionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  name: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  idcc: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => PappersCollectiveConventionCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PappersCollectiveConventionAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PappersCollectiveConventionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PappersCollectiveConventionMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PappersCollectiveConventionSumOrderByAggregateInputSchema).optional()
}).strict();

export const PappersCollectiveConventionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PappersCollectiveConventionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PappersCollectiveConventionScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersCollectiveConventionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveConventionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveConventionScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersCollectiveConventionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  idcc: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureWhereInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersCollectiveProcedureWhereInputSchema),z.lazy(() => PappersCollectiveProcedureWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveProcedureWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveProcedureWhereInputSchema),z.lazy(() => PappersCollectiveProcedureWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureOrderByWithRelationInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  startDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional()
}).strict();

export const PappersCollectiveProcedureWhereUniqueInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PappersCollectiveProcedureWhereInputSchema),z.lazy(() => PappersCollectiveProcedureWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveProcedureWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveProcedureWhereInputSchema),z.lazy(() => PappersCollectiveProcedureWhereInputSchema).array() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
}).strict());

export const PappersCollectiveProcedureOrderByWithAggregationInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  type: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  startDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => PappersCollectiveProcedureCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PappersCollectiveProcedureMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PappersCollectiveProcedureMinOrderByAggregateInputSchema).optional()
}).strict();

export const PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PappersFinancialDataWhereInputSchema: z.ZodType<Prisma.PappersFinancialDataWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersFinancialDataWhereInputSchema),z.lazy(() => PappersFinancialDataWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersFinancialDataWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersFinancialDataWhereInputSchema),z.lazy(() => PappersFinancialDataWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  operatingResults: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  charges: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  repaymentCapacity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataOrderByWithRelationInputSchema: z.ZodType<Prisma.PappersFinancialDataOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  year: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  revenue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  operatingResults: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  liquidity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  charges: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  repaymentCapacity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  equity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  netProfit: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional()
}).strict();

export const PappersFinancialDataWhereUniqueInputSchema: z.ZodType<Prisma.PappersFinancialDataWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PappersFinancialDataWhereInputSchema),z.lazy(() => PappersFinancialDataWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersFinancialDataWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersFinancialDataWhereInputSchema),z.lazy(() => PappersFinancialDataWhereInputSchema).array() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntNullableFilterSchema),z.number().int() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  operatingResults: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  charges: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  repaymentCapacity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  companyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
}).strict());

export const PappersFinancialDataOrderByWithAggregationInputSchema: z.ZodType<Prisma.PappersFinancialDataOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  year: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  revenue: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  operatingResults: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  liquidity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  charges: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  repaymentCapacity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  equity: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  netProfit: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => PappersFinancialDataCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PappersFinancialDataAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PappersFinancialDataMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PappersFinancialDataMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PappersFinancialDataSumOrderByAggregateInputSchema).optional()
}).strict();

export const PappersFinancialDataScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PappersFinancialDataScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PappersFinancialDataScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersFinancialDataScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersFinancialDataScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersFinancialDataScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersFinancialDataScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  operatingResults: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  charges: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  repaymentCapacity: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const PappersSanctionWhereInputSchema: z.ZodType<Prisma.PappersSanctionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersSanctionWhereInputSchema),z.lazy(() => PappersSanctionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersSanctionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersSanctionWhereInputSchema),z.lazy(() => PappersSanctionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  pappersRepresentativeId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  pappersBeneficiaryId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  autority: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isOngoing: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  pappersRepresentative: z.union([ z.lazy(() => PappersRepresentativeNullableRelationFilterSchema),z.lazy(() => PappersRepresentativeWhereInputSchema) ]).optional().nullable(),
  pappersBeneficiary: z.union([ z.lazy(() => PappersBeneficiaryNullableRelationFilterSchema),z.lazy(() => PappersBeneficiaryWhereInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionOrderByWithRelationInputSchema: z.ZodType<Prisma.PappersSanctionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  pappersRepresentativeId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  pappersBeneficiaryId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  description: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  autority: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isOngoing: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  startDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  pappersRepresentative: z.lazy(() => PappersRepresentativeOrderByWithRelationInputSchema).optional(),
  pappersBeneficiary: z.lazy(() => PappersBeneficiaryOrderByWithRelationInputSchema).optional()
}).strict();

export const PappersSanctionWhereUniqueInputSchema: z.ZodType<Prisma.PappersSanctionWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PappersSanctionWhereInputSchema),z.lazy(() => PappersSanctionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersSanctionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersSanctionWhereInputSchema),z.lazy(() => PappersSanctionWhereInputSchema).array() ]).optional(),
  pappersRepresentativeId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  pappersBeneficiaryId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  autority: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isOngoing: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  pappersRepresentative: z.union([ z.lazy(() => PappersRepresentativeNullableRelationFilterSchema),z.lazy(() => PappersRepresentativeWhereInputSchema) ]).optional().nullable(),
  pappersBeneficiary: z.union([ z.lazy(() => PappersBeneficiaryNullableRelationFilterSchema),z.lazy(() => PappersBeneficiaryWhereInputSchema) ]).optional().nullable(),
}).strict());

export const PappersSanctionOrderByWithAggregationInputSchema: z.ZodType<Prisma.PappersSanctionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  pappersRepresentativeId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  pappersBeneficiaryId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  description: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  autority: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  isOngoing: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  startDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => PappersSanctionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PappersSanctionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PappersSanctionMinOrderByAggregateInputSchema).optional()
}).strict();

export const PappersSanctionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PappersSanctionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PappersSanctionScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersSanctionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersSanctionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersSanctionScalarWhereWithAggregatesInputSchema),z.lazy(() => PappersSanctionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  pappersRepresentativeId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  pappersBeneficiaryId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  description: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  autority: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  isOngoing: z.union([ z.lazy(() => BoolNullableWithAggregatesFilterSchema),z.boolean() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PartnerWhereInputSchema: z.ZodType<Prisma.PartnerWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerWhereInputSchema),z.lazy(() => PartnerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerWhereInputSchema),z.lazy(() => PartnerWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  affiliateId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumPartnerStatusFilterSchema),z.lazy(() => PartnerStatusSchema) ]).optional(),
  profession: z.union([ z.lazy(() => EnumPartnerProfessionFilterSchema),z.lazy(() => PartnerProfessionSchema) ]).optional(),
  otherProfession: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => EnumPartnerLoanVolumeFilterSchema),z.lazy(() => PartnerLoanVolumeSchema) ]).optional(),
  clientTypes: z.lazy(() => EnumPartnerClientTypeNullableListFilterSchema).optional(),
  usageCases: z.lazy(() => EnumPartnerUsageCasesNullableListFilterSchema).optional(),
  siret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companySize: z.union([ z.lazy(() => EnumPartnerCompanySizeFilterSchema),z.lazy(() => PartnerCompanySizeSchema) ]).optional(),
  orias: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorListRelationFilterSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateListRelationFilterSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerListRelationFilterSchema).optional(),
  partnerCompanyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseListRelationFilterSchema).optional()
}).strict();

export const PartnerOrderByWithRelationInputSchema: z.ZodType<Prisma.PartnerOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  profession: z.lazy(() => SortOrderSchema).optional(),
  otherProfession: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  loanVolume: z.lazy(() => SortOrderSchema).optional(),
  clientTypes: z.lazy(() => SortOrderSchema).optional(),
  usageCases: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companySize: z.lazy(() => SortOrderSchema).optional(),
  orias: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  attio_record_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorOrderByRelationAggregateInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateOrderByRelationAggregateInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerOrderByRelationAggregateInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersOrderByWithRelationInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseOrderByRelationAggregateInputSchema).optional()
}).strict();

export const PartnerWhereUniqueInputSchema: z.ZodType<Prisma.PartnerWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    userId: z.string(),
    affiliateId: z.string()
  }),
  z.object({
    id: z.string(),
    userId: z.string(),
  }),
  z.object({
    id: z.string(),
    affiliateId: z.string(),
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    userId: z.string(),
    affiliateId: z.string(),
  }),
  z.object({
    userId: z.string(),
  }),
  z.object({
    affiliateId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  userId: z.string().optional(),
  affiliateId: z.string().optional(),
  AND: z.union([ z.lazy(() => PartnerWhereInputSchema),z.lazy(() => PartnerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerWhereInputSchema),z.lazy(() => PartnerWhereInputSchema).array() ]).optional(),
  companyName: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumPartnerStatusFilterSchema),z.lazy(() => PartnerStatusSchema) ]).optional(),
  profession: z.union([ z.lazy(() => EnumPartnerProfessionFilterSchema),z.lazy(() => PartnerProfessionSchema) ]).optional(),
  otherProfession: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => EnumPartnerLoanVolumeFilterSchema),z.lazy(() => PartnerLoanVolumeSchema) ]).optional(),
  clientTypes: z.lazy(() => EnumPartnerClientTypeNullableListFilterSchema).optional(),
  usageCases: z.lazy(() => EnumPartnerUsageCasesNullableListFilterSchema).optional(),
  siret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companySize: z.union([ z.lazy(() => EnumPartnerCompanySizeFilterSchema),z.lazy(() => PartnerCompanySizeSchema) ]).optional(),
  orias: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorListRelationFilterSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateListRelationFilterSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerListRelationFilterSchema).optional(),
  partnerCompanyPappers: z.union([ z.lazy(() => CompanyPappersNullableRelationFilterSchema),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional().nullable(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseListRelationFilterSchema).optional()
}).strict());

export const PartnerOrderByWithAggregationInputSchema: z.ZodType<Prisma.PartnerOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  profession: z.lazy(() => SortOrderSchema).optional(),
  otherProfession: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  loanVolume: z.lazy(() => SortOrderSchema).optional(),
  clientTypes: z.lazy(() => SortOrderSchema).optional(),
  usageCases: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companySize: z.lazy(() => SortOrderSchema).optional(),
  orias: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  attio_record_id: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PartnerCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PartnerMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PartnerMinOrderByAggregateInputSchema).optional()
}).strict();

export const PartnerScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PartnerScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  affiliateId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companyName: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumPartnerStatusWithAggregatesFilterSchema),z.lazy(() => PartnerStatusSchema) ]).optional(),
  profession: z.union([ z.lazy(() => EnumPartnerProfessionWithAggregatesFilterSchema),z.lazy(() => PartnerProfessionSchema) ]).optional(),
  otherProfession: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => EnumPartnerLoanVolumeWithAggregatesFilterSchema),z.lazy(() => PartnerLoanVolumeSchema) ]).optional(),
  clientTypes: z.lazy(() => EnumPartnerClientTypeNullableListFilterSchema).optional(),
  usageCases: z.lazy(() => EnumPartnerUsageCasesNullableListFilterSchema).optional(),
  siret: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  companySize: z.union([ z.lazy(() => EnumPartnerCompanySizeWithAggregatesFilterSchema),z.lazy(() => PartnerCompanySizeSchema) ]).optional(),
  orias: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PartnerCollaboratorWhereInputSchema: z.ZodType<Prisma.PartnerCollaboratorWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerCollaboratorWhereInputSchema),z.lazy(() => PartnerCollaboratorWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerCollaboratorWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerCollaboratorWhereInputSchema),z.lazy(() => PartnerCollaboratorWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  collaborator: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorOrderByWithRelationInputSchema: z.ZodType<Prisma.PartnerCollaboratorOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  partner: z.lazy(() => PartnerOrderByWithRelationInputSchema).optional(),
  collaborator: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const PartnerCollaboratorWhereUniqueInputSchema: z.ZodType<Prisma.PartnerCollaboratorWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PartnerCollaboratorWhereInputSchema),z.lazy(() => PartnerCollaboratorWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerCollaboratorWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerCollaboratorWhereInputSchema),z.lazy(() => PartnerCollaboratorWhereInputSchema).array() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  collaborator: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const PartnerCollaboratorOrderByWithAggregationInputSchema: z.ZodType<Prisma.PartnerCollaboratorOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PartnerCollaboratorCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PartnerCollaboratorMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PartnerCollaboratorMinOrderByAggregateInputSchema).optional()
}).strict();

export const PartnerCollaboratorScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PartnerCollaboratorScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerCollaboratorScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PartnerRateWhereInputSchema: z.ZodType<Prisma.PartnerRateWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerRateWhereInputSchema),z.lazy(() => PartnerRateWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerRateWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerRateWhereInputSchema),z.lazy(() => PartnerRateWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdBy: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  casePartner: z.lazy(() => CasePartnerListRelationFilterSchema).optional()
}).strict();

export const PartnerRateOrderByWithRelationInputSchema: z.ZodType<Prisma.PartnerRateOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  createdBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  partner: z.lazy(() => PartnerOrderByWithRelationInputSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerOrderByRelationAggregateInputSchema).optional()
}).strict();

export const PartnerRateWhereUniqueInputSchema: z.ZodType<Prisma.PartnerRateWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PartnerRateWhereInputSchema),z.lazy(() => PartnerRateWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerRateWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerRateWhereInputSchema),z.lazy(() => PartnerRateWhereInputSchema).array() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdBy: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  casePartner: z.lazy(() => CasePartnerListRelationFilterSchema).optional()
}).strict());

export const PartnerRateOrderByWithAggregationInputSchema: z.ZodType<Prisma.PartnerRateOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  createdBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PartnerRateCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PartnerRateAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PartnerRateMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PartnerRateMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PartnerRateSumOrderByAggregateInputSchema).optional()
}).strict();

export const PartnerRateScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PartnerRateScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerRateScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerRateScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerRateScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerRateScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerRateScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  rate: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdBy: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CasePartnerWhereInputSchema: z.ZodType<Prisma.CasePartnerWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CasePartnerWhereInputSchema),z.lazy(() => CasePartnerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CasePartnerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CasePartnerWhereInputSchema),z.lazy(() => CasePartnerWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rateId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  rate: z.union([ z.lazy(() => PartnerRateRelationFilterSchema),z.lazy(() => PartnerRateWhereInputSchema) ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
}).strict();

export const CasePartnerOrderByWithRelationInputSchema: z.ZodType<Prisma.CasePartnerOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  rateId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  partner: z.lazy(() => PartnerOrderByWithRelationInputSchema).optional(),
  rate: z.lazy(() => PartnerRateOrderByWithRelationInputSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional()
}).strict();

export const CasePartnerWhereUniqueInputSchema: z.ZodType<Prisma.CasePartnerWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    caseId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    caseId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  caseId: z.string().optional(),
  AND: z.union([ z.lazy(() => CasePartnerWhereInputSchema),z.lazy(() => CasePartnerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CasePartnerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CasePartnerWhereInputSchema),z.lazy(() => CasePartnerWhereInputSchema).array() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rateId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  rate: z.union([ z.lazy(() => PartnerRateRelationFilterSchema),z.lazy(() => PartnerRateWhereInputSchema) ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
}).strict());

export const CasePartnerOrderByWithAggregationInputSchema: z.ZodType<Prisma.CasePartnerOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  rateId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CasePartnerCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CasePartnerMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CasePartnerMinOrderByAggregateInputSchema).optional()
}).strict();

export const CasePartnerScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CasePartnerScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CasePartnerScalarWhereWithAggregatesInputSchema),z.lazy(() => CasePartnerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CasePartnerScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CasePartnerScalarWhereWithAggregatesInputSchema),z.lazy(() => CasePartnerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  rateId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PartnerPendingCaseWhereInputSchema: z.ZodType<Prisma.PartnerPendingCaseWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerPendingCaseWhereInputSchema),z.lazy(() => PartnerPendingCaseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerPendingCaseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerPendingCaseWhereInputSchema),z.lazy(() => PartnerPendingCaseWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  patrimony: z.union([ z.lazy(() => EnumProspectPatrimonyFilterSchema),z.lazy(() => ProspectPatrimonySchema) ]).optional(),
  product: z.union([ z.lazy(() => EnumProspectProductFilterSchema),z.lazy(() => ProspectProductSchema) ]).optional(),
  amount: z.union([ z.lazy(() => EnumProspectAmountFilterSchema),z.lazy(() => ProspectAmountSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => EnumProspectUrgencyFilterSchema),z.lazy(() => ProspectUrgencySchema) ]).optional(),
  rating: z.union([ z.lazy(() => EnumProspectRatingFilterSchema),z.lazy(() => ProspectRatingSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseOrderByWithRelationInputSchema: z.ZodType<Prisma.PartnerPendingCaseOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  patrimony: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  urgency: z.lazy(() => SortOrderSchema).optional(),
  rating: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  partner: z.lazy(() => PartnerOrderByWithRelationInputSchema).optional()
}).strict();

export const PartnerPendingCaseWhereUniqueInputSchema: z.ZodType<Prisma.PartnerPendingCaseWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PartnerPendingCaseWhereInputSchema),z.lazy(() => PartnerPendingCaseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerPendingCaseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerPendingCaseWhereInputSchema),z.lazy(() => PartnerPendingCaseWhereInputSchema).array() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  patrimony: z.union([ z.lazy(() => EnumProspectPatrimonyFilterSchema),z.lazy(() => ProspectPatrimonySchema) ]).optional(),
  product: z.union([ z.lazy(() => EnumProspectProductFilterSchema),z.lazy(() => ProspectProductSchema) ]).optional(),
  amount: z.union([ z.lazy(() => EnumProspectAmountFilterSchema),z.lazy(() => ProspectAmountSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => EnumProspectUrgencyFilterSchema),z.lazy(() => ProspectUrgencySchema) ]).optional(),
  rating: z.union([ z.lazy(() => EnumProspectRatingFilterSchema),z.lazy(() => ProspectRatingSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  partner: z.union([ z.lazy(() => PartnerRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
}).strict());

export const PartnerPendingCaseOrderByWithAggregationInputSchema: z.ZodType<Prisma.PartnerPendingCaseOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  patrimony: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  urgency: z.lazy(() => SortOrderSchema).optional(),
  rating: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PartnerPendingCaseCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PartnerPendingCaseMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PartnerPendingCaseMinOrderByAggregateInputSchema).optional()
}).strict();

export const PartnerPendingCaseScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PartnerPendingCaseScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerPendingCaseScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerPendingCaseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerPendingCaseScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerPendingCaseScalarWhereWithAggregatesInputSchema),z.lazy(() => PartnerPendingCaseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  patrimony: z.union([ z.lazy(() => EnumProspectPatrimonyWithAggregatesFilterSchema),z.lazy(() => ProspectPatrimonySchema) ]).optional(),
  product: z.union([ z.lazy(() => EnumProspectProductWithAggregatesFilterSchema),z.lazy(() => ProspectProductSchema) ]).optional(),
  amount: z.union([ z.lazy(() => EnumProspectAmountWithAggregatesFilterSchema),z.lazy(() => ProspectAmountSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => EnumProspectUrgencyWithAggregatesFilterSchema),z.lazy(() => ProspectUrgencySchema) ]).optional(),
  rating: z.union([ z.lazy(() => EnumProspectRatingWithAggregatesFilterSchema),z.lazy(() => ProspectRatingSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PendingCaseWhereInputSchema: z.ZodType<Prisma.PendingCaseWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PendingCaseWhereInputSchema),z.lazy(() => PendingCaseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PendingCaseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PendingCaseWhereInputSchema),z.lazy(() => PendingCaseWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumPendingCaseTypeFilterSchema),z.lazy(() => PendingCaseTypeSchema) ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  siret: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => EnumCompanyTypeNullableFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PendingCaseOrderByWithRelationInputSchema: z.ZodType<Prisma.PendingCaseOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  siret: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PendingCaseWhereUniqueInputSchema: z.ZodType<Prisma.PendingCaseWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => PendingCaseWhereInputSchema),z.lazy(() => PendingCaseWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PendingCaseWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PendingCaseWhereInputSchema),z.lazy(() => PendingCaseWhereInputSchema).array() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumPendingCaseTypeFilterSchema),z.lazy(() => PendingCaseTypeSchema) ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  siret: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => EnumCompanyTypeNullableFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict());

export const PendingCaseOrderByWithAggregationInputSchema: z.ZodType<Prisma.PendingCaseOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  siret: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyName: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PendingCaseCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PendingCaseAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PendingCaseMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PendingCaseMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PendingCaseSumOrderByAggregateInputSchema).optional()
}).strict();

export const PendingCaseScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PendingCaseScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PendingCaseScalarWhereWithAggregatesInputSchema),z.lazy(() => PendingCaseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PendingCaseScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PendingCaseScalarWhereWithAggregatesInputSchema),z.lazy(() => PendingCaseScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  description: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumPendingCaseTypeWithAggregatesFilterSchema),z.lazy(() => PendingCaseTypeSchema) ]).optional(),
  firstname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  siret: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  companyName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => EnumCompanyTypeNullableWithAggregatesFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableWithAggregatesFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  phone: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SimulationWhereInputSchema: z.ZodType<Prisma.SimulationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SimulationWhereInputSchema),z.lazy(() => SimulationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SimulationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SimulationWhereInputSchema),z.lazy(() => SimulationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  loanType: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  borrowedCapital: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  remainingMonths: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  expertFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  mandate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  bankFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  guaranteeFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  loansAmountTakenOver: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumSimulationStatusFilterSchema),z.lazy(() => SimulationStatusSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => EnumRejectionMotiveNullableFilterSchema),z.lazy(() => RejectionMotiveSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  contribution: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  author: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
}).strict();

export const SimulationOrderByWithRelationInputSchema: z.ZodType<Prisma.SimulationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  loanType: z.lazy(() => SortOrderSchema).optional(),
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  rejectionMotive: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  rejectionReason: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  statusUpdatedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  author: z.lazy(() => UserOrderByWithRelationInputSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional()
}).strict();

export const SimulationWhereUniqueInputSchema: z.ZodType<Prisma.SimulationWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => SimulationWhereInputSchema),z.lazy(() => SimulationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SimulationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SimulationWhereInputSchema),z.lazy(() => SimulationWhereInputSchema).array() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  loanType: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  borrowedCapital: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  remainingMonths: z.union([ z.lazy(() => IntFilterSchema),z.number().int() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  expertFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  mandate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  bankFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  guaranteeFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  loansAmountTakenOver: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumSimulationStatusFilterSchema),z.lazy(() => SimulationStatusSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => EnumRejectionMotiveNullableFilterSchema),z.lazy(() => RejectionMotiveSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  contribution: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  author: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
}).strict());

export const SimulationOrderByWithAggregationInputSchema: z.ZodType<Prisma.SimulationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  loanType: z.lazy(() => SortOrderSchema).optional(),
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  rejectionMotive: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  rejectionReason: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  statusUpdatedAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SimulationCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => SimulationAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SimulationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SimulationMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => SimulationSumOrderByAggregateInputSchema).optional()
}).strict();

export const SimulationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SimulationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SimulationScalarWhereWithAggregatesInputSchema),z.lazy(() => SimulationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SimulationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SimulationScalarWhereWithAggregatesInputSchema),z.lazy(() => SimulationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  loanType: z.union([ z.lazy(() => EnumLoanTypeWithAggregatesFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  borrowedCapital: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  remainingMonths: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  rate: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  expertFees: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  mandate: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  bankFees: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  guaranteeFees: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  loansAmountTakenOver: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumSimulationStatusWithAggregatesFilterSchema),z.lazy(() => SimulationStatusSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => EnumRejectionMotiveNullableWithAggregatesFilterSchema),z.lazy(() => RejectionMotiveSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  contribution: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SubmissionWhereInputSchema: z.ZodType<Prisma.SubmissionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SubmissionWhereInputSchema),z.lazy(() => SubmissionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubmissionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubmissionWhereInputSchema),z.lazy(() => SubmissionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bankId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  product: z.union([ z.lazy(() => EnumProductFilterSchema),z.lazy(() => ProductSchema) ]).optional(),
  reason: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  comment: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSubmissionStatusFilterSchema),z.lazy(() => SubmissionStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  bank: z.union([ z.lazy(() => BankRelationFilterSchema),z.lazy(() => BankWhereInputSchema) ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const SubmissionOrderByWithRelationInputSchema: z.ZodType<Prisma.SubmissionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  bankId: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  bank: z.lazy(() => BankOrderByWithRelationInputSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const SubmissionWhereUniqueInputSchema: z.ZodType<Prisma.SubmissionWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => SubmissionWhereInputSchema),z.lazy(() => SubmissionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubmissionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubmissionWhereInputSchema),z.lazy(() => SubmissionWhereInputSchema).array() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bankId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  product: z.union([ z.lazy(() => EnumProductFilterSchema),z.lazy(() => ProductSchema) ]).optional(),
  reason: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  comment: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSubmissionStatusFilterSchema),z.lazy(() => SubmissionStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  bank: z.union([ z.lazy(() => BankRelationFilterSchema),z.lazy(() => BankWhereInputSchema) ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const SubmissionOrderByWithAggregationInputSchema: z.ZodType<Prisma.SubmissionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  bankId: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SubmissionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SubmissionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SubmissionMinOrderByAggregateInputSchema).optional()
}).strict();

export const SubmissionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SubmissionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SubmissionScalarWhereWithAggregatesInputSchema),z.lazy(() => SubmissionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubmissionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubmissionScalarWhereWithAggregatesInputSchema),z.lazy(() => SubmissionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  bankId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  product: z.union([ z.lazy(() => EnumProductWithAggregatesFilterSchema),z.lazy(() => ProductSchema) ]).optional(),
  reason: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  comment: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSubmissionStatusWithAggregatesFilterSchema),z.lazy(() => SubmissionStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SuggestionWhereInputSchema: z.ZodType<Prisma.SuggestionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SuggestionWhereInputSchema),z.lazy(() => SuggestionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SuggestionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SuggestionWhereInputSchema),z.lazy(() => SuggestionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  field: z.union([ z.lazy(() => EnumSuggestionFieldFilterSchema),z.lazy(() => SuggestionFieldSchema) ]).optional(),
  value: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSuggestionStatusFilterSchema),z.lazy(() => SuggestionStatusSchema) ]).optional(),
  source: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
}).strict();

export const SuggestionOrderByWithRelationInputSchema: z.ZodType<Prisma.SuggestionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  field: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional()
}).strict();

export const SuggestionWhereUniqueInputSchema: z.ZodType<Prisma.SuggestionWhereUniqueInput> = z.object({
  id: z.string()
})
.and(z.object({
  id: z.string().optional(),
  AND: z.union([ z.lazy(() => SuggestionWhereInputSchema),z.lazy(() => SuggestionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SuggestionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SuggestionWhereInputSchema),z.lazy(() => SuggestionWhereInputSchema).array() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  field: z.union([ z.lazy(() => EnumSuggestionFieldFilterSchema),z.lazy(() => SuggestionFieldSchema) ]).optional(),
  value: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSuggestionStatusFilterSchema),z.lazy(() => SuggestionStatusSchema) ]).optional(),
  source: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
}).strict());

export const SuggestionOrderByWithAggregationInputSchema: z.ZodType<Prisma.SuggestionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  field: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SuggestionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SuggestionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SuggestionMinOrderByAggregateInputSchema).optional()
}).strict();

export const SuggestionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SuggestionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SuggestionScalarWhereWithAggregatesInputSchema),z.lazy(() => SuggestionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SuggestionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SuggestionScalarWhereWithAggregatesInputSchema),z.lazy(() => SuggestionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  field: z.union([ z.lazy(() => EnumSuggestionFieldWithAggregatesFilterSchema),z.lazy(() => SuggestionFieldSchema) ]).optional(),
  value: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSuggestionStatusWithAggregatesFilterSchema),z.lazy(() => SuggestionStatusSchema) ]).optional(),
  source: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SynthesisWhereInputSchema: z.ZodType<Prisma.SynthesisWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SynthesisWhereInputSchema),z.lazy(() => SynthesisWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SynthesisWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SynthesisWhereInputSchema),z.lazy(() => SynthesisWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  content: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict();

export const SynthesisOrderByWithRelationInputSchema: z.ZodType<Prisma.SynthesisOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  content: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  case: z.lazy(() => CaseOrderByWithRelationInputSchema).optional(),
  user: z.lazy(() => UserOrderByWithRelationInputSchema).optional()
}).strict();

export const SynthesisWhereUniqueInputSchema: z.ZodType<Prisma.SynthesisWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    caseId: z.string()
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    caseId: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  caseId: z.string().optional(),
  AND: z.union([ z.lazy(() => SynthesisWhereInputSchema),z.lazy(() => SynthesisWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SynthesisWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SynthesisWhereInputSchema),z.lazy(() => SynthesisWhereInputSchema).array() ]).optional(),
  content: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  case: z.union([ z.lazy(() => CaseRelationFilterSchema),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  user: z.union([ z.lazy(() => UserRelationFilterSchema),z.lazy(() => UserWhereInputSchema) ]).optional(),
}).strict());

export const SynthesisOrderByWithAggregationInputSchema: z.ZodType<Prisma.SynthesisOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  content: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => SynthesisCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => SynthesisMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => SynthesisMinOrderByAggregateInputSchema).optional()
}).strict();

export const SynthesisScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.SynthesisScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => SynthesisScalarWhereWithAggregatesInputSchema),z.lazy(() => SynthesisScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => SynthesisScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SynthesisScalarWhereWithAggregatesInputSchema),z.lazy(() => SynthesisScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  content: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  userId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const UserWhereInputSchema: z.ZodType<Prisma.UserWhereInput> = z.object({
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumUserRoleFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  phone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  calendarLink: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  gender: z.union([ z.lazy(() => EnumGenderNullableFilterSchema),z.lazy(() => GenderSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  passwordResetToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerificationToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  passwordCreationToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  disabledAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  accounts: z.lazy(() => AccountListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional(),
  customer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  caseTraces: z.lazy(() => CaseTraceListRelationFilterSchema).optional(),
  companies: z.lazy(() => CompanyListRelationFilterSchema).optional(),
  cases: z.lazy(() => CaseListRelationFilterSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorListRelationFilterSchema).optional(),
  Simulation: z.lazy(() => SimulationListRelationFilterSchema).optional(),
  submissions: z.lazy(() => SubmissionListRelationFilterSchema).optional(),
  synthesis: z.lazy(() => SynthesisListRelationFilterSchema).optional(),
  sentNotifications: z.lazy(() => NotificationListRelationFilterSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationListRelationFilterSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageListRelationFilterSchema).optional(),
  partner: z.union([ z.lazy(() => PartnerNullableRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional().nullable(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorListRelationFilterSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateListRelationFilterSchema).optional()
}).strict();

export const UserOrderByWithRelationInputSchema: z.ZodType<Prisma.UserOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  phone: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerified: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  image: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  calendarLink: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  gender: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  passwordResetToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordResetTokenExpiration: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerificationToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerificationTokenExpiration: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordCreationToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  disabledAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  accounts: z.lazy(() => AccountOrderByRelationAggregateInputSchema).optional(),
  sessions: z.lazy(() => SessionOrderByRelationAggregateInputSchema).optional(),
  customer: z.lazy(() => CustomerOrderByWithRelationInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceOrderByRelationAggregateInputSchema).optional(),
  companies: z.lazy(() => CompanyOrderByRelationAggregateInputSchema).optional(),
  cases: z.lazy(() => CaseOrderByRelationAggregateInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorOrderByRelationAggregateInputSchema).optional(),
  Simulation: z.lazy(() => SimulationOrderByRelationAggregateInputSchema).optional(),
  submissions: z.lazy(() => SubmissionOrderByRelationAggregateInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisOrderByRelationAggregateInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationOrderByRelationAggregateInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationOrderByRelationAggregateInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageOrderByRelationAggregateInputSchema).optional(),
  partner: z.lazy(() => PartnerOrderByWithRelationInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorOrderByRelationAggregateInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateOrderByRelationAggregateInputSchema).optional()
}).strict();

export const UserWhereUniqueInputSchema: z.ZodType<Prisma.UserWhereUniqueInput> = z.union([
  z.object({
    id: z.string(),
    customerId: z.string(),
    email: z.string()
  }),
  z.object({
    id: z.string(),
    customerId: z.string(),
  }),
  z.object({
    id: z.string(),
    email: z.string(),
  }),
  z.object({
    id: z.string(),
  }),
  z.object({
    customerId: z.string(),
    email: z.string(),
  }),
  z.object({
    customerId: z.string(),
  }),
  z.object({
    email: z.string(),
  }),
])
.and(z.object({
  id: z.string().optional(),
  customerId: z.string().optional(),
  email: z.string().optional(),
  AND: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserWhereInputSchema),z.lazy(() => UserWhereInputSchema).array() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumUserRoleFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  phone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  calendarLink: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  gender: z.union([ z.lazy(() => EnumGenderNullableFilterSchema),z.lazy(() => GenderSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  passwordResetToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerificationToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  passwordCreationToken: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  disabledAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  accounts: z.lazy(() => AccountListRelationFilterSchema).optional(),
  sessions: z.lazy(() => SessionListRelationFilterSchema).optional(),
  customer: z.union([ z.lazy(() => CustomerNullableRelationFilterSchema),z.lazy(() => CustomerWhereInputSchema) ]).optional().nullable(),
  caseTraces: z.lazy(() => CaseTraceListRelationFilterSchema).optional(),
  companies: z.lazy(() => CompanyListRelationFilterSchema).optional(),
  cases: z.lazy(() => CaseListRelationFilterSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorListRelationFilterSchema).optional(),
  Simulation: z.lazy(() => SimulationListRelationFilterSchema).optional(),
  submissions: z.lazy(() => SubmissionListRelationFilterSchema).optional(),
  synthesis: z.lazy(() => SynthesisListRelationFilterSchema).optional(),
  sentNotifications: z.lazy(() => NotificationListRelationFilterSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationListRelationFilterSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageListRelationFilterSchema).optional(),
  partner: z.union([ z.lazy(() => PartnerNullableRelationFilterSchema),z.lazy(() => PartnerWhereInputSchema) ]).optional().nullable(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorListRelationFilterSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateListRelationFilterSchema).optional()
}).strict());

export const UserOrderByWithAggregationInputSchema: z.ZodType<Prisma.UserOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  phone: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerified: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  image: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  calendarLink: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  gender: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  passwordResetToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordResetTokenExpiration: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerificationToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  emailVerificationTokenExpiration: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  passwordCreationToken: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  disabledAt: z.union([ z.lazy(() => SortOrderSchema),z.lazy(() => SortOrderInputSchema) ]).optional(),
  _count: z.lazy(() => UserCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => UserMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => UserMinOrderByAggregateInputSchema).optional()
}).strict();

export const UserScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.UserScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => UserScalarWhereWithAggregatesInputSchema),z.lazy(() => UserScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  firstname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  password: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  role: z.union([ z.lazy(() => EnumUserRoleWithAggregatesFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  phone: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  calendarLink: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  gender: z.union([ z.lazy(() => EnumGenderNullableWithAggregatesFilterSchema),z.lazy(() => GenderSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeWithAggregatesFilterSchema),z.coerce.date() ]).optional(),
  passwordResetToken: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  emailVerificationToken: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
  passwordCreationToken: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  disabledAt: z.union([ z.lazy(() => DateTimeNullableWithAggregatesFilterSchema),z.coerce.date() ]).optional().nullable(),
}).strict();

export const AssetCreateInputSchema: z.ZodType<Prisma.AssetCreateInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateInputSchema: z.ZodType<Prisma.AssetUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUpdateInputSchema: z.ZodType<Prisma.AssetUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetCreateManyInputSchema: z.ZodType<Prisma.AssetCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const AssetUpdateManyMutationInputSchema: z.ZodType<Prisma.AssetUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerCreateInputSchema: z.ZodType<Prisma.AssetJointOwnerCreateInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutAssetJointOwnersInputSchema)
}).strict();

export const AssetJointOwnerUncheckedCreateInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int()
}).strict();

export const AssetJointOwnerUpdateInputSchema: z.ZodType<Prisma.AssetJointOwnerUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneRequiredWithoutAssetJointOwnersNestedInputSchema).optional()
}).strict();

export const AssetJointOwnerUncheckedUpdateInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerCreateManyInputSchema: z.ZodType<Prisma.AssetJointOwnerCreateManyInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int()
}).strict();

export const AssetJointOwnerUpdateManyMutationInputSchema: z.ZodType<Prisma.AssetJointOwnerUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetShareholderCreateInputSchema: z.ZodType<Prisma.AssetShareholderCreateInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  type: z.lazy(() => AssetShareholderTypeSchema),
  sharePercentage: z.number().int(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutAssetShareholdersInputSchema)
}).strict();

export const AssetShareholderUncheckedCreateInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  type: z.lazy(() => AssetShareholderTypeSchema),
  sharePercentage: z.number().int()
}).strict();

export const AssetShareholderUpdateInputSchema: z.ZodType<Prisma.AssetShareholderUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneRequiredWithoutAssetShareholdersNestedInputSchema).optional()
}).strict();

export const AssetShareholderUncheckedUpdateInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetShareholderCreateManyInputSchema: z.ZodType<Prisma.AssetShareholderCreateManyInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  type: z.lazy(() => AssetShareholderTypeSchema),
  sharePercentage: z.number().int()
}).strict();

export const AssetShareholderUpdateManyMutationInputSchema: z.ZodType<Prisma.AssetShareholderUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetShareholderUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssistantResponseCreateInputSchema: z.ZodType<Prisma.AssistantResponseCreateInput> = z.object({
  id: z.string().optional(),
  fileHash: z.string(),
  assistantId: z.string(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  createdAt: z.coerce.date().optional()
}).strict();

export const AssistantResponseUncheckedCreateInputSchema: z.ZodType<Prisma.AssistantResponseUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  fileHash: z.string(),
  assistantId: z.string(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  createdAt: z.coerce.date().optional()
}).strict();

export const AssistantResponseUpdateInputSchema: z.ZodType<Prisma.AssistantResponseUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fileHash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assistantId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssistantResponseUncheckedUpdateInputSchema: z.ZodType<Prisma.AssistantResponseUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fileHash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assistantId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssistantResponseCreateManyInputSchema: z.ZodType<Prisma.AssistantResponseCreateManyInput> = z.object({
  id: z.string().optional(),
  fileHash: z.string(),
  assistantId: z.string(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  createdAt: z.coerce.date().optional()
}).strict();

export const AssistantResponseUpdateManyMutationInputSchema: z.ZodType<Prisma.AssistantResponseUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fileHash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assistantId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssistantResponseUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AssistantResponseUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  fileHash: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assistantId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  response: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AccountCreateInputSchema: z.ZodType<Prisma.AccountCreateInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable(),
  user: z.lazy(() => UserCreateNestedOneWithoutAccountsInputSchema)
}).strict();

export const AccountUncheckedCreateInputSchema: z.ZodType<Prisma.AccountUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUpdateInputSchema: z.ZodType<Prisma.AccountUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutAccountsNestedInputSchema).optional()
}).strict();

export const AccountUncheckedUpdateInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountCreateManyInputSchema: z.ZodType<Prisma.AccountCreateManyInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUpdateManyMutationInputSchema: z.ZodType<Prisma.AccountUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SessionCreateInputSchema: z.ZodType<Prisma.SessionCreateInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date(),
  user: z.lazy(() => UserCreateNestedOneWithoutSessionsInputSchema)
}).strict();

export const SessionUncheckedCreateInputSchema: z.ZodType<Prisma.SessionUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUpdateInputSchema: z.ZodType<Prisma.SessionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSessionsNestedInputSchema).optional()
}).strict();

export const SessionUncheckedUpdateInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionCreateManyInputSchema: z.ZodType<Prisma.SessionCreateManyInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  userId: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUpdateManyMutationInputSchema: z.ZodType<Prisma.SessionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenCreateInputSchema: z.ZodType<Prisma.VerificationTokenCreateInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUncheckedCreateInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedCreateInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUpdateInputSchema: z.ZodType<Prisma.VerificationTokenUpdateInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenUncheckedUpdateInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedUpdateInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenCreateManyInputSchema: z.ZodType<Prisma.VerificationTokenCreateManyInput> = z.object({
  identifier: z.string(),
  token: z.string(),
  expires: z.coerce.date()
}).strict();

export const VerificationTokenUpdateManyMutationInputSchema: z.ZodType<Prisma.VerificationTokenUpdateManyMutationInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const VerificationTokenUncheckedUpdateManyInputSchema: z.ZodType<Prisma.VerificationTokenUncheckedUpdateManyInput> = z.object({
  identifier: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  token: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BankCreateInputSchema: z.ZodType<Prisma.BankCreateInput> = z.object({
  id: z.string().optional(),
  name: z.string(),
  products: z.union([ z.lazy(() => BankCreateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.number().int(),
  assetTypes: z.union([ z.lazy(() => BankCreateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.lazy(() => BankLocalisationSchema),
  disabledAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutBankInputSchema).optional()
}).strict();

export const BankUncheckedCreateInputSchema: z.ZodType<Prisma.BankUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  name: z.string(),
  products: z.union([ z.lazy(() => BankCreateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.number().int(),
  assetTypes: z.union([ z.lazy(() => BankCreateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.lazy(() => BankLocalisationSchema),
  disabledAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutBankInputSchema).optional()
}).strict();

export const BankUpdateInputSchema: z.ZodType<Prisma.BankUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  products: z.union([ z.lazy(() => BankUpdateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  assetTypes: z.union([ z.lazy(() => BankUpdateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => EnumBankLocalisationFieldUpdateOperationsInputSchema) ]).optional(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutBankNestedInputSchema).optional()
}).strict();

export const BankUncheckedUpdateInputSchema: z.ZodType<Prisma.BankUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  products: z.union([ z.lazy(() => BankUpdateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  assetTypes: z.union([ z.lazy(() => BankUpdateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => EnumBankLocalisationFieldUpdateOperationsInputSchema) ]).optional(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutBankNestedInputSchema).optional()
}).strict();

export const BankCreateManyInputSchema: z.ZodType<Prisma.BankCreateManyInput> = z.object({
  id: z.string().optional(),
  name: z.string(),
  products: z.union([ z.lazy(() => BankCreateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.number().int(),
  assetTypes: z.union([ z.lazy(() => BankCreateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.lazy(() => BankLocalisationSchema),
  disabledAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const BankUpdateManyMutationInputSchema: z.ZodType<Prisma.BankUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  products: z.union([ z.lazy(() => BankUpdateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  assetTypes: z.union([ z.lazy(() => BankUpdateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => EnumBankLocalisationFieldUpdateOperationsInputSchema) ]).optional(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BankUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BankUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  products: z.union([ z.lazy(() => BankUpdateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  assetTypes: z.union([ z.lazy(() => BankUpdateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => EnumBankLocalisationFieldUpdateOperationsInputSchema) ]).optional(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCreateInputSchema: z.ZodType<Prisma.CaseCreateInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateInputSchema: z.ZodType<Prisma.CaseUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUpdateInputSchema: z.ZodType<Prisma.CaseUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseCreateManyInputSchema: z.ZodType<Prisma.CaseCreateManyInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseUpdateManyMutationInputSchema: z.ZodType<Prisma.CaseUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorCreateInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutCaseCollaboratorsInputSchema),
  collaborator: z.lazy(() => UserCreateNestedOneWithoutCasesAsCollaboratorInputSchema)
}).strict();

export const CaseCollaboratorUncheckedCreateInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCollaboratorUpdateInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCaseCollaboratorsNestedInputSchema).optional(),
  collaborator: z.lazy(() => UserUpdateOneRequiredWithoutCasesAsCollaboratorNestedInputSchema).optional()
}).strict();

export const CaseCollaboratorUncheckedUpdateInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorCreateManyInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCollaboratorUpdateManyMutationInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseTraceCreateInputSchema: z.ZodType<Prisma.CaseTraceCreateInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutCaseTraceInputSchema).optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCaseTracesInputSchema).optional()
}).strict();

export const CaseTraceUncheckedCreateInputSchema: z.ZodType<Prisma.CaseTraceUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string().optional().nullable(),
  userId: z.string().optional().nullable(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseTraceUpdateInputSchema: z.ZodType<Prisma.CaseTraceUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutCaseTraceNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCaseTracesNestedInputSchema).optional()
}).strict();

export const CaseTraceUncheckedUpdateInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseTraceCreateManyInputSchema: z.ZodType<Prisma.CaseTraceCreateManyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string().optional().nullable(),
  userId: z.string().optional().nullable(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseTraceUpdateManyMutationInputSchema: z.ZodType<Prisma.CaseTraceUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseTraceUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  userId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageCreateInputSchema: z.ZodType<Prisma.CaseMessageCreateInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutCaseMessagesInputSchema),
  sender: z.lazy(() => UserCreateNestedOneWithoutSentCaseMessagesInputSchema)
}).strict();

export const CaseMessageUncheckedCreateInputSchema: z.ZodType<Prisma.CaseMessageUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  caseId: z.string(),
  senderId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseMessageUpdateInputSchema: z.ZodType<Prisma.CaseMessageUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCaseMessagesNestedInputSchema).optional(),
  sender: z.lazy(() => UserUpdateOneRequiredWithoutSentCaseMessagesNestedInputSchema).optional()
}).strict();

export const CaseMessageUncheckedUpdateInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageCreateManyInputSchema: z.ZodType<Prisma.CaseMessageCreateManyInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  caseId: z.string(),
  senderId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseMessageUpdateManyMutationInputSchema: z.ZodType<Prisma.CaseMessageUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyCreateInputSchema: z.ZodType<Prisma.CompanyCreateInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUpdateInputSchema: z.ZodType<Prisma.CompanyUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyCreateManyInputSchema: z.ZodType<Prisma.CompanyCreateManyInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyUpdateManyMutationInputSchema: z.ZodType<Prisma.CompanyUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeCreateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyRealEstateLoanChargeInputSchema),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCompanyRealEstateLoanChargesInputSchema)
}).strict();

export const CompanyRealEstateLoanChargeUncheckedCreateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  caseId: z.string(),
  assetId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRealEstateLoanChargeUpdateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutCompanyRealEstateLoanChargeNestedInputSchema).optional(),
  asset: z.lazy(() => AssetUpdateOneRequiredWithoutCompanyRealEstateLoanChargesNestedInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeCreateManyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  caseId: z.string(),
  assetId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRealEstateLoanChargeUpdateManyMutationInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRentIncomeCreateInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateInput> = z.object({
  id: z.string().optional(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCompanyRentIncomeInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyRentIncomeInputSchema)
}).strict();

export const CompanyRentIncomeUncheckedCreateInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  assetId: z.string(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRentIncomeUpdateInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneWithoutCompanyRentIncomeNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutCompanyRentIncomeNestedInputSchema).optional()
}).strict();

export const CompanyRentIncomeUncheckedUpdateInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRentIncomeCreateManyInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateManyInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  assetId: z.string(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRentIncomeUpdateManyMutationInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRentIncomeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyFinancialDataCreateInputSchema: z.ZodType<Prisma.CompanyFinancialDataCreateInput> = z.object({
  id: z.string().optional(),
  year: z.number().int(),
  exploitationResult: z.number().optional().nullable(),
  depreciationCharge: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  loanInterest: z.number().optional().nullable(),
  revenue: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  balanceSheetSize: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  accountValue: z.number().optional().nullable(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyFinancialDatasInputSchema)
}).strict();

export const CompanyFinancialDataUncheckedCreateInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  year: z.number().int(),
  exploitationResult: z.number().optional().nullable(),
  depreciationCharge: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  loanInterest: z.number().optional().nullable(),
  revenue: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  balanceSheetSize: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  accountValue: z.number().optional().nullable()
}).strict();

export const CompanyFinancialDataUpdateInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutCompanyFinancialDatasNestedInputSchema).optional()
}).strict();

export const CompanyFinancialDataUncheckedUpdateInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CompanyFinancialDataCreateManyInputSchema: z.ZodType<Prisma.CompanyFinancialDataCreateManyInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  year: z.number().int(),
  exploitationResult: z.number().optional().nullable(),
  depreciationCharge: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  loanInterest: z.number().optional().nullable(),
  revenue: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  balanceSheetSize: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  accountValue: z.number().optional().nullable()
}).strict();

export const CompanyFinancialDataUpdateManyMutationInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CompanyFinancialDataUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CustomerCreateInputSchema: z.ZodType<Prisma.CustomerCreateInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUpdateInputSchema: z.ZodType<Prisma.CustomerUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateManyInputSchema: z.ZodType<Prisma.CustomerCreateManyInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CustomerUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerEmployeeCreateInputSchema: z.ZodType<Prisma.CustomerEmployeeCreateInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerEmployeeTypeSchema),
  isTrialing: z.boolean().optional().nullable(),
  monthlyIncome: z.number().int(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerEmployeeInputSchema)
}).strict();

export const CustomerEmployeeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  type: z.lazy(() => CustomerEmployeeTypeSchema),
  isTrialing: z.boolean().optional().nullable(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerEmployeeUpdateInputSchema: z.ZodType<Prisma.CustomerEmployeeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  isTrialing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerEmployeeNestedInputSchema).optional()
}).strict();

export const CustomerEmployeeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  isTrialing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerEmployeeCreateManyInputSchema: z.ZodType<Prisma.CustomerEmployeeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  type: z.lazy(() => CustomerEmployeeTypeSchema),
  isTrialing: z.boolean().optional().nullable(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerEmployeeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerEmployeeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  isTrialing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerEmployeeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  isTrialing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredCreateInputSchema: z.ZodType<Prisma.CustomerRetiredCreateInput> = z.object({
  id: z.string().optional(),
  monthlyPensionIncome: z.number().int(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerRetiredInputSchema)
}).strict();

export const CustomerRetiredUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyPensionIncome: z.number().int()
}).strict();

export const CustomerRetiredUpdateInputSchema: z.ZodType<Prisma.CustomerRetiredUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyPensionIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerRetiredNestedInputSchema).optional()
}).strict();

export const CustomerRetiredUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyPensionIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredCreateManyInputSchema: z.ZodType<Prisma.CustomerRetiredCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyPensionIncome: z.number().int()
}).strict();

export const CustomerRetiredUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerRetiredUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyPensionIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyPensionIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedCreateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateInput> = z.object({
  id: z.string().optional(),
  lastYearAverageMonthlyIncome: z.number().int(),
  hasEnoughAnnualReviews: z.boolean(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerSelfEmployedInputSchema)
}).strict();

export const CustomerSelfEmployedUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  lastYearAverageMonthlyIncome: z.number().int(),
  hasEnoughAnnualReviews: z.boolean()
}).strict();

export const CustomerSelfEmployedUpdateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerSelfEmployedNestedInputSchema).optional()
}).strict();

export const CustomerSelfEmployedUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedCreateManyInputSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  lastYearAverageMonthlyIncome: z.number().int(),
  hasEnoughAnnualReviews: z.boolean()
}).strict();

export const CustomerSelfEmployedUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentCreateInputSchema: z.ZodType<Prisma.CustomerInvestmentCreateInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  amount: z.number().int(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerInvestmentsInputSchema)
}).strict();

export const CustomerInvestmentUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  type: z.string(),
  amount: z.number().int()
}).strict();

export const CustomerInvestmentUpdateInputSchema: z.ZodType<Prisma.CustomerInvestmentUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerInvestmentsNestedInputSchema).optional()
}).strict();

export const CustomerInvestmentUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentCreateManyInputSchema: z.ZodType<Prisma.CustomerInvestmentCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  type: z.string(),
  amount: z.number().int()
}).strict();

export const CustomerInvestmentUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerInvestmentUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeCreateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerAdditionalIncomeTypeSchema),
  monthlyIncome: z.number().int(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerAdditionalIncomesInputSchema)
}).strict();

export const CustomerAdditionalIncomeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  type: z.lazy(() => CustomerAdditionalIncomeTypeSchema),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerAdditionalIncomeUpdateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerAdditionalIncomesNestedInputSchema).optional()
}).strict();

export const CustomerAdditionalIncomeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeCreateManyInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  type: z.lazy(() => CustomerAdditionalIncomeTypeSchema),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerAdditionalIncomeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeCreateInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateInput> = z.object({
  id: z.string().optional(),
  monthlyIncome: z.number().int(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCustomerRentIncomeInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerRentIncomesInputSchema)
}).strict();

export const CustomerRentIncomeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  assetId: z.string(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerRentIncomeUpdateInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneWithoutCustomerRentIncomeNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerRentIncomesNestedInputSchema).optional()
}).strict();

export const CustomerRentIncomeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeCreateManyInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  assetId: z.string(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerRentIncomeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeCreateInputSchema: z.ZodType<Prisma.CustomerRentChargeCreateInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerRentChargesInputSchema)
}).strict();

export const CustomerRentChargeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int()
}).strict();

export const CustomerRentChargeUpdateInputSchema: z.ZodType<Prisma.CustomerRentChargeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerRentChargesNestedInputSchema).optional()
}).strict();

export const CustomerRentChargeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeCreateManyInputSchema: z.ZodType<Prisma.CustomerRentChargeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int()
}).strict();

export const CustomerRentChargeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerRentChargeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeCreateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number(),
  Customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerConsumerLoanChargesInputSchema)
}).strict();

export const CustomerConsumerLoanChargeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number()
}).strict();

export const CustomerConsumerLoanChargeUpdateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  Customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerConsumerLoanChargesNestedInputSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeCreateManyInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number()
}).strict();

export const CustomerConsumerLoanChargeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeCreateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCustomerRealEstateLoanChargesInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutRealEstateLoanChargesInputSchema)
}).strict();

export const CustomerRealEstateLoanChargeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  assetId: z.string().optional().nullable(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema)
}).strict();

export const CustomerRealEstateLoanChargeUpdateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneWithoutCustomerRealEstateLoanChargesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutRealEstateLoanChargesNestedInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeCreateManyInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  assetId: z.string().optional().nullable(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema)
}).strict();

export const CustomerRealEstateLoanChargeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeCreateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  type: z.lazy(() => CustomerAdditionalChargeTypeSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerAdditionalChargesInputSchema)
}).strict();

export const CustomerAdditionalChargeUncheckedCreateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
  type: z.lazy(() => CustomerAdditionalChargeTypeSchema)
}).strict();

export const CustomerAdditionalChargeUpdateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerAdditionalChargesNestedInputSchema).optional()
}).strict();

export const CustomerAdditionalChargeUncheckedUpdateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeCreateManyInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int(),
  type: z.lazy(() => CustomerAdditionalChargeTypeSchema)
}).strict();

export const CustomerAdditionalChargeUpdateManyMutationInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationCreateInputSchema: z.ZodType<Prisma.NotificationCreateInput> = z.object({
  id: z.string().optional(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  receiver: z.lazy(() => UserCreateNestedOneWithoutReceivedNotificationsInputSchema),
  sender: z.lazy(() => UserCreateNestedOneWithoutSentNotificationsInputSchema)
}).strict();

export const NotificationUncheckedCreateInputSchema: z.ZodType<Prisma.NotificationUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  receiverId: z.string(),
  senderId: z.string(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const NotificationUpdateInputSchema: z.ZodType<Prisma.NotificationUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  receiver: z.lazy(() => UserUpdateOneRequiredWithoutReceivedNotificationsNestedInputSchema).optional(),
  sender: z.lazy(() => UserUpdateOneRequiredWithoutSentNotificationsNestedInputSchema).optional()
}).strict();

export const NotificationUncheckedUpdateInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationCreateManyInputSchema: z.ZodType<Prisma.NotificationCreateManyInput> = z.object({
  id: z.string().optional(),
  receiverId: z.string(),
  senderId: z.string(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const NotificationUpdateManyMutationInputSchema: z.ZodType<Prisma.NotificationUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationUncheckedUpdateManyInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyPappersCreateInputSchema: z.ZodType<Prisma.CompanyPappersCreateInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUpdateInputSchema: z.ZodType<Prisma.CompanyPappersUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersCreateManyInputSchema: z.ZodType<Prisma.CompanyPappersCreateManyInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyPappersUpdateManyMutationInputSchema: z.ZodType<Prisma.CompanyPappersUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyPappersUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PappersRepresentativeCreateInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutRepresentativesInputSchema).optional(),
  sanction: z.lazy(() => PappersSanctionCreateNestedManyWithoutPappersRepresentativeInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedCreateInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedCreateNestedManyWithoutPappersRepresentativeInputSchema).optional()
}).strict();

export const PappersRepresentativeUpdateInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutRepresentativesNestedInputSchema).optional(),
  sanction: z.lazy(() => PappersSanctionUpdateManyWithoutPappersRepresentativeNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedUpdateInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeCreateManyInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateManyInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable()
}).strict();

export const PappersRepresentativeUpdateManyMutationInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersRepresentativeUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersBeneficiaryCreateInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutBeneficiariesInputSchema).optional(),
  sanction: z.lazy(() => PappersSanctionCreateNestedManyWithoutPappersBeneficiaryInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedCreateInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedCreateNestedManyWithoutPappersBeneficiaryInputSchema).optional()
}).strict();

export const PappersBeneficiaryUpdateInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutBeneficiariesNestedInputSchema).optional(),
  sanction: z.lazy(() => PappersSanctionUpdateManyWithoutPappersBeneficiaryNestedInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedUpdateInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryNestedInputSchema).optional()
}).strict();

export const PappersBeneficiaryCreateManyInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateManyInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable()
}).strict();

export const PappersBeneficiaryUpdateManyMutationInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersBeneficiaryUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionCreateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  idcc: z.number().int().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCollectiveConventionInputSchema).optional()
}).strict();

export const PappersCollectiveConventionUncheckedCreateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  name: z.string().optional().nullable(),
  idcc: z.number().int().optional().nullable()
}).strict();

export const PappersCollectiveConventionUpdateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCollectiveConventionNestedInputSchema).optional()
}).strict();

export const PappersCollectiveConventionUncheckedUpdateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionCreateManyInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateManyInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  name: z.string().optional().nullable(),
  idcc: z.number().int().optional().nullable()
}).strict();

export const PappersCollectiveConventionUpdateManyMutationInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureCreateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateInput> = z.object({
  id: z.string().optional(),
  type: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCollectiveProcedureInputSchema).optional()
}).strict();

export const PappersCollectiveProcedureUncheckedCreateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  type: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersCollectiveProcedureUpdateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCollectiveProcedureNestedInputSchema).optional()
}).strict();

export const PappersCollectiveProcedureUncheckedUpdateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureCreateManyInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateManyInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  type: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersCollectiveProcedureUpdateManyMutationInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataCreateInputSchema: z.ZodType<Prisma.PappersFinancialDataCreateInput> = z.object({
  id: z.string().optional(),
  year: z.number().int().optional().nullable(),
  revenue: z.number().optional().nullable(),
  operatingResults: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  charges: z.number().optional().nullable(),
  repaymentCapacity: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutFinancialDataInputSchema).optional()
}).strict();

export const PappersFinancialDataUncheckedCreateInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  year: z.number().int().optional().nullable(),
  revenue: z.number().optional().nullable(),
  operatingResults: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  charges: z.number().optional().nullable(),
  repaymentCapacity: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable()
}).strict();

export const PappersFinancialDataUpdateInputSchema: z.ZodType<Prisma.PappersFinancialDataUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutFinancialDataNestedInputSchema).optional()
}).strict();

export const PappersFinancialDataUncheckedUpdateInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataCreateManyInputSchema: z.ZodType<Prisma.PappersFinancialDataCreateManyInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  year: z.number().int().optional().nullable(),
  revenue: z.number().optional().nullable(),
  operatingResults: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  charges: z.number().optional().nullable(),
  repaymentCapacity: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable()
}).strict();

export const PappersFinancialDataUpdateManyMutationInputSchema: z.ZodType<Prisma.PappersFinancialDataUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionCreateInputSchema: z.ZodType<Prisma.PappersSanctionCreateInput> = z.object({
  id: z.string().optional(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable(),
  pappersRepresentative: z.lazy(() => PappersRepresentativeCreateNestedOneWithoutSanctionInputSchema).optional(),
  pappersBeneficiary: z.lazy(() => PappersBeneficiaryCreateNestedOneWithoutSanctionInputSchema).optional()
}).strict();

export const PappersSanctionUncheckedCreateInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  pappersRepresentativeId: z.string().optional().nullable(),
  pappersBeneficiaryId: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersSanctionUpdateInputSchema: z.ZodType<Prisma.PappersSanctionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  pappersRepresentative: z.lazy(() => PappersRepresentativeUpdateOneWithoutSanctionNestedInputSchema).optional(),
  pappersBeneficiary: z.lazy(() => PappersBeneficiaryUpdateOneWithoutSanctionNestedInputSchema).optional()
}).strict();

export const PappersSanctionUncheckedUpdateInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pappersRepresentativeId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  pappersBeneficiaryId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionCreateManyInputSchema: z.ZodType<Prisma.PappersSanctionCreateManyInput> = z.object({
  id: z.string().optional(),
  pappersRepresentativeId: z.string().optional().nullable(),
  pappersBeneficiaryId: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersSanctionUpdateManyMutationInputSchema: z.ZodType<Prisma.PappersSanctionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pappersRepresentativeId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  pappersBeneficiaryId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PartnerCreateInputSchema: z.ZodType<Prisma.PartnerCreateInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerInputSchema),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUpdateInputSchema: z.ZodType<Prisma.PartnerUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerCreateManyInputSchema: z.ZodType<Prisma.PartnerCreateManyInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerUpdateManyMutationInputSchema: z.ZodType<Prisma.PartnerUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorCreateInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCollaboratorsInputSchema),
  collaborator: z.lazy(() => UserCreateNestedOneWithoutPartnerCollaboratorsInputSchema)
}).strict();

export const PartnerCollaboratorUncheckedCreateInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerCollaboratorUpdateInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerCollaboratorsNestedInputSchema).optional(),
  collaborator: z.lazy(() => UserUpdateOneRequiredWithoutPartnerCollaboratorsNestedInputSchema).optional()
}).strict();

export const PartnerCollaboratorUncheckedUpdateInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorCreateManyInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerCollaboratorUpdateManyMutationInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerRateCreateInputSchema: z.ZodType<Prisma.PartnerRateCreateInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  createdAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerRatesInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerRateCreatedByInputSchema),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutRateInputSchema).optional()
}).strict();

export const PartnerRateUncheckedCreateInputSchema: z.ZodType<Prisma.PartnerRateUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  partnerId: z.string(),
  createdBy: z.string(),
  createdAt: z.coerce.date().optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutRateInputSchema).optional()
}).strict();

export const PartnerRateUpdateInputSchema: z.ZodType<Prisma.PartnerRateUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerRatesNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerRateCreatedByNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutRateNestedInputSchema).optional()
}).strict();

export const PartnerRateUncheckedUpdateInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdBy: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutRateNestedInputSchema).optional()
}).strict();

export const PartnerRateCreateManyInputSchema: z.ZodType<Prisma.PartnerRateCreateManyInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  partnerId: z.string(),
  createdBy: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const PartnerRateUpdateManyMutationInputSchema: z.ZodType<Prisma.PartnerRateUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerRateUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdBy: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerCreateInputSchema: z.ZodType<Prisma.CasePartnerCreateInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCasesInputSchema),
  rate: z.lazy(() => PartnerRateCreateNestedOneWithoutCasePartnerInputSchema),
  case: z.lazy(() => CaseCreateNestedOneWithoutCasePartnerInputSchema)
}).strict();

export const CasePartnerUncheckedCreateInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  rateId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerUpdateInputSchema: z.ZodType<Prisma.CasePartnerUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerCasesNestedInputSchema).optional(),
  rate: z.lazy(() => PartnerRateUpdateOneRequiredWithoutCasePartnerNestedInputSchema).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCasePartnerNestedInputSchema).optional()
}).strict();

export const CasePartnerUncheckedUpdateInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerCreateManyInputSchema: z.ZodType<Prisma.CasePartnerCreateManyInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  rateId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerUpdateManyMutationInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseCreateInputSchema: z.ZodType<Prisma.PartnerPendingCaseCreateInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  patrimony: z.lazy(() => ProspectPatrimonySchema),
  product: z.lazy(() => ProspectProductSchema),
  amount: z.lazy(() => ProspectAmountSchema),
  urgency: z.lazy(() => ProspectUrgencySchema),
  rating: z.lazy(() => ProspectRatingSchema),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerPendingCasesInputSchema)
}).strict();

export const PartnerPendingCaseUncheckedCreateInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  patrimony: z.lazy(() => ProspectPatrimonySchema),
  product: z.lazy(() => ProspectProductSchema),
  amount: z.lazy(() => ProspectAmountSchema),
  urgency: z.lazy(() => ProspectUrgencySchema),
  rating: z.lazy(() => ProspectRatingSchema),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerPendingCaseUpdateInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerPendingCasesNestedInputSchema).optional()
}).strict();

export const PartnerPendingCaseUncheckedUpdateInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseCreateManyInputSchema: z.ZodType<Prisma.PartnerPendingCaseCreateManyInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  patrimony: z.lazy(() => ProspectPatrimonySchema),
  product: z.lazy(() => ProspectProductSchema),
  amount: z.lazy(() => ProspectAmountSchema),
  urgency: z.lazy(() => ProspectUrgencySchema),
  rating: z.lazy(() => ProspectRatingSchema),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerPendingCaseUpdateManyMutationInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PendingCaseCreateInputSchema: z.ZodType<Prisma.PendingCaseCreateInput> = z.object({
  id: z.string().optional(),
  requestedAmount: z.number().int(),
  description: z.string(),
  type: z.lazy(() => PendingCaseTypeSchema),
  firstname: z.string(),
  lastname: z.string(),
  siret: z.string().optional().nullable(),
  companyName: z.string().optional().nullable(),
  companyType: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  phone: z.string(),
  email: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const PendingCaseUncheckedCreateInputSchema: z.ZodType<Prisma.PendingCaseUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  requestedAmount: z.number().int(),
  description: z.string(),
  type: z.lazy(() => PendingCaseTypeSchema),
  firstname: z.string(),
  lastname: z.string(),
  siret: z.string().optional().nullable(),
  companyName: z.string().optional().nullable(),
  companyType: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  phone: z.string(),
  email: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const PendingCaseUpdateInputSchema: z.ZodType<Prisma.PendingCaseUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => EnumPendingCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NullableEnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PendingCaseUncheckedUpdateInputSchema: z.ZodType<Prisma.PendingCaseUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => EnumPendingCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NullableEnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PendingCaseCreateManyInputSchema: z.ZodType<Prisma.PendingCaseCreateManyInput> = z.object({
  id: z.string().optional(),
  requestedAmount: z.number().int(),
  description: z.string(),
  type: z.lazy(() => PendingCaseTypeSchema),
  firstname: z.string(),
  lastname: z.string(),
  siret: z.string().optional().nullable(),
  companyName: z.string().optional().nullable(),
  companyType: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  phone: z.string(),
  email: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const PendingCaseUpdateManyMutationInputSchema: z.ZodType<Prisma.PendingCaseUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => EnumPendingCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NullableEnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PendingCaseUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PendingCaseUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => EnumPendingCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyType: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NullableEnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationCreateInputSchema: z.ZodType<Prisma.SimulationCreateInput> = z.object({
  id: z.string().optional(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  author: z.lazy(() => UserCreateNestedOneWithoutSimulationInputSchema),
  case: z.lazy(() => CaseCreateNestedOneWithoutSimulationInputSchema)
}).strict();

export const SimulationUncheckedCreateInputSchema: z.ZodType<Prisma.SimulationUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  authorId: z.string(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SimulationUpdateInputSchema: z.ZodType<Prisma.SimulationUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  author: z.lazy(() => UserUpdateOneRequiredWithoutSimulationNestedInputSchema).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSimulationNestedInputSchema).optional()
}).strict();

export const SimulationUncheckedUpdateInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationCreateManyInputSchema: z.ZodType<Prisma.SimulationCreateManyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  authorId: z.string(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SimulationUpdateManyMutationInputSchema: z.ZodType<Prisma.SimulationUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionCreateInputSchema: z.ZodType<Prisma.SubmissionCreateInput> = z.object({
  id: z.string().optional(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  bank: z.lazy(() => BankCreateNestedOneWithoutSubmissionsInputSchema),
  case: z.lazy(() => CaseCreateNestedOneWithoutSubmissionsInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutSubmissionsInputSchema)
}).strict();

export const SubmissionUncheckedCreateInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  authorId: z.string(),
  bankId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionUpdateInputSchema: z.ZodType<Prisma.SubmissionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  bank: z.lazy(() => BankUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional()
}).strict();

export const SubmissionUncheckedUpdateInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bankId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionCreateManyInputSchema: z.ZodType<Prisma.SubmissionCreateManyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  authorId: z.string(),
  bankId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionUpdateManyMutationInputSchema: z.ZodType<Prisma.SubmissionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bankId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionCreateInputSchema: z.ZodType<Prisma.SuggestionCreateInput> = z.object({
  id: z.string().optional(),
  field: z.lazy(() => SuggestionFieldSchema),
  value: z.string(),
  status: z.lazy(() => SuggestionStatusSchema).optional(),
  source: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutSuggestionsInputSchema)
}).strict();

export const SuggestionUncheckedCreateInputSchema: z.ZodType<Prisma.SuggestionUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  field: z.lazy(() => SuggestionFieldSchema),
  value: z.string(),
  status: z.lazy(() => SuggestionStatusSchema).optional(),
  source: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SuggestionUpdateInputSchema: z.ZodType<Prisma.SuggestionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSuggestionsNestedInputSchema).optional()
}).strict();

export const SuggestionUncheckedUpdateInputSchema: z.ZodType<Prisma.SuggestionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionCreateManyInputSchema: z.ZodType<Prisma.SuggestionCreateManyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  field: z.lazy(() => SuggestionFieldSchema),
  value: z.string(),
  status: z.lazy(() => SuggestionStatusSchema).optional(),
  source: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SuggestionUpdateManyMutationInputSchema: z.ZodType<Prisma.SuggestionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SuggestionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SynthesisCreateInputSchema: z.ZodType<Prisma.SynthesisCreateInput> = z.object({
  id: z.string().optional(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutSynthesisInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutSynthesisInputSchema)
}).strict();

export const SynthesisUncheckedCreateInputSchema: z.ZodType<Prisma.SynthesisUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional(),
  userId: z.string()
}).strict();

export const SynthesisUpdateInputSchema: z.ZodType<Prisma.SynthesisUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSynthesisNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSynthesisNestedInputSchema).optional()
}).strict();

export const SynthesisUncheckedUpdateInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SynthesisCreateManyInputSchema: z.ZodType<Prisma.SynthesisCreateManyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional(),
  userId: z.string()
}).strict();

export const SynthesisUpdateManyMutationInputSchema: z.ZodType<Prisma.SynthesisUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SynthesisUncheckedUpdateManyInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const UserCreateInputSchema: z.ZodType<Prisma.UserCreateInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateInputSchema: z.ZodType<Prisma.UserUncheckedCreateInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUpdateInputSchema: z.ZodType<Prisma.UserUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateInputSchema: z.ZodType<Prisma.UserUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserCreateManyInputSchema: z.ZodType<Prisma.UserCreateManyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable()
}).strict();

export const UserUpdateManyMutationInputSchema: z.ZodType<Prisma.UserUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserUncheckedUpdateManyInputSchema: z.ZodType<Prisma.UserUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const StringFilterSchema: z.ZodType<Prisma.StringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const StringNullableFilterSchema: z.ZodType<Prisma.StringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const IntFilterSchema: z.ZodType<Prisma.IntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const IntNullableFilterSchema: z.ZodType<Prisma.IntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const EnumAssetTypeNullableFilterSchema: z.ZodType<Prisma.EnumAssetTypeNullableFilter> = z.object({
  equals: z.lazy(() => AssetTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NestedEnumAssetTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const EnumAssetUsageTypeFilterSchema: z.ZodType<Prisma.EnumAssetUsageTypeFilter> = z.object({
  equals: z.lazy(() => AssetUsageTypeSchema).optional(),
  in: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => NestedEnumAssetUsageTypeFilterSchema) ]).optional(),
}).strict();

export const EnumAssetOwnershipTypeFilterSchema: z.ZodType<Prisma.EnumAssetOwnershipTypeFilter> = z.object({
  equals: z.lazy(() => AssetOwnershipTypeSchema).optional(),
  in: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => NestedEnumAssetOwnershipTypeFilterSchema) ]).optional(),
}).strict();

export const EnumAssetCompanyTypeNullableFilterSchema: z.ZodType<Prisma.EnumAssetCompanyTypeNullableFilter> = z.object({
  equals: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NestedEnumAssetCompanyTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const DateTimeFilterSchema: z.ZodType<Prisma.DateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const CaseNullableRelationFilterSchema: z.ZodType<Prisma.CaseNullableRelationFilter> = z.object({
  is: z.lazy(() => CaseWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CaseWhereInputSchema).optional().nullable()
}).strict();

export const AssetJointOwnerListRelationFilterSchema: z.ZodType<Prisma.AssetJointOwnerListRelationFilter> = z.object({
  every: z.lazy(() => AssetJointOwnerWhereInputSchema).optional(),
  some: z.lazy(() => AssetJointOwnerWhereInputSchema).optional(),
  none: z.lazy(() => AssetJointOwnerWhereInputSchema).optional()
}).strict();

export const AssetShareholderListRelationFilterSchema: z.ZodType<Prisma.AssetShareholderListRelationFilter> = z.object({
  every: z.lazy(() => AssetShareholderWhereInputSchema).optional(),
  some: z.lazy(() => AssetShareholderWhereInputSchema).optional(),
  none: z.lazy(() => AssetShareholderWhereInputSchema).optional()
}).strict();

export const CustomerNullableRelationFilterSchema: z.ZodType<Prisma.CustomerNullableRelationFilter> = z.object({
  is: z.lazy(() => CustomerWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CustomerWhereInputSchema).optional().nullable()
}).strict();

export const CustomerRealEstateLoanChargeListRelationFilterSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeListRelationFilter> = z.object({
  every: z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).optional(),
  some: z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).optional(),
  none: z.lazy(() => CustomerRealEstateLoanChargeWhereInputSchema).optional()
}).strict();

export const CustomerRentIncomeNullableRelationFilterSchema: z.ZodType<Prisma.CustomerRentIncomeNullableRelationFilter> = z.object({
  is: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional().nullable()
}).strict();

export const CompanyNullableRelationFilterSchema: z.ZodType<Prisma.CompanyNullableRelationFilter> = z.object({
  is: z.lazy(() => CompanyWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CompanyWhereInputSchema).optional().nullable()
}).strict();

export const CompanyRealEstateLoanChargeListRelationFilterSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeListRelationFilter> = z.object({
  every: z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).optional(),
  some: z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).optional(),
  none: z.lazy(() => CompanyRealEstateLoanChargeWhereInputSchema).optional()
}).strict();

export const CompanyRentIncomeNullableRelationFilterSchema: z.ZodType<Prisma.CompanyRentIncomeNullableRelationFilter> = z.object({
  is: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional().nullable()
}).strict();

export const SortOrderInputSchema: z.ZodType<Prisma.SortOrderInput> = z.object({
  sort: z.lazy(() => SortOrderSchema),
  nulls: z.lazy(() => NullsOrderSchema).optional()
}).strict();

export const AssetJointOwnerOrderByRelationAggregateInputSchema: z.ZodType<Prisma.AssetJointOwnerOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetShareholderOrderByRelationAggregateInputSchema: z.ZodType<Prisma.AssetShareholderOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetCountOrderByAggregateInputSchema: z.ZodType<Prisma.AssetCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.lazy(() => SortOrderSchema).optional(),
  sellValue: z.lazy(() => SortOrderSchema).optional(),
  assetType: z.lazy(() => SortOrderSchema).optional(),
  usageType: z.lazy(() => SortOrderSchema).optional(),
  ownershipType: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AssetAvgOrderByAggregateInput> = z.object({
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.lazy(() => SortOrderSchema).optional(),
  sellValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AssetMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.lazy(() => SortOrderSchema).optional(),
  sellValue: z.lazy(() => SortOrderSchema).optional(),
  assetType: z.lazy(() => SortOrderSchema).optional(),
  usageType: z.lazy(() => SortOrderSchema).optional(),
  ownershipType: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetMinOrderByAggregateInputSchema: z.ZodType<Prisma.AssetMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.lazy(() => SortOrderSchema).optional(),
  sellValue: z.lazy(() => SortOrderSchema).optional(),
  assetType: z.lazy(() => SortOrderSchema).optional(),
  usageType: z.lazy(() => SortOrderSchema).optional(),
  ownershipType: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetSumOrderByAggregateInputSchema: z.ZodType<Prisma.AssetSumOrderByAggregateInput> = z.object({
  surface: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  rentalValue: z.lazy(() => SortOrderSchema).optional(),
  sellValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringWithAggregatesFilterSchema: z.ZodType<Prisma.StringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const StringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.StringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const IntWithAggregatesFilterSchema: z.ZodType<Prisma.IntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const IntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.IntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const EnumAssetTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumAssetTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NestedEnumAssetTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetTypeNullableFilterSchema).optional()
}).strict();

export const EnumAssetUsageTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumAssetUsageTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetUsageTypeSchema).optional(),
  in: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => NestedEnumAssetUsageTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetUsageTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetUsageTypeFilterSchema).optional()
}).strict();

export const EnumAssetOwnershipTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumAssetOwnershipTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetOwnershipTypeSchema).optional(),
  in: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => NestedEnumAssetOwnershipTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetOwnershipTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetOwnershipTypeFilterSchema).optional()
}).strict();

export const EnumAssetCompanyTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumAssetCompanyTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NestedEnumAssetCompanyTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetCompanyTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetCompanyTypeNullableFilterSchema).optional()
}).strict();

export const DateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const AssetRelationFilterSchema: z.ZodType<Prisma.AssetRelationFilter> = z.object({
  is: z.lazy(() => AssetWhereInputSchema).optional(),
  isNot: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetJointOwnerCountOrderByAggregateInputSchema: z.ZodType<Prisma.AssetJointOwnerCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetJointOwnerAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AssetJointOwnerAvgOrderByAggregateInput> = z.object({
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetJointOwnerMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AssetJointOwnerMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetJointOwnerMinOrderByAggregateInputSchema: z.ZodType<Prisma.AssetJointOwnerMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetJointOwnerSumOrderByAggregateInputSchema: z.ZodType<Prisma.AssetJointOwnerSumOrderByAggregateInput> = z.object({
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumAssetShareholderTypeFilterSchema: z.ZodType<Prisma.EnumAssetShareholderTypeFilter> = z.object({
  equals: z.lazy(() => AssetShareholderTypeSchema).optional(),
  in: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => NestedEnumAssetShareholderTypeFilterSchema) ]).optional(),
}).strict();

export const AssetShareholderCountOrderByAggregateInputSchema: z.ZodType<Prisma.AssetShareholderCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetShareholderAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AssetShareholderAvgOrderByAggregateInput> = z.object({
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetShareholderMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AssetShareholderMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetShareholderMinOrderByAggregateInputSchema: z.ZodType<Prisma.AssetShareholderMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssetShareholderSumOrderByAggregateInputSchema: z.ZodType<Prisma.AssetShareholderSumOrderByAggregateInput> = z.object({
  sharePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumAssetShareholderTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumAssetShareholderTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetShareholderTypeSchema).optional(),
  in: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => NestedEnumAssetShareholderTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetShareholderTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetShareholderTypeFilterSchema).optional()
}).strict();

export const JsonFilterSchema: z.ZodType<Prisma.JsonFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional()
}).strict();

export const AssistantResponseCountOrderByAggregateInputSchema: z.ZodType<Prisma.AssistantResponseCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fileHash: z.lazy(() => SortOrderSchema).optional(),
  assistantId: z.lazy(() => SortOrderSchema).optional(),
  response: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssistantResponseMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AssistantResponseMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fileHash: z.lazy(() => SortOrderSchema).optional(),
  assistantId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AssistantResponseMinOrderByAggregateInputSchema: z.ZodType<Prisma.AssistantResponseMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  fileHash: z.lazy(() => SortOrderSchema).optional(),
  assistantId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const JsonWithAggregatesFilterSchema: z.ZodType<Prisma.JsonWithAggregatesFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedJsonFilterSchema).optional(),
  _max: z.lazy(() => NestedJsonFilterSchema).optional()
}).strict();

export const UserRelationFilterSchema: z.ZodType<Prisma.UserRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional(),
  isNot: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const AccountProviderProviderAccountIdCompoundUniqueInputSchema: z.ZodType<Prisma.AccountProviderProviderAccountIdCompoundUniqueInput> = z.object({
  provider: z.string(),
  providerAccountId: z.string()
}).strict();

export const AccountCountOrderByAggregateInputSchema: z.ZodType<Prisma.AccountCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AccountAvgOrderByAggregateInput> = z.object({
  expires_at: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMinOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  provider: z.lazy(() => SortOrderSchema).optional(),
  providerAccountId: z.lazy(() => SortOrderSchema).optional(),
  refresh_token: z.lazy(() => SortOrderSchema).optional(),
  access_token: z.lazy(() => SortOrderSchema).optional(),
  expires_at: z.lazy(() => SortOrderSchema).optional(),
  token_type: z.lazy(() => SortOrderSchema).optional(),
  scope: z.lazy(() => SortOrderSchema).optional(),
  id_token: z.lazy(() => SortOrderSchema).optional(),
  session_state: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountSumOrderByAggregateInputSchema: z.ZodType<Prisma.AccountSumOrderByAggregateInput> = z.object({
  expires_at: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionCountOrderByAggregateInputSchema: z.ZodType<Prisma.SessionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionMinOrderByAggregateInputSchema: z.ZodType<Prisma.SessionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  sessionToken: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenIdentifierTokenCompoundUniqueInputSchema: z.ZodType<Prisma.VerificationTokenIdentifierTokenCompoundUniqueInput> = z.object({
  identifier: z.string(),
  token: z.string()
}).strict();

export const VerificationTokenCountOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenCountOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenMaxOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenMaxOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const VerificationTokenMinOrderByAggregateInputSchema: z.ZodType<Prisma.VerificationTokenMinOrderByAggregateInput> = z.object({
  identifier: z.lazy(() => SortOrderSchema).optional(),
  token: z.lazy(() => SortOrderSchema).optional(),
  expires: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumProductNullableListFilterSchema: z.ZodType<Prisma.EnumProductNullableListFilter> = z.object({
  equals: z.lazy(() => ProductSchema).array().optional().nullable(),
  has: z.lazy(() => ProductSchema).optional().nullable(),
  hasEvery: z.lazy(() => ProductSchema).array().optional(),
  hasSome: z.lazy(() => ProductSchema).array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const EnumAssetTypeNullableListFilterSchema: z.ZodType<Prisma.EnumAssetTypeNullableListFilter> = z.object({
  equals: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  has: z.lazy(() => AssetTypeSchema).optional().nullable(),
  hasEvery: z.lazy(() => AssetTypeSchema).array().optional(),
  hasSome: z.lazy(() => AssetTypeSchema).array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const EnumBankLocalisationFilterSchema: z.ZodType<Prisma.EnumBankLocalisationFilter> = z.object({
  equals: z.lazy(() => BankLocalisationSchema).optional(),
  in: z.lazy(() => BankLocalisationSchema).array().optional(),
  notIn: z.lazy(() => BankLocalisationSchema).array().optional(),
  not: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => NestedEnumBankLocalisationFilterSchema) ]).optional(),
}).strict();

export const DateTimeNullableFilterSchema: z.ZodType<Prisma.DateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const SubmissionListRelationFilterSchema: z.ZodType<Prisma.SubmissionListRelationFilter> = z.object({
  every: z.lazy(() => SubmissionWhereInputSchema).optional(),
  some: z.lazy(() => SubmissionWhereInputSchema).optional(),
  none: z.lazy(() => SubmissionWhereInputSchema).optional()
}).strict();

export const SubmissionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SubmissionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BankCountOrderByAggregateInputSchema: z.ZodType<Prisma.BankCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  products: z.lazy(() => SortOrderSchema).optional(),
  minDealAmount: z.lazy(() => SortOrderSchema).optional(),
  assetTypes: z.lazy(() => SortOrderSchema).optional(),
  acceptedLocalisation: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BankAvgOrderByAggregateInputSchema: z.ZodType<Prisma.BankAvgOrderByAggregateInput> = z.object({
  minDealAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BankMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BankMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  minDealAmount: z.lazy(() => SortOrderSchema).optional(),
  acceptedLocalisation: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BankMinOrderByAggregateInputSchema: z.ZodType<Prisma.BankMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  minDealAmount: z.lazy(() => SortOrderSchema).optional(),
  acceptedLocalisation: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BankSumOrderByAggregateInputSchema: z.ZodType<Prisma.BankSumOrderByAggregateInput> = z.object({
  minDealAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumBankLocalisationWithAggregatesFilterSchema: z.ZodType<Prisma.EnumBankLocalisationWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BankLocalisationSchema).optional(),
  in: z.lazy(() => BankLocalisationSchema).array().optional(),
  notIn: z.lazy(() => BankLocalisationSchema).array().optional(),
  not: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => NestedEnumBankLocalisationWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBankLocalisationFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBankLocalisationFilterSchema).optional()
}).strict();

export const DateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.DateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const EnumCasePurposeFilterSchema: z.ZodType<Prisma.EnumCasePurposeFilter> = z.object({
  equals: z.lazy(() => CasePurposeSchema).optional(),
  in: z.lazy(() => CasePurposeSchema).array().optional(),
  notIn: z.lazy(() => CasePurposeSchema).array().optional(),
  not: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => NestedEnumCasePurposeFilterSchema) ]).optional(),
}).strict();

export const EnumCaseTypeFilterSchema: z.ZodType<Prisma.EnumCaseTypeFilter> = z.object({
  equals: z.lazy(() => CaseTypeSchema).optional(),
  in: z.lazy(() => CaseTypeSchema).array().optional(),
  notIn: z.lazy(() => CaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => NestedEnumCaseTypeFilterSchema) ]).optional(),
}).strict();

export const EnumCaseStatusFilterSchema: z.ZodType<Prisma.EnumCaseStatusFilter> = z.object({
  equals: z.lazy(() => CaseStatusSchema).optional(),
  in: z.lazy(() => CaseStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => NestedEnumCaseStatusFilterSchema) ]).optional(),
}).strict();

export const EnumCasePriorityFilterSchema: z.ZodType<Prisma.EnumCasePriorityFilter> = z.object({
  equals: z.lazy(() => CasePrioritySchema).optional(),
  in: z.lazy(() => CasePrioritySchema).array().optional(),
  notIn: z.lazy(() => CasePrioritySchema).array().optional(),
  not: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => NestedEnumCasePriorityFilterSchema) ]).optional(),
}).strict();

export const AssetListRelationFilterSchema: z.ZodType<Prisma.AssetListRelationFilter> = z.object({
  every: z.lazy(() => AssetWhereInputSchema).optional(),
  some: z.lazy(() => AssetWhereInputSchema).optional(),
  none: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const CaseTraceNullableRelationFilterSchema: z.ZodType<Prisma.CaseTraceNullableRelationFilter> = z.object({
  is: z.lazy(() => CaseTraceWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CaseTraceWhereInputSchema).optional().nullable()
}).strict();

export const CaseCollaboratorListRelationFilterSchema: z.ZodType<Prisma.CaseCollaboratorListRelationFilter> = z.object({
  every: z.lazy(() => CaseCollaboratorWhereInputSchema).optional(),
  some: z.lazy(() => CaseCollaboratorWhereInputSchema).optional(),
  none: z.lazy(() => CaseCollaboratorWhereInputSchema).optional()
}).strict();

export const SynthesisNullableRelationFilterSchema: z.ZodType<Prisma.SynthesisNullableRelationFilter> = z.object({
  is: z.lazy(() => SynthesisWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => SynthesisWhereInputSchema).optional().nullable()
}).strict();

export const SuggestionListRelationFilterSchema: z.ZodType<Prisma.SuggestionListRelationFilter> = z.object({
  every: z.lazy(() => SuggestionWhereInputSchema).optional(),
  some: z.lazy(() => SuggestionWhereInputSchema).optional(),
  none: z.lazy(() => SuggestionWhereInputSchema).optional()
}).strict();

export const SimulationListRelationFilterSchema: z.ZodType<Prisma.SimulationListRelationFilter> = z.object({
  every: z.lazy(() => SimulationWhereInputSchema).optional(),
  some: z.lazy(() => SimulationWhereInputSchema).optional(),
  none: z.lazy(() => SimulationWhereInputSchema).optional()
}).strict();

export const CaseMessageListRelationFilterSchema: z.ZodType<Prisma.CaseMessageListRelationFilter> = z.object({
  every: z.lazy(() => CaseMessageWhereInputSchema).optional(),
  some: z.lazy(() => CaseMessageWhereInputSchema).optional(),
  none: z.lazy(() => CaseMessageWhereInputSchema).optional()
}).strict();

export const CasePartnerListRelationFilterSchema: z.ZodType<Prisma.CasePartnerListRelationFilter> = z.object({
  every: z.lazy(() => CasePartnerWhereInputSchema).optional(),
  some: z.lazy(() => CasePartnerWhereInputSchema).optional(),
  none: z.lazy(() => CasePartnerWhereInputSchema).optional()
}).strict();

export const AssetOrderByRelationAggregateInputSchema: z.ZodType<Prisma.AssetOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseCollaboratorOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CaseCollaboratorOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SuggestionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SuggestionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SimulationOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SimulationOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseMessageOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CaseMessageOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CasePartnerOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CasePartnerOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseCountOrderByAggregateInputSchema: z.ZodType<Prisma.CaseCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  coCustomerId: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  attio_record_id: z.lazy(() => SortOrderSchema).optional(),
  purpose: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  caseType: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CaseAvgOrderByAggregateInput> = z.object({
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CaseMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  coCustomerId: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  attio_record_id: z.lazy(() => SortOrderSchema).optional(),
  purpose: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  caseType: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseMinOrderByAggregateInputSchema: z.ZodType<Prisma.CaseMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  coCustomerId: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  attio_record_id: z.lazy(() => SortOrderSchema).optional(),
  purpose: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  caseType: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseSumOrderByAggregateInputSchema: z.ZodType<Prisma.CaseSumOrderByAggregateInput> = z.object({
  referenceNo: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCasePurposeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCasePurposeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CasePurposeSchema).optional(),
  in: z.lazy(() => CasePurposeSchema).array().optional(),
  notIn: z.lazy(() => CasePurposeSchema).array().optional(),
  not: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => NestedEnumCasePurposeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCasePurposeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCasePurposeFilterSchema).optional()
}).strict();

export const EnumCaseTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCaseTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseTypeSchema).optional(),
  in: z.lazy(() => CaseTypeSchema).array().optional(),
  notIn: z.lazy(() => CaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => NestedEnumCaseTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseTypeFilterSchema).optional()
}).strict();

export const EnumCaseStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCaseStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseStatusSchema).optional(),
  in: z.lazy(() => CaseStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => NestedEnumCaseStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseStatusFilterSchema).optional()
}).strict();

export const EnumCasePriorityWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCasePriorityWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CasePrioritySchema).optional(),
  in: z.lazy(() => CasePrioritySchema).array().optional(),
  notIn: z.lazy(() => CasePrioritySchema).array().optional(),
  not: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => NestedEnumCasePriorityWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCasePriorityFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCasePriorityFilterSchema).optional()
}).strict();

export const CaseRelationFilterSchema: z.ZodType<Prisma.CaseRelationFilter> = z.object({
  is: z.lazy(() => CaseWhereInputSchema).optional(),
  isNot: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseCollaboratorCountOrderByAggregateInputSchema: z.ZodType<Prisma.CaseCollaboratorCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseCollaboratorMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CaseCollaboratorMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseCollaboratorMinOrderByAggregateInputSchema: z.ZodType<Prisma.CaseCollaboratorMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const JsonNullableFilterSchema: z.ZodType<Prisma.JsonNullableFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional()
}).strict();

export const UserNullableRelationFilterSchema: z.ZodType<Prisma.UserNullableRelationFilter> = z.object({
  is: z.lazy(() => UserWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => UserWhereInputSchema).optional().nullable()
}).strict();

export const CaseTraceCountOrderByAggregateInputSchema: z.ZodType<Prisma.CaseTraceCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  answersInput: z.lazy(() => SortOrderSchema).optional(),
  contactInput: z.lazy(() => SortOrderSchema).optional(),
  dbCreateOutput: z.lazy(() => SortOrderSchema).optional(),
  dbCreateError: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseTraceMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CaseTraceMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseTraceMinOrderByAggregateInputSchema: z.ZodType<Prisma.CaseTraceMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const JsonNullableWithAggregatesFilterSchema: z.ZodType<Prisma.JsonNullableWithAggregatesFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedJsonNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedJsonNullableFilterSchema).optional()
}).strict();

export const EnumUserRoleFilterSchema: z.ZodType<Prisma.EnumUserRoleFilter> = z.object({
  equals: z.lazy(() => UserRoleSchema).optional(),
  in: z.lazy(() => UserRoleSchema).array().optional(),
  notIn: z.lazy(() => UserRoleSchema).array().optional(),
  not: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => NestedEnumUserRoleFilterSchema) ]).optional(),
}).strict();

export const EnumCaseMessageModelFilterSchema: z.ZodType<Prisma.EnumCaseMessageModelFilter> = z.object({
  equals: z.lazy(() => CaseMessageModelSchema).optional(),
  in: z.lazy(() => CaseMessageModelSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageModelSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => NestedEnumCaseMessageModelFilterSchema) ]).optional(),
}).strict();

export const EnumCaseMessageStatusFilterSchema: z.ZodType<Prisma.EnumCaseMessageStatusFilter> = z.object({
  equals: z.lazy(() => CaseMessageStatusSchema).optional(),
  in: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => NestedEnumCaseMessageStatusFilterSchema) ]).optional(),
}).strict();

export const CaseMessageCountOrderByAggregateInputSchema: z.ZodType<Prisma.CaseMessageCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  senderRole: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  modelData: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseMessageMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CaseMessageMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  senderRole: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseMessageMinOrderByAggregateInputSchema: z.ZodType<Prisma.CaseMessageMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  senderRole: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumUserRoleWithAggregatesFilterSchema: z.ZodType<Prisma.EnumUserRoleWithAggregatesFilter> = z.object({
  equals: z.lazy(() => UserRoleSchema).optional(),
  in: z.lazy(() => UserRoleSchema).array().optional(),
  notIn: z.lazy(() => UserRoleSchema).array().optional(),
  not: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => NestedEnumUserRoleWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumUserRoleFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumUserRoleFilterSchema).optional()
}).strict();

export const EnumCaseMessageModelWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCaseMessageModelWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseMessageModelSchema).optional(),
  in: z.lazy(() => CaseMessageModelSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageModelSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => NestedEnumCaseMessageModelWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseMessageModelFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseMessageModelFilterSchema).optional()
}).strict();

export const EnumCaseMessageStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCaseMessageStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseMessageStatusSchema).optional(),
  in: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => NestedEnumCaseMessageStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseMessageStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseMessageStatusFilterSchema).optional()
}).strict();

export const EnumCompanyTypeFilterSchema: z.ZodType<Prisma.EnumCompanyTypeFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional(),
  in: z.lazy(() => CompanyTypeSchema).array().optional(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeFilterSchema) ]).optional(),
}).strict();

export const EnumTaxTypeNullableFilterSchema: z.ZodType<Prisma.EnumTaxTypeNullableFilter> = z.object({
  equals: z.lazy(() => TaxTypeSchema).optional().nullable(),
  in: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NestedEnumTaxTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const CaseListRelationFilterSchema: z.ZodType<Prisma.CaseListRelationFilter> = z.object({
  every: z.lazy(() => CaseWhereInputSchema).optional(),
  some: z.lazy(() => CaseWhereInputSchema).optional(),
  none: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CompanyRentIncomeListRelationFilterSchema: z.ZodType<Prisma.CompanyRentIncomeListRelationFilter> = z.object({
  every: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional(),
  some: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional(),
  none: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional()
}).strict();

export const CompanyPappersNullableRelationFilterSchema: z.ZodType<Prisma.CompanyPappersNullableRelationFilter> = z.object({
  is: z.lazy(() => CompanyPappersWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CompanyPappersWhereInputSchema).optional().nullable()
}).strict();

export const CompanyFinancialDataListRelationFilterSchema: z.ZodType<Prisma.CompanyFinancialDataListRelationFilter> = z.object({
  every: z.lazy(() => CompanyFinancialDataWhereInputSchema).optional(),
  some: z.lazy(() => CompanyFinancialDataWhereInputSchema).optional(),
  none: z.lazy(() => CompanyFinancialDataWhereInputSchema).optional()
}).strict();

export const CaseOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CaseOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRentIncomeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CompanyRentIncomeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyFinancialDataOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CompanyFinancialDataOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyCountOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyMinOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  inseeCode: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCompanyTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCompanyTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional(),
  in: z.lazy(() => CompanyTypeSchema).array().optional(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCompanyTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCompanyTypeFilterSchema).optional()
}).strict();

export const EnumTaxTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumTaxTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => TaxTypeSchema).optional().nullable(),
  in: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NestedEnumTaxTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumTaxTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumTaxTypeNullableFilterSchema).optional()
}).strict();

export const EnumLoanGuaranteeFilterSchema: z.ZodType<Prisma.EnumLoanGuaranteeFilter> = z.object({
  equals: z.lazy(() => LoanGuaranteeSchema).optional(),
  in: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  notIn: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => NestedEnumLoanGuaranteeFilterSchema) ]).optional(),
}).strict();

export const FloatFilterSchema: z.ZodType<Prisma.FloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const EnumLoanTypeFilterSchema: z.ZodType<Prisma.EnumLoanTypeFilter> = z.object({
  equals: z.lazy(() => LoanTypeSchema).optional(),
  in: z.lazy(() => LoanTypeSchema).array().optional(),
  notIn: z.lazy(() => LoanTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => NestedEnumLoanTypeFilterSchema) ]).optional(),
}).strict();

export const CompanyRelationFilterSchema: z.ZodType<Prisma.CompanyRelationFilter> = z.object({
  is: z.lazy(() => CompanyWhereInputSchema).optional(),
  isNot: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeAvgOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeSumOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumLoanGuaranteeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumLoanGuaranteeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => LoanGuaranteeSchema).optional(),
  in: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  notIn: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => NestedEnumLoanGuaranteeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumLoanGuaranteeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumLoanGuaranteeFilterSchema).optional()
}).strict();

export const FloatWithAggregatesFilterSchema: z.ZodType<Prisma.FloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const EnumLoanTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumLoanTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => LoanTypeSchema).optional(),
  in: z.lazy(() => LoanTypeSchema).array().optional(),
  notIn: z.lazy(() => LoanTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => NestedEnumLoanTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumLoanTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumLoanTypeFilterSchema).optional()
}).strict();

export const AssetNullableRelationFilterSchema: z.ZodType<Prisma.AssetNullableRelationFilter> = z.object({
  is: z.lazy(() => AssetWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => AssetWhereInputSchema).optional().nullable()
}).strict();

export const CompanyRentIncomeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRentIncomeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRentIncomeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRentIncomeAvgOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRentIncomeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRentIncomeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRentIncomeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRentIncomeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyRentIncomeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyRentIncomeSumOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatNullableFilterSchema: z.ZodType<Prisma.FloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const CompanyFinancialDataCountOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyFinancialDataCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.lazy(() => SortOrderSchema).optional(),
  depreciationCharge: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional(),
  loanInterest: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  balanceSheetSize: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  accountValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyFinancialDataAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyFinancialDataAvgOrderByAggregateInput> = z.object({
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.lazy(() => SortOrderSchema).optional(),
  depreciationCharge: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional(),
  loanInterest: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  balanceSheetSize: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  accountValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyFinancialDataMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyFinancialDataMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.lazy(() => SortOrderSchema).optional(),
  depreciationCharge: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional(),
  loanInterest: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  balanceSheetSize: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  accountValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyFinancialDataMinOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyFinancialDataMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.lazy(() => SortOrderSchema).optional(),
  depreciationCharge: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional(),
  loanInterest: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  balanceSheetSize: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  accountValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyFinancialDataSumOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyFinancialDataSumOrderByAggregateInput> = z.object({
  year: z.lazy(() => SortOrderSchema).optional(),
  exploitationResult: z.lazy(() => SortOrderSchema).optional(),
  depreciationCharge: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional(),
  loanInterest: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  balanceSheetSize: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  accountValue: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatNullableWithAggregatesFilterSchema: z.ZodType<Prisma.FloatNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatNullableFilterSchema).optional()
}).strict();

export const EnumMaritalStatusNullableFilterSchema: z.ZodType<Prisma.EnumMaritalStatusNullableFilter> = z.object({
  equals: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  in: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NestedEnumMaritalStatusNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const EnumMarriageTypeNullableFilterSchema: z.ZodType<Prisma.EnumMarriageTypeNullableFilter> = z.object({
  equals: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  in: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NestedEnumMarriageTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const EnumJobTypeFilterSchema: z.ZodType<Prisma.EnumJobTypeFilter> = z.object({
  equals: z.lazy(() => JobTypeSchema).optional(),
  in: z.lazy(() => JobTypeSchema).array().optional(),
  notIn: z.lazy(() => JobTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => NestedEnumJobTypeFilterSchema) ]).optional(),
}).strict();

export const EnumHousingStatusNullableFilterSchema: z.ZodType<Prisma.EnumHousingStatusNullableFilter> = z.object({
  equals: z.lazy(() => HousingStatusSchema).optional().nullable(),
  in: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NestedEnumHousingStatusNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const CustomerEmployeeNullableRelationFilterSchema: z.ZodType<Prisma.CustomerEmployeeNullableRelationFilter> = z.object({
  is: z.lazy(() => CustomerEmployeeWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CustomerEmployeeWhereInputSchema).optional().nullable()
}).strict();

export const CustomerRetiredNullableRelationFilterSchema: z.ZodType<Prisma.CustomerRetiredNullableRelationFilter> = z.object({
  is: z.lazy(() => CustomerRetiredWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CustomerRetiredWhereInputSchema).optional().nullable()
}).strict();

export const CustomerSelfEmployedNullableRelationFilterSchema: z.ZodType<Prisma.CustomerSelfEmployedNullableRelationFilter> = z.object({
  is: z.lazy(() => CustomerSelfEmployedWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => CustomerSelfEmployedWhereInputSchema).optional().nullable()
}).strict();

export const CustomerInvestmentListRelationFilterSchema: z.ZodType<Prisma.CustomerInvestmentListRelationFilter> = z.object({
  every: z.lazy(() => CustomerInvestmentWhereInputSchema).optional(),
  some: z.lazy(() => CustomerInvestmentWhereInputSchema).optional(),
  none: z.lazy(() => CustomerInvestmentWhereInputSchema).optional()
}).strict();

export const CustomerAdditionalIncomeListRelationFilterSchema: z.ZodType<Prisma.CustomerAdditionalIncomeListRelationFilter> = z.object({
  every: z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).optional(),
  some: z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).optional(),
  none: z.lazy(() => CustomerAdditionalIncomeWhereInputSchema).optional()
}).strict();

export const CustomerRentIncomeListRelationFilterSchema: z.ZodType<Prisma.CustomerRentIncomeListRelationFilter> = z.object({
  every: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional(),
  some: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional(),
  none: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeListRelationFilterSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeListRelationFilter> = z.object({
  every: z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).optional(),
  some: z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).optional(),
  none: z.lazy(() => CustomerConsumerLoanChargeWhereInputSchema).optional()
}).strict();

export const CustomerRentChargeListRelationFilterSchema: z.ZodType<Prisma.CustomerRentChargeListRelationFilter> = z.object({
  every: z.lazy(() => CustomerRentChargeWhereInputSchema).optional(),
  some: z.lazy(() => CustomerRentChargeWhereInputSchema).optional(),
  none: z.lazy(() => CustomerRentChargeWhereInputSchema).optional()
}).strict();

export const CustomerAdditionalChargeListRelationFilterSchema: z.ZodType<Prisma.CustomerAdditionalChargeListRelationFilter> = z.object({
  every: z.lazy(() => CustomerAdditionalChargeWhereInputSchema).optional(),
  some: z.lazy(() => CustomerAdditionalChargeWhereInputSchema).optional(),
  none: z.lazy(() => CustomerAdditionalChargeWhereInputSchema).optional()
}).strict();

export const CustomerInvestmentOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerInvestmentOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalIncomeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentIncomeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerRentIncomeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentChargeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerRentChargeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalChargeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  maritalStatus: z.lazy(() => SortOrderSchema).optional(),
  marriagetype: z.lazy(() => SortOrderSchema).optional(),
  jobType: z.lazy(() => SortOrderSchema).optional(),
  housingStatus: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAvgOrderByAggregateInput> = z.object({
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  maritalStatus: z.lazy(() => SortOrderSchema).optional(),
  marriagetype: z.lazy(() => SortOrderSchema).optional(),
  jobType: z.lazy(() => SortOrderSchema).optional(),
  housingStatus: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.lazy(() => SortOrderSchema).optional(),
  address: z.lazy(() => SortOrderSchema).optional(),
  zipcode: z.lazy(() => SortOrderSchema).optional(),
  city: z.lazy(() => SortOrderSchema).optional(),
  country: z.lazy(() => SortOrderSchema).optional(),
  maritalStatus: z.lazy(() => SortOrderSchema).optional(),
  marriagetype: z.lazy(() => SortOrderSchema).optional(),
  jobType: z.lazy(() => SortOrderSchema).optional(),
  housingStatus: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerSumOrderByAggregateInput> = z.object({
  age: z.lazy(() => SortOrderSchema).optional(),
  childrenSupportedCount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumMaritalStatusNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumMaritalStatusNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  in: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NestedEnumMaritalStatusNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumMaritalStatusNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumMaritalStatusNullableFilterSchema).optional()
}).strict();

export const EnumMarriageTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumMarriageTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  in: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NestedEnumMarriageTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumMarriageTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumMarriageTypeNullableFilterSchema).optional()
}).strict();

export const EnumJobTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumJobTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => JobTypeSchema).optional(),
  in: z.lazy(() => JobTypeSchema).array().optional(),
  notIn: z.lazy(() => JobTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => NestedEnumJobTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumJobTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumJobTypeFilterSchema).optional()
}).strict();

export const EnumHousingStatusNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumHousingStatusNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => HousingStatusSchema).optional().nullable(),
  in: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NestedEnumHousingStatusNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumHousingStatusNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumHousingStatusNullableFilterSchema).optional()
}).strict();

export const EnumCustomerEmployeeTypeFilterSchema: z.ZodType<Prisma.EnumCustomerEmployeeTypeFilter> = z.object({
  equals: z.lazy(() => CustomerEmployeeTypeSchema).optional(),
  in: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => NestedEnumCustomerEmployeeTypeFilterSchema) ]).optional(),
}).strict();

export const BoolNullableFilterSchema: z.ZodType<Prisma.BoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const CustomerRelationFilterSchema: z.ZodType<Prisma.CustomerRelationFilter> = z.object({
  is: z.lazy(() => CustomerWhereInputSchema).optional(),
  isNot: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerEmployeeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerEmployeeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  isTrialing: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerEmployeeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerEmployeeAvgOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerEmployeeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerEmployeeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  isTrialing: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerEmployeeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerEmployeeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  isTrialing: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerEmployeeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerEmployeeSumOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCustomerEmployeeTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCustomerEmployeeTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CustomerEmployeeTypeSchema).optional(),
  in: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => NestedEnumCustomerEmployeeTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCustomerEmployeeTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCustomerEmployeeTypeFilterSchema).optional()
}).strict();

export const BoolNullableWithAggregatesFilterSchema: z.ZodType<Prisma.BoolNullableWithAggregatesFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolNullableFilterSchema).optional()
}).strict();

export const CustomerRetiredCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRetiredCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRetiredAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRetiredAvgOrderByAggregateInput> = z.object({
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRetiredMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRetiredMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRetiredMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRetiredMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRetiredSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRetiredSumOrderByAggregateInput> = z.object({
  monthlyPensionIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BoolFilterSchema: z.ZodType<Prisma.BoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  hasEnoughAnnualReviews: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerSelfEmployedAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedAvgOrderByAggregateInput> = z.object({
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerSelfEmployedMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  hasEnoughAnnualReviews: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerSelfEmployedMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional(),
  hasEnoughAnnualReviews: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerSelfEmployedSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerSelfEmployedSumOrderByAggregateInput> = z.object({
  lastYearAverageMonthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BoolWithAggregatesFilterSchema: z.ZodType<Prisma.BoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict();

export const CustomerInvestmentCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerInvestmentCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerInvestmentAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerInvestmentAvgOrderByAggregateInput> = z.object({
  amount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerInvestmentMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerInvestmentMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerInvestmentMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerInvestmentMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerInvestmentSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerInvestmentSumOrderByAggregateInput> = z.object({
  amount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCustomerAdditionalIncomeTypeFilterSchema: z.ZodType<Prisma.EnumCustomerAdditionalIncomeTypeFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalIncomeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeFilterSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalIncomeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeAvgOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalIncomeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalIncomeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalIncomeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeSumOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCustomerAdditionalIncomeTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCustomerAdditionalIncomeTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalIncomeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeFilterSchema).optional()
}).strict();

export const CustomerRentIncomeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentIncomeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentIncomeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentIncomeAvgOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentIncomeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentIncomeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentIncomeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentIncomeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentIncomeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentIncomeSumOrderByAggregateInput> = z.object({
  monthlyIncome: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentChargeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentChargeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentChargeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentChargeAvgOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentChargeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentChargeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentChargeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentChargeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRentChargeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRentChargeSumOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeAvgOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerConsumerLoanChargeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeSumOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeAvgOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  assetId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  guarantee: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeSumOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  loanAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCustomerAdditionalChargeTypeFilterSchema: z.ZodType<Prisma.EnumCustomerAdditionalChargeTypeFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalChargeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalChargeTypeFilterSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeCountOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalChargeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeAvgOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalChargeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalChargeMinOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  monthlyCharge: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CustomerAdditionalChargeSumOrderByAggregateInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeSumOrderByAggregateInput> = z.object({
  monthlyCharge: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumCustomerAdditionalChargeTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCustomerAdditionalChargeTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalChargeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalChargeTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCustomerAdditionalChargeTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCustomerAdditionalChargeTypeFilterSchema).optional()
}).strict();

export const EnumNotificationModelFilterSchema: z.ZodType<Prisma.EnumNotificationModelFilter> = z.object({
  equals: z.lazy(() => NotificationModelSchema).optional(),
  in: z.lazy(() => NotificationModelSchema).array().optional(),
  notIn: z.lazy(() => NotificationModelSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => NestedEnumNotificationModelFilterSchema) ]).optional(),
}).strict();

export const EnumNotificationStatusFilterSchema: z.ZodType<Prisma.EnumNotificationStatusFilter> = z.object({
  equals: z.lazy(() => NotificationStatusSchema).optional(),
  in: z.lazy(() => NotificationStatusSchema).array().optional(),
  notIn: z.lazy(() => NotificationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => NestedEnumNotificationStatusFilterSchema) ]).optional(),
}).strict();

export const NotificationCountOrderByAggregateInputSchema: z.ZodType<Prisma.NotificationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  receiverId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  modelData: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const NotificationMaxOrderByAggregateInputSchema: z.ZodType<Prisma.NotificationMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  receiverId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const NotificationMinOrderByAggregateInputSchema: z.ZodType<Prisma.NotificationMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  receiverId: z.lazy(() => SortOrderSchema).optional(),
  senderId: z.lazy(() => SortOrderSchema).optional(),
  model: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumNotificationModelWithAggregatesFilterSchema: z.ZodType<Prisma.EnumNotificationModelWithAggregatesFilter> = z.object({
  equals: z.lazy(() => NotificationModelSchema).optional(),
  in: z.lazy(() => NotificationModelSchema).array().optional(),
  notIn: z.lazy(() => NotificationModelSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => NestedEnumNotificationModelWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumNotificationModelFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumNotificationModelFilterSchema).optional()
}).strict();

export const EnumNotificationStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumNotificationStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => NotificationStatusSchema).optional(),
  in: z.lazy(() => NotificationStatusSchema).array().optional(),
  notIn: z.lazy(() => NotificationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => NestedEnumNotificationStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumNotificationStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumNotificationStatusFilterSchema).optional()
}).strict();

export const PartnerNullableRelationFilterSchema: z.ZodType<Prisma.PartnerNullableRelationFilter> = z.object({
  is: z.lazy(() => PartnerWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => PartnerWhereInputSchema).optional().nullable()
}).strict();

export const PappersRepresentativeListRelationFilterSchema: z.ZodType<Prisma.PappersRepresentativeListRelationFilter> = z.object({
  every: z.lazy(() => PappersRepresentativeWhereInputSchema).optional(),
  some: z.lazy(() => PappersRepresentativeWhereInputSchema).optional(),
  none: z.lazy(() => PappersRepresentativeWhereInputSchema).optional()
}).strict();

export const PappersBeneficiaryListRelationFilterSchema: z.ZodType<Prisma.PappersBeneficiaryListRelationFilter> = z.object({
  every: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional(),
  some: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional(),
  none: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional()
}).strict();

export const PappersCollectiveConventionListRelationFilterSchema: z.ZodType<Prisma.PappersCollectiveConventionListRelationFilter> = z.object({
  every: z.lazy(() => PappersCollectiveConventionWhereInputSchema).optional(),
  some: z.lazy(() => PappersCollectiveConventionWhereInputSchema).optional(),
  none: z.lazy(() => PappersCollectiveConventionWhereInputSchema).optional()
}).strict();

export const PappersCollectiveProcedureListRelationFilterSchema: z.ZodType<Prisma.PappersCollectiveProcedureListRelationFilter> = z.object({
  every: z.lazy(() => PappersCollectiveProcedureWhereInputSchema).optional(),
  some: z.lazy(() => PappersCollectiveProcedureWhereInputSchema).optional(),
  none: z.lazy(() => PappersCollectiveProcedureWhereInputSchema).optional()
}).strict();

export const PappersFinancialDataListRelationFilterSchema: z.ZodType<Prisma.PappersFinancialDataListRelationFilter> = z.object({
  every: z.lazy(() => PappersFinancialDataWhereInputSchema).optional(),
  some: z.lazy(() => PappersFinancialDataWhereInputSchema).optional(),
  none: z.lazy(() => PappersFinancialDataWhereInputSchema).optional()
}).strict();

export const PappersRepresentativeOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PappersRepresentativeOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersBeneficiaryOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PappersBeneficiaryOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveConventionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveProcedureOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersFinancialDataOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PappersFinancialDataOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyPappersCountOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyPappersCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  formatedSiret: z.lazy(() => SortOrderSchema).optional(),
  formatedSiren: z.lazy(() => SortOrderSchema).optional(),
  creationDate: z.lazy(() => SortOrderSchema).optional(),
  isActive: z.lazy(() => SortOrderSchema).optional(),
  legalStatus: z.lazy(() => SortOrderSchema).optional(),
  tvaNumber: z.lazy(() => SortOrderSchema).optional(),
  rcsNumber: z.lazy(() => SortOrderSchema).optional(),
  capital: z.lazy(() => SortOrderSchema).optional(),
  primaryActivity: z.lazy(() => SortOrderSchema).optional(),
  nafCode: z.lazy(() => SortOrderSchema).optional(),
  nafCodeLabel: z.lazy(() => SortOrderSchema).optional(),
  activity: z.lazy(() => SortOrderSchema).optional(),
  exerciseForm: z.lazy(() => SortOrderSchema).optional(),
  workForce: z.lazy(() => SortOrderSchema).optional(),
  workForceYear: z.lazy(() => SortOrderSchema).optional(),
  financialYearEndDate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyPappersAvgOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyPappersAvgOrderByAggregateInput> = z.object({
  capital: z.lazy(() => SortOrderSchema).optional(),
  workForceYear: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyPappersMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyPappersMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  formatedSiret: z.lazy(() => SortOrderSchema).optional(),
  formatedSiren: z.lazy(() => SortOrderSchema).optional(),
  creationDate: z.lazy(() => SortOrderSchema).optional(),
  isActive: z.lazy(() => SortOrderSchema).optional(),
  legalStatus: z.lazy(() => SortOrderSchema).optional(),
  tvaNumber: z.lazy(() => SortOrderSchema).optional(),
  rcsNumber: z.lazy(() => SortOrderSchema).optional(),
  capital: z.lazy(() => SortOrderSchema).optional(),
  primaryActivity: z.lazy(() => SortOrderSchema).optional(),
  nafCode: z.lazy(() => SortOrderSchema).optional(),
  nafCodeLabel: z.lazy(() => SortOrderSchema).optional(),
  activity: z.lazy(() => SortOrderSchema).optional(),
  exerciseForm: z.lazy(() => SortOrderSchema).optional(),
  workForce: z.lazy(() => SortOrderSchema).optional(),
  workForceYear: z.lazy(() => SortOrderSchema).optional(),
  financialYearEndDate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyPappersMinOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyPappersMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyId: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  formatedSiret: z.lazy(() => SortOrderSchema).optional(),
  formatedSiren: z.lazy(() => SortOrderSchema).optional(),
  creationDate: z.lazy(() => SortOrderSchema).optional(),
  isActive: z.lazy(() => SortOrderSchema).optional(),
  legalStatus: z.lazy(() => SortOrderSchema).optional(),
  tvaNumber: z.lazy(() => SortOrderSchema).optional(),
  rcsNumber: z.lazy(() => SortOrderSchema).optional(),
  capital: z.lazy(() => SortOrderSchema).optional(),
  primaryActivity: z.lazy(() => SortOrderSchema).optional(),
  nafCode: z.lazy(() => SortOrderSchema).optional(),
  nafCodeLabel: z.lazy(() => SortOrderSchema).optional(),
  activity: z.lazy(() => SortOrderSchema).optional(),
  exerciseForm: z.lazy(() => SortOrderSchema).optional(),
  workForce: z.lazy(() => SortOrderSchema).optional(),
  workForceYear: z.lazy(() => SortOrderSchema).optional(),
  financialYearEndDate: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyPappersSumOrderByAggregateInputSchema: z.ZodType<Prisma.CompanyPappersSumOrderByAggregateInput> = z.object({
  capital: z.lazy(() => SortOrderSchema).optional(),
  workForceYear: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersSanctionListRelationFilterSchema: z.ZodType<Prisma.PappersSanctionListRelationFilter> = z.object({
  every: z.lazy(() => PappersSanctionWhereInputSchema).optional(),
  some: z.lazy(() => PappersSanctionWhereInputSchema).optional(),
  none: z.lazy(() => PappersSanctionWhereInputSchema).optional()
}).strict();

export const PappersSanctionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PappersSanctionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersRepresentativeCountOrderByAggregateInputSchema: z.ZodType<Prisma.PappersRepresentativeCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  hasOngoingSanction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersRepresentativeAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PappersRepresentativeAvgOrderByAggregateInput> = z.object({
  age: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersRepresentativeMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PappersRepresentativeMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  hasOngoingSanction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersRepresentativeMinOrderByAggregateInputSchema: z.ZodType<Prisma.PappersRepresentativeMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  age: z.lazy(() => SortOrderSchema).optional(),
  hasOngoingSanction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersRepresentativeSumOrderByAggregateInputSchema: z.ZodType<Prisma.PappersRepresentativeSumOrderByAggregateInput> = z.object({
  age: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersBeneficiaryCountOrderByAggregateInputSchema: z.ZodType<Prisma.PappersBeneficiaryCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  sharesPercentage: z.lazy(() => SortOrderSchema).optional(),
  votePercentage: z.lazy(() => SortOrderSchema).optional(),
  hasOngoingSanction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersBeneficiaryAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PappersBeneficiaryAvgOrderByAggregateInput> = z.object({
  sharesPercentage: z.lazy(() => SortOrderSchema).optional(),
  votePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersBeneficiaryMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PappersBeneficiaryMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  sharesPercentage: z.lazy(() => SortOrderSchema).optional(),
  votePercentage: z.lazy(() => SortOrderSchema).optional(),
  hasOngoingSanction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersBeneficiaryMinOrderByAggregateInputSchema: z.ZodType<Prisma.PappersBeneficiaryMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  sharesPercentage: z.lazy(() => SortOrderSchema).optional(),
  votePercentage: z.lazy(() => SortOrderSchema).optional(),
  hasOngoingSanction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersBeneficiarySumOrderByAggregateInputSchema: z.ZodType<Prisma.PappersBeneficiarySumOrderByAggregateInput> = z.object({
  sharesPercentage: z.lazy(() => SortOrderSchema).optional(),
  votePercentage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveConventionCountOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  idcc: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveConventionAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionAvgOrderByAggregateInput> = z.object({
  idcc: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveConventionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  idcc: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveConventionMinOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  idcc: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveConventionSumOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveConventionSumOrderByAggregateInput> = z.object({
  idcc: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveProcedureCountOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  startDate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveProcedureMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  startDate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersCollectiveProcedureMinOrderByAggregateInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  startDate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersFinancialDataCountOrderByAggregateInputSchema: z.ZodType<Prisma.PappersFinancialDataCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  operatingResults: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  charges: z.lazy(() => SortOrderSchema).optional(),
  repaymentCapacity: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersFinancialDataAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PappersFinancialDataAvgOrderByAggregateInput> = z.object({
  year: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  operatingResults: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  charges: z.lazy(() => SortOrderSchema).optional(),
  repaymentCapacity: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersFinancialDataMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PappersFinancialDataMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  operatingResults: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  charges: z.lazy(() => SortOrderSchema).optional(),
  repaymentCapacity: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersFinancialDataMinOrderByAggregateInputSchema: z.ZodType<Prisma.PappersFinancialDataMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  companyPappersId: z.lazy(() => SortOrderSchema).optional(),
  year: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  operatingResults: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  charges: z.lazy(() => SortOrderSchema).optional(),
  repaymentCapacity: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersFinancialDataSumOrderByAggregateInputSchema: z.ZodType<Prisma.PappersFinancialDataSumOrderByAggregateInput> = z.object({
  year: z.lazy(() => SortOrderSchema).optional(),
  revenue: z.lazy(() => SortOrderSchema).optional(),
  operatingResults: z.lazy(() => SortOrderSchema).optional(),
  liquidity: z.lazy(() => SortOrderSchema).optional(),
  charges: z.lazy(() => SortOrderSchema).optional(),
  repaymentCapacity: z.lazy(() => SortOrderSchema).optional(),
  equity: z.lazy(() => SortOrderSchema).optional(),
  netProfit: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersRepresentativeNullableRelationFilterSchema: z.ZodType<Prisma.PappersRepresentativeNullableRelationFilter> = z.object({
  is: z.lazy(() => PappersRepresentativeWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => PappersRepresentativeWhereInputSchema).optional().nullable()
}).strict();

export const PappersBeneficiaryNullableRelationFilterSchema: z.ZodType<Prisma.PappersBeneficiaryNullableRelationFilter> = z.object({
  is: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional().nullable(),
  isNot: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional().nullable()
}).strict();

export const PappersSanctionCountOrderByAggregateInputSchema: z.ZodType<Prisma.PappersSanctionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  pappersRepresentativeId: z.lazy(() => SortOrderSchema).optional(),
  pappersBeneficiaryId: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  autority: z.lazy(() => SortOrderSchema).optional(),
  isOngoing: z.lazy(() => SortOrderSchema).optional(),
  startDate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersSanctionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PappersSanctionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  pappersRepresentativeId: z.lazy(() => SortOrderSchema).optional(),
  pappersBeneficiaryId: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  autority: z.lazy(() => SortOrderSchema).optional(),
  isOngoing: z.lazy(() => SortOrderSchema).optional(),
  startDate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PappersSanctionMinOrderByAggregateInputSchema: z.ZodType<Prisma.PappersSanctionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  pappersRepresentativeId: z.lazy(() => SortOrderSchema).optional(),
  pappersBeneficiaryId: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  autority: z.lazy(() => SortOrderSchema).optional(),
  isOngoing: z.lazy(() => SortOrderSchema).optional(),
  startDate: z.lazy(() => SortOrderSchema).optional(),
  endDate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumPartnerStatusFilterSchema: z.ZodType<Prisma.EnumPartnerStatusFilter> = z.object({
  equals: z.lazy(() => PartnerStatusSchema).optional(),
  in: z.lazy(() => PartnerStatusSchema).array().optional(),
  notIn: z.lazy(() => PartnerStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => NestedEnumPartnerStatusFilterSchema) ]).optional(),
}).strict();

export const EnumPartnerProfessionFilterSchema: z.ZodType<Prisma.EnumPartnerProfessionFilter> = z.object({
  equals: z.lazy(() => PartnerProfessionSchema).optional(),
  in: z.lazy(() => PartnerProfessionSchema).array().optional(),
  notIn: z.lazy(() => PartnerProfessionSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => NestedEnumPartnerProfessionFilterSchema) ]).optional(),
}).strict();

export const EnumPartnerLoanVolumeFilterSchema: z.ZodType<Prisma.EnumPartnerLoanVolumeFilter> = z.object({
  equals: z.lazy(() => PartnerLoanVolumeSchema).optional(),
  in: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  notIn: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => NestedEnumPartnerLoanVolumeFilterSchema) ]).optional(),
}).strict();

export const EnumPartnerClientTypeNullableListFilterSchema: z.ZodType<Prisma.EnumPartnerClientTypeNullableListFilter> = z.object({
  equals: z.lazy(() => PartnerClientTypeSchema).array().optional().nullable(),
  has: z.lazy(() => PartnerClientTypeSchema).optional().nullable(),
  hasEvery: z.lazy(() => PartnerClientTypeSchema).array().optional(),
  hasSome: z.lazy(() => PartnerClientTypeSchema).array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const EnumPartnerUsageCasesNullableListFilterSchema: z.ZodType<Prisma.EnumPartnerUsageCasesNullableListFilter> = z.object({
  equals: z.lazy(() => PartnerUsageCasesSchema).array().optional().nullable(),
  has: z.lazy(() => PartnerUsageCasesSchema).optional().nullable(),
  hasEvery: z.lazy(() => PartnerUsageCasesSchema).array().optional(),
  hasSome: z.lazy(() => PartnerUsageCasesSchema).array().optional(),
  isEmpty: z.boolean().optional()
}).strict();

export const EnumPartnerCompanySizeFilterSchema: z.ZodType<Prisma.EnumPartnerCompanySizeFilter> = z.object({
  equals: z.lazy(() => PartnerCompanySizeSchema).optional(),
  in: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  notIn: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => NestedEnumPartnerCompanySizeFilterSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorListRelationFilterSchema: z.ZodType<Prisma.PartnerCollaboratorListRelationFilter> = z.object({
  every: z.lazy(() => PartnerCollaboratorWhereInputSchema).optional(),
  some: z.lazy(() => PartnerCollaboratorWhereInputSchema).optional(),
  none: z.lazy(() => PartnerCollaboratorWhereInputSchema).optional()
}).strict();

export const PartnerRateListRelationFilterSchema: z.ZodType<Prisma.PartnerRateListRelationFilter> = z.object({
  every: z.lazy(() => PartnerRateWhereInputSchema).optional(),
  some: z.lazy(() => PartnerRateWhereInputSchema).optional(),
  none: z.lazy(() => PartnerRateWhereInputSchema).optional()
}).strict();

export const PartnerPendingCaseListRelationFilterSchema: z.ZodType<Prisma.PartnerPendingCaseListRelationFilter> = z.object({
  every: z.lazy(() => PartnerPendingCaseWhereInputSchema).optional(),
  some: z.lazy(() => PartnerPendingCaseWhereInputSchema).optional(),
  none: z.lazy(() => PartnerPendingCaseWhereInputSchema).optional()
}).strict();

export const PartnerCollaboratorOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PartnerCollaboratorOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PartnerRateOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerPendingCaseOrderByRelationAggregateInputSchema: z.ZodType<Prisma.PartnerPendingCaseOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerCountOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  profession: z.lazy(() => SortOrderSchema).optional(),
  otherProfession: z.lazy(() => SortOrderSchema).optional(),
  loanVolume: z.lazy(() => SortOrderSchema).optional(),
  clientTypes: z.lazy(() => SortOrderSchema).optional(),
  usageCases: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companySize: z.lazy(() => SortOrderSchema).optional(),
  orias: z.lazy(() => SortOrderSchema).optional(),
  attio_record_id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  profession: z.lazy(() => SortOrderSchema).optional(),
  otherProfession: z.lazy(() => SortOrderSchema).optional(),
  loanVolume: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companySize: z.lazy(() => SortOrderSchema).optional(),
  orias: z.lazy(() => SortOrderSchema).optional(),
  attio_record_id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerMinOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional(),
  affiliateId: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  profession: z.lazy(() => SortOrderSchema).optional(),
  otherProfession: z.lazy(() => SortOrderSchema).optional(),
  loanVolume: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companySize: z.lazy(() => SortOrderSchema).optional(),
  orias: z.lazy(() => SortOrderSchema).optional(),
  attio_record_id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumPartnerStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumPartnerStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerStatusSchema).optional(),
  in: z.lazy(() => PartnerStatusSchema).array().optional(),
  notIn: z.lazy(() => PartnerStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => NestedEnumPartnerStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerStatusFilterSchema).optional()
}).strict();

export const EnumPartnerProfessionWithAggregatesFilterSchema: z.ZodType<Prisma.EnumPartnerProfessionWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerProfessionSchema).optional(),
  in: z.lazy(() => PartnerProfessionSchema).array().optional(),
  notIn: z.lazy(() => PartnerProfessionSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => NestedEnumPartnerProfessionWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerProfessionFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerProfessionFilterSchema).optional()
}).strict();

export const EnumPartnerLoanVolumeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumPartnerLoanVolumeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerLoanVolumeSchema).optional(),
  in: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  notIn: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => NestedEnumPartnerLoanVolumeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerLoanVolumeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerLoanVolumeFilterSchema).optional()
}).strict();

export const EnumPartnerCompanySizeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumPartnerCompanySizeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerCompanySizeSchema).optional(),
  in: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  notIn: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => NestedEnumPartnerCompanySizeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerCompanySizeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerCompanySizeFilterSchema).optional()
}).strict();

export const PartnerRelationFilterSchema: z.ZodType<Prisma.PartnerRelationFilter> = z.object({
  is: z.lazy(() => PartnerWhereInputSchema).optional(),
  isNot: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerCollaboratorCountOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerCollaboratorCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerCollaboratorMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerCollaboratorMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerCollaboratorMinOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerCollaboratorMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  collaboratorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateCountOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerRateCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  createdBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerRateAvgOrderByAggregateInput> = z.object({
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerRateMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  createdBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateMinOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerRateMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  createdBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateSumOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerRateSumOrderByAggregateInput> = z.object({
  rate: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerRateRelationFilterSchema: z.ZodType<Prisma.PartnerRateRelationFilter> = z.object({
  is: z.lazy(() => PartnerRateWhereInputSchema).optional(),
  isNot: z.lazy(() => PartnerRateWhereInputSchema).optional()
}).strict();

export const CasePartnerCountOrderByAggregateInputSchema: z.ZodType<Prisma.CasePartnerCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  rateId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CasePartnerMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CasePartnerMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  rateId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CasePartnerMinOrderByAggregateInputSchema: z.ZodType<Prisma.CasePartnerMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  rateId: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumProspectPatrimonyFilterSchema: z.ZodType<Prisma.EnumProspectPatrimonyFilter> = z.object({
  equals: z.lazy(() => ProspectPatrimonySchema).optional(),
  in: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  notIn: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => NestedEnumProspectPatrimonyFilterSchema) ]).optional(),
}).strict();

export const EnumProspectProductFilterSchema: z.ZodType<Prisma.EnumProspectProductFilter> = z.object({
  equals: z.lazy(() => ProspectProductSchema).optional(),
  in: z.lazy(() => ProspectProductSchema).array().optional(),
  notIn: z.lazy(() => ProspectProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => NestedEnumProspectProductFilterSchema) ]).optional(),
}).strict();

export const EnumProspectAmountFilterSchema: z.ZodType<Prisma.EnumProspectAmountFilter> = z.object({
  equals: z.lazy(() => ProspectAmountSchema).optional(),
  in: z.lazy(() => ProspectAmountSchema).array().optional(),
  notIn: z.lazy(() => ProspectAmountSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => NestedEnumProspectAmountFilterSchema) ]).optional(),
}).strict();

export const EnumProspectUrgencyFilterSchema: z.ZodType<Prisma.EnumProspectUrgencyFilter> = z.object({
  equals: z.lazy(() => ProspectUrgencySchema).optional(),
  in: z.lazy(() => ProspectUrgencySchema).array().optional(),
  notIn: z.lazy(() => ProspectUrgencySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => NestedEnumProspectUrgencyFilterSchema) ]).optional(),
}).strict();

export const EnumProspectRatingFilterSchema: z.ZodType<Prisma.EnumProspectRatingFilter> = z.object({
  equals: z.lazy(() => ProspectRatingSchema).optional(),
  in: z.lazy(() => ProspectRatingSchema).array().optional(),
  notIn: z.lazy(() => ProspectRatingSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => NestedEnumProspectRatingFilterSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseCountOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerPendingCaseCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  patrimony: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  urgency: z.lazy(() => SortOrderSchema).optional(),
  rating: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerPendingCaseMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerPendingCaseMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  patrimony: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  urgency: z.lazy(() => SortOrderSchema).optional(),
  rating: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PartnerPendingCaseMinOrderByAggregateInputSchema: z.ZodType<Prisma.PartnerPendingCaseMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  partnerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  patrimony: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  amount: z.lazy(() => SortOrderSchema).optional(),
  urgency: z.lazy(() => SortOrderSchema).optional(),
  rating: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumProspectPatrimonyWithAggregatesFilterSchema: z.ZodType<Prisma.EnumProspectPatrimonyWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectPatrimonySchema).optional(),
  in: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  notIn: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => NestedEnumProspectPatrimonyWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectPatrimonyFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectPatrimonyFilterSchema).optional()
}).strict();

export const EnumProspectProductWithAggregatesFilterSchema: z.ZodType<Prisma.EnumProspectProductWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectProductSchema).optional(),
  in: z.lazy(() => ProspectProductSchema).array().optional(),
  notIn: z.lazy(() => ProspectProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => NestedEnumProspectProductWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectProductFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectProductFilterSchema).optional()
}).strict();

export const EnumProspectAmountWithAggregatesFilterSchema: z.ZodType<Prisma.EnumProspectAmountWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectAmountSchema).optional(),
  in: z.lazy(() => ProspectAmountSchema).array().optional(),
  notIn: z.lazy(() => ProspectAmountSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => NestedEnumProspectAmountWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectAmountFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectAmountFilterSchema).optional()
}).strict();

export const EnumProspectUrgencyWithAggregatesFilterSchema: z.ZodType<Prisma.EnumProspectUrgencyWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectUrgencySchema).optional(),
  in: z.lazy(() => ProspectUrgencySchema).array().optional(),
  notIn: z.lazy(() => ProspectUrgencySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => NestedEnumProspectUrgencyWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectUrgencyFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectUrgencyFilterSchema).optional()
}).strict();

export const EnumProspectRatingWithAggregatesFilterSchema: z.ZodType<Prisma.EnumProspectRatingWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectRatingSchema).optional(),
  in: z.lazy(() => ProspectRatingSchema).array().optional(),
  notIn: z.lazy(() => ProspectRatingSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => NestedEnumProspectRatingWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectRatingFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectRatingFilterSchema).optional()
}).strict();

export const EnumPendingCaseTypeFilterSchema: z.ZodType<Prisma.EnumPendingCaseTypeFilter> = z.object({
  equals: z.lazy(() => PendingCaseTypeSchema).optional(),
  in: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  notIn: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => NestedEnumPendingCaseTypeFilterSchema) ]).optional(),
}).strict();

export const EnumCompanyTypeNullableFilterSchema: z.ZodType<Prisma.EnumCompanyTypeNullableFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const PendingCaseCountOrderByAggregateInputSchema: z.ZodType<Prisma.PendingCaseCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PendingCaseAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PendingCaseAvgOrderByAggregateInput> = z.object({
  requestedAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PendingCaseMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PendingCaseMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PendingCaseMinOrderByAggregateInputSchema: z.ZodType<Prisma.PendingCaseMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  requestedAmount: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  type: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  siret: z.lazy(() => SortOrderSchema).optional(),
  companyName: z.lazy(() => SortOrderSchema).optional(),
  companyType: z.lazy(() => SortOrderSchema).optional(),
  taxType: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PendingCaseSumOrderByAggregateInputSchema: z.ZodType<Prisma.PendingCaseSumOrderByAggregateInput> = z.object({
  requestedAmount: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumPendingCaseTypeWithAggregatesFilterSchema: z.ZodType<Prisma.EnumPendingCaseTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PendingCaseTypeSchema).optional(),
  in: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  notIn: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => NestedEnumPendingCaseTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPendingCaseTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPendingCaseTypeFilterSchema).optional()
}).strict();

export const EnumCompanyTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumCompanyTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCompanyTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCompanyTypeNullableFilterSchema).optional()
}).strict();

export const EnumSimulationStatusFilterSchema: z.ZodType<Prisma.EnumSimulationStatusFilter> = z.object({
  equals: z.lazy(() => SimulationStatusSchema).optional(),
  in: z.lazy(() => SimulationStatusSchema).array().optional(),
  notIn: z.lazy(() => SimulationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => NestedEnumSimulationStatusFilterSchema) ]).optional(),
}).strict();

export const EnumRejectionMotiveNullableFilterSchema: z.ZodType<Prisma.EnumRejectionMotiveNullableFilter> = z.object({
  equals: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  in: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  notIn: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NestedEnumRejectionMotiveNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const SimulationCountOrderByAggregateInputSchema: z.ZodType<Prisma.SimulationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  loanType: z.lazy(() => SortOrderSchema).optional(),
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  rejectionMotive: z.lazy(() => SortOrderSchema).optional(),
  rejectionReason: z.lazy(() => SortOrderSchema).optional(),
  statusUpdatedAt: z.lazy(() => SortOrderSchema).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SimulationAvgOrderByAggregateInputSchema: z.ZodType<Prisma.SimulationAvgOrderByAggregateInput> = z.object({
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SimulationMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SimulationMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  loanType: z.lazy(() => SortOrderSchema).optional(),
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  rejectionMotive: z.lazy(() => SortOrderSchema).optional(),
  rejectionReason: z.lazy(() => SortOrderSchema).optional(),
  statusUpdatedAt: z.lazy(() => SortOrderSchema).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SimulationMinOrderByAggregateInputSchema: z.ZodType<Prisma.SimulationMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  loanType: z.lazy(() => SortOrderSchema).optional(),
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  rejectionMotive: z.lazy(() => SortOrderSchema).optional(),
  rejectionReason: z.lazy(() => SortOrderSchema).optional(),
  statusUpdatedAt: z.lazy(() => SortOrderSchema).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SimulationSumOrderByAggregateInputSchema: z.ZodType<Prisma.SimulationSumOrderByAggregateInput> = z.object({
  borrowedCapital: z.lazy(() => SortOrderSchema).optional(),
  remainingMonths: z.lazy(() => SortOrderSchema).optional(),
  rate: z.lazy(() => SortOrderSchema).optional(),
  expertFees: z.lazy(() => SortOrderSchema).optional(),
  mandate: z.lazy(() => SortOrderSchema).optional(),
  bankFees: z.lazy(() => SortOrderSchema).optional(),
  guaranteeFees: z.lazy(() => SortOrderSchema).optional(),
  loansAmountTakenOver: z.lazy(() => SortOrderSchema).optional(),
  monthlyChargesLoansTakenOver: z.lazy(() => SortOrderSchema).optional(),
  contribution: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumSimulationStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumSimulationStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SimulationStatusSchema).optional(),
  in: z.lazy(() => SimulationStatusSchema).array().optional(),
  notIn: z.lazy(() => SimulationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => NestedEnumSimulationStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSimulationStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSimulationStatusFilterSchema).optional()
}).strict();

export const EnumRejectionMotiveNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumRejectionMotiveNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  in: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  notIn: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NestedEnumRejectionMotiveNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumRejectionMotiveNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumRejectionMotiveNullableFilterSchema).optional()
}).strict();

export const EnumProductFilterSchema: z.ZodType<Prisma.EnumProductFilter> = z.object({
  equals: z.lazy(() => ProductSchema).optional(),
  in: z.lazy(() => ProductSchema).array().optional(),
  notIn: z.lazy(() => ProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProductSchema),z.lazy(() => NestedEnumProductFilterSchema) ]).optional(),
}).strict();

export const EnumSubmissionStatusFilterSchema: z.ZodType<Prisma.EnumSubmissionStatusFilter> = z.object({
  equals: z.lazy(() => SubmissionStatusSchema).optional(),
  in: z.lazy(() => SubmissionStatusSchema).array().optional(),
  notIn: z.lazy(() => SubmissionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => NestedEnumSubmissionStatusFilterSchema) ]).optional(),
}).strict();

export const BankRelationFilterSchema: z.ZodType<Prisma.BankRelationFilter> = z.object({
  is: z.lazy(() => BankWhereInputSchema).optional(),
  isNot: z.lazy(() => BankWhereInputSchema).optional()
}).strict();

export const SubmissionCountOrderByAggregateInputSchema: z.ZodType<Prisma.SubmissionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  bankId: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubmissionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SubmissionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  bankId: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SubmissionMinOrderByAggregateInputSchema: z.ZodType<Prisma.SubmissionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  bankId: z.lazy(() => SortOrderSchema).optional(),
  product: z.lazy(() => SortOrderSchema).optional(),
  reason: z.lazy(() => SortOrderSchema).optional(),
  comment: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumProductWithAggregatesFilterSchema: z.ZodType<Prisma.EnumProductWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProductSchema).optional(),
  in: z.lazy(() => ProductSchema).array().optional(),
  notIn: z.lazy(() => ProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProductSchema),z.lazy(() => NestedEnumProductWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProductFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProductFilterSchema).optional()
}).strict();

export const EnumSubmissionStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumSubmissionStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SubmissionStatusSchema).optional(),
  in: z.lazy(() => SubmissionStatusSchema).array().optional(),
  notIn: z.lazy(() => SubmissionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => NestedEnumSubmissionStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSubmissionStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSubmissionStatusFilterSchema).optional()
}).strict();

export const EnumSuggestionFieldFilterSchema: z.ZodType<Prisma.EnumSuggestionFieldFilter> = z.object({
  equals: z.lazy(() => SuggestionFieldSchema).optional(),
  in: z.lazy(() => SuggestionFieldSchema).array().optional(),
  notIn: z.lazy(() => SuggestionFieldSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => NestedEnumSuggestionFieldFilterSchema) ]).optional(),
}).strict();

export const EnumSuggestionStatusFilterSchema: z.ZodType<Prisma.EnumSuggestionStatusFilter> = z.object({
  equals: z.lazy(() => SuggestionStatusSchema).optional(),
  in: z.lazy(() => SuggestionStatusSchema).array().optional(),
  notIn: z.lazy(() => SuggestionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => NestedEnumSuggestionStatusFilterSchema) ]).optional(),
}).strict();

export const SuggestionCountOrderByAggregateInputSchema: z.ZodType<Prisma.SuggestionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  field: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SuggestionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SuggestionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  field: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SuggestionMinOrderByAggregateInputSchema: z.ZodType<Prisma.SuggestionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  field: z.lazy(() => SortOrderSchema).optional(),
  value: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  source: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumSuggestionFieldWithAggregatesFilterSchema: z.ZodType<Prisma.EnumSuggestionFieldWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SuggestionFieldSchema).optional(),
  in: z.lazy(() => SuggestionFieldSchema).array().optional(),
  notIn: z.lazy(() => SuggestionFieldSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => NestedEnumSuggestionFieldWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSuggestionFieldFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSuggestionFieldFilterSchema).optional()
}).strict();

export const EnumSuggestionStatusWithAggregatesFilterSchema: z.ZodType<Prisma.EnumSuggestionStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SuggestionStatusSchema).optional(),
  in: z.lazy(() => SuggestionStatusSchema).array().optional(),
  notIn: z.lazy(() => SuggestionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => NestedEnumSuggestionStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSuggestionStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSuggestionStatusFilterSchema).optional()
}).strict();

export const SynthesisCountOrderByAggregateInputSchema: z.ZodType<Prisma.SynthesisCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  content: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SynthesisMaxOrderByAggregateInputSchema: z.ZodType<Prisma.SynthesisMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  content: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SynthesisMinOrderByAggregateInputSchema: z.ZodType<Prisma.SynthesisMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  caseId: z.lazy(() => SortOrderSchema).optional(),
  content: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  userId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumGenderNullableFilterSchema: z.ZodType<Prisma.EnumGenderNullableFilter> = z.object({
  equals: z.lazy(() => GenderSchema).optional().nullable(),
  in: z.lazy(() => GenderSchema).array().optional().nullable(),
  notIn: z.lazy(() => GenderSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NestedEnumGenderNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const AccountListRelationFilterSchema: z.ZodType<Prisma.AccountListRelationFilter> = z.object({
  every: z.lazy(() => AccountWhereInputSchema).optional(),
  some: z.lazy(() => AccountWhereInputSchema).optional(),
  none: z.lazy(() => AccountWhereInputSchema).optional()
}).strict();

export const SessionListRelationFilterSchema: z.ZodType<Prisma.SessionListRelationFilter> = z.object({
  every: z.lazy(() => SessionWhereInputSchema).optional(),
  some: z.lazy(() => SessionWhereInputSchema).optional(),
  none: z.lazy(() => SessionWhereInputSchema).optional()
}).strict();

export const CaseTraceListRelationFilterSchema: z.ZodType<Prisma.CaseTraceListRelationFilter> = z.object({
  every: z.lazy(() => CaseTraceWhereInputSchema).optional(),
  some: z.lazy(() => CaseTraceWhereInputSchema).optional(),
  none: z.lazy(() => CaseTraceWhereInputSchema).optional()
}).strict();

export const CompanyListRelationFilterSchema: z.ZodType<Prisma.CompanyListRelationFilter> = z.object({
  every: z.lazy(() => CompanyWhereInputSchema).optional(),
  some: z.lazy(() => CompanyWhereInputSchema).optional(),
  none: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const SynthesisListRelationFilterSchema: z.ZodType<Prisma.SynthesisListRelationFilter> = z.object({
  every: z.lazy(() => SynthesisWhereInputSchema).optional(),
  some: z.lazy(() => SynthesisWhereInputSchema).optional(),
  none: z.lazy(() => SynthesisWhereInputSchema).optional()
}).strict();

export const NotificationListRelationFilterSchema: z.ZodType<Prisma.NotificationListRelationFilter> = z.object({
  every: z.lazy(() => NotificationWhereInputSchema).optional(),
  some: z.lazy(() => NotificationWhereInputSchema).optional(),
  none: z.lazy(() => NotificationWhereInputSchema).optional()
}).strict();

export const AccountOrderByRelationAggregateInputSchema: z.ZodType<Prisma.AccountOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SessionOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SessionOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CaseTraceOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CaseTraceOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CompanyOrderByRelationAggregateInputSchema: z.ZodType<Prisma.CompanyOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const SynthesisOrderByRelationAggregateInputSchema: z.ZodType<Prisma.SynthesisOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const NotificationOrderByRelationAggregateInputSchema: z.ZodType<Prisma.NotificationOrderByRelationAggregateInput> = z.object({
  _count: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserCountOrderByAggregateInputSchema: z.ZodType<Prisma.UserCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  calendarLink: z.lazy(() => SortOrderSchema).optional(),
  gender: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  passwordResetToken: z.lazy(() => SortOrderSchema).optional(),
  passwordResetTokenExpiration: z.lazy(() => SortOrderSchema).optional(),
  emailVerificationToken: z.lazy(() => SortOrderSchema).optional(),
  emailVerificationTokenExpiration: z.lazy(() => SortOrderSchema).optional(),
  passwordCreationToken: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMaxOrderByAggregateInputSchema: z.ZodType<Prisma.UserMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  calendarLink: z.lazy(() => SortOrderSchema).optional(),
  gender: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  passwordResetToken: z.lazy(() => SortOrderSchema).optional(),
  passwordResetTokenExpiration: z.lazy(() => SortOrderSchema).optional(),
  emailVerificationToken: z.lazy(() => SortOrderSchema).optional(),
  emailVerificationTokenExpiration: z.lazy(() => SortOrderSchema).optional(),
  passwordCreationToken: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const UserMinOrderByAggregateInputSchema: z.ZodType<Prisma.UserMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  customerId: z.lazy(() => SortOrderSchema).optional(),
  firstname: z.lazy(() => SortOrderSchema).optional(),
  lastname: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  password: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  phone: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  calendarLink: z.lazy(() => SortOrderSchema).optional(),
  gender: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  passwordResetToken: z.lazy(() => SortOrderSchema).optional(),
  passwordResetTokenExpiration: z.lazy(() => SortOrderSchema).optional(),
  emailVerificationToken: z.lazy(() => SortOrderSchema).optional(),
  emailVerificationTokenExpiration: z.lazy(() => SortOrderSchema).optional(),
  passwordCreationToken: z.lazy(() => SortOrderSchema).optional(),
  disabledAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const EnumGenderNullableWithAggregatesFilterSchema: z.ZodType<Prisma.EnumGenderNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => GenderSchema).optional().nullable(),
  in: z.lazy(() => GenderSchema).array().optional().nullable(),
  notIn: z.lazy(() => GenderSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NestedEnumGenderNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumGenderNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumGenderNullableFilterSchema).optional()
}).strict();

export const CaseCreateNestedOneWithoutAssetsInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutAssetsInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutAssetsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutAssetsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const AssetJointOwnerCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetJointOwnerCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetShareholderCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetShareholderCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutAssetsInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutAssetsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutAssetsInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateNestedOneWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  connect: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).optional()
}).strict();

export const CompanyCreateNestedOneWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutAssetsInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutAssetsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutAssetsInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateNestedOneWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  connect: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).optional()
}).strict();

export const AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetJointOwnerCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetShareholderCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  connect: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  connect: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).optional()
}).strict();

export const StringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.StringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional()
}).strict();

export const NullableStringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional().nullable()
}).strict();

export const IntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.IntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableIntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableEnumAssetTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumAssetTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => AssetTypeSchema).optional().nullable()
}).strict();

export const EnumAssetUsageTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumAssetUsageTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => AssetUsageTypeSchema).optional()
}).strict();

export const EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumAssetOwnershipTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => AssetOwnershipTypeSchema).optional()
}).strict();

export const NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumAssetCompanyTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => AssetCompanyTypeSchema).optional().nullable()
}).strict();

export const DateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.DateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional()
}).strict();

export const CaseUpdateOneWithoutAssetsNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneWithoutAssetsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutAssetsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutAssetsInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutAssetsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutAssetsInputSchema),z.lazy(() => CaseUpdateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutAssetsInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.AssetJointOwnerUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetJointOwnerUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetJointOwnerCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetJointOwnerUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetJointOwnerUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetJointOwnerScalarWhereInputSchema),z.lazy(() => AssetJointOwnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetShareholderUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.AssetShareholderUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetShareholderUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetShareholderUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetShareholderCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetShareholderUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetShareholderUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetShareholderUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => AssetShareholderUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetShareholderScalarWhereInputSchema),z.lazy(() => AssetShareholderScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerUpdateOneWithoutAssetsNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneWithoutAssetsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutAssetsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutAssetsInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutAssetsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutAssetsInputSchema),z.lazy(() => CustomerUpdateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutAssetsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateOneWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  upsert: z.lazy(() => CustomerRentIncomeUpsertWithoutAssetInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerRentIncomeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerRentIncomeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerRentIncomeUpdateToOneWithWhereWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]).optional(),
}).strict();

export const CompanyUpdateOneWithoutAssetsNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneWithoutAssetsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutAssetsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutAssetsInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutAssetsInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutAssetsInputSchema),z.lazy(() => CompanyUpdateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutAssetsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateOneWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  upsert: z.lazy(() => CompanyRentIncomeUpsertWithoutAssetInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyRentIncomeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyRentIncomeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyRentIncomeUpdateToOneWithWhereWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetJointOwnerUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetJointOwnerCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),z.lazy(() => AssetJointOwnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetJointOwnerUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetJointOwnerUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetJointOwnerScalarWhereInputSchema),z.lazy(() => AssetJointOwnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema).array(),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema),z.lazy(() => AssetShareholderCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetShareholderUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetShareholderUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetShareholderCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetShareholderWhereUniqueInputSchema),z.lazy(() => AssetShareholderWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetShareholderUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => AssetShareholderUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetShareholderUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => AssetShareholderUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetShareholderScalarWhereInputSchema),z.lazy(() => AssetShareholderScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  upsert: z.lazy(() => CustomerRentIncomeUpsertWithoutAssetInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerRentIncomeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerRentIncomeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerRentIncomeUpdateToOneWithWhereWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutAssetInputSchema).optional(),
  upsert: z.lazy(() => CompanyRentIncomeUpsertWithoutAssetInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyRentIncomeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyRentIncomeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyRentIncomeUpdateToOneWithWhereWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]).optional(),
}).strict();

export const AssetCreateNestedOneWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetCreateNestedOneWithoutAssetJointOwnersInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetJointOwnersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutAssetJointOwnersInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional()
}).strict();

export const AssetUpdateOneRequiredWithoutAssetJointOwnersNestedInputSchema: z.ZodType<Prisma.AssetUpdateOneRequiredWithoutAssetJointOwnersNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetJointOwnersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutAssetJointOwnersInputSchema).optional(),
  upsert: z.lazy(() => AssetUpsertWithoutAssetJointOwnersInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AssetUpdateToOneWithWhereWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUpdateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutAssetJointOwnersInputSchema) ]).optional(),
}).strict();

export const AssetCreateNestedOneWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetCreateNestedOneWithoutAssetShareholdersInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetShareholdersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutAssetShareholdersInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional()
}).strict();

export const EnumAssetShareholderTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumAssetShareholderTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => AssetShareholderTypeSchema).optional()
}).strict();

export const AssetUpdateOneRequiredWithoutAssetShareholdersNestedInputSchema: z.ZodType<Prisma.AssetUpdateOneRequiredWithoutAssetShareholdersNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetShareholdersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutAssetShareholdersInputSchema).optional(),
  upsert: z.lazy(() => AssetUpsertWithoutAssetShareholdersInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AssetUpdateToOneWithWhereWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUpdateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutAssetShareholdersInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutAccountsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutAccountsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutAccountsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutAccountsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutAccountsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutAccountsInputSchema),z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSessionsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutSessionsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSessionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSessionsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSessionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSessionsInputSchema),z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]).optional(),
}).strict();

export const BankCreateproductsInputSchema: z.ZodType<Prisma.BankCreateproductsInput> = z.object({
  set: z.lazy(() => ProductSchema).array()
}).strict();

export const BankCreateassetTypesInputSchema: z.ZodType<Prisma.BankCreateassetTypesInput> = z.object({
  set: z.lazy(() => AssetTypeSchema).array()
}).strict();

export const SubmissionCreateNestedManyWithoutBankInputSchema: z.ZodType<Prisma.SubmissionCreateNestedManyWithoutBankInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutBankInputSchema),z.lazy(() => SubmissionCreateWithoutBankInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyBankInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUncheckedCreateNestedManyWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateNestedManyWithoutBankInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutBankInputSchema),z.lazy(() => SubmissionCreateWithoutBankInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyBankInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const BankUpdateproductsInputSchema: z.ZodType<Prisma.BankUpdateproductsInput> = z.object({
  set: z.lazy(() => ProductSchema).array().optional(),
  push: z.union([ z.lazy(() => ProductSchema),z.lazy(() => ProductSchema).array() ]).optional(),
}).strict();

export const BankUpdateassetTypesInputSchema: z.ZodType<Prisma.BankUpdateassetTypesInput> = z.object({
  set: z.lazy(() => AssetTypeSchema).array().optional(),
  push: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
}).strict();

export const EnumBankLocalisationFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumBankLocalisationFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => BankLocalisationSchema).optional()
}).strict();

export const NullableDateTimeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableDateTimeFieldUpdateOperationsInput> = z.object({
  set: z.coerce.date().optional().nullable()
}).strict();

export const SubmissionUpdateManyWithoutBankNestedInputSchema: z.ZodType<Prisma.SubmissionUpdateManyWithoutBankNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutBankInputSchema),z.lazy(() => SubmissionCreateWithoutBankInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutBankInputSchema),z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutBankInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyBankInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutBankInputSchema),z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutBankInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubmissionUpdateManyWithWhereWithoutBankInputSchema),z.lazy(() => SubmissionUpdateManyWithWhereWithoutBankInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyWithoutBankNestedInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyWithoutBankNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutBankInputSchema),z.lazy(() => SubmissionCreateWithoutBankInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutBankInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutBankInputSchema),z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutBankInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyBankInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutBankInputSchema),z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutBankInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubmissionUpdateManyWithWhereWithoutBankInputSchema),z.lazy(() => SubmissionUpdateManyWithWhereWithoutBankInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutCasesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCasesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCasesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCasesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCasesAsMainCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsMainCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCasesAsMainCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCasesAsCoCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsCoCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCasesAsCoCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutCaseAsCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCaseAsCompanyInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCaseAsCompanyInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict();

export const AssetCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.AssetCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCaseInputSchema),z.lazy(() => AssetCreateWithoutCaseInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseTraceCreateNestedOneWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceCreateNestedOneWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseTraceCreateOrConnectWithoutCaseInputSchema).optional(),
  connect: z.lazy(() => CaseTraceWhereUniqueInputSchema).optional()
}).strict();

export const CaseCollaboratorCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubmissionCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutCaseInputSchema),z.lazy(() => SubmissionCreateWithoutCaseInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SynthesisCreateNestedOneWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisCreateNestedOneWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SynthesisCreateOrConnectWithoutCaseInputSchema).optional(),
  connect: z.lazy(() => SynthesisWhereUniqueInputSchema).optional()
}).strict();

export const SuggestionCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SuggestionCreateWithoutCaseInputSchema),z.lazy(() => SuggestionCreateWithoutCaseInputSchema).array(),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SuggestionCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SimulationCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.SimulationCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutCaseInputSchema),z.lazy(() => SimulationCreateWithoutCaseInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateWithoutCaseInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.AssetUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCaseInputSchema),z.lazy(() => AssetCreateWithoutCaseInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceUncheckedCreateNestedOneWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseTraceCreateOrConnectWithoutCaseInputSchema).optional(),
  connect: z.lazy(() => CaseTraceWhereUniqueInputSchema).optional()
}).strict();

export const CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutCaseInputSchema),z.lazy(() => SubmissionCreateWithoutCaseInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisUncheckedCreateNestedOneWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SynthesisCreateOrConnectWithoutCaseInputSchema).optional(),
  connect: z.lazy(() => SynthesisWhereUniqueInputSchema).optional()
}).strict();

export const SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SuggestionCreateWithoutCaseInputSchema),z.lazy(() => SuggestionCreateWithoutCaseInputSchema).array(),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SuggestionCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SimulationUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutCaseInputSchema),z.lazy(() => SimulationCreateWithoutCaseInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateNestedManyWithoutCaseInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateWithoutCaseInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyCaseInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const EnumCasePurposeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCasePurposeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CasePurposeSchema).optional()
}).strict();

export const EnumCaseTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCaseTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CaseTypeSchema).optional()
}).strict();

export const EnumCaseStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCaseStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CaseStatusSchema).optional()
}).strict();

export const EnumCasePriorityFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCasePriorityFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CasePrioritySchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutCasesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCasesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCasesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCasesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCasesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCasesInputSchema),z.lazy(() => UserUpdateWithoutCasesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCasesInputSchema) ]).optional(),
}).strict();

export const CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneWithoutCasesAsMainCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsMainCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCasesAsMainCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCasesAsMainCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUpdateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCasesAsMainCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneWithoutCasesAsCoCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsCoCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCasesAsCoCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCasesAsCoCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUpdateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCasesAsCoCustomerInputSchema) ]).optional(),
}).strict();

export const CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneWithoutCaseAsCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCaseAsCompanyInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCaseAsCompanyInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutCaseAsCompanyInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUpdateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCaseAsCompanyInputSchema) ]).optional(),
}).strict();

export const AssetUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.AssetUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCaseInputSchema),z.lazy(() => AssetCreateWithoutCaseInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => AssetUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => AssetUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => AssetUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseTraceUpdateOneWithoutCaseNestedInputSchema: z.ZodType<Prisma.CaseTraceUpdateOneWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseTraceCreateOrConnectWithoutCaseInputSchema).optional(),
  upsert: z.lazy(() => CaseTraceUpsertWithoutCaseInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CaseTraceWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CaseTraceWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CaseTraceWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseTraceUpdateToOneWithWhereWithoutCaseInputSchema),z.lazy(() => CaseTraceUpdateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedUpdateWithoutCaseInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseCollaboratorScalarWhereInputSchema),z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.SubmissionUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutCaseInputSchema),z.lazy(() => SubmissionCreateWithoutCaseInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubmissionUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => SubmissionUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SynthesisUpdateOneWithoutCaseNestedInputSchema: z.ZodType<Prisma.SynthesisUpdateOneWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SynthesisCreateOrConnectWithoutCaseInputSchema).optional(),
  upsert: z.lazy(() => SynthesisUpsertWithoutCaseInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => SynthesisWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => SynthesisWhereInputSchema) ]).optional(),
  connect: z.lazy(() => SynthesisWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SynthesisUpdateToOneWithWhereWithoutCaseInputSchema),z.lazy(() => SynthesisUpdateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedUpdateWithoutCaseInputSchema) ]).optional(),
}).strict();

export const SuggestionUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.SuggestionUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SuggestionCreateWithoutCaseInputSchema),z.lazy(() => SuggestionCreateWithoutCaseInputSchema).array(),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SuggestionUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SuggestionUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SuggestionCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SuggestionUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SuggestionUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SuggestionUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => SuggestionUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SuggestionScalarWhereInputSchema),z.lazy(() => SuggestionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SimulationUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.SimulationUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutCaseInputSchema),z.lazy(() => SimulationCreateWithoutCaseInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SimulationUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SimulationUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SimulationUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SimulationUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SimulationUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => SimulationUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SimulationScalarWhereInputSchema),z.lazy(() => SimulationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.CaseMessageUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseMessageUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => CaseMessageUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseMessageScalarWhereInputSchema),z.lazy(() => CaseMessageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateWithoutCaseInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CasePartnerUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => CasePartnerUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCaseInputSchema),z.lazy(() => AssetCreateWithoutCaseInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => AssetUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => AssetUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => AssetUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateOneWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseTraceCreateOrConnectWithoutCaseInputSchema).optional(),
  upsert: z.lazy(() => CaseTraceUpsertWithoutCaseInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CaseTraceWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CaseTraceWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CaseTraceWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseTraceUpdateToOneWithWhereWithoutCaseInputSchema),z.lazy(() => CaseTraceUpdateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedUpdateWithoutCaseInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseCollaboratorScalarWhereInputSchema),z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutCaseInputSchema),z.lazy(() => SubmissionCreateWithoutCaseInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubmissionUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => SubmissionUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateOneWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutCaseInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => SynthesisCreateOrConnectWithoutCaseInputSchema).optional(),
  upsert: z.lazy(() => SynthesisUpsertWithoutCaseInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => SynthesisWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => SynthesisWhereInputSchema) ]).optional(),
  connect: z.lazy(() => SynthesisWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => SynthesisUpdateToOneWithWhereWithoutCaseInputSchema),z.lazy(() => SynthesisUpdateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedUpdateWithoutCaseInputSchema) ]).optional(),
}).strict();

export const SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.SuggestionUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SuggestionCreateWithoutCaseInputSchema),z.lazy(() => SuggestionCreateWithoutCaseInputSchema).array(),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SuggestionCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SuggestionUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SuggestionUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SuggestionCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SuggestionWhereUniqueInputSchema),z.lazy(() => SuggestionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SuggestionUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SuggestionUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SuggestionUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => SuggestionUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SuggestionScalarWhereInputSchema),z.lazy(() => SuggestionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutCaseInputSchema),z.lazy(() => SimulationCreateWithoutCaseInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SimulationUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SimulationUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SimulationUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => SimulationUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SimulationUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => SimulationUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SimulationScalarWhereInputSchema),z.lazy(() => SimulationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateWithoutCaseInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseMessageUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => CaseMessageUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseMessageScalarWhereInputSchema),z.lazy(() => CaseMessageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyWithoutCaseNestedInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateWithoutCaseInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutCaseInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyCaseInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutCaseInputSchema),z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutCaseInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CasePartnerUpdateManyWithWhereWithoutCaseInputSchema),z.lazy(() => CasePartnerUpdateManyWithWhereWithoutCaseInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseCreateNestedOneWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutCaseCollaboratorsInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseCollaboratorsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCaseCollaboratorsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCasesAsCollaboratorInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesAsCollaboratorInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCasesAsCollaboratorInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseUpdateOneRequiredWithoutCaseCollaboratorsNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutCaseCollaboratorsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseCollaboratorsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCaseCollaboratorsInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutCaseCollaboratorsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUpdateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseCollaboratorsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutCasesAsCollaboratorNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCasesAsCollaboratorNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesAsCollaboratorInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCasesAsCollaboratorInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCasesAsCollaboratorInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUpdateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCasesAsCollaboratorInputSchema) ]).optional(),
}).strict();

export const CaseCreateNestedOneWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutCaseTraceInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseTraceInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCaseTraceInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCaseTracesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCaseTracesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCaseTracesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseUpdateOneWithoutCaseTraceNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneWithoutCaseTraceNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseTraceInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCaseTraceInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutCaseTraceInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CaseWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutCaseTraceInputSchema),z.lazy(() => CaseUpdateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseTraceInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneWithoutCaseTracesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutCaseTracesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCaseTracesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCaseTracesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCaseTracesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCaseTracesInputSchema),z.lazy(() => UserUpdateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCaseTracesInputSchema) ]).optional(),
}).strict();

export const CaseCreateNestedOneWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutCaseMessagesInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseMessagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCaseMessagesInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSentCaseMessagesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentCaseMessagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSentCaseMessagesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const EnumUserRoleFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumUserRoleFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => UserRoleSchema).optional()
}).strict();

export const EnumCaseMessageModelFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCaseMessageModelFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CaseMessageModelSchema).optional()
}).strict();

export const EnumCaseMessageStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCaseMessageStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CaseMessageStatusSchema).optional()
}).strict();

export const CaseUpdateOneRequiredWithoutCaseMessagesNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutCaseMessagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseMessagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCaseMessagesInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutCaseMessagesInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutCaseMessagesInputSchema),z.lazy(() => CaseUpdateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseMessagesInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutSentCaseMessagesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSentCaseMessagesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentCaseMessagesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSentCaseMessagesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSentCaseMessagesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUpdateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSentCaseMessagesInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutCompaniesInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCompaniesInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompaniesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCompaniesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CaseCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCompanyInputSchema),z.lazy(() => CaseCreateWithoutCompanyInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.AssetCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyInputSchema),z.lazy(() => AssetCreateWithoutCompanyInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRentIncomeCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCompanyInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCompanyInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyFinancialDataCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCompanyInputSchema),z.lazy(() => CaseCreateWithoutCompanyInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyInputSchema),z.lazy(() => AssetCreateWithoutCompanyInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRentIncomeCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateNestedOneWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCompanyInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCompanyInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInput> = z.object({
  create: z.union([ z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyFinancialDataCreateManyCompanyInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const EnumCompanyTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCompanyTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CompanyTypeSchema).optional()
}).strict();

export const NullableEnumTaxTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumTaxTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => TaxTypeSchema).optional().nullable()
}).strict();

export const UserUpdateOneRequiredWithoutCompaniesNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutCompaniesNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompaniesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCompaniesInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCompaniesInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCompaniesInputSchema),z.lazy(() => UserUpdateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCompaniesInputSchema) ]).optional(),
}).strict();

export const CaseUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CaseUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCompanyInputSchema),z.lazy(() => CaseCreateWithoutCompanyInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.AssetUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyInputSchema),z.lazy(() => AssetCreateWithoutCompanyInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => AssetUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => AssetUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => AssetUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyRentIncomeUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRentIncomeCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyRentIncomeUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyRentIncomeUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyRentIncomeScalarWhereInputSchema),z.lazy(() => CompanyRentIncomeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUpdateOneWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCompanyInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCompanyInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutCompanyInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCompanyInputSchema) ]).optional(),
}).strict();

export const CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyFinancialDataUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyFinancialDataCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyFinancialDataUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyFinancialDataUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyFinancialDataScalarWhereInputSchema),z.lazy(() => CompanyFinancialDataScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCompanyInputSchema),z.lazy(() => CaseCreateWithoutCompanyInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyInputSchema),z.lazy(() => AssetCreateWithoutCompanyInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => AssetUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => AssetUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => AssetUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRealEstateLoanChargeCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyRentIncomeUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyRentIncomeCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyRentIncomeUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyRentIncomeUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyRentIncomeScalarWhereInputSchema),z.lazy(() => CompanyRentIncomeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCompanyInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCompanyInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutCompanyInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCompanyInputSchema) ]).optional(),
}).strict();

export const CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema).array(),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyFinancialDataUpsertWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUpsertWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyFinancialDataCreateManyCompanyInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyFinancialDataUpdateWithWhereUniqueWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUpdateWithWhereUniqueWithoutCompanyInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyFinancialDataUpdateManyWithWhereWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUpdateManyWithWhereWithoutCompanyInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyFinancialDataScalarWhereInputSchema),z.lazy(() => CompanyFinancialDataScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyCreateNestedOneWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutCompanyRealEstateLoanChargeInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRealEstateLoanChargeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyRealEstateLoanChargeInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict();

export const AssetCreateNestedOneWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetCreateNestedOneWithoutCompanyRealEstateLoanChargesInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRealEstateLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCompanyRealEstateLoanChargesInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional()
}).strict();

export const EnumLoanGuaranteeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumLoanGuaranteeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => LoanGuaranteeSchema).optional()
}).strict();

export const FloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.FloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const EnumLoanTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumLoanTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => LoanTypeSchema).optional()
}).strict();

export const CompanyUpdateOneRequiredWithoutCompanyRealEstateLoanChargeNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutCompanyRealEstateLoanChargeNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRealEstateLoanChargeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyRealEstateLoanChargeInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutCompanyRealEstateLoanChargeInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUpdateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyRealEstateLoanChargeInputSchema) ]).optional(),
}).strict();

export const AssetUpdateOneRequiredWithoutCompanyRealEstateLoanChargesNestedInputSchema: z.ZodType<Prisma.AssetUpdateOneRequiredWithoutCompanyRealEstateLoanChargesNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRealEstateLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCompanyRealEstateLoanChargesInputSchema).optional(),
  upsert: z.lazy(() => AssetUpsertWithoutCompanyRealEstateLoanChargesInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AssetUpdateToOneWithWhereWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUpdateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyRealEstateLoanChargesInputSchema) ]).optional(),
}).strict();

export const AssetCreateNestedOneWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetCreateNestedOneWithoutCompanyRentIncomeInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCompanyRentIncomeInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional()
}).strict();

export const CompanyCreateNestedOneWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutCompanyRentIncomeInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyRentIncomeInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict();

export const AssetUpdateOneWithoutCompanyRentIncomeNestedInputSchema: z.ZodType<Prisma.AssetUpdateOneWithoutCompanyRentIncomeNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCompanyRentIncomeInputSchema).optional(),
  upsert: z.lazy(() => AssetUpsertWithoutCompanyRentIncomeInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => AssetWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => AssetWhereInputSchema) ]).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AssetUpdateToOneWithWhereWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUpdateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyRentIncomeInputSchema) ]).optional(),
}).strict();

export const CompanyUpdateOneRequiredWithoutCompanyRentIncomeNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutCompanyRentIncomeNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyRentIncomeInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutCompanyRentIncomeInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUpdateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyRentIncomeInputSchema) ]).optional(),
}).strict();

export const CompanyCreateNestedOneWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutCompanyFinancialDatasInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyFinancialDatasInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyFinancialDatasInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict();

export const NullableFloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableFloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const CompanyUpdateOneRequiredWithoutCompanyFinancialDatasNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneRequiredWithoutCompanyFinancialDatasNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyFinancialDatasInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyFinancialDatasInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutCompanyFinancialDatasInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUpdateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyFinancialDatasInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CaseCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCustomerInputSchema),z.lazy(() => CaseCreateWithoutCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseCreateNestedManyWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseCreateNestedManyWithoutCoCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateWithoutCoCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCoCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerEmployeeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerEmployeeCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerEmployeeWhereUniqueInputSchema).optional()
}).strict();

export const CustomerRetiredCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRetiredCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRetiredCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerRetiredWhereUniqueInputSchema).optional()
}).strict();

export const CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerSelfEmployedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerSelfEmployedCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerSelfEmployedWhereUniqueInputSchema).optional()
}).strict();

export const CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerInvestmentCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.AssetCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerInputSchema),z.lazy(() => AssetCreateWithoutCustomerInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerConsumerLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.UserUncheckedCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCustomerInputSchema),z.lazy(() => CaseCreateWithoutCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedCreateNestedManyWithoutCoCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateWithoutCoCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCoCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerEmployeeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerEmployeeCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerEmployeeWhereUniqueInputSchema).optional()
}).strict();

export const CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRetiredCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRetiredCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerRetiredWhereUniqueInputSchema).optional()
}).strict();

export const CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerSelfEmployedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerSelfEmployedCreateOrConnectWithoutCustomerInputSchema).optional(),
  connect: z.lazy(() => CustomerSelfEmployedWhereUniqueInputSchema).optional()
}).strict();

export const CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerInvestmentCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AssetUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerInputSchema),z.lazy(() => AssetCreateWithoutCustomerInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerConsumerLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NullableEnumMaritalStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumMaritalStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => MaritalStatusSchema).optional().nullable()
}).strict();

export const NullableEnumMarriageTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumMarriageTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => MarriageTypeSchema).optional().nullable()
}).strict();

export const EnumJobTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumJobTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => JobTypeSchema).optional()
}).strict();

export const NullableEnumHousingStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumHousingStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => HousingStatusSchema).optional().nullable()
}).strict();

export const UserUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.UserUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => UserUpdateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CaseUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CaseUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCustomerInputSchema),z.lazy(() => CaseCreateWithoutCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseUpdateManyWithoutCoCustomerNestedInputSchema: z.ZodType<Prisma.CaseUpdateManyWithoutCoCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateWithoutCoCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutCoCustomerInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutCoCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCoCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutCoCustomerInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutCoCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutCoCustomerInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutCoCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerEmployeeUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerEmployeeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerEmployeeCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerEmployeeUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerEmployeeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerEmployeeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerEmployeeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerEmployeeUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRetiredUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRetiredCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRetiredCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerRetiredUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerRetiredWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerRetiredWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerRetiredWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerRetiredUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerSelfEmployedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerSelfEmployedCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerSelfEmployedUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerSelfEmployedWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerSelfEmployedWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerSelfEmployedWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerSelfEmployedUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerInvestmentUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerInvestmentUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerInvestmentCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerInvestmentUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerInvestmentUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerInvestmentScalarWhereInputSchema),z.lazy(() => CustomerInvestmentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerAdditionalIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRentIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRentIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRentIncomeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRentIncomeScalarWhereInputSchema),z.lazy(() => CustomerRentIncomeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.AssetUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerInputSchema),z.lazy(() => AssetCreateWithoutCustomerInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => AssetUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => AssetUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => AssetUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerConsumerLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRentChargeUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRentChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRentChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRentChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRentChargeScalarWhereInputSchema),z.lazy(() => CustomerRentChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerAdditionalChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const UserUncheckedUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.UserUncheckedUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => UserWhereInputSchema) ]).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => UserUpdateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCustomerInputSchema),z.lazy(() => CaseCreateWithoutCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutCoCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateWithoutCoCustomerInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema),z.lazy(() => CaseCreateOrConnectWithoutCoCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutCoCustomerInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutCoCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyCoCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutCoCustomerInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutCoCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutCoCustomerInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutCoCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerEmployeeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerEmployeeCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerEmployeeUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerEmployeeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerEmployeeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerEmployeeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerEmployeeUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRetiredCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerRetiredCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerRetiredUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerRetiredWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerRetiredWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerRetiredWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerRetiredUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerSelfEmployedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerSelfEmployedCreateOrConnectWithoutCustomerInputSchema).optional(),
  upsert: z.lazy(() => CustomerSelfEmployedUpsertWithoutCustomerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerSelfEmployedWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerSelfEmployedWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerSelfEmployedWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerSelfEmployedUpdateToOneWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedUpdateWithoutCustomerInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerInvestmentUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerInvestmentCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),z.lazy(() => CustomerInvestmentWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerInvestmentUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerInvestmentUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerInvestmentScalarWhereInputSchema),z.lazy(() => CustomerInvestmentScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerAdditionalIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRentIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentIncomeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRentIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRentIncomeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRentIncomeScalarWhereInputSchema),z.lazy(() => CustomerRentIncomeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerInputSchema),z.lazy(() => AssetCreateWithoutCustomerInputSchema).array(),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => AssetCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AssetUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => AssetUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AssetCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AssetWhereUniqueInputSchema),z.lazy(() => AssetWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AssetUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => AssetUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AssetUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => AssetUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRealEstateLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerConsumerLoanChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerRentChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerRentChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),z.lazy(() => CustomerRentChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerRentChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerRentChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerRentChargeScalarWhereInputSchema),z.lazy(() => CustomerRentChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema).array(),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CustomerAdditionalChargeUpsertWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUpsertWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CustomerAdditionalChargeCreateManyCustomerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateWithWhereUniqueWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUpdateWithWhereUniqueWithoutCustomerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateManyWithWhereWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUpdateManyWithWhereWithoutCustomerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerEmployeeInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerEmployeeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerEmployeeInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCustomerEmployeeTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CustomerEmployeeTypeSchema).optional()
}).strict();

export const NullableBoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableBoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional().nullable()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerEmployeeNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerEmployeeNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerEmployeeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerEmployeeInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerEmployeeInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerEmployeeInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerRetiredInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRetiredInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerRetiredInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerRetiredNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerRetiredNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRetiredInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerRetiredInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerRetiredInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRetiredInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerSelfEmployedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerSelfEmployedInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerSelfEmployedInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const BoolFieldUpdateOperationsInputSchema: z.ZodType<Prisma.BoolFieldUpdateOperationsInput> = z.object({
  set: z.boolean().optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerSelfEmployedNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerSelfEmployedNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerSelfEmployedInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerSelfEmployedInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerSelfEmployedInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerSelfEmployedInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerInvestmentsInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerInvestmentsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerInvestmentsInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerInvestmentsNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerInvestmentsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerInvestmentsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerInvestmentsInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerInvestmentsInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerInvestmentsInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerAdditionalIncomesInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalIncomesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerAdditionalIncomesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CustomerAdditionalIncomeTypeSchema).optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerAdditionalIncomesNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerAdditionalIncomesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalIncomesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerAdditionalIncomesInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerAdditionalIncomesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerAdditionalIncomesInputSchema) ]).optional(),
}).strict();

export const AssetCreateNestedOneWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetCreateNestedOneWithoutCustomerRentIncomeInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRentIncomeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCustomerRentIncomeInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional()
}).strict();

export const CustomerCreateNestedOneWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerRentIncomesInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentIncomesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerRentIncomesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const AssetUpdateOneWithoutCustomerRentIncomeNestedInputSchema: z.ZodType<Prisma.AssetUpdateOneWithoutCustomerRentIncomeNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRentIncomeInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCustomerRentIncomeInputSchema).optional(),
  upsert: z.lazy(() => AssetUpsertWithoutCustomerRentIncomeInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => AssetWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => AssetWhereInputSchema) ]).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AssetUpdateToOneWithWhereWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUpdateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerRentIncomeInputSchema) ]).optional(),
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerRentIncomesNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerRentIncomesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentIncomesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerRentIncomesInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerRentIncomesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRentIncomesInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerRentChargesInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerRentChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerRentChargesNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerRentChargesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerRentChargesInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerRentChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRentChargesInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerConsumerLoanChargesInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerConsumerLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerConsumerLoanChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerConsumerLoanChargesNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerConsumerLoanChargesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerConsumerLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerConsumerLoanChargesInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerConsumerLoanChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerConsumerLoanChargesInputSchema) ]).optional(),
}).strict();

export const AssetCreateNestedOneWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetCreateNestedOneWithoutCustomerRealEstateLoanChargesInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRealEstateLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCustomerRealEstateLoanChargesInputSchema).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional()
}).strict();

export const CustomerCreateNestedOneWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutRealEstateLoanChargesInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutRealEstateLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutRealEstateLoanChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const AssetUpdateOneWithoutCustomerRealEstateLoanChargesNestedInputSchema: z.ZodType<Prisma.AssetUpdateOneWithoutCustomerRealEstateLoanChargesNestedInput> = z.object({
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRealEstateLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => AssetCreateOrConnectWithoutCustomerRealEstateLoanChargesInputSchema).optional(),
  upsert: z.lazy(() => AssetUpsertWithoutCustomerRealEstateLoanChargesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => AssetWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => AssetWhereInputSchema) ]).optional(),
  connect: z.lazy(() => AssetWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => AssetUpdateToOneWithWhereWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUpdateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerRealEstateLoanChargesInputSchema) ]).optional(),
}).strict();

export const CustomerUpdateOneRequiredWithoutRealEstateLoanChargesNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutRealEstateLoanChargesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutRealEstateLoanChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutRealEstateLoanChargesInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutRealEstateLoanChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUpdateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutRealEstateLoanChargesInputSchema) ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutCustomerAdditionalChargesInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerAdditionalChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CustomerAdditionalChargeTypeSchema).optional()
}).strict();

export const CustomerUpdateOneRequiredWithoutCustomerAdditionalChargesNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneRequiredWithoutCustomerAdditionalChargesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalChargesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutCustomerAdditionalChargesInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutCustomerAdditionalChargesInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUpdateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerAdditionalChargesInputSchema) ]).optional(),
}).strict();

export const UserCreateNestedOneWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutReceivedNotificationsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedNotificationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutReceivedNotificationsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSentNotificationsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentNotificationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSentNotificationsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const EnumNotificationModelFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumNotificationModelFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => NotificationModelSchema).optional()
}).strict();

export const EnumNotificationStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumNotificationStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => NotificationStatusSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutReceivedNotificationsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutReceivedNotificationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedNotificationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutReceivedNotificationsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutReceivedNotificationsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUpdateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedNotificationsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutSentNotificationsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSentNotificationsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentNotificationsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSentNotificationsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSentNotificationsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSentNotificationsInputSchema),z.lazy(() => UserUpdateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSentNotificationsInputSchema) ]).optional(),
}).strict();

export const CompanyCreateNestedOneWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyCreateNestedOneWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyPappersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyPappersInputSchema).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional()
}).strict();

export const PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutPartnerCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCompanyPappersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerCompanyPappersInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersRepresentativeCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersBeneficiaryCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveConventionCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveProcedureCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersFinancialDataCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersRepresentativeCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersBeneficiaryCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveConventionCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveProcedureCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInput> = z.object({
  create: z.union([ z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersFinancialDataCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyUpdateOneWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.CompanyUpdateOneWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyPappersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyCreateOrConnectWithoutCompanyPappersInputSchema).optional(),
  upsert: z.lazy(() => CompanyUpsertWithoutCompanyPappersInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateToOneWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUpdateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyPappersInputSchema) ]).optional(),
}).strict();

export const PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema: z.ZodType<Prisma.PartnerUpdateOneWithoutPartnerCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCompanyPappersInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerCompanyPappersInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutPartnerCompanyPappersInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUpdateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCompanyPappersInputSchema) ]).optional(),
}).strict();

export const PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersRepresentativeUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersRepresentativeCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersRepresentativeUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersRepresentativeUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersRepresentativeScalarWhereInputSchema),z.lazy(() => PappersRepresentativeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersBeneficiaryUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersBeneficiaryCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersBeneficiaryUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersBeneficiaryUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersBeneficiaryScalarWhereInputSchema),z.lazy(() => PappersBeneficiaryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersCollectiveConventionUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveConventionCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersCollectiveConventionUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersCollectiveConventionUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema),z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersCollectiveProcedureUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveProcedureCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema),z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersFinancialDataUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersFinancialDataCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersFinancialDataUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersFinancialDataUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersFinancialDataScalarWhereInputSchema),z.lazy(() => PappersFinancialDataScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersRepresentativeUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersRepresentativeCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersRepresentativeUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersRepresentativeUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersRepresentativeScalarWhereInputSchema),z.lazy(() => PappersRepresentativeScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersBeneficiaryUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersBeneficiaryCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersBeneficiaryUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersBeneficiaryUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersBeneficiaryScalarWhereInputSchema),z.lazy(() => PappersBeneficiaryScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersCollectiveConventionUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveConventionCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersCollectiveConventionUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersCollectiveConventionUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema),z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersCollectiveProcedureUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersCollectiveProcedureCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema),z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema).array(),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersFinancialDataUpsertWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUpsertWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersFinancialDataCreateManyCompanyPappersInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),z.lazy(() => PappersFinancialDataWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersFinancialDataUpdateWithWhereUniqueWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUpdateWithWhereUniqueWithoutCompanyPappersInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersFinancialDataUpdateManyWithWhereWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUpdateManyWithWhereWithoutCompanyPappersInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersFinancialDataScalarWhereInputSchema),z.lazy(() => PappersFinancialDataScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutRepresentativesInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutRepresentativesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutRepresentativesInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const PappersSanctionCreateNestedManyWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionCreateNestedManyWithoutPappersRepresentativeInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersRepresentativeInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersSanctionUncheckedCreateNestedManyWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedCreateNestedManyWithoutPappersRepresentativeInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersRepresentativeInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUpdateOneWithoutRepresentativesNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutRepresentativesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutRepresentativesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutRepresentativesInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutRepresentativesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUpdateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutRepresentativesInputSchema) ]).optional(),
}).strict();

export const PappersSanctionUpdateManyWithoutPappersRepresentativeNestedInputSchema: z.ZodType<Prisma.PappersSanctionUpdateManyWithoutPappersRepresentativeNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersRepresentativeInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersSanctionScalarWhereInputSchema),z.lazy(() => PappersSanctionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeNestedInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersRepresentativeInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersRepresentativeInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersSanctionScalarWhereInputSchema),z.lazy(() => PappersSanctionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutBeneficiariesInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutBeneficiariesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutBeneficiariesInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const PappersSanctionCreateNestedManyWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionCreateNestedManyWithoutPappersBeneficiaryInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersBeneficiaryInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PappersSanctionUncheckedCreateNestedManyWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedCreateNestedManyWithoutPappersBeneficiaryInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersBeneficiaryInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUpdateOneWithoutBeneficiariesNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutBeneficiariesNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutBeneficiariesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutBeneficiariesInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutBeneficiariesInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUpdateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutBeneficiariesInputSchema) ]).optional(),
}).strict();

export const PappersSanctionUpdateManyWithoutPappersBeneficiaryNestedInputSchema: z.ZodType<Prisma.PappersSanctionUpdateManyWithoutPappersBeneficiaryNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersBeneficiaryInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersSanctionScalarWhereInputSchema),z.lazy(() => PappersSanctionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryNestedInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema).array(),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUpsertWithWhereUniqueWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PappersSanctionCreateManyPappersBeneficiaryInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PappersSanctionWhereUniqueInputSchema),z.lazy(() => PappersSanctionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUpdateWithWhereUniqueWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUpdateManyWithWhereWithoutPappersBeneficiaryInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PappersSanctionScalarWhereInputSchema),z.lazy(() => PappersSanctionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutCollectiveConventionInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveConventionInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCollectiveConventionInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const CompanyPappersUpdateOneWithoutCollectiveConventionNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutCollectiveConventionNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveConventionInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCollectiveConventionInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutCollectiveConventionInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUpdateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCollectiveConventionInputSchema) ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutCollectiveProcedureInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveProcedureInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCollectiveProcedureInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const CompanyPappersUpdateOneWithoutCollectiveProcedureNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutCollectiveProcedureNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveProcedureInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutCollectiveProcedureInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutCollectiveProcedureInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUpdateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCollectiveProcedureInputSchema) ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutFinancialDataInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutFinancialDataInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutFinancialDataInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const CompanyPappersUpdateOneWithoutFinancialDataNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutFinancialDataNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutFinancialDataInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutFinancialDataInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutFinancialDataInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUpdateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutFinancialDataInputSchema) ]).optional(),
}).strict();

export const PappersRepresentativeCreateNestedOneWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateNestedOneWithoutSanctionInput> = z.object({
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutSanctionInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PappersRepresentativeCreateOrConnectWithoutSanctionInputSchema).optional(),
  connect: z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).optional()
}).strict();

export const PappersBeneficiaryCreateNestedOneWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateNestedOneWithoutSanctionInput> = z.object({
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutSanctionInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutSanctionInputSchema).optional(),
  connect: z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).optional()
}).strict();

export const PappersRepresentativeUpdateOneWithoutSanctionNestedInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateOneWithoutSanctionNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutSanctionInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PappersRepresentativeCreateOrConnectWithoutSanctionInputSchema).optional(),
  upsert: z.lazy(() => PappersRepresentativeUpsertWithoutSanctionInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => PappersRepresentativeWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => PappersRepresentativeWhereInputSchema) ]).optional(),
  connect: z.lazy(() => PappersRepresentativeWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PappersRepresentativeUpdateToOneWithWhereWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUpdateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedUpdateWithoutSanctionInputSchema) ]).optional(),
}).strict();

export const PappersBeneficiaryUpdateOneWithoutSanctionNestedInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateOneWithoutSanctionNestedInput> = z.object({
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutSanctionInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PappersBeneficiaryCreateOrConnectWithoutSanctionInputSchema).optional(),
  upsert: z.lazy(() => PappersBeneficiaryUpsertWithoutSanctionInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => PappersBeneficiaryWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => PappersBeneficiaryWhereInputSchema) ]).optional(),
  connect: z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PappersBeneficiaryUpdateToOneWithWhereWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUpdateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedUpdateWithoutSanctionInputSchema) ]).optional(),
}).strict();

export const PartnerCreateclientTypesInputSchema: z.ZodType<Prisma.PartnerCreateclientTypesInput> = z.object({
  set: z.lazy(() => PartnerClientTypeSchema).array()
}).strict();

export const PartnerCreateusageCasesInputSchema: z.ZodType<Prisma.PartnerCreateusageCasesInput> = z.object({
  set: z.lazy(() => PartnerUsageCasesSchema).array()
}).strict();

export const UserCreateNestedOneWithoutPartnerInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutPartnerInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersCreateNestedOneWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersCreateNestedOneWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutPartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutPartnerInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerPendingCaseCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUncheckedCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateNestedOneWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutPartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutPartnerInputSchema).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional()
}).strict();

export const PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerPendingCaseCreateManyPartnerInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const EnumPartnerStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumPartnerStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => PartnerStatusSchema).optional()
}).strict();

export const EnumPartnerProfessionFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumPartnerProfessionFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => PartnerProfessionSchema).optional()
}).strict();

export const EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumPartnerLoanVolumeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => PartnerLoanVolumeSchema).optional()
}).strict();

export const PartnerUpdateclientTypesInputSchema: z.ZodType<Prisma.PartnerUpdateclientTypesInput> = z.object({
  set: z.lazy(() => PartnerClientTypeSchema).array().optional(),
  push: z.union([ z.lazy(() => PartnerClientTypeSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
}).strict();

export const PartnerUpdateusageCasesInputSchema: z.ZodType<Prisma.PartnerUpdateusageCasesInput> = z.object({
  set: z.lazy(() => PartnerUsageCasesSchema).array().optional(),
  push: z.union([ z.lazy(() => PartnerUsageCasesSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
}).strict();

export const EnumPartnerCompanySizeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumPartnerCompanySizeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => PartnerCompanySizeSchema).optional()
}).strict();

export const UserUpdateOneRequiredWithoutPartnerNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutPartnerInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutPartnerInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutPartnerInputSchema),z.lazy(() => UserUpdateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.PartnerRateUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerRateUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => PartnerRateUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerRateScalarWhereInputSchema),z.lazy(() => PartnerRateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CasePartnerUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => CasePartnerUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUpdateOneWithoutPartnerNestedInputSchema: z.ZodType<Prisma.CompanyPappersUpdateOneWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutPartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutPartnerInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutPartnerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUpdateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutPartnerInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerPendingCaseUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerPendingCaseCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerPendingCaseUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerPendingCaseUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerPendingCaseScalarWhereInputSchema),z.lazy(() => PartnerPendingCaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerRateUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => PartnerRateUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerRateScalarWhereInputSchema),z.lazy(() => PartnerRateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CasePartnerUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => CasePartnerUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutPartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CompanyPappersCreateOrConnectWithoutPartnerInputSchema).optional(),
  upsert: z.lazy(() => CompanyPappersUpsertWithoutPartnerInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CompanyPappersWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CompanyPappersWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CompanyPappersUpdateToOneWithWhereWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUpdateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutPartnerInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema).array(),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerPendingCaseUpsertWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUpsertWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerPendingCaseCreateManyPartnerInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerPendingCaseUpdateWithWhereUniqueWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUpdateWithWhereUniqueWithoutPartnerInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerPendingCaseUpdateManyWithWhereWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUpdateManyWithWhereWithoutPartnerInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerPendingCaseScalarWhereInputSchema),z.lazy(() => PartnerPendingCaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerCreateNestedOneWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutPartnerCollaboratorsInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerCollaboratorsInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutPartnerCollaboratorsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutPartnerCollaboratorsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const PartnerUpdateOneRequiredWithoutPartnerCollaboratorsNestedInputSchema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutPartnerCollaboratorsNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerCollaboratorsInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutPartnerCollaboratorsInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUpdateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCollaboratorsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutPartnerCollaboratorsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutPartnerCollaboratorsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutPartnerCollaboratorsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutPartnerCollaboratorsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUpdateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerCollaboratorsInputSchema) ]).optional(),
}).strict();

export const PartnerCreateNestedOneWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutPartnerRatesInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerRatesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerRatesInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutPartnerRateCreatedByInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerRateCreatedByInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutPartnerRateCreatedByInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CasePartnerCreateNestedManyWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerCreateNestedManyWithoutRateInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutRateInputSchema),z.lazy(() => CasePartnerCreateWithoutRateInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyRateInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUncheckedCreateNestedManyWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateNestedManyWithoutRateInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutRateInputSchema),z.lazy(() => CasePartnerCreateWithoutRateInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyRateInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerUpdateOneRequiredWithoutPartnerRatesNestedInputSchema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutPartnerRatesNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerRatesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerRatesInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutPartnerRatesInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUpdateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerRatesInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutPartnerRateCreatedByNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutPartnerRateCreatedByNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerRateCreatedByInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutPartnerRateCreatedByInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutPartnerRateCreatedByInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUpdateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerRateCreatedByInputSchema) ]).optional(),
}).strict();

export const CasePartnerUpdateManyWithoutRateNestedInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyWithoutRateNestedInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutRateInputSchema),z.lazy(() => CasePartnerCreateWithoutRateInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutRateInputSchema),z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutRateInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyRateInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutRateInputSchema),z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutRateInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CasePartnerUpdateManyWithWhereWithoutRateInputSchema),z.lazy(() => CasePartnerUpdateManyWithWhereWithoutRateInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyWithoutRateNestedInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyWithoutRateNestedInput> = z.object({
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutRateInputSchema),z.lazy(() => CasePartnerCreateWithoutRateInputSchema).array(),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema),z.lazy(() => CasePartnerCreateOrConnectWithoutRateInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutRateInputSchema),z.lazy(() => CasePartnerUpsertWithWhereUniqueWithoutRateInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CasePartnerCreateManyRateInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CasePartnerWhereUniqueInputSchema),z.lazy(() => CasePartnerWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutRateInputSchema),z.lazy(() => CasePartnerUpdateWithWhereUniqueWithoutRateInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CasePartnerUpdateManyWithWhereWithoutRateInputSchema),z.lazy(() => CasePartnerUpdateManyWithWhereWithoutRateInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerCreateNestedOneWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutPartnerCasesInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCasesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerCasesInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const PartnerRateCreateNestedOneWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateNestedOneWithoutCasePartnerInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutCasePartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerRateCreateOrConnectWithoutCasePartnerInputSchema).optional(),
  connect: z.lazy(() => PartnerRateWhereUniqueInputSchema).optional()
}).strict();

export const CaseCreateNestedOneWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutCasePartnerInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCasePartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCasePartnerInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const PartnerUpdateOneRequiredWithoutPartnerCasesNestedInputSchema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutPartnerCasesNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCasesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerCasesInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutPartnerCasesInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUpdateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCasesInputSchema) ]).optional(),
}).strict();

export const PartnerRateUpdateOneRequiredWithoutCasePartnerNestedInputSchema: z.ZodType<Prisma.PartnerRateUpdateOneRequiredWithoutCasePartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutCasePartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerRateCreateOrConnectWithoutCasePartnerInputSchema).optional(),
  upsert: z.lazy(() => PartnerRateUpsertWithoutCasePartnerInputSchema).optional(),
  connect: z.lazy(() => PartnerRateWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerRateUpdateToOneWithWhereWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUpdateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutCasePartnerInputSchema) ]).optional(),
}).strict();

export const CaseUpdateOneRequiredWithoutCasePartnerNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutCasePartnerNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCasePartnerInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutCasePartnerInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutCasePartnerInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutCasePartnerInputSchema),z.lazy(() => CaseUpdateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCasePartnerInputSchema) ]).optional(),
}).strict();

export const PartnerCreateNestedOneWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutPartnerPendingCasesInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerPendingCasesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerPendingCasesInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const EnumProspectPatrimonyFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumProspectPatrimonyFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ProspectPatrimonySchema).optional()
}).strict();

export const EnumProspectProductFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumProspectProductFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ProspectProductSchema).optional()
}).strict();

export const EnumProspectAmountFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumProspectAmountFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ProspectAmountSchema).optional()
}).strict();

export const EnumProspectUrgencyFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumProspectUrgencyFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ProspectUrgencySchema).optional()
}).strict();

export const EnumProspectRatingFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumProspectRatingFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ProspectRatingSchema).optional()
}).strict();

export const PartnerUpdateOneRequiredWithoutPartnerPendingCasesNestedInputSchema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutPartnerPendingCasesNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerPendingCasesInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutPartnerPendingCasesInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutPartnerPendingCasesInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUpdateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerPendingCasesInputSchema) ]).optional(),
}).strict();

export const EnumPendingCaseTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumPendingCaseTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => PendingCaseTypeSchema).optional()
}).strict();

export const NullableEnumCompanyTypeFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumCompanyTypeFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => CompanyTypeSchema).optional().nullable()
}).strict();

export const UserCreateNestedOneWithoutSimulationInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSimulationInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedCreateWithoutSimulationInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSimulationInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseCreateNestedOneWithoutSimulationInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutSimulationInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSimulationInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSimulationInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const EnumSimulationStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumSimulationStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => SimulationStatusSchema).optional()
}).strict();

export const NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumRejectionMotiveFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => RejectionMotiveSchema).optional().nullable()
}).strict();

export const UserUpdateOneRequiredWithoutSimulationNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSimulationNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedCreateWithoutSimulationInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSimulationInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSimulationInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSimulationInputSchema),z.lazy(() => UserUpdateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSimulationInputSchema) ]).optional(),
}).strict();

export const CaseUpdateOneRequiredWithoutSimulationNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutSimulationNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSimulationInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSimulationInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutSimulationInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutSimulationInputSchema),z.lazy(() => CaseUpdateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSimulationInputSchema) ]).optional(),
}).strict();

export const BankCreateNestedOneWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankCreateNestedOneWithoutSubmissionsInput> = z.object({
  create: z.union([ z.lazy(() => BankCreateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedCreateWithoutSubmissionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BankCreateOrConnectWithoutSubmissionsInputSchema).optional(),
  connect: z.lazy(() => BankWhereUniqueInputSchema).optional()
}).strict();

export const CaseCreateNestedOneWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutSubmissionsInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSubmissionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSubmissionsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSubmissionsInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSubmissionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSubmissionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const EnumProductFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumProductFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => ProductSchema).optional()
}).strict();

export const EnumSubmissionStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumSubmissionStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => SubmissionStatusSchema).optional()
}).strict();

export const BankUpdateOneRequiredWithoutSubmissionsNestedInputSchema: z.ZodType<Prisma.BankUpdateOneRequiredWithoutSubmissionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => BankCreateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedCreateWithoutSubmissionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => BankCreateOrConnectWithoutSubmissionsInputSchema).optional(),
  upsert: z.lazy(() => BankUpsertWithoutSubmissionsInputSchema).optional(),
  connect: z.lazy(() => BankWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => BankUpdateToOneWithWhereWithoutSubmissionsInputSchema),z.lazy(() => BankUpdateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedUpdateWithoutSubmissionsInputSchema) ]).optional(),
}).strict();

export const CaseUpdateOneRequiredWithoutSubmissionsNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutSubmissionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSubmissionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSubmissionsInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutSubmissionsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutSubmissionsInputSchema),z.lazy(() => CaseUpdateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSubmissionsInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutSubmissionsNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSubmissionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSubmissionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSubmissionsInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSubmissionsInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSubmissionsInputSchema),z.lazy(() => UserUpdateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSubmissionsInputSchema) ]).optional(),
}).strict();

export const CaseCreateNestedOneWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutSuggestionsInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSuggestionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSuggestionsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const EnumSuggestionFieldFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumSuggestionFieldFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => SuggestionFieldSchema).optional()
}).strict();

export const EnumSuggestionStatusFieldUpdateOperationsInputSchema: z.ZodType<Prisma.EnumSuggestionStatusFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => SuggestionStatusSchema).optional()
}).strict();

export const CaseUpdateOneRequiredWithoutSuggestionsNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutSuggestionsNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSuggestionsInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSuggestionsInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutSuggestionsInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutSuggestionsInputSchema),z.lazy(() => CaseUpdateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSuggestionsInputSchema) ]).optional(),
}).strict();

export const CaseCreateNestedOneWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseCreateNestedOneWithoutSynthesisInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSynthesisInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSynthesisInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional()
}).strict();

export const UserCreateNestedOneWithoutSynthesisInputSchema: z.ZodType<Prisma.UserCreateNestedOneWithoutSynthesisInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedCreateWithoutSynthesisInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSynthesisInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional()
}).strict();

export const CaseUpdateOneRequiredWithoutSynthesisNestedInputSchema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutSynthesisNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSynthesisInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CaseCreateOrConnectWithoutSynthesisInputSchema).optional(),
  upsert: z.lazy(() => CaseUpsertWithoutSynthesisInputSchema).optional(),
  connect: z.lazy(() => CaseWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CaseUpdateToOneWithWhereWithoutSynthesisInputSchema),z.lazy(() => CaseUpdateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSynthesisInputSchema) ]).optional(),
}).strict();

export const UserUpdateOneRequiredWithoutSynthesisNestedInputSchema: z.ZodType<Prisma.UserUpdateOneRequiredWithoutSynthesisNestedInput> = z.object({
  create: z.union([ z.lazy(() => UserCreateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedCreateWithoutSynthesisInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => UserCreateOrConnectWithoutSynthesisInputSchema).optional(),
  upsert: z.lazy(() => UserUpsertWithoutSynthesisInputSchema).optional(),
  connect: z.lazy(() => UserWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => UserUpdateToOneWithWhereWithoutSynthesisInputSchema),z.lazy(() => UserUpdateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSynthesisInputSchema) ]).optional(),
}).strict();

export const AccountCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SessionCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CustomerCreateNestedOneWithoutUserInputSchema: z.ZodType<Prisma.CustomerCreateNestedOneWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutUserInputSchema).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional()
}).strict();

export const CaseTraceCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutUserInputSchema),z.lazy(() => CaseTraceCreateWithoutUserInputSchema).array(),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseTraceCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.CompanyCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutUserInputSchema),z.lazy(() => CompanyCreateWithoutUserInputSchema).array(),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema),z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.CaseCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutUserInputSchema),z.lazy(() => CaseCreateWithoutUserInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateNestedManyWithoutCollaboratorInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SimulationCreateNestedManyWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationCreateNestedManyWithoutAuthorInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutAuthorInputSchema),z.lazy(() => SimulationCreateWithoutAuthorInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyAuthorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubmissionCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SubmissionCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutUserInputSchema),z.lazy(() => SubmissionCreateWithoutUserInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SynthesisCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SynthesisCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutUserInputSchema),z.lazy(() => SynthesisCreateWithoutUserInputSchema).array(),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema),z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SynthesisCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NotificationCreateNestedManyWithoutSenderInputSchema: z.ZodType<Prisma.NotificationCreateNestedManyWithoutSenderInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutSenderInputSchema),z.lazy(() => NotificationCreateWithoutSenderInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManySenderInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NotificationCreateNestedManyWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationCreateNestedManyWithoutReceiverInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutReceiverInputSchema),z.lazy(() => NotificationCreateWithoutReceiverInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManyReceiverInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageCreateNestedManyWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageCreateNestedManyWithoutSenderInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateWithoutSenderInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManySenderInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerCreateNestedOneWithoutUserInputSchema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutUserInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateNestedManyWithoutCollaboratorInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutUserInputSchema),z.lazy(() => PartnerRateCreateWithoutUserInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const AccountUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SessionUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutUserInputSchema),z.lazy(() => CaseTraceCreateWithoutUserInputSchema).array(),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseTraceCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CompanyUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutUserInputSchema),z.lazy(() => CompanyCreateWithoutUserInputSchema).array(),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema),z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.CaseUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutUserInputSchema),z.lazy(() => CaseCreateWithoutUserInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUncheckedCreateNestedManyWithoutAuthorInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutAuthorInputSchema),z.lazy(() => SimulationCreateWithoutAuthorInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyAuthorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutUserInputSchema),z.lazy(() => SubmissionCreateWithoutUserInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const SynthesisUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutUserInputSchema),z.lazy(() => SynthesisCreateWithoutUserInputSchema).array(),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema),z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SynthesisCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NotificationUncheckedCreateNestedManyWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUncheckedCreateNestedManyWithoutSenderInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutSenderInputSchema),z.lazy(() => NotificationCreateWithoutSenderInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManySenderInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUncheckedCreateNestedManyWithoutReceiverInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutReceiverInputSchema),z.lazy(() => NotificationCreateWithoutReceiverInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManyReceiverInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUncheckedCreateNestedManyWithoutSenderInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateWithoutSenderInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManySenderInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerUncheckedCreateNestedOneWithoutUserInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateNestedOneWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutUserInputSchema).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional()
}).strict();

export const PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUncheckedCreateNestedManyWithoutUserInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutUserInputSchema),z.lazy(() => PartnerRateCreateWithoutUserInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyUserInputEnvelopeSchema).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
}).strict();

export const NullableEnumGenderFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableEnumGenderFieldUpdateOperationsInput> = z.object({
  set: z.lazy(() => GenderSchema).optional().nullable()
}).strict();

export const AccountUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.AccountUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SessionUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SessionUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CustomerUpdateOneWithoutUserNestedInputSchema: z.ZodType<Prisma.CustomerUpdateOneWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CustomerCreateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => CustomerCreateOrConnectWithoutUserInputSchema).optional(),
  upsert: z.lazy(() => CustomerUpsertWithoutUserInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => CustomerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => CustomerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => CustomerUpdateToOneWithWhereWithoutUserInputSchema),z.lazy(() => CustomerUpdateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutUserInputSchema) ]).optional(),
}).strict();

export const CaseTraceUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.CaseTraceUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutUserInputSchema),z.lazy(() => CaseTraceCreateWithoutUserInputSchema).array(),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseTraceUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseTraceUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseTraceCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseTraceUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseTraceUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseTraceUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => CaseTraceUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseTraceScalarWhereInputSchema),z.lazy(() => CaseTraceScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.CompanyUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutUserInputSchema),z.lazy(() => CompanyCreateWithoutUserInputSchema).array(),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema),z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CompanyUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CompanyUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => CompanyUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyScalarWhereInputSchema),z.lazy(() => CompanyScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.CaseUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutUserInputSchema),z.lazy(() => CaseCreateWithoutUserInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateManyWithoutCollaboratorNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseCollaboratorScalarWhereInputSchema),z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SimulationUpdateManyWithoutAuthorNestedInputSchema: z.ZodType<Prisma.SimulationUpdateManyWithoutAuthorNestedInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutAuthorInputSchema),z.lazy(() => SimulationCreateWithoutAuthorInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SimulationUpsertWithWhereUniqueWithoutAuthorInputSchema),z.lazy(() => SimulationUpsertWithWhereUniqueWithoutAuthorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyAuthorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SimulationUpdateWithWhereUniqueWithoutAuthorInputSchema),z.lazy(() => SimulationUpdateWithWhereUniqueWithoutAuthorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SimulationUpdateManyWithWhereWithoutAuthorInputSchema),z.lazy(() => SimulationUpdateManyWithWhereWithoutAuthorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SimulationScalarWhereInputSchema),z.lazy(() => SimulationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SubmissionUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutUserInputSchema),z.lazy(() => SubmissionCreateWithoutUserInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubmissionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SubmissionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SynthesisUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SynthesisUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutUserInputSchema),z.lazy(() => SynthesisCreateWithoutUserInputSchema).array(),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema),z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SynthesisUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SynthesisUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SynthesisCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SynthesisUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SynthesisUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SynthesisUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SynthesisUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SynthesisScalarWhereInputSchema),z.lazy(() => SynthesisScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const NotificationUpdateManyWithoutSenderNestedInputSchema: z.ZodType<Prisma.NotificationUpdateManyWithoutSenderNestedInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutSenderInputSchema),z.lazy(() => NotificationCreateWithoutSenderInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => NotificationUpsertWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => NotificationUpsertWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManySenderInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => NotificationUpdateWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => NotificationUpdateWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => NotificationUpdateManyWithWhereWithoutSenderInputSchema),z.lazy(() => NotificationUpdateManyWithWhereWithoutSenderInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => NotificationScalarWhereInputSchema),z.lazy(() => NotificationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const NotificationUpdateManyWithoutReceiverNestedInputSchema: z.ZodType<Prisma.NotificationUpdateManyWithoutReceiverNestedInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutReceiverInputSchema),z.lazy(() => NotificationCreateWithoutReceiverInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => NotificationUpsertWithWhereUniqueWithoutReceiverInputSchema),z.lazy(() => NotificationUpsertWithWhereUniqueWithoutReceiverInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManyReceiverInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => NotificationUpdateWithWhereUniqueWithoutReceiverInputSchema),z.lazy(() => NotificationUpdateWithWhereUniqueWithoutReceiverInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => NotificationUpdateManyWithWhereWithoutReceiverInputSchema),z.lazy(() => NotificationUpdateManyWithWhereWithoutReceiverInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => NotificationScalarWhereInputSchema),z.lazy(() => NotificationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageUpdateManyWithoutSenderNestedInputSchema: z.ZodType<Prisma.CaseMessageUpdateManyWithoutSenderNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateWithoutSenderInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManySenderInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseMessageUpdateManyWithWhereWithoutSenderInputSchema),z.lazy(() => CaseMessageUpdateManyWithWhereWithoutSenderInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseMessageScalarWhereInputSchema),z.lazy(() => CaseMessageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerUpdateOneWithoutUserNestedInputSchema: z.ZodType<Prisma.PartnerUpdateOneWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutUserInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutUserInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutUserInputSchema),z.lazy(() => PartnerUpdateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutUserInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.PartnerRateUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutUserInputSchema),z.lazy(() => PartnerRateCreateWithoutUserInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerRateUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => PartnerRateUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerRateScalarWhereInputSchema),z.lazy(() => PartnerRateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const AccountUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountCreateWithoutUserInputSchema).array(),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema),z.lazy(() => AccountCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => AccountCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => AccountWhereUniqueInputSchema),z.lazy(() => AccountWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => AccountUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => AccountUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionCreateWithoutUserInputSchema).array(),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SessionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SessionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SessionWhereUniqueInputSchema),z.lazy(() => SessionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SessionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SessionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutUserInputSchema),z.lazy(() => CaseTraceCreateWithoutUserInputSchema).array(),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseTraceCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseTraceUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseTraceUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseTraceCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseTraceWhereUniqueInputSchema),z.lazy(() => CaseTraceWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseTraceUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseTraceUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseTraceUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => CaseTraceUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseTraceScalarWhereInputSchema),z.lazy(() => CaseTraceScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CompanyUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CompanyCreateWithoutUserInputSchema),z.lazy(() => CompanyCreateWithoutUserInputSchema).array(),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema),z.lazy(() => CompanyCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CompanyUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CompanyUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CompanyCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CompanyWhereUniqueInputSchema),z.lazy(() => CompanyWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CompanyUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CompanyUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CompanyUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => CompanyUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CompanyScalarWhereInputSchema),z.lazy(() => CompanyScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCreateWithoutUserInputSchema),z.lazy(() => CaseCreateWithoutUserInputSchema).array(),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema),z.lazy(() => CaseCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseWhereUniqueInputSchema),z.lazy(() => CaseWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => CaseUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => CaseUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),z.lazy(() => CaseCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseCollaboratorScalarWhereInputSchema),z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateManyWithoutAuthorNestedInput> = z.object({
  create: z.union([ z.lazy(() => SimulationCreateWithoutAuthorInputSchema),z.lazy(() => SimulationCreateWithoutAuthorInputSchema).array(),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema),z.lazy(() => SimulationCreateOrConnectWithoutAuthorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SimulationUpsertWithWhereUniqueWithoutAuthorInputSchema),z.lazy(() => SimulationUpsertWithWhereUniqueWithoutAuthorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SimulationCreateManyAuthorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SimulationWhereUniqueInputSchema),z.lazy(() => SimulationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SimulationUpdateWithWhereUniqueWithoutAuthorInputSchema),z.lazy(() => SimulationUpdateWithWhereUniqueWithoutAuthorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SimulationUpdateManyWithWhereWithoutAuthorInputSchema),z.lazy(() => SimulationUpdateManyWithWhereWithoutAuthorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SimulationScalarWhereInputSchema),z.lazy(() => SimulationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SubmissionCreateWithoutUserInputSchema),z.lazy(() => SubmissionCreateWithoutUserInputSchema).array(),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema),z.lazy(() => SubmissionCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SubmissionUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SubmissionCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SubmissionWhereUniqueInputSchema),z.lazy(() => SubmissionWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SubmissionUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SubmissionUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SubmissionUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => SynthesisCreateWithoutUserInputSchema),z.lazy(() => SynthesisCreateWithoutUserInputSchema).array(),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema),z.lazy(() => SynthesisCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => SynthesisUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SynthesisUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => SynthesisCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => SynthesisWhereUniqueInputSchema),z.lazy(() => SynthesisWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => SynthesisUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => SynthesisUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => SynthesisUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => SynthesisUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => SynthesisScalarWhereInputSchema),z.lazy(() => SynthesisScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateManyWithoutSenderNestedInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutSenderInputSchema),z.lazy(() => NotificationCreateWithoutSenderInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => NotificationUpsertWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => NotificationUpsertWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManySenderInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => NotificationUpdateWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => NotificationUpdateWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => NotificationUpdateManyWithWhereWithoutSenderInputSchema),z.lazy(() => NotificationUpdateManyWithWhereWithoutSenderInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => NotificationScalarWhereInputSchema),z.lazy(() => NotificationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateManyWithoutReceiverNestedInput> = z.object({
  create: z.union([ z.lazy(() => NotificationCreateWithoutReceiverInputSchema),z.lazy(() => NotificationCreateWithoutReceiverInputSchema).array(),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema),z.lazy(() => NotificationCreateOrConnectWithoutReceiverInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => NotificationUpsertWithWhereUniqueWithoutReceiverInputSchema),z.lazy(() => NotificationUpsertWithWhereUniqueWithoutReceiverInputSchema).array() ]).optional(),
  createMany: z.lazy(() => NotificationCreateManyReceiverInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => NotificationWhereUniqueInputSchema),z.lazy(() => NotificationWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => NotificationUpdateWithWhereUniqueWithoutReceiverInputSchema),z.lazy(() => NotificationUpdateWithWhereUniqueWithoutReceiverInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => NotificationUpdateManyWithWhereWithoutReceiverInputSchema),z.lazy(() => NotificationUpdateManyWithWhereWithoutReceiverInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => NotificationScalarWhereInputSchema),z.lazy(() => NotificationScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateManyWithoutSenderNestedInput> = z.object({
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateWithoutSenderInputSchema).array(),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema),z.lazy(() => CaseMessageCreateOrConnectWithoutSenderInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => CaseMessageUpsertWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  createMany: z.lazy(() => CaseMessageCreateManySenderInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => CaseMessageWhereUniqueInputSchema),z.lazy(() => CaseMessageWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutSenderInputSchema),z.lazy(() => CaseMessageUpdateWithWhereUniqueWithoutSenderInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => CaseMessageUpdateManyWithWhereWithoutSenderInputSchema),z.lazy(() => CaseMessageUpdateManyWithWhereWithoutSenderInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => CaseMessageScalarWhereInputSchema),z.lazy(() => CaseMessageScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerUncheckedUpdateOneWithoutUserNestedInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateOneWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCreateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutUserInputSchema) ]).optional(),
  connectOrCreate: z.lazy(() => PartnerCreateOrConnectWithoutUserInputSchema).optional(),
  upsert: z.lazy(() => PartnerUpsertWithoutUserInputSchema).optional(),
  disconnect: z.union([ z.boolean(),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  delete: z.union([ z.boolean(),z.lazy(() => PartnerWhereInputSchema) ]).optional(),
  connect: z.lazy(() => PartnerWhereUniqueInputSchema).optional(),
  update: z.union([ z.lazy(() => PartnerUpdateToOneWithWhereWithoutUserInputSchema),z.lazy(() => PartnerUpdateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutUserInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema).array(),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerCollaboratorCreateManyCollaboratorInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateManyWithoutUserNestedInput> = z.object({
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutUserInputSchema),z.lazy(() => PartnerRateCreateWithoutUserInputSchema).array(),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema).array() ]).optional(),
  connectOrCreate: z.union([ z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema),z.lazy(() => PartnerRateCreateOrConnectWithoutUserInputSchema).array() ]).optional(),
  upsert: z.union([ z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutUserInputSchema),z.lazy(() => PartnerRateUpsertWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  createMany: z.lazy(() => PartnerRateCreateManyUserInputEnvelopeSchema).optional(),
  set: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  disconnect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  delete: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  connect: z.union([ z.lazy(() => PartnerRateWhereUniqueInputSchema),z.lazy(() => PartnerRateWhereUniqueInputSchema).array() ]).optional(),
  update: z.union([ z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutUserInputSchema),z.lazy(() => PartnerRateUpdateWithWhereUniqueWithoutUserInputSchema).array() ]).optional(),
  updateMany: z.union([ z.lazy(() => PartnerRateUpdateManyWithWhereWithoutUserInputSchema),z.lazy(() => PartnerRateUpdateManyWithWhereWithoutUserInputSchema).array() ]).optional(),
  deleteMany: z.union([ z.lazy(() => PartnerRateScalarWhereInputSchema),z.lazy(() => PartnerRateScalarWhereInputSchema).array() ]).optional(),
}).strict();

export const NestedStringFilterSchema: z.ZodType<Prisma.NestedStringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const NestedStringNullableFilterSchema: z.ZodType<Prisma.NestedStringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntFilterSchema: z.ZodType<Prisma.NestedIntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const NestedIntNullableFilterSchema: z.ZodType<Prisma.NestedIntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumAssetTypeNullableFilterSchema: z.ZodType<Prisma.NestedEnumAssetTypeNullableFilter> = z.object({
  equals: z.lazy(() => AssetTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NestedEnumAssetTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumAssetUsageTypeFilterSchema: z.ZodType<Prisma.NestedEnumAssetUsageTypeFilter> = z.object({
  equals: z.lazy(() => AssetUsageTypeSchema).optional(),
  in: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => NestedEnumAssetUsageTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumAssetOwnershipTypeFilterSchema: z.ZodType<Prisma.NestedEnumAssetOwnershipTypeFilter> = z.object({
  equals: z.lazy(() => AssetOwnershipTypeSchema).optional(),
  in: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => NestedEnumAssetOwnershipTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumAssetCompanyTypeNullableFilterSchema: z.ZodType<Prisma.NestedEnumAssetCompanyTypeNullableFilter> = z.object({
  equals: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NestedEnumAssetCompanyTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedDateTimeFilterSchema: z.ZodType<Prisma.NestedDateTimeFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeFilterSchema) ]).optional(),
}).strict();

export const NestedStringWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const NestedStringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const NestedIntWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const NestedFloatFilterSchema: z.ZodType<Prisma.NestedFloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const NestedIntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const NestedFloatNullableFilterSchema: z.ZodType<Prisma.NestedFloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumAssetTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumAssetTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NestedEnumAssetTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetTypeNullableFilterSchema).optional()
}).strict();

export const NestedEnumAssetUsageTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumAssetUsageTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetUsageTypeSchema).optional(),
  in: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetUsageTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => NestedEnumAssetUsageTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetUsageTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetUsageTypeFilterSchema).optional()
}).strict();

export const NestedEnumAssetOwnershipTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumAssetOwnershipTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetOwnershipTypeSchema).optional(),
  in: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetOwnershipTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => NestedEnumAssetOwnershipTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetOwnershipTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetOwnershipTypeFilterSchema).optional()
}).strict();

export const NestedEnumAssetCompanyTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumAssetCompanyTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => AssetCompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NestedEnumAssetCompanyTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetCompanyTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetCompanyTypeNullableFilterSchema).optional()
}).strict();

export const NestedDateTimeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional(),
  in: z.coerce.date().array().optional(),
  notIn: z.coerce.date().array().optional(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeFilterSchema).optional()
}).strict();

export const NestedEnumAssetShareholderTypeFilterSchema: z.ZodType<Prisma.NestedEnumAssetShareholderTypeFilter> = z.object({
  equals: z.lazy(() => AssetShareholderTypeSchema).optional(),
  in: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => NestedEnumAssetShareholderTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumAssetShareholderTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumAssetShareholderTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => AssetShareholderTypeSchema).optional(),
  in: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  notIn: z.lazy(() => AssetShareholderTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => NestedEnumAssetShareholderTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumAssetShareholderTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumAssetShareholderTypeFilterSchema).optional()
}).strict();

export const NestedJsonFilterSchema: z.ZodType<Prisma.NestedJsonFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional()
}).strict();

export const NestedEnumBankLocalisationFilterSchema: z.ZodType<Prisma.NestedEnumBankLocalisationFilter> = z.object({
  equals: z.lazy(() => BankLocalisationSchema).optional(),
  in: z.lazy(() => BankLocalisationSchema).array().optional(),
  notIn: z.lazy(() => BankLocalisationSchema).array().optional(),
  not: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => NestedEnumBankLocalisationFilterSchema) ]).optional(),
}).strict();

export const NestedDateTimeNullableFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumBankLocalisationWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumBankLocalisationWithAggregatesFilter> = z.object({
  equals: z.lazy(() => BankLocalisationSchema).optional(),
  in: z.lazy(() => BankLocalisationSchema).array().optional(),
  notIn: z.lazy(() => BankLocalisationSchema).array().optional(),
  not: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => NestedEnumBankLocalisationWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumBankLocalisationFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumBankLocalisationFilterSchema).optional()
}).strict();

export const NestedDateTimeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedDateTimeNullableWithAggregatesFilter> = z.object({
  equals: z.coerce.date().optional().nullable(),
  in: z.coerce.date().array().optional().nullable(),
  notIn: z.coerce.date().array().optional().nullable(),
  lt: z.coerce.date().optional(),
  lte: z.coerce.date().optional(),
  gt: z.coerce.date().optional(),
  gte: z.coerce.date().optional(),
  not: z.union([ z.coerce.date(),z.lazy(() => NestedDateTimeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedDateTimeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedDateTimeNullableFilterSchema).optional()
}).strict();

export const NestedEnumCasePurposeFilterSchema: z.ZodType<Prisma.NestedEnumCasePurposeFilter> = z.object({
  equals: z.lazy(() => CasePurposeSchema).optional(),
  in: z.lazy(() => CasePurposeSchema).array().optional(),
  notIn: z.lazy(() => CasePurposeSchema).array().optional(),
  not: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => NestedEnumCasePurposeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCaseTypeFilterSchema: z.ZodType<Prisma.NestedEnumCaseTypeFilter> = z.object({
  equals: z.lazy(() => CaseTypeSchema).optional(),
  in: z.lazy(() => CaseTypeSchema).array().optional(),
  notIn: z.lazy(() => CaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => NestedEnumCaseTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCaseStatusFilterSchema: z.ZodType<Prisma.NestedEnumCaseStatusFilter> = z.object({
  equals: z.lazy(() => CaseStatusSchema).optional(),
  in: z.lazy(() => CaseStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => NestedEnumCaseStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCasePriorityFilterSchema: z.ZodType<Prisma.NestedEnumCasePriorityFilter> = z.object({
  equals: z.lazy(() => CasePrioritySchema).optional(),
  in: z.lazy(() => CasePrioritySchema).array().optional(),
  notIn: z.lazy(() => CasePrioritySchema).array().optional(),
  not: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => NestedEnumCasePriorityFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCasePurposeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCasePurposeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CasePurposeSchema).optional(),
  in: z.lazy(() => CasePurposeSchema).array().optional(),
  notIn: z.lazy(() => CasePurposeSchema).array().optional(),
  not: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => NestedEnumCasePurposeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCasePurposeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCasePurposeFilterSchema).optional()
}).strict();

export const NestedEnumCaseTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCaseTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseTypeSchema).optional(),
  in: z.lazy(() => CaseTypeSchema).array().optional(),
  notIn: z.lazy(() => CaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => NestedEnumCaseTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseTypeFilterSchema).optional()
}).strict();

export const NestedEnumCaseStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCaseStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseStatusSchema).optional(),
  in: z.lazy(() => CaseStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => NestedEnumCaseStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseStatusFilterSchema).optional()
}).strict();

export const NestedEnumCasePriorityWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCasePriorityWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CasePrioritySchema).optional(),
  in: z.lazy(() => CasePrioritySchema).array().optional(),
  notIn: z.lazy(() => CasePrioritySchema).array().optional(),
  not: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => NestedEnumCasePriorityWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCasePriorityFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCasePriorityFilterSchema).optional()
}).strict();

export const NestedJsonNullableFilterSchema: z.ZodType<Prisma.NestedJsonNullableFilter> = z.object({
  equals: InputJsonValueSchema.optional(),
  path: z.string().array().optional(),
  string_contains: z.string().optional(),
  string_starts_with: z.string().optional(),
  string_ends_with: z.string().optional(),
  array_contains: InputJsonValueSchema.optional().nullable(),
  array_starts_with: InputJsonValueSchema.optional().nullable(),
  array_ends_with: InputJsonValueSchema.optional().nullable(),
  lt: InputJsonValueSchema.optional(),
  lte: InputJsonValueSchema.optional(),
  gt: InputJsonValueSchema.optional(),
  gte: InputJsonValueSchema.optional(),
  not: InputJsonValueSchema.optional()
}).strict();

export const NestedEnumUserRoleFilterSchema: z.ZodType<Prisma.NestedEnumUserRoleFilter> = z.object({
  equals: z.lazy(() => UserRoleSchema).optional(),
  in: z.lazy(() => UserRoleSchema).array().optional(),
  notIn: z.lazy(() => UserRoleSchema).array().optional(),
  not: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => NestedEnumUserRoleFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCaseMessageModelFilterSchema: z.ZodType<Prisma.NestedEnumCaseMessageModelFilter> = z.object({
  equals: z.lazy(() => CaseMessageModelSchema).optional(),
  in: z.lazy(() => CaseMessageModelSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageModelSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => NestedEnumCaseMessageModelFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCaseMessageStatusFilterSchema: z.ZodType<Prisma.NestedEnumCaseMessageStatusFilter> = z.object({
  equals: z.lazy(() => CaseMessageStatusSchema).optional(),
  in: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => NestedEnumCaseMessageStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumUserRoleWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumUserRoleWithAggregatesFilter> = z.object({
  equals: z.lazy(() => UserRoleSchema).optional(),
  in: z.lazy(() => UserRoleSchema).array().optional(),
  notIn: z.lazy(() => UserRoleSchema).array().optional(),
  not: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => NestedEnumUserRoleWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumUserRoleFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumUserRoleFilterSchema).optional()
}).strict();

export const NestedEnumCaseMessageModelWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCaseMessageModelWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseMessageModelSchema).optional(),
  in: z.lazy(() => CaseMessageModelSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageModelSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => NestedEnumCaseMessageModelWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseMessageModelFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseMessageModelFilterSchema).optional()
}).strict();

export const NestedEnumCaseMessageStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCaseMessageStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CaseMessageStatusSchema).optional(),
  in: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  notIn: z.lazy(() => CaseMessageStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => NestedEnumCaseMessageStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCaseMessageStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCaseMessageStatusFilterSchema).optional()
}).strict();

export const NestedEnumCompanyTypeFilterSchema: z.ZodType<Prisma.NestedEnumCompanyTypeFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional(),
  in: z.lazy(() => CompanyTypeSchema).array().optional(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumTaxTypeNullableFilterSchema: z.ZodType<Prisma.NestedEnumTaxTypeNullableFilter> = z.object({
  equals: z.lazy(() => TaxTypeSchema).optional().nullable(),
  in: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NestedEnumTaxTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumCompanyTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCompanyTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional(),
  in: z.lazy(() => CompanyTypeSchema).array().optional(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCompanyTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCompanyTypeFilterSchema).optional()
}).strict();

export const NestedEnumTaxTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumTaxTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => TaxTypeSchema).optional().nullable(),
  in: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => TaxTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NestedEnumTaxTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumTaxTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumTaxTypeNullableFilterSchema).optional()
}).strict();

export const NestedEnumLoanGuaranteeFilterSchema: z.ZodType<Prisma.NestedEnumLoanGuaranteeFilter> = z.object({
  equals: z.lazy(() => LoanGuaranteeSchema).optional(),
  in: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  notIn: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => NestedEnumLoanGuaranteeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumLoanTypeFilterSchema: z.ZodType<Prisma.NestedEnumLoanTypeFilter> = z.object({
  equals: z.lazy(() => LoanTypeSchema).optional(),
  in: z.lazy(() => LoanTypeSchema).array().optional(),
  notIn: z.lazy(() => LoanTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => NestedEnumLoanTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumLoanGuaranteeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumLoanGuaranteeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => LoanGuaranteeSchema).optional(),
  in: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  notIn: z.lazy(() => LoanGuaranteeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => NestedEnumLoanGuaranteeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumLoanGuaranteeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumLoanGuaranteeFilterSchema).optional()
}).strict();

export const NestedFloatWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const NestedEnumLoanTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumLoanTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => LoanTypeSchema).optional(),
  in: z.lazy(() => LoanTypeSchema).array().optional(),
  notIn: z.lazy(() => LoanTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => NestedEnumLoanTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumLoanTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumLoanTypeFilterSchema).optional()
}).strict();

export const NestedFloatNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatNullableFilterSchema).optional()
}).strict();

export const NestedEnumMaritalStatusNullableFilterSchema: z.ZodType<Prisma.NestedEnumMaritalStatusNullableFilter> = z.object({
  equals: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  in: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NestedEnumMaritalStatusNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumMarriageTypeNullableFilterSchema: z.ZodType<Prisma.NestedEnumMarriageTypeNullableFilter> = z.object({
  equals: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  in: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NestedEnumMarriageTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumJobTypeFilterSchema: z.ZodType<Prisma.NestedEnumJobTypeFilter> = z.object({
  equals: z.lazy(() => JobTypeSchema).optional(),
  in: z.lazy(() => JobTypeSchema).array().optional(),
  notIn: z.lazy(() => JobTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => NestedEnumJobTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumHousingStatusNullableFilterSchema: z.ZodType<Prisma.NestedEnumHousingStatusNullableFilter> = z.object({
  equals: z.lazy(() => HousingStatusSchema).optional().nullable(),
  in: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NestedEnumHousingStatusNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumMaritalStatusNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumMaritalStatusNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  in: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => MaritalStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NestedEnumMaritalStatusNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumMaritalStatusNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumMaritalStatusNullableFilterSchema).optional()
}).strict();

export const NestedEnumMarriageTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumMarriageTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  in: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => MarriageTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NestedEnumMarriageTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumMarriageTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumMarriageTypeNullableFilterSchema).optional()
}).strict();

export const NestedEnumJobTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumJobTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => JobTypeSchema).optional(),
  in: z.lazy(() => JobTypeSchema).array().optional(),
  notIn: z.lazy(() => JobTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => NestedEnumJobTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumJobTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumJobTypeFilterSchema).optional()
}).strict();

export const NestedEnumHousingStatusNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumHousingStatusNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => HousingStatusSchema).optional().nullable(),
  in: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  notIn: z.lazy(() => HousingStatusSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NestedEnumHousingStatusNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumHousingStatusNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumHousingStatusNullableFilterSchema).optional()
}).strict();

export const NestedEnumCustomerEmployeeTypeFilterSchema: z.ZodType<Prisma.NestedEnumCustomerEmployeeTypeFilter> = z.object({
  equals: z.lazy(() => CustomerEmployeeTypeSchema).optional(),
  in: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => NestedEnumCustomerEmployeeTypeFilterSchema) ]).optional(),
}).strict();

export const NestedBoolNullableFilterSchema: z.ZodType<Prisma.NestedBoolNullableFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumCustomerEmployeeTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCustomerEmployeeTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CustomerEmployeeTypeSchema).optional(),
  in: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerEmployeeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => NestedEnumCustomerEmployeeTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCustomerEmployeeTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCustomerEmployeeTypeFilterSchema).optional()
}).strict();

export const NestedBoolNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolNullableWithAggregatesFilter> = z.object({
  equals: z.boolean().optional().nullable(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolNullableFilterSchema).optional()
}).strict();

export const NestedBoolFilterSchema: z.ZodType<Prisma.NestedBoolFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolFilterSchema) ]).optional(),
}).strict();

export const NestedBoolWithAggregatesFilterSchema: z.ZodType<Prisma.NestedBoolWithAggregatesFilter> = z.object({
  equals: z.boolean().optional(),
  not: z.union([ z.boolean(),z.lazy(() => NestedBoolWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedBoolFilterSchema).optional(),
  _max: z.lazy(() => NestedBoolFilterSchema).optional()
}).strict();

export const NestedEnumCustomerAdditionalIncomeTypeFilterSchema: z.ZodType<Prisma.NestedEnumCustomerAdditionalIncomeTypeFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalIncomeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCustomerAdditionalIncomeTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCustomerAdditionalIncomeTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalIncomeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalIncomeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCustomerAdditionalIncomeTypeFilterSchema).optional()
}).strict();

export const NestedEnumCustomerAdditionalChargeTypeFilterSchema: z.ZodType<Prisma.NestedEnumCustomerAdditionalChargeTypeFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalChargeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalChargeTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCustomerAdditionalChargeTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCustomerAdditionalChargeTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CustomerAdditionalChargeTypeSchema).optional(),
  in: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  notIn: z.lazy(() => CustomerAdditionalChargeTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => NestedEnumCustomerAdditionalChargeTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCustomerAdditionalChargeTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCustomerAdditionalChargeTypeFilterSchema).optional()
}).strict();

export const NestedEnumNotificationModelFilterSchema: z.ZodType<Prisma.NestedEnumNotificationModelFilter> = z.object({
  equals: z.lazy(() => NotificationModelSchema).optional(),
  in: z.lazy(() => NotificationModelSchema).array().optional(),
  notIn: z.lazy(() => NotificationModelSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => NestedEnumNotificationModelFilterSchema) ]).optional(),
}).strict();

export const NestedEnumNotificationStatusFilterSchema: z.ZodType<Prisma.NestedEnumNotificationStatusFilter> = z.object({
  equals: z.lazy(() => NotificationStatusSchema).optional(),
  in: z.lazy(() => NotificationStatusSchema).array().optional(),
  notIn: z.lazy(() => NotificationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => NestedEnumNotificationStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumNotificationModelWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumNotificationModelWithAggregatesFilter> = z.object({
  equals: z.lazy(() => NotificationModelSchema).optional(),
  in: z.lazy(() => NotificationModelSchema).array().optional(),
  notIn: z.lazy(() => NotificationModelSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => NestedEnumNotificationModelWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumNotificationModelFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumNotificationModelFilterSchema).optional()
}).strict();

export const NestedEnumNotificationStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumNotificationStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => NotificationStatusSchema).optional(),
  in: z.lazy(() => NotificationStatusSchema).array().optional(),
  notIn: z.lazy(() => NotificationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => NestedEnumNotificationStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumNotificationStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumNotificationStatusFilterSchema).optional()
}).strict();

export const NestedEnumPartnerStatusFilterSchema: z.ZodType<Prisma.NestedEnumPartnerStatusFilter> = z.object({
  equals: z.lazy(() => PartnerStatusSchema).optional(),
  in: z.lazy(() => PartnerStatusSchema).array().optional(),
  notIn: z.lazy(() => PartnerStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => NestedEnumPartnerStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumPartnerProfessionFilterSchema: z.ZodType<Prisma.NestedEnumPartnerProfessionFilter> = z.object({
  equals: z.lazy(() => PartnerProfessionSchema).optional(),
  in: z.lazy(() => PartnerProfessionSchema).array().optional(),
  notIn: z.lazy(() => PartnerProfessionSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => NestedEnumPartnerProfessionFilterSchema) ]).optional(),
}).strict();

export const NestedEnumPartnerLoanVolumeFilterSchema: z.ZodType<Prisma.NestedEnumPartnerLoanVolumeFilter> = z.object({
  equals: z.lazy(() => PartnerLoanVolumeSchema).optional(),
  in: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  notIn: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => NestedEnumPartnerLoanVolumeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumPartnerCompanySizeFilterSchema: z.ZodType<Prisma.NestedEnumPartnerCompanySizeFilter> = z.object({
  equals: z.lazy(() => PartnerCompanySizeSchema).optional(),
  in: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  notIn: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => NestedEnumPartnerCompanySizeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumPartnerStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumPartnerStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerStatusSchema).optional(),
  in: z.lazy(() => PartnerStatusSchema).array().optional(),
  notIn: z.lazy(() => PartnerStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => NestedEnumPartnerStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerStatusFilterSchema).optional()
}).strict();

export const NestedEnumPartnerProfessionWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumPartnerProfessionWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerProfessionSchema).optional(),
  in: z.lazy(() => PartnerProfessionSchema).array().optional(),
  notIn: z.lazy(() => PartnerProfessionSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => NestedEnumPartnerProfessionWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerProfessionFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerProfessionFilterSchema).optional()
}).strict();

export const NestedEnumPartnerLoanVolumeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumPartnerLoanVolumeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerLoanVolumeSchema).optional(),
  in: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  notIn: z.lazy(() => PartnerLoanVolumeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => NestedEnumPartnerLoanVolumeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerLoanVolumeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerLoanVolumeFilterSchema).optional()
}).strict();

export const NestedEnumPartnerCompanySizeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumPartnerCompanySizeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PartnerCompanySizeSchema).optional(),
  in: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  notIn: z.lazy(() => PartnerCompanySizeSchema).array().optional(),
  not: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => NestedEnumPartnerCompanySizeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPartnerCompanySizeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPartnerCompanySizeFilterSchema).optional()
}).strict();

export const NestedEnumProspectPatrimonyFilterSchema: z.ZodType<Prisma.NestedEnumProspectPatrimonyFilter> = z.object({
  equals: z.lazy(() => ProspectPatrimonySchema).optional(),
  in: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  notIn: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => NestedEnumProspectPatrimonyFilterSchema) ]).optional(),
}).strict();

export const NestedEnumProspectProductFilterSchema: z.ZodType<Prisma.NestedEnumProspectProductFilter> = z.object({
  equals: z.lazy(() => ProspectProductSchema).optional(),
  in: z.lazy(() => ProspectProductSchema).array().optional(),
  notIn: z.lazy(() => ProspectProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => NestedEnumProspectProductFilterSchema) ]).optional(),
}).strict();

export const NestedEnumProspectAmountFilterSchema: z.ZodType<Prisma.NestedEnumProspectAmountFilter> = z.object({
  equals: z.lazy(() => ProspectAmountSchema).optional(),
  in: z.lazy(() => ProspectAmountSchema).array().optional(),
  notIn: z.lazy(() => ProspectAmountSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => NestedEnumProspectAmountFilterSchema) ]).optional(),
}).strict();

export const NestedEnumProspectUrgencyFilterSchema: z.ZodType<Prisma.NestedEnumProspectUrgencyFilter> = z.object({
  equals: z.lazy(() => ProspectUrgencySchema).optional(),
  in: z.lazy(() => ProspectUrgencySchema).array().optional(),
  notIn: z.lazy(() => ProspectUrgencySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => NestedEnumProspectUrgencyFilterSchema) ]).optional(),
}).strict();

export const NestedEnumProspectRatingFilterSchema: z.ZodType<Prisma.NestedEnumProspectRatingFilter> = z.object({
  equals: z.lazy(() => ProspectRatingSchema).optional(),
  in: z.lazy(() => ProspectRatingSchema).array().optional(),
  notIn: z.lazy(() => ProspectRatingSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => NestedEnumProspectRatingFilterSchema) ]).optional(),
}).strict();

export const NestedEnumProspectPatrimonyWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumProspectPatrimonyWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectPatrimonySchema).optional(),
  in: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  notIn: z.lazy(() => ProspectPatrimonySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => NestedEnumProspectPatrimonyWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectPatrimonyFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectPatrimonyFilterSchema).optional()
}).strict();

export const NestedEnumProspectProductWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumProspectProductWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectProductSchema).optional(),
  in: z.lazy(() => ProspectProductSchema).array().optional(),
  notIn: z.lazy(() => ProspectProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => NestedEnumProspectProductWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectProductFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectProductFilterSchema).optional()
}).strict();

export const NestedEnumProspectAmountWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumProspectAmountWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectAmountSchema).optional(),
  in: z.lazy(() => ProspectAmountSchema).array().optional(),
  notIn: z.lazy(() => ProspectAmountSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => NestedEnumProspectAmountWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectAmountFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectAmountFilterSchema).optional()
}).strict();

export const NestedEnumProspectUrgencyWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumProspectUrgencyWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectUrgencySchema).optional(),
  in: z.lazy(() => ProspectUrgencySchema).array().optional(),
  notIn: z.lazy(() => ProspectUrgencySchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => NestedEnumProspectUrgencyWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectUrgencyFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectUrgencyFilterSchema).optional()
}).strict();

export const NestedEnumProspectRatingWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumProspectRatingWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProspectRatingSchema).optional(),
  in: z.lazy(() => ProspectRatingSchema).array().optional(),
  notIn: z.lazy(() => ProspectRatingSchema).array().optional(),
  not: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => NestedEnumProspectRatingWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProspectRatingFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProspectRatingFilterSchema).optional()
}).strict();

export const NestedEnumPendingCaseTypeFilterSchema: z.ZodType<Prisma.NestedEnumPendingCaseTypeFilter> = z.object({
  equals: z.lazy(() => PendingCaseTypeSchema).optional(),
  in: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  notIn: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => NestedEnumPendingCaseTypeFilterSchema) ]).optional(),
}).strict();

export const NestedEnumCompanyTypeNullableFilterSchema: z.ZodType<Prisma.NestedEnumCompanyTypeNullableFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumPendingCaseTypeWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumPendingCaseTypeWithAggregatesFilter> = z.object({
  equals: z.lazy(() => PendingCaseTypeSchema).optional(),
  in: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  notIn: z.lazy(() => PendingCaseTypeSchema).array().optional(),
  not: z.union([ z.lazy(() => PendingCaseTypeSchema),z.lazy(() => NestedEnumPendingCaseTypeWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumPendingCaseTypeFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumPendingCaseTypeFilterSchema).optional()
}).strict();

export const NestedEnumCompanyTypeNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumCompanyTypeNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => CompanyTypeSchema).optional().nullable(),
  in: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  notIn: z.lazy(() => CompanyTypeSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => NestedEnumCompanyTypeNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumCompanyTypeNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumCompanyTypeNullableFilterSchema).optional()
}).strict();

export const NestedEnumSimulationStatusFilterSchema: z.ZodType<Prisma.NestedEnumSimulationStatusFilter> = z.object({
  equals: z.lazy(() => SimulationStatusSchema).optional(),
  in: z.lazy(() => SimulationStatusSchema).array().optional(),
  notIn: z.lazy(() => SimulationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => NestedEnumSimulationStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumRejectionMotiveNullableFilterSchema: z.ZodType<Prisma.NestedEnumRejectionMotiveNullableFilter> = z.object({
  equals: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  in: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  notIn: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NestedEnumRejectionMotiveNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumSimulationStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumSimulationStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SimulationStatusSchema).optional(),
  in: z.lazy(() => SimulationStatusSchema).array().optional(),
  notIn: z.lazy(() => SimulationStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => NestedEnumSimulationStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSimulationStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSimulationStatusFilterSchema).optional()
}).strict();

export const NestedEnumRejectionMotiveNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumRejectionMotiveNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  in: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  notIn: z.lazy(() => RejectionMotiveSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NestedEnumRejectionMotiveNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumRejectionMotiveNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumRejectionMotiveNullableFilterSchema).optional()
}).strict();

export const NestedEnumProductFilterSchema: z.ZodType<Prisma.NestedEnumProductFilter> = z.object({
  equals: z.lazy(() => ProductSchema).optional(),
  in: z.lazy(() => ProductSchema).array().optional(),
  notIn: z.lazy(() => ProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProductSchema),z.lazy(() => NestedEnumProductFilterSchema) ]).optional(),
}).strict();

export const NestedEnumSubmissionStatusFilterSchema: z.ZodType<Prisma.NestedEnumSubmissionStatusFilter> = z.object({
  equals: z.lazy(() => SubmissionStatusSchema).optional(),
  in: z.lazy(() => SubmissionStatusSchema).array().optional(),
  notIn: z.lazy(() => SubmissionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => NestedEnumSubmissionStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumProductWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumProductWithAggregatesFilter> = z.object({
  equals: z.lazy(() => ProductSchema).optional(),
  in: z.lazy(() => ProductSchema).array().optional(),
  notIn: z.lazy(() => ProductSchema).array().optional(),
  not: z.union([ z.lazy(() => ProductSchema),z.lazy(() => NestedEnumProductWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumProductFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumProductFilterSchema).optional()
}).strict();

export const NestedEnumSubmissionStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumSubmissionStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SubmissionStatusSchema).optional(),
  in: z.lazy(() => SubmissionStatusSchema).array().optional(),
  notIn: z.lazy(() => SubmissionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => NestedEnumSubmissionStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSubmissionStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSubmissionStatusFilterSchema).optional()
}).strict();

export const NestedEnumSuggestionFieldFilterSchema: z.ZodType<Prisma.NestedEnumSuggestionFieldFilter> = z.object({
  equals: z.lazy(() => SuggestionFieldSchema).optional(),
  in: z.lazy(() => SuggestionFieldSchema).array().optional(),
  notIn: z.lazy(() => SuggestionFieldSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => NestedEnumSuggestionFieldFilterSchema) ]).optional(),
}).strict();

export const NestedEnumSuggestionStatusFilterSchema: z.ZodType<Prisma.NestedEnumSuggestionStatusFilter> = z.object({
  equals: z.lazy(() => SuggestionStatusSchema).optional(),
  in: z.lazy(() => SuggestionStatusSchema).array().optional(),
  notIn: z.lazy(() => SuggestionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => NestedEnumSuggestionStatusFilterSchema) ]).optional(),
}).strict();

export const NestedEnumSuggestionFieldWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumSuggestionFieldWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SuggestionFieldSchema).optional(),
  in: z.lazy(() => SuggestionFieldSchema).array().optional(),
  notIn: z.lazy(() => SuggestionFieldSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => NestedEnumSuggestionFieldWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSuggestionFieldFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSuggestionFieldFilterSchema).optional()
}).strict();

export const NestedEnumSuggestionStatusWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumSuggestionStatusWithAggregatesFilter> = z.object({
  equals: z.lazy(() => SuggestionStatusSchema).optional(),
  in: z.lazy(() => SuggestionStatusSchema).array().optional(),
  notIn: z.lazy(() => SuggestionStatusSchema).array().optional(),
  not: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => NestedEnumSuggestionStatusWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumSuggestionStatusFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumSuggestionStatusFilterSchema).optional()
}).strict();

export const NestedEnumGenderNullableFilterSchema: z.ZodType<Prisma.NestedEnumGenderNullableFilter> = z.object({
  equals: z.lazy(() => GenderSchema).optional().nullable(),
  in: z.lazy(() => GenderSchema).array().optional().nullable(),
  notIn: z.lazy(() => GenderSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NestedEnumGenderNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedEnumGenderNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedEnumGenderNullableWithAggregatesFilter> = z.object({
  equals: z.lazy(() => GenderSchema).optional().nullable(),
  in: z.lazy(() => GenderSchema).array().optional().nullable(),
  notIn: z.lazy(() => GenderSchema).array().optional().nullable(),
  not: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NestedEnumGenderNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedEnumGenderNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedEnumGenderNullableFilterSchema).optional()
}).strict();

export const CaseCreateWithoutAssetsInputSchema: z.ZodType<Prisma.CaseCreateWithoutAssetsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutAssetsInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutAssetsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutAssetsInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutAssetsInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutAssetsInputSchema) ]),
}).strict();

export const AssetJointOwnerCreateWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int()
}).strict();

export const AssetJointOwnerUncheckedCreateWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int()
}).strict();

export const AssetJointOwnerCreateOrConnectWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerCreateOrConnectWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const AssetJointOwnerCreateManyAssetInputEnvelopeSchema: z.ZodType<Prisma.AssetJointOwnerCreateManyAssetInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AssetJointOwnerCreateManyAssetInputSchema),z.lazy(() => AssetJointOwnerCreateManyAssetInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const AssetShareholderCreateWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  type: z.lazy(() => AssetShareholderTypeSchema),
  sharePercentage: z.number().int()
}).strict();

export const AssetShareholderUncheckedCreateWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  type: z.lazy(() => AssetShareholderTypeSchema),
  sharePercentage: z.number().int()
}).strict();

export const AssetShareholderCreateOrConnectWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderCreateOrConnectWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetShareholderWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const AssetShareholderCreateManyAssetInputEnvelopeSchema: z.ZodType<Prisma.AssetShareholderCreateManyAssetInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AssetShareholderCreateManyAssetInputSchema),z.lazy(() => AssetShareholderCreateManyAssetInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerCreateWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerCreateWithoutAssetsInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutAssetsInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutAssetsInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutAssetsInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutRealEstateLoanChargesInputSchema)
}).strict();

export const CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema)
}).strict();

export const CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateOrConnectWithoutAssetInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyAssetInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateManyAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateManyAssetInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerRentIncomeCreateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  monthlyIncome: z.number().int(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCustomerRentIncomesInputSchema)
}).strict();

export const CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerRentIncomeCreateOrConnectWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateOrConnectWithoutAssetInput> = z.object({
  where: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const CompanyCreateWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyCreateWithoutAssetsInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutAssetsInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutAssetsInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutAssetsInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyRealEstateLoanChargeInputSchema)
}).strict();

export const CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  caseId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateOrConnectWithoutAssetInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeCreateManyAssetInputEnvelopeSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyAssetInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateManyAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateManyAssetInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyRentIncomeCreateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyRentIncomeInputSchema)
}).strict();

export const CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedCreateWithoutAssetInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRentIncomeCreateOrConnectWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateOrConnectWithoutAssetInput> = z.object({
  where: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const CaseUpsertWithoutAssetsInputSchema: z.ZodType<Prisma.CaseUpsertWithoutAssetsInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutAssetsInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutAssetsInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutAssetsInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutAssetsInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutAssetsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutAssetsInputSchema) ]),
}).strict();

export const CaseUpdateWithoutAssetsInputSchema: z.ZodType<Prisma.CaseUpdateWithoutAssetsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutAssetsInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutAssetsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const AssetJointOwnerUpsertWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUpsertWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AssetJointOwnerUpdateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedUpdateWithoutAssetInputSchema) ]),
  create: z.union([ z.lazy(() => AssetJointOwnerCreateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const AssetJointOwnerUpdateWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUpdateWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetJointOwnerWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AssetJointOwnerUpdateWithoutAssetInputSchema),z.lazy(() => AssetJointOwnerUncheckedUpdateWithoutAssetInputSchema) ]),
}).strict();

export const AssetJointOwnerUpdateManyWithWhereWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUpdateManyWithWhereWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetJointOwnerScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AssetJointOwnerUpdateManyMutationInputSchema),z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetInputSchema) ]),
}).strict();

export const AssetJointOwnerScalarWhereInputSchema: z.ZodType<Prisma.AssetJointOwnerScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssetJointOwnerScalarWhereInputSchema),z.lazy(() => AssetJointOwnerScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetJointOwnerScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetJointOwnerScalarWhereInputSchema),z.lazy(() => AssetJointOwnerScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const AssetShareholderUpsertWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUpsertWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetShareholderWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AssetShareholderUpdateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedUpdateWithoutAssetInputSchema) ]),
  create: z.union([ z.lazy(() => AssetShareholderCreateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const AssetShareholderUpdateWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUpdateWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetShareholderWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AssetShareholderUpdateWithoutAssetInputSchema),z.lazy(() => AssetShareholderUncheckedUpdateWithoutAssetInputSchema) ]),
}).strict();

export const AssetShareholderUpdateManyWithWhereWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUpdateManyWithWhereWithoutAssetInput> = z.object({
  where: z.lazy(() => AssetShareholderScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AssetShareholderUpdateManyMutationInputSchema),z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetInputSchema) ]),
}).strict();

export const AssetShareholderScalarWhereInputSchema: z.ZodType<Prisma.AssetShareholderScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssetShareholderScalarWhereInputSchema),z.lazy(() => AssetShareholderScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetShareholderScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetShareholderScalarWhereInputSchema),z.lazy(() => AssetShareholderScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumAssetShareholderTypeFilterSchema),z.lazy(() => AssetShareholderTypeSchema) ]).optional(),
  sharePercentage: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerUpsertWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutAssetsInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutAssetsInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutAssetsInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutAssetsInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutAssetsInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutAssetsInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutAssetsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutAssetsInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutAssetsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateWithoutAssetInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithoutAssetInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateWithoutAssetInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateManyMutationInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeScalarWhereInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeUpsertWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpsertWithoutAssetInput> = z.object({
  update: z.union([ z.lazy(() => CustomerRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutAssetInputSchema) ]),
  where: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional()
}).strict();

export const CustomerRentIncomeUpdateToOneWithWhereWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateToOneWithWhereWithoutAssetInput> = z.object({
  where: z.lazy(() => CustomerRentIncomeWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]),
}).strict();

export const CustomerRentIncomeUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutCustomerRentIncomesNestedInputSchema).optional()
}).strict();

export const CustomerRentIncomeUncheckedUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyUpsertWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutAssetsInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutAssetsInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutAssetsInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyUpdateToOneWithWhereWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutAssetsInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutAssetsInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutAssetsInputSchema) ]),
}).strict();

export const CompanyUpdateWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutAssetsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutAssetsInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutAssetsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateWithoutAssetInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutAssetInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutAssetInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithoutAssetInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateWithoutAssetInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutAssetInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateManyMutationInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeScalarWhereInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => EnumLoanGuaranteeFilterSchema),z.lazy(() => LoanGuaranteeSchema) ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  loanAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  type: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyRentIncomeUpsertWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpsertWithoutAssetInput> = z.object({
  update: z.union([ z.lazy(() => CompanyRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutAssetInputSchema) ]),
  where: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional()
}).strict();

export const CompanyRentIncomeUpdateToOneWithWhereWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateToOneWithWhereWithoutAssetInput> = z.object({
  where: z.lazy(() => CompanyRentIncomeWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyRentIncomeUpdateWithoutAssetInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateWithoutAssetInputSchema) ]),
}).strict();

export const CompanyRentIncomeUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutCompanyRentIncomeNestedInputSchema).optional()
}).strict();

export const CompanyRentIncomeUncheckedUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetCreateWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetCreateWithoutAssetJointOwnersInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutAssetJointOwnersInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutAssetJointOwnersInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetJointOwnersInputSchema) ]),
}).strict();

export const AssetUpsertWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetUpsertWithoutAssetJointOwnersInput> = z.object({
  update: z.union([ z.lazy(() => AssetUpdateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutAssetJointOwnersInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetJointOwnersInputSchema) ]),
  where: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetUpdateToOneWithWhereWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetUpdateToOneWithWhereWithoutAssetJointOwnersInput> = z.object({
  where: z.lazy(() => AssetWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AssetUpdateWithoutAssetJointOwnersInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutAssetJointOwnersInputSchema) ]),
}).strict();

export const AssetUpdateWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetUpdateWithoutAssetJointOwnersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutAssetJointOwnersInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutAssetJointOwnersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetCreateWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetCreateWithoutAssetShareholdersInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutAssetShareholdersInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutAssetShareholdersInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetShareholdersInputSchema) ]),
}).strict();

export const AssetUpsertWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetUpsertWithoutAssetShareholdersInput> = z.object({
  update: z.union([ z.lazy(() => AssetUpdateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutAssetShareholdersInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedCreateWithoutAssetShareholdersInputSchema) ]),
  where: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetUpdateToOneWithWhereWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetUpdateToOneWithWhereWithoutAssetShareholdersInput> = z.object({
  where: z.lazy(() => AssetWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AssetUpdateWithoutAssetShareholdersInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutAssetShareholdersInputSchema) ]),
}).strict();

export const AssetUpdateWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetUpdateWithoutAssetShareholdersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutAssetShareholdersInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutAssetShareholdersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateWithoutAccountsInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutAccountsInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutAccountsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutAccountsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]),
}).strict();

export const UserUpsertWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpsertWithoutAccountsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedCreateWithoutAccountsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutAccountsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutAccountsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutAccountsInputSchema) ]),
}).strict();

export const UserUpdateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUpdateWithoutAccountsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutAccountsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutAccountsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateWithoutSessionsInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSessionsInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSessionsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSessionsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]),
}).strict();

export const UserUpsertWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpsertWithoutSessionsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSessionsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSessionsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSessionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSessionsInputSchema) ]),
}).strict();

export const UserUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSessionsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSessionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const SubmissionCreateWithoutBankInputSchema: z.ZodType<Prisma.SubmissionCreateWithoutBankInput> = z.object({
  id: z.string().optional(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutSubmissionsInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutSubmissionsInputSchema)
}).strict();

export const SubmissionUncheckedCreateWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateWithoutBankInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  authorId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionCreateOrConnectWithoutBankInputSchema: z.ZodType<Prisma.SubmissionCreateOrConnectWithoutBankInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SubmissionCreateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema) ]),
}).strict();

export const SubmissionCreateManyBankInputEnvelopeSchema: z.ZodType<Prisma.SubmissionCreateManyBankInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SubmissionCreateManyBankInputSchema),z.lazy(() => SubmissionCreateManyBankInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SubmissionUpsertWithWhereUniqueWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUpsertWithWhereUniqueWithoutBankInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SubmissionUpdateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedUpdateWithoutBankInputSchema) ]),
  create: z.union([ z.lazy(() => SubmissionCreateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutBankInputSchema) ]),
}).strict();

export const SubmissionUpdateWithWhereUniqueWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUpdateWithWhereUniqueWithoutBankInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SubmissionUpdateWithoutBankInputSchema),z.lazy(() => SubmissionUncheckedUpdateWithoutBankInputSchema) ]),
}).strict();

export const SubmissionUpdateManyWithWhereWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUpdateManyWithWhereWithoutBankInput> = z.object({
  where: z.lazy(() => SubmissionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SubmissionUpdateManyMutationInputSchema),z.lazy(() => SubmissionUncheckedUpdateManyWithoutBankInputSchema) ]),
}).strict();

export const SubmissionScalarWhereInputSchema: z.ZodType<Prisma.SubmissionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SubmissionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SubmissionScalarWhereInputSchema),z.lazy(() => SubmissionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  bankId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  product: z.union([ z.lazy(() => EnumProductFilterSchema),z.lazy(() => ProductSchema) ]).optional(),
  reason: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  comment: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSubmissionStatusFilterSchema),z.lazy(() => SubmissionStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const UserCreateWithoutCasesInputSchema: z.ZodType<Prisma.UserCreateWithoutCasesInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCasesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCasesInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCasesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCasesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCasesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesInputSchema) ]),
}).strict();

export const CustomerCreateWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCasesAsMainCustomerInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCasesAsMainCustomerInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCasesAsMainCustomerInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsMainCustomerInputSchema) ]),
}).strict();

export const CustomerCreateWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCasesAsCoCustomerInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCasesAsCoCustomerInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCasesAsCoCustomerInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsCoCustomerInputSchema) ]),
}).strict();

export const CompanyCreateWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyCreateWithoutCaseAsCompanyInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutCaseAsCompanyInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutCaseAsCompanyInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCaseAsCompanyInputSchema) ]),
}).strict();

export const AssetCreateWithoutCaseInputSchema: z.ZodType<Prisma.AssetCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const AssetCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.AssetCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AssetCreateManyCaseInputSchema),z.lazy(() => AssetCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CaseTraceCreateWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCaseTracesInputSchema).optional()
}).strict();

export const CaseTraceUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  userId: z.string().optional().nullable(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseTraceCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseTraceWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CaseCollaboratorCreateWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  collaborator: z.lazy(() => UserCreateNestedOneWithoutCasesAsCollaboratorInputSchema)
}).strict();

export const CaseCollaboratorUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCollaboratorCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CaseCollaboratorCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseCollaboratorCreateManyCaseInputSchema),z.lazy(() => CaseCollaboratorCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SubmissionCreateWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  bank: z.lazy(() => BankCreateNestedOneWithoutSubmissionsInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutSubmissionsInputSchema)
}).strict();

export const SubmissionUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  authorId: z.string(),
  bankId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SubmissionCreateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SubmissionCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.SubmissionCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SubmissionCreateManyCaseInputSchema),z.lazy(() => SubmissionCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SynthesisCreateWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutSynthesisInputSchema)
}).strict();

export const SynthesisUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional(),
  userId: z.string()
}).strict();

export const SynthesisCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => SynthesisWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SynthesisCreateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SuggestionCreateWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  field: z.lazy(() => SuggestionFieldSchema),
  value: z.string(),
  status: z.lazy(() => SuggestionStatusSchema).optional(),
  source: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SuggestionUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  field: z.lazy(() => SuggestionFieldSchema),
  value: z.string(),
  status: z.lazy(() => SuggestionStatusSchema).optional(),
  source: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SuggestionCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => SuggestionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SuggestionCreateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SuggestionCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.SuggestionCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SuggestionCreateManyCaseInputSchema),z.lazy(() => SuggestionCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SimulationCreateWithoutCaseInputSchema: z.ZodType<Prisma.SimulationCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  author: z.lazy(() => UserCreateNestedOneWithoutSimulationInputSchema)
}).strict();

export const SimulationUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  authorId: z.string(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SimulationCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.SimulationCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => SimulationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SimulationCreateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SimulationCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.SimulationCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SimulationCreateManyCaseInputSchema),z.lazy(() => SimulationCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CaseMessageCreateWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  sender: z.lazy(() => UserCreateNestedOneWithoutSentCaseMessagesInputSchema)
}).strict();

export const CaseMessageUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  senderId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseMessageCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseMessageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CaseMessageCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.CaseMessageCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseMessageCreateManyCaseInputSchema),z.lazy(() => CaseMessageCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CasePartnerCreateWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCasesInputSchema),
  rate: z.lazy(() => PartnerRateCreateNestedOneWithoutCasePartnerInputSchema)
}).strict();

export const CasePartnerUncheckedCreateWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateWithoutCaseInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  rateId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerCreateOrConnectWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerCreateOrConnectWithoutCaseInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CasePartnerCreateManyCaseInputEnvelopeSchema: z.ZodType<Prisma.CasePartnerCreateManyCaseInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CasePartnerCreateManyCaseInputSchema),z.lazy(() => CasePartnerCreateManyCaseInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserUpsertWithoutCasesInputSchema: z.ZodType<Prisma.UserUpsertWithoutCasesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCasesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCasesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCasesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCasesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCasesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCasesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCasesInputSchema) ]),
}).strict();

export const UserUpdateWithoutCasesInputSchema: z.ZodType<Prisma.UserUpdateWithoutCasesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCasesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCasesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CustomerUpsertWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCasesAsMainCustomerInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCasesAsMainCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsMainCustomerInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCasesAsMainCustomerInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCasesAsMainCustomerInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCasesAsMainCustomerInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCasesAsMainCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCasesAsMainCustomerInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCasesAsMainCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUpsertWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCasesAsCoCustomerInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCasesAsCoCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCasesAsCoCustomerInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCasesAsCoCustomerInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCasesAsCoCustomerInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCasesAsCoCustomerInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCasesAsCoCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCasesAsCoCustomerInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCasesAsCoCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CompanyUpsertWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutCaseAsCompanyInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCaseAsCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCaseAsCompanyInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyUpdateToOneWithWhereWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutCaseAsCompanyInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutCaseAsCompanyInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCaseAsCompanyInputSchema) ]),
}).strict();

export const CompanyUpdateWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutCaseAsCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutCaseAsCompanyInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutCaseAsCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const AssetUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.AssetUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AssetUpdateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const AssetUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.AssetUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCaseInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const AssetUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.AssetUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => AssetScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AssetUpdateManyMutationInputSchema),z.lazy(() => AssetUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const AssetScalarWhereInputSchema: z.ZodType<Prisma.AssetScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AssetScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AssetScalarWhereInputSchema),z.lazy(() => AssetScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  caseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  surface: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  value: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  rentalValue: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  sellValue: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => EnumAssetTypeNullableFilterSchema),z.lazy(() => AssetTypeSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => EnumAssetUsageTypeFilterSchema),z.lazy(() => AssetUsageTypeSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => EnumAssetOwnershipTypeFilterSchema),z.lazy(() => AssetOwnershipTypeSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => EnumAssetCompanyTypeNullableFilterSchema),z.lazy(() => AssetCompanyTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseTraceUpsertWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceUpsertWithoutCaseInput> = z.object({
  update: z.union([ z.lazy(() => CaseTraceUpdateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutCaseInputSchema) ]),
  where: z.lazy(() => CaseTraceWhereInputSchema).optional()
}).strict();

export const CaseTraceUpdateToOneWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceUpdateToOneWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseTraceWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseTraceUpdateWithoutCaseInputSchema),z.lazy(() => CaseTraceUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const CaseTraceUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCaseTracesNestedInputSchema).optional()
}).strict();

export const CaseTraceUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseCollaboratorUpdateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CaseCollaboratorUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseCollaboratorUpdateWithoutCaseInputSchema),z.lazy(() => CaseCollaboratorUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const CaseCollaboratorUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseCollaboratorScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseCollaboratorUpdateManyMutationInputSchema),z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const CaseCollaboratorScalarWhereInputSchema: z.ZodType<Prisma.CaseCollaboratorScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseCollaboratorScalarWhereInputSchema),z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseCollaboratorScalarWhereInputSchema),z.lazy(() => CaseCollaboratorScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SubmissionUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SubmissionUpdateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => SubmissionCreateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SubmissionUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SubmissionUpdateWithoutCaseInputSchema),z.lazy(() => SubmissionUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const SubmissionUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => SubmissionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SubmissionUpdateManyMutationInputSchema),z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const SynthesisUpsertWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisUpsertWithoutCaseInput> = z.object({
  update: z.union([ z.lazy(() => SynthesisUpdateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => SynthesisCreateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutCaseInputSchema) ]),
  where: z.lazy(() => SynthesisWhereInputSchema).optional()
}).strict();

export const SynthesisUpdateToOneWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisUpdateToOneWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => SynthesisWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => SynthesisUpdateWithoutCaseInputSchema),z.lazy(() => SynthesisUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const SynthesisUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSynthesisNestedInputSchema).optional()
}).strict();

export const SynthesisUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => SuggestionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SuggestionUpdateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => SuggestionCreateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SuggestionUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => SuggestionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SuggestionUpdateWithoutCaseInputSchema),z.lazy(() => SuggestionUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const SuggestionUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => SuggestionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SuggestionUpdateManyMutationInputSchema),z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const SuggestionScalarWhereInputSchema: z.ZodType<Prisma.SuggestionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SuggestionScalarWhereInputSchema),z.lazy(() => SuggestionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SuggestionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SuggestionScalarWhereInputSchema),z.lazy(() => SuggestionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  field: z.union([ z.lazy(() => EnumSuggestionFieldFilterSchema),z.lazy(() => SuggestionFieldSchema) ]).optional(),
  value: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => EnumSuggestionStatusFilterSchema),z.lazy(() => SuggestionStatusSchema) ]).optional(),
  source: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const SimulationUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => SimulationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SimulationUpdateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => SimulationCreateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const SimulationUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => SimulationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SimulationUpdateWithoutCaseInputSchema),z.lazy(() => SimulationUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const SimulationUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => SimulationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SimulationUpdateManyMutationInputSchema),z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const SimulationScalarWhereInputSchema: z.ZodType<Prisma.SimulationScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SimulationScalarWhereInputSchema),z.lazy(() => SimulationScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SimulationScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SimulationScalarWhereInputSchema),z.lazy(() => SimulationScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  loanType: z.union([ z.lazy(() => EnumLoanTypeFilterSchema),z.lazy(() => LoanTypeSchema) ]).optional(),
  borrowedCapital: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  remainingMonths: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  expertFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  mandate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  bankFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  guaranteeFees: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  loansAmountTakenOver: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  status: z.union([ z.lazy(() => EnumSimulationStatusFilterSchema),z.lazy(() => SimulationStatusSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => EnumRejectionMotiveNullableFilterSchema),z.lazy(() => RejectionMotiveSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.lazy(() => DateTimeNullableFilterSchema),z.coerce.date() ]).optional().nullable(),
  contribution: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseMessageUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseMessageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseMessageUpdateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CaseMessageUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseMessageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseMessageUpdateWithoutCaseInputSchema),z.lazy(() => CaseMessageUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const CaseMessageUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => CaseMessageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseMessageUpdateManyMutationInputSchema),z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const CaseMessageScalarWhereInputSchema: z.ZodType<Prisma.CaseMessageScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseMessageScalarWhereInputSchema),z.lazy(() => CaseMessageScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseMessageScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseMessageScalarWhereInputSchema),z.lazy(() => CaseMessageScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderRole: z.union([ z.lazy(() => EnumUserRoleFilterSchema),z.lazy(() => UserRoleSchema) ]).optional(),
  model: z.union([ z.lazy(() => EnumCaseMessageModelFilterSchema),z.lazy(() => CaseMessageModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumCaseMessageStatusFilterSchema),z.lazy(() => CaseMessageStatusSchema) ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CasePartnerUpsertWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUpsertWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedUpdateWithoutCaseInputSchema) ]),
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutCaseInputSchema) ]),
}).strict();

export const CasePartnerUpdateWithWhereUniqueWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUpdateWithWhereUniqueWithoutCaseInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CasePartnerUpdateWithoutCaseInputSchema),z.lazy(() => CasePartnerUncheckedUpdateWithoutCaseInputSchema) ]),
}).strict();

export const CasePartnerUpdateManyWithWhereWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyWithWhereWithoutCaseInput> = z.object({
  where: z.lazy(() => CasePartnerScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CasePartnerUpdateManyMutationInputSchema),z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseInputSchema) ]),
}).strict();

export const CasePartnerScalarWhereInputSchema: z.ZodType<Prisma.CasePartnerScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CasePartnerScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CasePartnerScalarWhereInputSchema),z.lazy(() => CasePartnerScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rateId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseCreateWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseCreateWithoutCaseCollaboratorsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCaseCollaboratorsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCaseCollaboratorsInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseCollaboratorsInputSchema) ]),
}).strict();

export const UserCreateWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserCreateWithoutCasesAsCollaboratorInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCasesAsCollaboratorInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCasesAsCollaboratorInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesAsCollaboratorInputSchema) ]),
}).strict();

export const CaseUpsertWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseUpsertWithoutCaseCollaboratorsInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseCollaboratorsInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseCollaboratorsInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutCaseCollaboratorsInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCaseCollaboratorsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseCollaboratorsInputSchema) ]),
}).strict();

export const CaseUpdateWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCaseCollaboratorsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCaseCollaboratorsInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCaseCollaboratorsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserUpsertWithoutCasesAsCollaboratorInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCasesAsCollaboratorInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedCreateWithoutCasesAsCollaboratorInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCasesAsCollaboratorInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCasesAsCollaboratorInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCasesAsCollaboratorInputSchema) ]),
}).strict();

export const UserUpdateWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserUpdateWithoutCasesAsCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCasesAsCollaboratorInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCasesAsCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CaseCreateWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseCreateWithoutCaseTraceInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCaseTraceInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCaseTraceInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseTraceInputSchema) ]),
}).strict();

export const UserCreateWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserCreateWithoutCaseTracesInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCaseTracesInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCaseTracesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCaseTracesInputSchema) ]),
}).strict();

export const CaseUpsertWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseUpsertWithoutCaseTraceInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseTraceInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseTraceInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutCaseTraceInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCaseTraceInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseTraceInputSchema) ]),
}).strict();

export const CaseUpdateWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCaseTraceInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCaseTraceInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCaseTraceInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserUpsertWithoutCaseTracesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCaseTracesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCaseTracesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCaseTracesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCaseTracesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCaseTracesInputSchema) ]),
}).strict();

export const UserUpdateWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserUpdateWithoutCaseTracesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCaseTracesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCaseTracesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CaseCreateWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseCreateWithoutCaseMessagesInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCaseMessagesInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCaseMessagesInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseMessagesInputSchema) ]),
}).strict();

export const UserCreateWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserCreateWithoutSentCaseMessagesInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSentCaseMessagesInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSentCaseMessagesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentCaseMessagesInputSchema) ]),
}).strict();

export const CaseUpsertWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseUpsertWithoutCaseMessagesInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseMessagesInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCaseMessagesInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutCaseMessagesInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCaseMessagesInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCaseMessagesInputSchema) ]),
}).strict();

export const CaseUpdateWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCaseMessagesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCaseMessagesInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCaseMessagesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserUpsertWithoutSentCaseMessagesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSentCaseMessagesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentCaseMessagesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSentCaseMessagesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSentCaseMessagesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSentCaseMessagesInputSchema) ]),
}).strict();

export const UserUpdateWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserUpdateWithoutSentCaseMessagesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSentCaseMessagesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSentCaseMessagesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutCompaniesInputSchema: z.ZodType<Prisma.UserCreateWithoutCompaniesInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCompaniesInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCompaniesInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCompaniesInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCompaniesInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompaniesInputSchema) ]),
}).strict();

export const CaseCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CaseCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CaseCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.CaseCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseCreateManyCompanyInputSchema),z.lazy(() => CaseCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const AssetCreateWithoutCompanyInputSchema: z.ZodType<Prisma.AssetCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const AssetCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.AssetCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AssetCreateManyCompanyInputSchema),z.lazy(() => AssetCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCompanyRealEstateLoanChargesInputSchema)
}).strict();

export const CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  assetId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateManyCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyRentIncomeCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCompanyRentIncomeInputSchema).optional()
}).strict();

export const CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRentIncomeCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRentIncomeCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.CompanyRentIncomeCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CompanyRentIncomeCreateManyCompanyInputSchema),z.lazy(() => CompanyRentIncomeCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyPappersCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyFinancialDataCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  year: z.number().int(),
  exploitationResult: z.number().optional().nullable(),
  depreciationCharge: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  loanInterest: z.number().optional().nullable(),
  revenue: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  balanceSheetSize: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  accountValue: z.number().optional().nullable()
}).strict();

export const CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedCreateWithoutCompanyInput> = z.object({
  id: z.string().optional(),
  year: z.number().int(),
  exploitationResult: z.number().optional().nullable(),
  depreciationCharge: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  loanInterest: z.number().optional().nullable(),
  revenue: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  balanceSheetSize: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  accountValue: z.number().optional().nullable()
}).strict();

export const CompanyFinancialDataCreateOrConnectWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataCreateOrConnectWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyFinancialDataCreateManyCompanyInputEnvelopeSchema: z.ZodType<Prisma.CompanyFinancialDataCreateManyCompanyInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CompanyFinancialDataCreateManyCompanyInputSchema),z.lazy(() => CompanyFinancialDataCreateManyCompanyInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserUpsertWithoutCompaniesInputSchema: z.ZodType<Prisma.UserUpsertWithoutCompaniesInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCompaniesInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedCreateWithoutCompaniesInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCompaniesInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCompaniesInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCompaniesInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCompaniesInputSchema) ]),
}).strict();

export const UserUpdateWithoutCompaniesInputSchema: z.ZodType<Prisma.UserUpdateWithoutCompaniesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCompaniesInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCompaniesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CaseUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseUpdateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CaseUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCompanyInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict();

export const CaseUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => CaseScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateManyMutationInputSchema),z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict();

export const CaseScalarWhereInputSchema: z.ZodType<Prisma.CaseScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseScalarWhereInputSchema),z.lazy(() => CaseScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  referenceNo: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  coCustomerId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  companyId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  attio_record_id: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => EnumCasePurposeFilterSchema),z.lazy(() => CasePurposeSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  requestedAmount: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => EnumCaseTypeFilterSchema),z.lazy(() => CaseTypeSchema) ]).optional(),
  status: z.union([ z.lazy(() => EnumCaseStatusFilterSchema),z.lazy(() => CaseStatusSchema) ]).optional(),
  priority: z.union([ z.lazy(() => EnumCasePriorityFilterSchema),z.lazy(() => CasePrioritySchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const AssetUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AssetUpdateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const AssetUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCompanyInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict();

export const AssetUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => AssetScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AssetUpdateManyMutationInputSchema),z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyRealEstateLoanChargeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRealEstateLoanChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CompanyRealEstateLoanChargeUpdateManyMutationInputSchema),z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRentIncomeUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CompanyRentIncomeUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyRentIncomeCreateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRentIncomeUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRentIncomeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CompanyRentIncomeUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRentIncomeUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyRentIncomeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CompanyRentIncomeUpdateManyMutationInputSchema),z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyRentIncomeScalarWhereInputSchema: z.ZodType<Prisma.CompanyRentIncomeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyRentIncomeScalarWhereInputSchema),z.lazy(() => CompanyRentIncomeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyRentIncomeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyRentIncomeScalarWhereInputSchema),z.lazy(() => CompanyRentIncomeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyPappersUpsertWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutCompanyInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCompanyInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyFinancialDataUpsertWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpsertWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CompanyFinancialDataUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedUpdateWithoutCompanyInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyFinancialDataCreateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedCreateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyFinancialDataUpdateWithWhereUniqueWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateWithWhereUniqueWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyFinancialDataWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CompanyFinancialDataUpdateWithoutCompanyInputSchema),z.lazy(() => CompanyFinancialDataUncheckedUpdateWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyFinancialDataUpdateManyWithWhereWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateManyWithWhereWithoutCompanyInput> = z.object({
  where: z.lazy(() => CompanyFinancialDataScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CompanyFinancialDataUpdateManyMutationInputSchema),z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyInputSchema) ]),
}).strict();

export const CompanyFinancialDataScalarWhereInputSchema: z.ZodType<Prisma.CompanyFinancialDataScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyFinancialDataScalarWhereInputSchema),z.lazy(() => CompanyFinancialDataScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyFinancialDataScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyFinancialDataScalarWhereInputSchema),z.lazy(() => CompanyFinancialDataScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  exploitationResult: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  depreciationCharge: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  loanInterest: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  balanceSheetSize: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  accountValue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const CompanyCreateWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyCreateWithoutCompanyRealEstateLoanChargeInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutCompanyRealEstateLoanChargeInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutCompanyRealEstateLoanChargeInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRealEstateLoanChargeInputSchema) ]),
}).strict();

export const AssetCreateWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetCreateWithoutCompanyRealEstateLoanChargesInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCompanyRealEstateLoanChargesInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCompanyRealEstateLoanChargesInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRealEstateLoanChargesInputSchema) ]),
}).strict();

export const CompanyUpsertWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutCompanyRealEstateLoanChargeInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyRealEstateLoanChargeInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRealEstateLoanChargeInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyUpdateToOneWithWhereWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutCompanyRealEstateLoanChargeInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyRealEstateLoanChargeInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyRealEstateLoanChargeInputSchema) ]),
}).strict();

export const CompanyUpdateWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutCompanyRealEstateLoanChargeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutCompanyRealEstateLoanChargeInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutCompanyRealEstateLoanChargeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const AssetUpsertWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUpsertWithoutCompanyRealEstateLoanChargesInput> = z.object({
  update: z.union([ z.lazy(() => AssetUpdateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyRealEstateLoanChargesInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRealEstateLoanChargesInputSchema) ]),
  where: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetUpdateToOneWithWhereWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUpdateToOneWithWhereWithoutCompanyRealEstateLoanChargesInput> = z.object({
  where: z.lazy(() => AssetWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCompanyRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyRealEstateLoanChargesInputSchema) ]),
}).strict();

export const AssetUpdateWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCompanyRealEstateLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCompanyRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCompanyRealEstateLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetCreateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetCreateWithoutCompanyRentIncomeInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCompanyRentIncomeInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCompanyRentIncomeInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]),
}).strict();

export const CompanyCreateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyCreateWithoutCompanyRentIncomeInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutCompanyRentIncomeInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutCompanyRentIncomeInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]),
}).strict();

export const AssetUpsertWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetUpsertWithoutCompanyRentIncomeInput> = z.object({
  update: z.union([ z.lazy(() => AssetUpdateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyRentIncomeInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]),
  where: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetUpdateToOneWithWhereWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetUpdateToOneWithWhereWithoutCompanyRentIncomeInput> = z.object({
  where: z.lazy(() => AssetWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCompanyRentIncomeInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCompanyRentIncomeInputSchema) ]),
}).strict();

export const AssetUpdateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCompanyRentIncomeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCompanyRentIncomeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional()
}).strict();

export const CompanyUpsertWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutCompanyRentIncomeInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyRentIncomeInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyRentIncomeInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyUpdateToOneWithWhereWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutCompanyRentIncomeInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyRentIncomeInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyRentIncomeInputSchema) ]),
}).strict();

export const CompanyUpdateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutCompanyRentIncomeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutCompanyRentIncomeInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutCompanyRentIncomeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyCreateWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyCreateWithoutCompanyFinancialDatasInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutCompanyFinancialDatasInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutCompanyFinancialDatasInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyFinancialDatasInputSchema) ]),
}).strict();

export const CompanyUpsertWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutCompanyFinancialDatasInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyFinancialDatasInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyFinancialDatasInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyUpdateToOneWithWhereWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutCompanyFinancialDatasInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyFinancialDatasInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyFinancialDatasInputSchema) ]),
}).strict();

export const CompanyUpdateWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutCompanyFinancialDatasInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutCompanyFinancialDatasInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutCompanyFinancialDatasInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutCustomerInputSchema: z.ZodType<Prisma.UserCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CaseCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CaseCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CaseCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CaseCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseCreateManyCustomerInputSchema),z.lazy(() => CaseCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CaseCreateWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseCreateWithoutCoCustomerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCoCustomerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCoCustomerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema) ]),
}).strict();

export const CaseCreateManyCoCustomerInputEnvelopeSchema: z.ZodType<Prisma.CaseCreateManyCoCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseCreateManyCoCustomerInputSchema),z.lazy(() => CaseCreateManyCoCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerEmployeeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerEmployeeTypeSchema),
  isTrialing: z.boolean().optional().nullable(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerEmployeeTypeSchema),
  isTrialing: z.boolean().optional().nullable(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerEmployeeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerEmployeeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerEmployeeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRetiredCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyPensionIncome: z.number().int()
}).strict();

export const CustomerRetiredUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyPensionIncome: z.number().int()
}).strict();

export const CustomerRetiredCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRetiredWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerRetiredCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerSelfEmployedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  lastYearAverageMonthlyIncome: z.number().int(),
  hasEnoughAnnualReviews: z.boolean()
}).strict();

export const CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  lastYearAverageMonthlyIncome: z.number().int(),
  hasEnoughAnnualReviews: z.boolean()
}).strict();

export const CustomerSelfEmployedCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerSelfEmployedWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerSelfEmployedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerInvestmentCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  amount: z.number().int()
}).strict();

export const CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  amount: z.number().int()
}).strict();

export const CustomerInvestmentCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerInvestmentCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerInvestmentCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerInvestmentCreateManyCustomerInputSchema),z.lazy(() => CustomerInvestmentCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerAdditionalIncomeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerAdditionalIncomeTypeSchema),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerAdditionalIncomeTypeSchema),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalIncomeCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateManyCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerRentIncomeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyIncome: z.number().int(),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCustomerRentIncomeInputSchema).optional()
}).strict();

export const CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerRentIncomeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentIncomeCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerRentIncomeCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerRentIncomeCreateManyCustomerInputSchema),z.lazy(() => CustomerRentIncomeCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const AssetCreateWithoutCustomerInputSchema: z.ZodType<Prisma.AssetCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const AssetCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.AssetCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AssetCreateManyCustomerInputSchema),z.lazy(() => AssetCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  asset: z.lazy(() => AssetCreateNestedOneWithoutCustomerRealEstateLoanChargesInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  assetId: z.string().optional().nullable(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema)
}).strict();

export const CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateManyCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number()
}).strict();

export const CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number()
}).strict();

export const CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerConsumerLoanChargeCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateManyCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerRentChargeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int()
}).strict();

export const CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int()
}).strict();

export const CustomerRentChargeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentChargeCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerRentChargeCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerRentChargeCreateManyCustomerInputSchema),z.lazy(() => CustomerRentChargeCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerAdditionalChargeCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  type: z.lazy(() => CustomerAdditionalChargeTypeSchema)
}).strict();

export const CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedCreateWithoutCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  type: z.lazy(() => CustomerAdditionalChargeTypeSchema)
}).strict();

export const CustomerAdditionalChargeCreateOrConnectWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateOrConnectWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalChargeCreateManyCustomerInputEnvelopeSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateManyCustomerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CustomerAdditionalChargeCreateManyCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeCreateManyCustomerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserUpsertWithoutCustomerInputSchema: z.ZodType<Prisma.UserUpsertWithoutCustomerInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedCreateWithoutCustomerInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutCustomerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const UserUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.UserUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CaseUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseUpdateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CaseUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCustomerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CaseUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CaseScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateManyMutationInputSchema),z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CaseUpsertWithWhereUniqueWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUpsertWithWhereUniqueWithoutCoCustomerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseUpdateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCoCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCoCustomerInputSchema) ]),
}).strict();

export const CaseUpdateWithWhereUniqueWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUpdateWithWhereUniqueWithoutCoCustomerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCoCustomerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCoCustomerInputSchema) ]),
}).strict();

export const CaseUpdateManyWithWhereWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUpdateManyWithWhereWithoutCoCustomerInput> = z.object({
  where: z.lazy(() => CaseScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateManyMutationInputSchema),z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerInputSchema) ]),
}).strict();

export const CustomerEmployeeUpsertWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeUpsertWithoutCustomerInput> = z.object({
  update: z.union([ z.lazy(() => CustomerEmployeeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerEmployeeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedCreateWithoutCustomerInputSchema) ]),
  where: z.lazy(() => CustomerEmployeeWhereInputSchema).optional()
}).strict();

export const CustomerEmployeeUpdateToOneWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeUpdateToOneWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerEmployeeWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerEmployeeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerEmployeeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerEmployeeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  isTrialing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerEmployeeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerEmployeeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerEmployeeTypeSchema),z.lazy(() => EnumCustomerEmployeeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  isTrialing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredUpsertWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredUpsertWithoutCustomerInput> = z.object({
  update: z.union([ z.lazy(() => CustomerRetiredUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerRetiredCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedCreateWithoutCustomerInputSchema) ]),
  where: z.lazy(() => CustomerRetiredWhereInputSchema).optional()
}).strict();

export const CustomerRetiredUpdateToOneWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredUpdateToOneWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRetiredWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerRetiredUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRetiredUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRetiredUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyPensionIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRetiredUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRetiredUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyPensionIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedUpsertWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUpsertWithoutCustomerInput> = z.object({
  update: z.union([ z.lazy(() => CustomerSelfEmployedUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerSelfEmployedCreateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedCreateWithoutCustomerInputSchema) ]),
  where: z.lazy(() => CustomerSelfEmployedWhereInputSchema).optional()
}).strict();

export const CustomerSelfEmployedUpdateToOneWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateToOneWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerSelfEmployedWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerSelfEmployedUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerSelfEmployedUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerSelfEmployedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerSelfEmployedUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerSelfEmployedUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastYearAverageMonthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  hasEnoughAnnualReviews: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerInvestmentUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerInvestmentCreateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerInvestmentUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerInvestmentWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerInvestmentUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerInvestmentUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerInvestmentUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerInvestmentScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerInvestmentUpdateManyMutationInputSchema),z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerInvestmentScalarWhereInputSchema: z.ZodType<Prisma.CustomerInvestmentScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerInvestmentScalarWhereInputSchema),z.lazy(() => CustomerInvestmentScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerInvestmentScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerInvestmentScalarWhereInputSchema),z.lazy(() => CustomerInvestmentScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  amount: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerAdditionalIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalIncomeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalIncomeUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerAdditionalIncomeUpdateManyMutationInputSchema),z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalIncomeScalarWhereInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalIncomeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalIncomeTypeFilterSchema),z.lazy(() => CustomerAdditionalIncomeTypeSchema) ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerRentIncomeUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerRentIncomeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerRentIncomeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentIncomeUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentIncomeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerRentIncomeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentIncomeUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentIncomeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerRentIncomeUpdateManyMutationInputSchema),z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentIncomeScalarWhereInputSchema: z.ZodType<Prisma.CustomerRentIncomeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRentIncomeScalarWhereInputSchema),z.lazy(() => CustomerRentIncomeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentIncomeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentIncomeScalarWhereInputSchema),z.lazy(() => CustomerRentIncomeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  assetId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyIncome: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const AssetUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AssetUpdateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const AssetUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCustomerInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const AssetUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => AssetScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AssetUpdateManyMutationInputSchema),z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerRealEstateLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRealEstateLoanChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerRealEstateLoanChargeUpdateManyMutationInputSchema),z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerConsumerLoanChargeUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerConsumerLoanChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerConsumerLoanChargeUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerConsumerLoanChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerConsumerLoanChargeUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerConsumerLoanChargeUpdateManyMutationInputSchema),z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerConsumerLoanChargeScalarWhereInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema),z.lazy(() => CustomerConsumerLoanChargeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  endDate: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerRentChargeUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerRentChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerRentChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentChargeUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerRentChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerRentChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentChargeUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerRentChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerRentChargeUpdateManyMutationInputSchema),z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerRentChargeScalarWhereInputSchema: z.ZodType<Prisma.CustomerRentChargeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerRentChargeScalarWhereInputSchema),z.lazy(() => CustomerRentChargeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerRentChargeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerRentChargeScalarWhereInputSchema),z.lazy(() => CustomerRentChargeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const CustomerAdditionalChargeUpsertWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpsertWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerAdditionalChargeCreateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedCreateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalChargeUpdateWithWhereUniqueWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateWithWhereUniqueWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalChargeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateWithoutCustomerInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedUpdateWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalChargeUpdateManyWithWhereWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateManyWithWhereWithoutCustomerInput> = z.object({
  where: z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CustomerAdditionalChargeUpdateManyMutationInputSchema),z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerInputSchema) ]),
}).strict();

export const CustomerAdditionalChargeScalarWhereInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema),z.lazy(() => CustomerAdditionalChargeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  customerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  monthlyCharge: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  type: z.union([ z.lazy(() => EnumCustomerAdditionalChargeTypeFilterSchema),z.lazy(() => CustomerAdditionalChargeTypeSchema) ]).optional(),
}).strict();

export const CustomerCreateWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerEmployeeInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerEmployeeInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerEmployeeInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerEmployeeInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerEmployeeInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerEmployeeInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerEmployeeInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerEmployeeInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerEmployeeInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerEmployeeInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerEmployeeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerEmployeeInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerEmployeeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerRetiredInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerRetiredInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerRetiredInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRetiredInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerRetiredInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRetiredInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRetiredInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerRetiredInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerRetiredInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRetiredInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerRetiredInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerRetiredInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerRetiredInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerSelfEmployedInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerSelfEmployedInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerSelfEmployedInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerSelfEmployedInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerSelfEmployedInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerSelfEmployedInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerSelfEmployedInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerSelfEmployedInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerSelfEmployedInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerSelfEmployedInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerSelfEmployedInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerSelfEmployedInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerSelfEmployedInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerInvestmentsInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerInvestmentsInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerInvestmentsInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerInvestmentsInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerInvestmentsInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerInvestmentsInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerInvestmentsInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerInvestmentsInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerInvestmentsInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerInvestmentsInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerInvestmentsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerInvestmentsInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerInvestmentsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerAdditionalIncomesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerAdditionalIncomesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerAdditionalIncomesInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalIncomesInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerAdditionalIncomesInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerAdditionalIncomesInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalIncomesInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerAdditionalIncomesInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerAdditionalIncomesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerAdditionalIncomesInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerAdditionalIncomesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerAdditionalIncomesInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerAdditionalIncomesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const AssetCreateWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetCreateWithoutCustomerRentIncomeInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCustomerRentIncomeInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCustomerRentIncomeInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRentIncomeInputSchema) ]),
}).strict();

export const CustomerCreateWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerRentIncomesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerRentIncomesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerRentIncomesInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentIncomesInputSchema) ]),
}).strict();

export const AssetUpsertWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetUpsertWithoutCustomerRentIncomeInput> = z.object({
  update: z.union([ z.lazy(() => AssetUpdateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerRentIncomeInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRentIncomeInputSchema) ]),
  where: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetUpdateToOneWithWhereWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetUpdateToOneWithWhereWithoutCustomerRentIncomeInput> = z.object({
  where: z.lazy(() => AssetWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCustomerRentIncomeInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerRentIncomeInputSchema) ]),
}).strict();

export const AssetUpdateWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCustomerRentIncomeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCustomerRentIncomeInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCustomerRentIncomeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const CustomerUpsertWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerRentIncomesInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRentIncomesInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentIncomesInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerRentIncomesInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerRentIncomesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRentIncomesInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerRentIncomesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerRentIncomesInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerRentIncomesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerRentChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerRentChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerRentChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentChargesInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerRentChargesInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRentChargesInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerRentChargesInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerRentChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerRentChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerRentChargesInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerRentChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerRentChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerRentChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerConsumerLoanChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerConsumerLoanChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerConsumerLoanChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerConsumerLoanChargesInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerConsumerLoanChargesInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerConsumerLoanChargesInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerConsumerLoanChargesInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerConsumerLoanChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerConsumerLoanChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerConsumerLoanChargesInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerConsumerLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerConsumerLoanChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerConsumerLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const AssetCreateWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetCreateWithoutCustomerRealEstateLoanChargesInput> = z.object({
  id: z.string().optional(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutAssetsInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderCreateNestedManyWithoutAssetInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutAssetsInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeCreateNestedOneWithoutAssetInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutAssetsInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetUncheckedCreateWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUncheckedCreateWithoutCustomerRealEstateLoanChargesInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutAssetInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedOneWithoutAssetInputSchema).optional()
}).strict();

export const AssetCreateOrConnectWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetCreateOrConnectWithoutCustomerRealEstateLoanChargesInput> = z.object({
  where: z.lazy(() => AssetWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRealEstateLoanChargesInputSchema) ]),
}).strict();

export const CustomerCreateWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerCreateWithoutRealEstateLoanChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutRealEstateLoanChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutRealEstateLoanChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutRealEstateLoanChargesInputSchema) ]),
}).strict();

export const AssetUpsertWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUpsertWithoutCustomerRealEstateLoanChargesInput> = z.object({
  update: z.union([ z.lazy(() => AssetUpdateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerRealEstateLoanChargesInputSchema) ]),
  create: z.union([ z.lazy(() => AssetCreateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedCreateWithoutCustomerRealEstateLoanChargesInputSchema) ]),
  where: z.lazy(() => AssetWhereInputSchema).optional()
}).strict();

export const AssetUpdateToOneWithWhereWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUpdateToOneWithWhereWithoutCustomerRealEstateLoanChargesInput> = z.object({
  where: z.lazy(() => AssetWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => AssetUpdateWithoutCustomerRealEstateLoanChargesInputSchema),z.lazy(() => AssetUncheckedUpdateWithoutCustomerRealEstateLoanChargesInputSchema) ]),
}).strict();

export const AssetUpdateWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCustomerRealEstateLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCustomerRealEstateLoanChargesInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCustomerRealEstateLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const CustomerUpsertWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutRealEstateLoanChargesInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutRealEstateLoanChargesInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutRealEstateLoanChargesInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutRealEstateLoanChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutRealEstateLoanChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutRealEstateLoanChargesInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutRealEstateLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutRealEstateLoanChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutRealEstateLoanChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerCreateWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerCreateWithoutCustomerAdditionalChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutCustomerAdditionalChargesInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutCustomerAdditionalChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalChargesInputSchema) ]),
}).strict();

export const CustomerUpsertWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutCustomerAdditionalChargesInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerAdditionalChargesInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutCustomerAdditionalChargesInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutCustomerAdditionalChargesInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutCustomerAdditionalChargesInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutCustomerAdditionalChargesInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutCustomerAdditionalChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutCustomerAdditionalChargesInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutCustomerAdditionalChargesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserCreateWithoutReceivedNotificationsInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutReceivedNotificationsInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutReceivedNotificationsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedNotificationsInputSchema) ]),
}).strict();

export const UserCreateWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserCreateWithoutSentNotificationsInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSentNotificationsInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSentNotificationsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentNotificationsInputSchema) ]),
}).strict();

export const UserUpsertWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserUpsertWithoutReceivedNotificationsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedNotificationsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutReceivedNotificationsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutReceivedNotificationsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutReceivedNotificationsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutReceivedNotificationsInputSchema) ]),
}).strict();

export const UserUpdateWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserUpdateWithoutReceivedNotificationsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutReceivedNotificationsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutReceivedNotificationsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserUpsertWithoutSentNotificationsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSentNotificationsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSentNotificationsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSentNotificationsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSentNotificationsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSentNotificationsInputSchema) ]),
}).strict();

export const UserUpdateWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserUpdateWithoutSentNotificationsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSentNotificationsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSentNotificationsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CompanyCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCompaniesInputSchema),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  userId: z.string(),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PartnerCreateWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerCreateWithoutPartnerCompanyPappersInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerInputSchema),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutPartnerCompanyPappersInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerCreateOrConnectWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutPartnerCompanyPappersInput> = z.object({
  where: z.lazy(() => PartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCompanyPappersInputSchema) ]),
}).strict();

export const PappersRepresentativeCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  sanction: z.lazy(() => PappersSanctionCreateNestedManyWithoutPappersRepresentativeInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedCreateNestedManyWithoutPappersRepresentativeInputSchema).optional()
}).strict();

export const PappersRepresentativeCreateOrConnectWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateOrConnectWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersRepresentativeCreateManyCompanyPappersInputEnvelopeSchema: z.ZodType<Prisma.PappersRepresentativeCreateManyCompanyPappersInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersRepresentativeCreateManyCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeCreateManyCompanyPappersInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PappersBeneficiaryCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  sanction: z.lazy(() => PappersSanctionCreateNestedManyWithoutPappersBeneficiaryInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedCreateNestedManyWithoutPappersBeneficiaryInputSchema).optional()
}).strict();

export const PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateOrConnectWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersBeneficiaryCreateManyCompanyPappersInputEnvelopeSchema: z.ZodType<Prisma.PappersBeneficiaryCreateManyCompanyPappersInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersBeneficiaryCreateManyCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryCreateManyCompanyPappersInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  idcc: z.number().int().optional().nullable()
}).strict();

export const PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  idcc: z.number().int().optional().nullable()
}).strict();

export const PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateOrConnectWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveConventionCreateManyCompanyPappersInputEnvelopeSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateManyCompanyPappersInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersCollectiveConventionCreateManyCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionCreateManyCompanyPappersInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  type: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  type: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateOrConnectWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveProcedureCreateManyCompanyPappersInputEnvelopeSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateManyCompanyPappersInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersCollectiveProcedureCreateManyCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureCreateManyCompanyPappersInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PappersFinancialDataCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  year: z.number().int().optional().nullable(),
  revenue: z.number().optional().nullable(),
  operatingResults: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  charges: z.number().optional().nullable(),
  repaymentCapacity: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable()
}).strict();

export const PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedCreateWithoutCompanyPappersInput> = z.object({
  id: z.string().optional(),
  year: z.number().int().optional().nullable(),
  revenue: z.number().optional().nullable(),
  operatingResults: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  charges: z.number().optional().nullable(),
  repaymentCapacity: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable()
}).strict();

export const PappersFinancialDataCreateOrConnectWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataCreateOrConnectWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersFinancialDataCreateManyCompanyPappersInputEnvelopeSchema: z.ZodType<Prisma.PappersFinancialDataCreateManyCompanyPappersInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersFinancialDataCreateManyCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataCreateManyCompanyPappersInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyUpsertWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyUpsertWithoutCompanyPappersInput> = z.object({
  update: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutCompanyPappersInputSchema) ]),
  where: z.lazy(() => CompanyWhereInputSchema).optional()
}).strict();

export const CompanyUpdateToOneWithWhereWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyUpdateToOneWithWhereWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => CompanyWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutCompanyPappersInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const CompanyUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCompaniesNestedInputSchema).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const PartnerUpsertWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerUpsertWithoutPartnerCompanyPappersInput> = z.object({
  update: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCompanyPappersInputSchema) ]),
  where: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerUpdateToOneWithWhereWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerUpdateToOneWithWhereWithoutPartnerCompanyPappersInput> = z.object({
  where: z.lazy(() => PartnerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerCompanyPappersInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCompanyPappersInputSchema) ]),
}).strict();

export const PartnerUpdateWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerUpdateWithoutPartnerCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateWithoutPartnerCompanyPappersInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutPartnerCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeUpsertWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUpsertWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersRepresentativeUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersRepresentativeUpdateWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersRepresentativeUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersRepresentativeUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersRepresentativeUpdateManyWithWhereWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateManyWithWhereWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersRepresentativeScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersRepresentativeUpdateManyMutationInputSchema),z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersRepresentativeScalarWhereInputSchema: z.ZodType<Prisma.PappersRepresentativeScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersRepresentativeScalarWhereInputSchema),z.lazy(() => PappersRepresentativeScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersRepresentativeScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersRepresentativeScalarWhereInputSchema),z.lazy(() => PappersRepresentativeScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  age: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
}).strict();

export const PappersBeneficiaryUpsertWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpsertWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersBeneficiaryUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersBeneficiaryUpdateWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersBeneficiaryUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersBeneficiaryUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersBeneficiaryUpdateManyWithWhereWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateManyWithWhereWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersBeneficiaryScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersBeneficiaryUpdateManyMutationInputSchema),z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersBeneficiaryScalarWhereInputSchema: z.ZodType<Prisma.PappersBeneficiaryScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersBeneficiaryScalarWhereInputSchema),z.lazy(() => PappersBeneficiaryScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersBeneficiaryScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersBeneficiaryScalarWhereInputSchema),z.lazy(() => PappersBeneficiaryScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  sharesPercentage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  votePercentage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionUpsertWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpsertWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersCollectiveConventionUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => PappersCollectiveConventionCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveConventionUpdateWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveConventionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersCollectiveConventionUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveConventionUpdateManyWithWhereWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateManyWithWhereWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersCollectiveConventionUpdateManyMutationInputSchema),z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveConventionScalarWhereInputSchema: z.ZodType<Prisma.PappersCollectiveConventionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema),z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema),z.lazy(() => PappersCollectiveConventionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  idcc: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureUpsertWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpsertWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => PappersCollectiveProcedureCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveProcedureUpdateWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveProcedureWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveProcedureUpdateManyWithWhereWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateManyWithWhereWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersCollectiveProcedureUpdateManyMutationInputSchema),z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersCollectiveProcedureScalarWhereInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema),z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema),z.lazy(() => PappersCollectiveProcedureScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PappersFinancialDataUpsertWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUpsertWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersFinancialDataUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
  create: z.union([ z.lazy(() => PappersFinancialDataCreateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedCreateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersFinancialDataUpdateWithWhereUniqueWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUpdateWithWhereUniqueWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersFinancialDataWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersFinancialDataUpdateWithoutCompanyPappersInputSchema),z.lazy(() => PappersFinancialDataUncheckedUpdateWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersFinancialDataUpdateManyWithWhereWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUpdateManyWithWhereWithoutCompanyPappersInput> = z.object({
  where: z.lazy(() => PappersFinancialDataScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersFinancialDataUpdateManyMutationInputSchema),z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersInputSchema) ]),
}).strict();

export const PappersFinancialDataScalarWhereInputSchema: z.ZodType<Prisma.PappersFinancialDataScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersFinancialDataScalarWhereInputSchema),z.lazy(() => PappersFinancialDataScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersFinancialDataScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersFinancialDataScalarWhereInputSchema),z.lazy(() => PappersFinancialDataScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  companyPappersId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  year: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  revenue: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  operatingResults: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  liquidity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  charges: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  repaymentCapacity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  equity: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  netProfit: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
}).strict();

export const CompanyPappersCreateWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutRepresentativesInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutRepresentativesInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutRepresentativesInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutRepresentativesInputSchema) ]),
}).strict();

export const PappersSanctionCreateWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionCreateWithoutPappersRepresentativeInput> = z.object({
  id: z.string().optional(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable(),
  pappersBeneficiary: z.lazy(() => PappersBeneficiaryCreateNestedOneWithoutSanctionInputSchema).optional()
}).strict();

export const PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedCreateWithoutPappersRepresentativeInput> = z.object({
  id: z.string().optional(),
  pappersBeneficiaryId: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersSanctionCreateOrConnectWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionCreateOrConnectWithoutPappersRepresentativeInput> = z.object({
  where: z.lazy(() => PappersSanctionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema) ]),
}).strict();

export const PappersSanctionCreateManyPappersRepresentativeInputEnvelopeSchema: z.ZodType<Prisma.PappersSanctionCreateManyPappersRepresentativeInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersSanctionCreateManyPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionCreateManyPappersRepresentativeInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyPappersUpsertWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutRepresentativesInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutRepresentativesInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutRepresentativesInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutRepresentativesInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutRepresentativesInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutRepresentativesInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutRepresentativesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutRepresentativesInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutRepresentativesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const PappersSanctionUpsertWithWhereUniqueWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUpsertWithWhereUniqueWithoutPappersRepresentativeInput> = z.object({
  where: z.lazy(() => PappersSanctionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersSanctionUpdateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedUpdateWithoutPappersRepresentativeInputSchema) ]),
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersRepresentativeInputSchema) ]),
}).strict();

export const PappersSanctionUpdateWithWhereUniqueWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUpdateWithWhereUniqueWithoutPappersRepresentativeInput> = z.object({
  where: z.lazy(() => PappersSanctionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersSanctionUpdateWithoutPappersRepresentativeInputSchema),z.lazy(() => PappersSanctionUncheckedUpdateWithoutPappersRepresentativeInputSchema) ]),
}).strict();

export const PappersSanctionUpdateManyWithWhereWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUpdateManyWithWhereWithoutPappersRepresentativeInput> = z.object({
  where: z.lazy(() => PappersSanctionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersSanctionUpdateManyMutationInputSchema),z.lazy(() => PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeInputSchema) ]),
}).strict();

export const PappersSanctionScalarWhereInputSchema: z.ZodType<Prisma.PappersSanctionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PappersSanctionScalarWhereInputSchema),z.lazy(() => PappersSanctionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PappersSanctionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PappersSanctionScalarWhereInputSchema),z.lazy(() => PappersSanctionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  pappersRepresentativeId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  pappersBeneficiaryId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  autority: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  isOngoing: z.union([ z.lazy(() => BoolNullableFilterSchema),z.boolean() ]).optional().nullable(),
  startDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  endDate: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const CompanyPappersCreateWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutBeneficiariesInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutBeneficiariesInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutBeneficiariesInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutBeneficiariesInputSchema) ]),
}).strict();

export const PappersSanctionCreateWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionCreateWithoutPappersBeneficiaryInput> = z.object({
  id: z.string().optional(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable(),
  pappersRepresentative: z.lazy(() => PappersRepresentativeCreateNestedOneWithoutSanctionInputSchema).optional()
}).strict();

export const PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInput> = z.object({
  id: z.string().optional(),
  pappersRepresentativeId: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionCreateOrConnectWithoutPappersBeneficiaryInput> = z.object({
  where: z.lazy(() => PappersSanctionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema) ]),
}).strict();

export const PappersSanctionCreateManyPappersBeneficiaryInputEnvelopeSchema: z.ZodType<Prisma.PappersSanctionCreateManyPappersBeneficiaryInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PappersSanctionCreateManyPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionCreateManyPappersBeneficiaryInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyPappersUpsertWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutBeneficiariesInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutBeneficiariesInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutBeneficiariesInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutBeneficiariesInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutBeneficiariesInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutBeneficiariesInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutBeneficiariesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutBeneficiariesInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutBeneficiariesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const PappersSanctionUpsertWithWhereUniqueWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUpsertWithWhereUniqueWithoutPappersBeneficiaryInput> = z.object({
  where: z.lazy(() => PappersSanctionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PappersSanctionUpdateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedUpdateWithoutPappersBeneficiaryInputSchema) ]),
  create: z.union([ z.lazy(() => PappersSanctionCreateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedCreateWithoutPappersBeneficiaryInputSchema) ]),
}).strict();

export const PappersSanctionUpdateWithWhereUniqueWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUpdateWithWhereUniqueWithoutPappersBeneficiaryInput> = z.object({
  where: z.lazy(() => PappersSanctionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PappersSanctionUpdateWithoutPappersBeneficiaryInputSchema),z.lazy(() => PappersSanctionUncheckedUpdateWithoutPappersBeneficiaryInputSchema) ]),
}).strict();

export const PappersSanctionUpdateManyWithWhereWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUpdateManyWithWhereWithoutPappersBeneficiaryInput> = z.object({
  where: z.lazy(() => PappersSanctionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PappersSanctionUpdateManyMutationInputSchema),z.lazy(() => PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryInputSchema) ]),
}).strict();

export const CompanyPappersCreateWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutCollectiveConventionInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutCollectiveConventionInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutCollectiveConventionInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveConventionInputSchema) ]),
}).strict();

export const CompanyPappersUpsertWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutCollectiveConventionInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCollectiveConventionInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveConventionInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutCollectiveConventionInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutCollectiveConventionInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCollectiveConventionInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutCollectiveConventionInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutCollectiveConventionInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutCollectiveConventionInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersCreateWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutCollectiveProcedureInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutCollectiveProcedureInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutCollectiveProcedureInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveProcedureInputSchema) ]),
}).strict();

export const CompanyPappersUpsertWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutCollectiveProcedureInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCollectiveProcedureInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutCollectiveProcedureInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutCollectiveProcedureInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutCollectiveProcedureInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutCollectiveProcedureInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutCollectiveProcedureInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutCollectiveProcedureInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutCollectiveProcedureInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersCreateWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutFinancialDataInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutFinancialDataInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  partnerId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutFinancialDataInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutFinancialDataInputSchema) ]),
}).strict();

export const CompanyPappersUpsertWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutFinancialDataInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutFinancialDataInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutFinancialDataInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutFinancialDataInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutFinancialDataInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutFinancialDataInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutFinancialDataInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutPartnerCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutFinancialDataInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutFinancialDataInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  partnerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeCreateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateWithoutSanctionInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutRepresentativesInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedCreateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedCreateWithoutSanctionInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable()
}).strict();

export const PappersRepresentativeCreateOrConnectWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateOrConnectWithoutSanctionInput> = z.object({
  where: z.lazy(() => PappersRepresentativeWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutSanctionInputSchema) ]),
}).strict();

export const PappersBeneficiaryCreateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateWithoutSanctionInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutBeneficiariesInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedCreateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedCreateWithoutSanctionInput> = z.object({
  id: z.string().optional(),
  companyPappersId: z.string(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable()
}).strict();

export const PappersBeneficiaryCreateOrConnectWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateOrConnectWithoutSanctionInput> = z.object({
  where: z.lazy(() => PappersBeneficiaryWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutSanctionInputSchema) ]),
}).strict();

export const PappersRepresentativeUpsertWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeUpsertWithoutSanctionInput> = z.object({
  update: z.union([ z.lazy(() => PappersRepresentativeUpdateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedUpdateWithoutSanctionInputSchema) ]),
  create: z.union([ z.lazy(() => PappersRepresentativeCreateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedCreateWithoutSanctionInputSchema) ]),
  where: z.lazy(() => PappersRepresentativeWhereInputSchema).optional()
}).strict();

export const PappersRepresentativeUpdateToOneWithWhereWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateToOneWithWhereWithoutSanctionInput> = z.object({
  where: z.lazy(() => PappersRepresentativeWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PappersRepresentativeUpdateWithoutSanctionInputSchema),z.lazy(() => PappersRepresentativeUncheckedUpdateWithoutSanctionInputSchema) ]),
}).strict();

export const PappersRepresentativeUpdateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateWithoutSanctionInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutRepresentativesNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedUpdateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedUpdateWithoutSanctionInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersBeneficiaryUpsertWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpsertWithoutSanctionInput> = z.object({
  update: z.union([ z.lazy(() => PappersBeneficiaryUpdateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedUpdateWithoutSanctionInputSchema) ]),
  create: z.union([ z.lazy(() => PappersBeneficiaryCreateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedCreateWithoutSanctionInputSchema) ]),
  where: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional()
}).strict();

export const PappersBeneficiaryUpdateToOneWithWhereWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateToOneWithWhereWithoutSanctionInput> = z.object({
  where: z.lazy(() => PappersBeneficiaryWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PappersBeneficiaryUpdateWithoutSanctionInputSchema),z.lazy(() => PappersBeneficiaryUncheckedUpdateWithoutSanctionInputSchema) ]),
}).strict();

export const PappersBeneficiaryUpdateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateWithoutSanctionInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutBeneficiariesNestedInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedUpdateWithoutSanctionInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedUpdateWithoutSanctionInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyPappersId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const UserCreateWithoutPartnerInputSchema: z.ZodType<Prisma.UserCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutPartnerInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutPartnerInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutPartnerInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerCollaboratorCreateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  collaborator: z.lazy(() => UserCreateNestedOneWithoutPartnerCollaboratorsInputSchema)
}).strict();

export const PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerCollaboratorCreateOrConnectWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateOrConnectWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerCollaboratorCreateManyPartnerInputEnvelopeSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyPartnerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PartnerCollaboratorCreateManyPartnerInputSchema),z.lazy(() => PartnerCollaboratorCreateManyPartnerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PartnerRateCreateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  createdAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerRateCreatedByInputSchema),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutRateInputSchema).optional()
}).strict();

export const PartnerRateUncheckedCreateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUncheckedCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  createdBy: z.string(),
  createdAt: z.coerce.date().optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutRateInputSchema).optional()
}).strict();

export const PartnerRateCreateOrConnectWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateOrConnectWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerRateCreateManyPartnerInputEnvelopeSchema: z.ZodType<Prisma.PartnerRateCreateManyPartnerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PartnerRateCreateManyPartnerInputSchema),z.lazy(() => PartnerRateCreateManyPartnerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CasePartnerCreateWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  rate: z.lazy(() => PartnerRateCreateNestedOneWithoutCasePartnerInputSchema),
  case: z.lazy(() => CaseCreateNestedOneWithoutCasePartnerInputSchema)
}).strict();

export const CasePartnerUncheckedCreateWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  rateId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerCreateOrConnectWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerCreateOrConnectWithoutPartnerInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const CasePartnerCreateManyPartnerInputEnvelopeSchema: z.ZodType<Prisma.CasePartnerCreateManyPartnerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CasePartnerCreateManyPartnerInputSchema),z.lazy(() => CasePartnerCreateManyPartnerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyPappersCreateWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCompanyPappersInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedCreateWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  formatedSiret: z.string(),
  formatedSiren: z.string(),
  creationDate: z.string(),
  isActive: z.boolean(),
  legalStatus: z.string(),
  tvaNumber: z.string(),
  rcsNumber: z.string().optional().nullable(),
  capital: z.number().optional().nullable(),
  primaryActivity: z.string().optional().nullable(),
  nafCode: z.string(),
  nafCodeLabel: z.string(),
  activity: z.string().optional().nullable(),
  exerciseForm: z.string().optional().nullable(),
  workForce: z.string().optional().nullable(),
  workForceYear: z.number().int().optional().nullable(),
  financialYearEndDate: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedCreateNestedManyWithoutCompanyPappersInputSchema).optional()
}).strict();

export const CompanyPappersCreateOrConnectWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersCreateOrConnectWithoutPartnerInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerPendingCaseCreateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  patrimony: z.lazy(() => ProspectPatrimonySchema),
  product: z.lazy(() => ProspectProductSchema),
  amount: z.lazy(() => ProspectAmountSchema),
  urgency: z.lazy(() => ProspectUrgencySchema),
  rating: z.lazy(() => ProspectRatingSchema),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedCreateWithoutPartnerInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  patrimony: z.lazy(() => ProspectPatrimonySchema),
  product: z.lazy(() => ProspectProductSchema),
  amount: z.lazy(() => ProspectAmountSchema),
  urgency: z.lazy(() => ProspectUrgencySchema),
  rating: z.lazy(() => ProspectRatingSchema),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerPendingCaseCreateOrConnectWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseCreateOrConnectWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerPendingCaseCreateManyPartnerInputEnvelopeSchema: z.ZodType<Prisma.PartnerPendingCaseCreateManyPartnerInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PartnerPendingCaseCreateManyPartnerInputSchema),z.lazy(() => PartnerPendingCaseCreateManyPartnerInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const UserUpsertWithoutPartnerInputSchema: z.ZodType<Prisma.UserUpsertWithoutPartnerInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutPartnerInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutPartnerInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutPartnerInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerInputSchema) ]),
}).strict();

export const UserUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.UserUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const PartnerCollaboratorUpsertWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpsertWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedUpdateWithoutPartnerInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerCollaboratorUpdateWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithoutPartnerInputSchema),z.lazy(() => PartnerCollaboratorUncheckedUpdateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerCollaboratorUpdateManyWithWhereWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateManyWithWhereWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PartnerCollaboratorUpdateManyMutationInputSchema),z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerCollaboratorScalarWhereInputSchema: z.ZodType<Prisma.PartnerCollaboratorScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),z.lazy(() => PartnerCollaboratorScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  collaboratorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PartnerRateUpsertWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUpsertWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PartnerRateUpdateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutPartnerInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerRateUpdateWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUpdateWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PartnerRateUpdateWithoutPartnerInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerRateUpdateManyWithWhereWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUpdateManyWithWhereWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerRateScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PartnerRateUpdateManyMutationInputSchema),z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerRateScalarWhereInputSchema: z.ZodType<Prisma.PartnerRateScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerRateScalarWhereInputSchema),z.lazy(() => PartnerRateScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerRateScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerRateScalarWhereInputSchema),z.lazy(() => PartnerRateScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  rate: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdBy: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CasePartnerUpsertWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUpsertWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedUpdateWithoutPartnerInputSchema) ]),
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const CasePartnerUpdateWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUpdateWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CasePartnerUpdateWithoutPartnerInputSchema),z.lazy(() => CasePartnerUncheckedUpdateWithoutPartnerInputSchema) ]),
}).strict();

export const CasePartnerUpdateManyWithWhereWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyWithWhereWithoutPartnerInput> = z.object({
  where: z.lazy(() => CasePartnerScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CasePartnerUpdateManyMutationInputSchema),z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerInputSchema) ]),
}).strict();

export const CompanyPappersUpsertWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersUpsertWithoutPartnerInput> = z.object({
  update: z.union([ z.lazy(() => CompanyPappersUpdateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutPartnerInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyPappersCreateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedCreateWithoutPartnerInputSchema) ]),
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional()
}).strict();

export const CompanyPappersUpdateToOneWithWhereWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersUpdateToOneWithWhereWithoutPartnerInput> = z.object({
  where: z.lazy(() => CompanyPappersWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CompanyPappersUpdateWithoutPartnerInputSchema),z.lazy(() => CompanyPappersUncheckedUpdateWithoutPartnerInputSchema) ]),
}).strict();

export const CompanyPappersUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCompanyPappersNestedInputSchema).optional(),
  representatives: z.lazy(() => PappersRepresentativeUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const CompanyPappersUncheckedUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.CompanyPappersUncheckedUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  formatedSiret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  formatedSiren: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  creationDate: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  isActive: z.union([ z.boolean(),z.lazy(() => BoolFieldUpdateOperationsInputSchema) ]).optional(),
  legalStatus: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  tvaNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rcsNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  capital: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  primaryActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  nafCode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  nafCodeLabel: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  activity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  exerciseForm: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForce: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workForceYear: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  financialYearEndDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  representatives: z.lazy(() => PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  beneficiaries: z.lazy(() => PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveConvention: z.lazy(() => PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  collectiveProcedure: z.lazy(() => PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional(),
  financialData: z.lazy(() => PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersNestedInputSchema).optional()
}).strict();

export const PartnerPendingCaseUpsertWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpsertWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PartnerPendingCaseUpdateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedUpdateWithoutPartnerInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerPendingCaseCreateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedCreateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerPendingCaseUpdateWithWhereUniqueWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateWithWhereUniqueWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerPendingCaseWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PartnerPendingCaseUpdateWithoutPartnerInputSchema),z.lazy(() => PartnerPendingCaseUncheckedUpdateWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerPendingCaseUpdateManyWithWhereWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateManyWithWhereWithoutPartnerInput> = z.object({
  where: z.lazy(() => PartnerPendingCaseScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PartnerPendingCaseUpdateManyMutationInputSchema),z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerInputSchema) ]),
}).strict();

export const PartnerPendingCaseScalarWhereInputSchema: z.ZodType<Prisma.PartnerPendingCaseScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PartnerPendingCaseScalarWhereInputSchema),z.lazy(() => PartnerPendingCaseScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PartnerPendingCaseScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PartnerPendingCaseScalarWhereInputSchema),z.lazy(() => PartnerPendingCaseScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  partnerId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  lastname: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  phone: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  patrimony: z.union([ z.lazy(() => EnumProspectPatrimonyFilterSchema),z.lazy(() => ProspectPatrimonySchema) ]).optional(),
  product: z.union([ z.lazy(() => EnumProspectProductFilterSchema),z.lazy(() => ProspectProductSchema) ]).optional(),
  amount: z.union([ z.lazy(() => EnumProspectAmountFilterSchema),z.lazy(() => ProspectAmountSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => EnumProspectUrgencyFilterSchema),z.lazy(() => ProspectUrgencySchema) ]).optional(),
  rating: z.union([ z.lazy(() => EnumProspectRatingFilterSchema),z.lazy(() => ProspectRatingSchema) ]).optional(),
  description: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const PartnerCreateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerCreateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerInputSchema),
  partnerRates: z.lazy(() => PartnerRateCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerCreateOrConnectWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutPartnerCollaboratorsInput> = z.object({
  where: z.lazy(() => PartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]),
}).strict();

export const UserCreateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserCreateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutPartnerCollaboratorsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]),
}).strict();

export const PartnerUpsertWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerUpsertWithoutPartnerCollaboratorsInput> = z.object({
  update: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCollaboratorsInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]),
  where: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerUpdateToOneWithWhereWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerUpdateToOneWithWhereWithoutPartnerCollaboratorsInput> = z.object({
  where: z.lazy(() => PartnerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCollaboratorsInputSchema) ]),
}).strict();

export const PartnerUpdateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerUpdateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserUpsertWithoutPartnerCollaboratorsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerCollaboratorsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerCollaboratorsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutPartnerCollaboratorsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutPartnerCollaboratorsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerCollaboratorsInputSchema) ]),
}).strict();

export const UserUpdateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserUpdateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutPartnerCollaboratorsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutPartnerCollaboratorsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const PartnerCreateWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerCreateWithoutPartnerRatesInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerInputSchema),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutPartnerRatesInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerCreateOrConnectWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutPartnerRatesInput> = z.object({
  where: z.lazy(() => PartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerRatesInputSchema) ]),
}).strict();

export const UserCreateWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserCreateWithoutPartnerRateCreatedByInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutPartnerRateCreatedByInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutPartnerRateCreatedByInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerRateCreatedByInputSchema) ]),
}).strict();

export const CasePartnerCreateWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerCreateWithoutRateInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCasesInputSchema),
  case: z.lazy(() => CaseCreateNestedOneWithoutCasePartnerInputSchema)
}).strict();

export const CasePartnerUncheckedCreateWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUncheckedCreateWithoutRateInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerCreateOrConnectWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerCreateOrConnectWithoutRateInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema) ]),
}).strict();

export const CasePartnerCreateManyRateInputEnvelopeSchema: z.ZodType<Prisma.CasePartnerCreateManyRateInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CasePartnerCreateManyRateInputSchema),z.lazy(() => CasePartnerCreateManyRateInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PartnerUpsertWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerUpsertWithoutPartnerRatesInput> = z.object({
  update: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerRatesInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerRatesInputSchema) ]),
  where: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerUpdateToOneWithWhereWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerUpdateToOneWithWhereWithoutPartnerRatesInput> = z.object({
  where: z.lazy(() => PartnerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerRatesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerRatesInputSchema) ]),
}).strict();

export const PartnerUpdateWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerUpdateWithoutPartnerRatesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateWithoutPartnerRatesInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutPartnerRatesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserUpsertWithoutPartnerRateCreatedByInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerRateCreatedByInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedCreateWithoutPartnerRateCreatedByInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutPartnerRateCreatedByInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutPartnerRateCreatedByInputSchema),z.lazy(() => UserUncheckedUpdateWithoutPartnerRateCreatedByInputSchema) ]),
}).strict();

export const UserUpdateWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserUpdateWithoutPartnerRateCreatedByInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutPartnerRateCreatedByInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutPartnerRateCreatedByInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional()
}).strict();

export const CasePartnerUpsertWithWhereUniqueWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUpsertWithWhereUniqueWithoutRateInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CasePartnerUpdateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedUpdateWithoutRateInputSchema) ]),
  create: z.union([ z.lazy(() => CasePartnerCreateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedCreateWithoutRateInputSchema) ]),
}).strict();

export const CasePartnerUpdateWithWhereUniqueWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUpdateWithWhereUniqueWithoutRateInput> = z.object({
  where: z.lazy(() => CasePartnerWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CasePartnerUpdateWithoutRateInputSchema),z.lazy(() => CasePartnerUncheckedUpdateWithoutRateInputSchema) ]),
}).strict();

export const CasePartnerUpdateManyWithWhereWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUpdateManyWithWhereWithoutRateInput> = z.object({
  where: z.lazy(() => CasePartnerScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CasePartnerUpdateManyMutationInputSchema),z.lazy(() => CasePartnerUncheckedUpdateManyWithoutRateInputSchema) ]),
}).strict();

export const PartnerCreateWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerCreateWithoutPartnerCasesInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerInputSchema),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutPartnerCasesInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerCreateOrConnectWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutPartnerCasesInput> = z.object({
  where: z.lazy(() => PartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCasesInputSchema) ]),
}).strict();

export const PartnerRateCreateWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateWithoutCasePartnerInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  createdAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerRatesInputSchema),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerRateCreatedByInputSchema)
}).strict();

export const PartnerRateUncheckedCreateWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateUncheckedCreateWithoutCasePartnerInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  partnerId: z.string(),
  createdBy: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const PartnerRateCreateOrConnectWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateOrConnectWithoutCasePartnerInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutCasePartnerInputSchema) ]),
}).strict();

export const CaseCreateWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseCreateWithoutCasePartnerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutCasePartnerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCasePartnerInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCasePartnerInputSchema) ]),
}).strict();

export const PartnerUpsertWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerUpsertWithoutPartnerCasesInput> = z.object({
  update: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCasesInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerCasesInputSchema) ]),
  where: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerUpdateToOneWithWhereWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerUpdateToOneWithWhereWithoutPartnerCasesInput> = z.object({
  where: z.lazy(() => PartnerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerCasesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerCasesInputSchema) ]),
}).strict();

export const PartnerUpdateWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerUpdateWithoutPartnerCasesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateWithoutPartnerCasesInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutPartnerCasesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerRateUpsertWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateUpsertWithoutCasePartnerInput> = z.object({
  update: z.union([ z.lazy(() => PartnerRateUpdateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutCasePartnerInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutCasePartnerInputSchema) ]),
  where: z.lazy(() => PartnerRateWhereInputSchema).optional()
}).strict();

export const PartnerRateUpdateToOneWithWhereWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateUpdateToOneWithWhereWithoutCasePartnerInput> = z.object({
  where: z.lazy(() => PartnerRateWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerRateUpdateWithoutCasePartnerInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutCasePartnerInputSchema) ]),
}).strict();

export const PartnerRateUpdateWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateUpdateWithoutCasePartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerRatesNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerRateCreatedByNestedInputSchema).optional()
}).strict();

export const PartnerRateUncheckedUpdateWithoutCasePartnerInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateWithoutCasePartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdBy: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseUpsertWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseUpsertWithoutCasePartnerInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCasePartnerInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedCreateWithoutCasePartnerInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutCasePartnerInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutCasePartnerInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutCasePartnerInputSchema) ]),
}).strict();

export const CaseUpdateWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCasePartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCasePartnerInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCasePartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const PartnerCreateWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerCreateWithoutPartnerPendingCasesInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutPartnerInputSchema),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutPartnerPendingCasesInput> = z.object({
  id: z.string().optional(),
  userId: z.string(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerCreateOrConnectWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutPartnerPendingCasesInput> = z.object({
  where: z.lazy(() => PartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerPendingCasesInputSchema) ]),
}).strict();

export const PartnerUpsertWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerUpsertWithoutPartnerPendingCasesInput> = z.object({
  update: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerPendingCasesInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCreateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutPartnerPendingCasesInputSchema) ]),
  where: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerUpdateToOneWithWhereWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerUpdateToOneWithWhereWithoutPartnerPendingCasesInput> = z.object({
  where: z.lazy(() => PartnerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerUpdateWithoutPartnerPendingCasesInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutPartnerPendingCasesInputSchema) ]),
}).strict();

export const PartnerUpdateWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerUpdateWithoutPartnerPendingCasesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateWithoutPartnerPendingCasesInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutPartnerPendingCasesInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema).optional()
}).strict();

export const UserCreateWithoutSimulationInputSchema: z.ZodType<Prisma.UserCreateWithoutSimulationInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSimulationInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSimulationInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSimulationInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSimulationInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedCreateWithoutSimulationInputSchema) ]),
}).strict();

export const CaseCreateWithoutSimulationInputSchema: z.ZodType<Prisma.CaseCreateWithoutSimulationInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutSimulationInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutSimulationInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutSimulationInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutSimulationInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSimulationInputSchema) ]),
}).strict();

export const UserUpsertWithoutSimulationInputSchema: z.ZodType<Prisma.UserUpsertWithoutSimulationInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSimulationInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedCreateWithoutSimulationInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSimulationInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSimulationInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSimulationInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSimulationInputSchema) ]),
}).strict();

export const UserUpdateWithoutSimulationInputSchema: z.ZodType<Prisma.UserUpdateWithoutSimulationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSimulationInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSimulationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CaseUpsertWithoutSimulationInputSchema: z.ZodType<Prisma.CaseUpsertWithoutSimulationInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSimulationInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSimulationInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutSimulationInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutSimulationInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutSimulationInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSimulationInputSchema) ]),
}).strict();

export const CaseUpdateWithoutSimulationInputSchema: z.ZodType<Prisma.CaseUpdateWithoutSimulationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutSimulationInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutSimulationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const BankCreateWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankCreateWithoutSubmissionsInput> = z.object({
  id: z.string().optional(),
  name: z.string(),
  products: z.union([ z.lazy(() => BankCreateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.number().int(),
  assetTypes: z.union([ z.lazy(() => BankCreateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.lazy(() => BankLocalisationSchema),
  disabledAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const BankUncheckedCreateWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankUncheckedCreateWithoutSubmissionsInput> = z.object({
  id: z.string().optional(),
  name: z.string(),
  products: z.union([ z.lazy(() => BankCreateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.number().int(),
  assetTypes: z.union([ z.lazy(() => BankCreateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.lazy(() => BankLocalisationSchema),
  disabledAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const BankCreateOrConnectWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankCreateOrConnectWithoutSubmissionsInput> = z.object({
  where: z.lazy(() => BankWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => BankCreateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedCreateWithoutSubmissionsInputSchema) ]),
}).strict();

export const CaseCreateWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseCreateWithoutSubmissionsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutSubmissionsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutSubmissionsInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSubmissionsInputSchema) ]),
}).strict();

export const UserCreateWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserCreateWithoutSubmissionsInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSubmissionsInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSubmissionsInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSubmissionsInputSchema) ]),
}).strict();

export const BankUpsertWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankUpsertWithoutSubmissionsInput> = z.object({
  update: z.union([ z.lazy(() => BankUpdateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedUpdateWithoutSubmissionsInputSchema) ]),
  create: z.union([ z.lazy(() => BankCreateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedCreateWithoutSubmissionsInputSchema) ]),
  where: z.lazy(() => BankWhereInputSchema).optional()
}).strict();

export const BankUpdateToOneWithWhereWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankUpdateToOneWithWhereWithoutSubmissionsInput> = z.object({
  where: z.lazy(() => BankWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => BankUpdateWithoutSubmissionsInputSchema),z.lazy(() => BankUncheckedUpdateWithoutSubmissionsInputSchema) ]),
}).strict();

export const BankUpdateWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankUpdateWithoutSubmissionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  products: z.union([ z.lazy(() => BankUpdateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  assetTypes: z.union([ z.lazy(() => BankUpdateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => EnumBankLocalisationFieldUpdateOperationsInputSchema) ]).optional(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const BankUncheckedUpdateWithoutSubmissionsInputSchema: z.ZodType<Prisma.BankUncheckedUpdateWithoutSubmissionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  products: z.union([ z.lazy(() => BankUpdateproductsInputSchema),z.lazy(() => ProductSchema).array() ]).optional(),
  minDealAmount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  assetTypes: z.union([ z.lazy(() => BankUpdateassetTypesInputSchema),z.lazy(() => AssetTypeSchema).array() ]).optional(),
  acceptedLocalisation: z.union([ z.lazy(() => BankLocalisationSchema),z.lazy(() => EnumBankLocalisationFieldUpdateOperationsInputSchema) ]).optional(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseUpsertWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseUpsertWithoutSubmissionsInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSubmissionsInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSubmissionsInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutSubmissionsInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutSubmissionsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSubmissionsInputSchema) ]),
}).strict();

export const CaseUpdateWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseUpdateWithoutSubmissionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutSubmissionsInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutSubmissionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserUpsertWithoutSubmissionsInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSubmissionsInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedCreateWithoutSubmissionsInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSubmissionsInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSubmissionsInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSubmissionsInputSchema) ]),
}).strict();

export const UserUpdateWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserUpdateWithoutSubmissionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSubmissionsInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSubmissionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const CaseCreateWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseCreateWithoutSuggestionsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutSuggestionsInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutSuggestionsInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSuggestionsInputSchema) ]),
}).strict();

export const CaseUpsertWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseUpsertWithoutSuggestionsInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSuggestionsInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSuggestionsInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutSuggestionsInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutSuggestionsInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSuggestionsInputSchema) ]),
}).strict();

export const CaseUpdateWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseUpdateWithoutSuggestionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutSuggestionsInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutSuggestionsInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseCreateWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseCreateWithoutSynthesisInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  user: z.lazy(() => UserCreateNestedOneWithoutCasesInputSchema),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutSynthesisInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutSynthesisInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSynthesisInputSchema) ]),
}).strict();

export const UserCreateWithoutSynthesisInputSchema: z.ZodType<Prisma.UserCreateWithoutSynthesisInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionCreateNestedManyWithoutUserInputSchema).optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserUncheckedCreateWithoutSynthesisInputSchema: z.ZodType<Prisma.UserUncheckedCreateWithoutSynthesisInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  password: z.string().optional().nullable(),
  role: z.lazy(() => UserRoleSchema).optional(),
  phone: z.string().optional().nullable(),
  emailVerified: z.coerce.date().optional().nullable(),
  image: z.string().optional().nullable(),
  calendarLink: z.string().optional().nullable(),
  gender: z.lazy(() => GenderSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetTokenExpiration: z.coerce.date().optional().nullable(),
  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationTokenExpiration: z.coerce.date().optional().nullable(),
  passwordCreationToken: z.string().optional().nullable(),
  disabledAt: z.coerce.date().optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutAuthorInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutUserInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedCreateNestedManyWithoutReceiverInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutSenderInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedCreateNestedOneWithoutUserInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutCollaboratorInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutUserInputSchema).optional()
}).strict();

export const UserCreateOrConnectWithoutSynthesisInputSchema: z.ZodType<Prisma.UserCreateOrConnectWithoutSynthesisInput> = z.object({
  where: z.lazy(() => UserWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => UserCreateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedCreateWithoutSynthesisInputSchema) ]),
}).strict();

export const CaseUpsertWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseUpsertWithoutSynthesisInput> = z.object({
  update: z.union([ z.lazy(() => CaseUpdateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSynthesisInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedCreateWithoutSynthesisInputSchema) ]),
  where: z.lazy(() => CaseWhereInputSchema).optional()
}).strict();

export const CaseUpdateToOneWithWhereWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseUpdateToOneWithWhereWithoutSynthesisInput> = z.object({
  where: z.lazy(() => CaseWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CaseUpdateWithoutSynthesisInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutSynthesisInputSchema) ]),
}).strict();

export const CaseUpdateWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseUpdateWithoutSynthesisInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutSynthesisInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutSynthesisInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const UserUpsertWithoutSynthesisInputSchema: z.ZodType<Prisma.UserUpsertWithoutSynthesisInput> = z.object({
  update: z.union([ z.lazy(() => UserUpdateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSynthesisInputSchema) ]),
  create: z.union([ z.lazy(() => UserCreateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedCreateWithoutSynthesisInputSchema) ]),
  where: z.lazy(() => UserWhereInputSchema).optional()
}).strict();

export const UserUpdateToOneWithWhereWithoutSynthesisInputSchema: z.ZodType<Prisma.UserUpdateToOneWithWhereWithoutSynthesisInput> = z.object({
  where: z.lazy(() => UserWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => UserUpdateWithoutSynthesisInputSchema),z.lazy(() => UserUncheckedUpdateWithoutSynthesisInputSchema) ]),
}).strict();

export const UserUpdateWithoutSynthesisInputSchema: z.ZodType<Prisma.UserUpdateWithoutSynthesisInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUpdateManyWithoutUserNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const UserUncheckedUpdateWithoutSynthesisInputSchema: z.ZodType<Prisma.UserUncheckedUpdateWithoutSynthesisInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  password: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  role: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  calendarLink: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  gender: z.union([ z.lazy(() => GenderSchema),z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  passwordResetToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordResetTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerificationTokenExpiration: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  passwordCreationToken: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  disabledAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accounts: z.lazy(() => AccountUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sessions: z.lazy(() => SessionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  caseTraces: z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  companies: z.lazy(() => CompanyUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  cases: z.lazy(() => CaseUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  casesAsCollaborator: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserNestedInputSchema).optional(),
  sentNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  receivedNotifications: z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverNestedInputSchema).optional(),
  sentCaseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderNestedInputSchema).optional(),
  partner: z.lazy(() => PartnerUncheckedUpdateOneWithoutUserNestedInputSchema).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorNestedInputSchema).optional(),
  partnerRateCreatedBy: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserNestedInputSchema).optional()
}).strict();

export const AccountCreateWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const AccountCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.AccountCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const AccountCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.AccountCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => AccountCreateManyUserInputSchema),z.lazy(() => AccountCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SessionCreateWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const SessionCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.SessionCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SessionCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.SessionCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SessionCreateManyUserInputSchema),z.lazy(() => SessionCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CustomerCreateWithoutUserInputSchema: z.ZodType<Prisma.CustomerCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  casesAsMainCustomer: z.lazy(() => CaseCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.CustomerUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  age: z.number().int(),
  childrenSupportedCount: z.number().int().optional().nullable(),
  address: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  maritalStatus: z.lazy(() => MaritalStatusSchema).optional().nullable(),
  marriagetype: z.lazy(() => MarriageTypeSchema).optional().nullable(),
  jobType: z.lazy(() => JobTypeSchema),
  housingStatus: z.lazy(() => HousingStatusSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCoCustomerInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedCreateNestedOneWithoutCustomerInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedCreateNestedManyWithoutCustomerInputSchema).optional()
}).strict();

export const CustomerCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.CustomerCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => CustomerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CustomerCreateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CaseTraceCreateWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutCaseTraceInputSchema).optional()
}).strict();

export const CaseTraceUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  caseId: z.string().optional().nullable(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseTraceCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => CaseTraceWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CaseTraceCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.CaseTraceCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseTraceCreateManyUserInputSchema),z.lazy(() => CaseTraceCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CompanyCreateWithoutUserInputSchema: z.ZodType<Prisma.CompanyCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.CompanyUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedCreateNestedManyWithoutCompanyInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutCompanyInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedCreateNestedManyWithoutCompanyInputSchema).optional()
}).strict();

export const CompanyCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.CompanyCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CompanyCreateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CompanyCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.CompanyCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CompanyCreateManyUserInputSchema),z.lazy(() => CompanyCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CaseCreateWithoutUserInputSchema: z.ZodType<Prisma.CaseCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  customer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsMainCustomerInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerCreateNestedOneWithoutCasesAsCoCustomerInputSchema).optional(),
  company: z.lazy(() => CompanyCreateNestedOneWithoutCaseAsCompanyInputSchema).optional(),
  assets: z.lazy(() => AssetCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.CaseUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  assets: z.lazy(() => AssetUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedCreateNestedOneWithoutCaseInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedCreateNestedManyWithoutCaseInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutCaseInputSchema).optional()
}).strict();

export const CaseCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.CaseCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCreateWithoutUserInputSchema),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CaseCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.CaseCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseCreateManyUserInputSchema),z.lazy(() => CaseCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CaseCollaboratorCreateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateWithoutCollaboratorInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutCaseCollaboratorsInputSchema)
}).strict();

export const CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedCreateWithoutCollaboratorInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCollaboratorCreateOrConnectWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateOrConnectWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema) ]),
}).strict();

export const CaseCollaboratorCreateManyCollaboratorInputEnvelopeSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyCollaboratorInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseCollaboratorCreateManyCollaboratorInputSchema),z.lazy(() => CaseCollaboratorCreateManyCollaboratorInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SimulationCreateWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationCreateWithoutAuthorInput> = z.object({
  id: z.string().optional(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutSimulationInputSchema)
}).strict();

export const SimulationUncheckedCreateWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUncheckedCreateWithoutAuthorInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SimulationCreateOrConnectWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationCreateOrConnectWithoutAuthorInput> = z.object({
  where: z.lazy(() => SimulationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SimulationCreateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema) ]),
}).strict();

export const SimulationCreateManyAuthorInputEnvelopeSchema: z.ZodType<Prisma.SimulationCreateManyAuthorInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SimulationCreateManyAuthorInputSchema),z.lazy(() => SimulationCreateManyAuthorInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SubmissionCreateWithoutUserInputSchema: z.ZodType<Prisma.SubmissionCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  bank: z.lazy(() => BankCreateNestedOneWithoutSubmissionsInputSchema),
  case: z.lazy(() => CaseCreateNestedOneWithoutSubmissionsInputSchema)
}).strict();

export const SubmissionUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  bankId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.SubmissionCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SubmissionCreateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SubmissionCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.SubmissionCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SubmissionCreateManyUserInputSchema),z.lazy(() => SubmissionCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const SynthesisCreateWithoutUserInputSchema: z.ZodType<Prisma.SynthesisCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutSynthesisInputSchema)
}).strict();

export const SynthesisUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional()
}).strict();

export const SynthesisCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.SynthesisCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => SynthesisWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => SynthesisCreateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SynthesisCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.SynthesisCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => SynthesisCreateManyUserInputSchema),z.lazy(() => SynthesisCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const NotificationCreateWithoutSenderInputSchema: z.ZodType<Prisma.NotificationCreateWithoutSenderInput> = z.object({
  id: z.string().optional(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  receiver: z.lazy(() => UserCreateNestedOneWithoutReceivedNotificationsInputSchema)
}).strict();

export const NotificationUncheckedCreateWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUncheckedCreateWithoutSenderInput> = z.object({
  id: z.string().optional(),
  receiverId: z.string(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const NotificationCreateOrConnectWithoutSenderInputSchema: z.ZodType<Prisma.NotificationCreateOrConnectWithoutSenderInput> = z.object({
  where: z.lazy(() => NotificationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => NotificationCreateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema) ]),
}).strict();

export const NotificationCreateManySenderInputEnvelopeSchema: z.ZodType<Prisma.NotificationCreateManySenderInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => NotificationCreateManySenderInputSchema),z.lazy(() => NotificationCreateManySenderInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const NotificationCreateWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationCreateWithoutReceiverInput> = z.object({
  id: z.string().optional(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  sender: z.lazy(() => UserCreateNestedOneWithoutSentNotificationsInputSchema)
}).strict();

export const NotificationUncheckedCreateWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUncheckedCreateWithoutReceiverInput> = z.object({
  id: z.string().optional(),
  senderId: z.string(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const NotificationCreateOrConnectWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationCreateOrConnectWithoutReceiverInput> = z.object({
  where: z.lazy(() => NotificationWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => NotificationCreateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema) ]),
}).strict();

export const NotificationCreateManyReceiverInputEnvelopeSchema: z.ZodType<Prisma.NotificationCreateManyReceiverInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => NotificationCreateManyReceiverInputSchema),z.lazy(() => NotificationCreateManyReceiverInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const CaseMessageCreateWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageCreateWithoutSenderInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  case: z.lazy(() => CaseCreateNestedOneWithoutCaseMessagesInputSchema)
}).strict();

export const CaseMessageUncheckedCreateWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUncheckedCreateWithoutSenderInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseMessageCreateOrConnectWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageCreateOrConnectWithoutSenderInput> = z.object({
  where: z.lazy(() => CaseMessageWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema) ]),
}).strict();

export const CaseMessageCreateManySenderInputEnvelopeSchema: z.ZodType<Prisma.CaseMessageCreateManySenderInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => CaseMessageCreateManySenderInputSchema),z.lazy(() => CaseMessageCreateManySenderInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PartnerCreateWithoutUserInputSchema: z.ZodType<Prisma.PartnerCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  affiliateId: z.string(),
  companyName: z.string(),
  status: z.lazy(() => PartnerStatusSchema).optional(),
  profession: z.lazy(() => PartnerProfessionSchema),
  otherProfession: z.string().optional().nullable(),
  loanVolume: z.lazy(() => PartnerLoanVolumeSchema),
  clientTypes: z.union([ z.lazy(() => PartnerCreateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerCreateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.string(),
  companySize: z.lazy(() => PartnerCompanySizeSchema),
  orias: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutPartnerInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedCreateNestedOneWithoutPartnerInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedCreateNestedManyWithoutPartnerInputSchema).optional()
}).strict();

export const PartnerCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => PartnerWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCreateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const PartnerCollaboratorCreateWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateWithoutCollaboratorInput> = z.object({
  id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerCollaboratorsInputSchema)
}).strict();

export const PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedCreateWithoutCollaboratorInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerCollaboratorCreateOrConnectWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateOrConnectWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema) ]),
}).strict();

export const PartnerCollaboratorCreateManyCollaboratorInputEnvelopeSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyCollaboratorInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PartnerCollaboratorCreateManyCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorCreateManyCollaboratorInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const PartnerRateCreateWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  createdAt: z.coerce.date().optional(),
  partner: z.lazy(() => PartnerCreateNestedOneWithoutPartnerRatesInputSchema),
  casePartner: z.lazy(() => CasePartnerCreateNestedManyWithoutRateInputSchema).optional()
}).strict();

export const PartnerRateUncheckedCreateWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUncheckedCreateWithoutUserInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  partnerId: z.string(),
  createdAt: z.coerce.date().optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedCreateNestedManyWithoutRateInputSchema).optional()
}).strict();

export const PartnerRateCreateOrConnectWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateCreateOrConnectWithoutUserInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const PartnerRateCreateManyUserInputEnvelopeSchema: z.ZodType<Prisma.PartnerRateCreateManyUserInputEnvelope> = z.object({
  data: z.union([ z.lazy(() => PartnerRateCreateManyUserInputSchema),z.lazy(() => PartnerRateCreateManyUserInputSchema).array() ]),
  skipDuplicates: z.boolean().optional()
}).strict();

export const AccountUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.AccountUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => AccountUpdateWithoutUserInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => AccountCreateWithoutUserInputSchema),z.lazy(() => AccountUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const AccountUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => AccountWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateWithoutUserInputSchema),z.lazy(() => AccountUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const AccountUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => AccountScalarWhereInputSchema),
  data: z.union([ z.lazy(() => AccountUpdateManyMutationInputSchema),z.lazy(() => AccountUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const AccountScalarWhereInputSchema: z.ZodType<Prisma.AccountScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereInputSchema),z.lazy(() => AccountScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  provider: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  providerAccountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  refresh_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  access_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  expires_at: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  token_type: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  scope: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  id_token: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  session_state: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const SessionUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SessionUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SessionUpdateWithoutUserInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => SessionCreateWithoutUserInputSchema),z.lazy(() => SessionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SessionUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SessionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateWithoutUserInputSchema),z.lazy(() => SessionUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const SessionUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => SessionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SessionUpdateManyMutationInputSchema),z.lazy(() => SessionUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const SessionScalarWhereInputSchema: z.ZodType<Prisma.SessionScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SessionScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SessionScalarWhereInputSchema),z.lazy(() => SessionScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  sessionToken: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  expires: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CustomerUpsertWithoutUserInputSchema: z.ZodType<Prisma.CustomerUpsertWithoutUserInput> = z.object({
  update: z.union([ z.lazy(() => CustomerUpdateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => CustomerCreateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedCreateWithoutUserInputSchema) ]),
  where: z.lazy(() => CustomerWhereInputSchema).optional()
}).strict();

export const CustomerUpdateToOneWithWhereWithoutUserInputSchema: z.ZodType<Prisma.CustomerUpdateToOneWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => CustomerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => CustomerUpdateWithoutUserInputSchema),z.lazy(() => CustomerUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const CustomerUpdateWithoutUserInputSchema: z.ZodType<Prisma.CustomerUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CustomerUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.CustomerUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  age: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  childrenSupportedCount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  zipcode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  city: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  maritalStatus: z.union([ z.lazy(() => MaritalStatusSchema),z.lazy(() => NullableEnumMaritalStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  marriagetype: z.union([ z.lazy(() => MarriageTypeSchema),z.lazy(() => NullableEnumMarriageTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  jobType: z.union([ z.lazy(() => JobTypeSchema),z.lazy(() => EnumJobTypeFieldUpdateOperationsInputSchema) ]).optional(),
  housingStatus: z.union([ z.lazy(() => HousingStatusSchema),z.lazy(() => NullableEnumHousingStatusFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  casesAsMainCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  casesAsCoCustomer: z.lazy(() => CaseUncheckedUpdateManyWithoutCoCustomerNestedInputSchema).optional(),
  customerEmployee: z.lazy(() => CustomerEmployeeUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerRetired: z.lazy(() => CustomerRetiredUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerSelfEmployed: z.lazy(() => CustomerSelfEmployedUncheckedUpdateOneWithoutCustomerNestedInputSchema).optional(),
  customerInvestments: z.lazy(() => CustomerInvestmentUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalIncomes: z.lazy(() => CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentIncomes: z.lazy(() => CustomerRentIncomeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  realEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerConsumerLoanCharges: z.lazy(() => CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerRentCharges: z.lazy(() => CustomerRentChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional(),
  customerAdditionalCharges: z.lazy(() => CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerNestedInputSchema).optional()
}).strict();

export const CaseTraceUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => CaseTraceWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseTraceUpdateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => CaseTraceCreateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CaseTraceUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => CaseTraceWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseTraceUpdateWithoutUserInputSchema),z.lazy(() => CaseTraceUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const CaseTraceUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => CaseTraceScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseTraceUpdateManyMutationInputSchema),z.lazy(() => CaseTraceUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const CaseTraceScalarWhereInputSchema: z.ZodType<Prisma.CaseTraceScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CaseTraceScalarWhereInputSchema),z.lazy(() => CaseTraceScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CaseTraceScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CaseTraceScalarWhereInputSchema),z.lazy(() => CaseTraceScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  userId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  affiliateId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  answersInput: z.lazy(() => JsonFilterSchema).optional(),
  contactInput: z.lazy(() => JsonNullableFilterSchema).optional(),
  dbCreateOutput: z.lazy(() => JsonNullableFilterSchema).optional(),
  dbCreateError: z.lazy(() => JsonNullableFilterSchema).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CompanyUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.CompanyUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CompanyUpdateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => CompanyCreateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CompanyUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.CompanyUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => CompanyWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CompanyUpdateWithoutUserInputSchema),z.lazy(() => CompanyUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const CompanyUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.CompanyUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => CompanyScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CompanyUpdateManyMutationInputSchema),z.lazy(() => CompanyUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const CompanyScalarWhereInputSchema: z.ZodType<Prisma.CompanyScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CompanyScalarWhereInputSchema),z.lazy(() => CompanyScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CompanyScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CompanyScalarWhereInputSchema),z.lazy(() => CompanyScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  type: z.union([ z.lazy(() => EnumCompanyTypeFilterSchema),z.lazy(() => CompanyTypeSchema) ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  siret: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  address: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  zipcode: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  city: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  inseeCode: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  country: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  taxType: z.union([ z.lazy(() => EnumTaxTypeNullableFilterSchema),z.lazy(() => TaxTypeSchema) ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const CaseUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.CaseUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseUpdateWithoutUserInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCreateWithoutUserInputSchema),z.lazy(() => CaseUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const CaseUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.CaseUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => CaseWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateWithoutUserInputSchema),z.lazy(() => CaseUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const CaseUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.CaseUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => CaseScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseUpdateManyMutationInputSchema),z.lazy(() => CaseUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const CaseCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseCollaboratorUpdateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedUpdateWithoutCollaboratorInputSchema) ]),
  create: z.union([ z.lazy(() => CaseCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedCreateWithoutCollaboratorInputSchema) ]),
}).strict();

export const CaseCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CaseCollaboratorWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseCollaboratorUpdateWithoutCollaboratorInputSchema),z.lazy(() => CaseCollaboratorUncheckedUpdateWithoutCollaboratorInputSchema) ]),
}).strict();

export const CaseCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateManyWithWhereWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => CaseCollaboratorScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseCollaboratorUpdateManyMutationInputSchema),z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorInputSchema) ]),
}).strict();

export const SimulationUpsertWithWhereUniqueWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUpsertWithWhereUniqueWithoutAuthorInput> = z.object({
  where: z.lazy(() => SimulationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SimulationUpdateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedUpdateWithoutAuthorInputSchema) ]),
  create: z.union([ z.lazy(() => SimulationCreateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedCreateWithoutAuthorInputSchema) ]),
}).strict();

export const SimulationUpdateWithWhereUniqueWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUpdateWithWhereUniqueWithoutAuthorInput> = z.object({
  where: z.lazy(() => SimulationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SimulationUpdateWithoutAuthorInputSchema),z.lazy(() => SimulationUncheckedUpdateWithoutAuthorInputSchema) ]),
}).strict();

export const SimulationUpdateManyWithWhereWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUpdateManyWithWhereWithoutAuthorInput> = z.object({
  where: z.lazy(() => SimulationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SimulationUpdateManyMutationInputSchema),z.lazy(() => SimulationUncheckedUpdateManyWithoutAuthorInputSchema) ]),
}).strict();

export const SubmissionUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SubmissionUpdateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => SubmissionCreateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SubmissionUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SubmissionWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SubmissionUpdateWithoutUserInputSchema),z.lazy(() => SubmissionUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const SubmissionUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => SubmissionScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SubmissionUpdateManyMutationInputSchema),z.lazy(() => SubmissionUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const SynthesisUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SynthesisWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => SynthesisUpdateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => SynthesisCreateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const SynthesisUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => SynthesisWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => SynthesisUpdateWithoutUserInputSchema),z.lazy(() => SynthesisUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const SynthesisUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => SynthesisScalarWhereInputSchema),
  data: z.union([ z.lazy(() => SynthesisUpdateManyMutationInputSchema),z.lazy(() => SynthesisUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const SynthesisScalarWhereInputSchema: z.ZodType<Prisma.SynthesisScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => SynthesisScalarWhereInputSchema),z.lazy(() => SynthesisScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => SynthesisScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => SynthesisScalarWhereInputSchema),z.lazy(() => SynthesisScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  caseId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  content: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  userId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const NotificationUpsertWithWhereUniqueWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUpsertWithWhereUniqueWithoutSenderInput> = z.object({
  where: z.lazy(() => NotificationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => NotificationUpdateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedUpdateWithoutSenderInputSchema) ]),
  create: z.union([ z.lazy(() => NotificationCreateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutSenderInputSchema) ]),
}).strict();

export const NotificationUpdateWithWhereUniqueWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUpdateWithWhereUniqueWithoutSenderInput> = z.object({
  where: z.lazy(() => NotificationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => NotificationUpdateWithoutSenderInputSchema),z.lazy(() => NotificationUncheckedUpdateWithoutSenderInputSchema) ]),
}).strict();

export const NotificationUpdateManyWithWhereWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUpdateManyWithWhereWithoutSenderInput> = z.object({
  where: z.lazy(() => NotificationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => NotificationUpdateManyMutationInputSchema),z.lazy(() => NotificationUncheckedUpdateManyWithoutSenderInputSchema) ]),
}).strict();

export const NotificationScalarWhereInputSchema: z.ZodType<Prisma.NotificationScalarWhereInput> = z.object({
  AND: z.union([ z.lazy(() => NotificationScalarWhereInputSchema),z.lazy(() => NotificationScalarWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => NotificationScalarWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => NotificationScalarWhereInputSchema),z.lazy(() => NotificationScalarWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  receiverId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  senderId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  model: z.union([ z.lazy(() => EnumNotificationModelFilterSchema),z.lazy(() => NotificationModelSchema) ]).optional(),
  modelData: z.lazy(() => JsonFilterSchema).optional(),
  status: z.union([ z.lazy(() => EnumNotificationStatusFilterSchema),z.lazy(() => NotificationStatusSchema) ]).optional(),
  createdAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
  updatedAt: z.union([ z.lazy(() => DateTimeFilterSchema),z.coerce.date() ]).optional(),
}).strict();

export const NotificationUpsertWithWhereUniqueWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUpsertWithWhereUniqueWithoutReceiverInput> = z.object({
  where: z.lazy(() => NotificationWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => NotificationUpdateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedUpdateWithoutReceiverInputSchema) ]),
  create: z.union([ z.lazy(() => NotificationCreateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedCreateWithoutReceiverInputSchema) ]),
}).strict();

export const NotificationUpdateWithWhereUniqueWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUpdateWithWhereUniqueWithoutReceiverInput> = z.object({
  where: z.lazy(() => NotificationWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => NotificationUpdateWithoutReceiverInputSchema),z.lazy(() => NotificationUncheckedUpdateWithoutReceiverInputSchema) ]),
}).strict();

export const NotificationUpdateManyWithWhereWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUpdateManyWithWhereWithoutReceiverInput> = z.object({
  where: z.lazy(() => NotificationScalarWhereInputSchema),
  data: z.union([ z.lazy(() => NotificationUpdateManyMutationInputSchema),z.lazy(() => NotificationUncheckedUpdateManyWithoutReceiverInputSchema) ]),
}).strict();

export const CaseMessageUpsertWithWhereUniqueWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUpsertWithWhereUniqueWithoutSenderInput> = z.object({
  where: z.lazy(() => CaseMessageWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => CaseMessageUpdateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedUpdateWithoutSenderInputSchema) ]),
  create: z.union([ z.lazy(() => CaseMessageCreateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedCreateWithoutSenderInputSchema) ]),
}).strict();

export const CaseMessageUpdateWithWhereUniqueWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUpdateWithWhereUniqueWithoutSenderInput> = z.object({
  where: z.lazy(() => CaseMessageWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => CaseMessageUpdateWithoutSenderInputSchema),z.lazy(() => CaseMessageUncheckedUpdateWithoutSenderInputSchema) ]),
}).strict();

export const CaseMessageUpdateManyWithWhereWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUpdateManyWithWhereWithoutSenderInput> = z.object({
  where: z.lazy(() => CaseMessageScalarWhereInputSchema),
  data: z.union([ z.lazy(() => CaseMessageUpdateManyMutationInputSchema),z.lazy(() => CaseMessageUncheckedUpdateManyWithoutSenderInputSchema) ]),
}).strict();

export const PartnerUpsertWithoutUserInputSchema: z.ZodType<Prisma.PartnerUpsertWithoutUserInput> = z.object({
  update: z.union([ z.lazy(() => PartnerUpdateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCreateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedCreateWithoutUserInputSchema) ]),
  where: z.lazy(() => PartnerWhereInputSchema).optional()
}).strict();

export const PartnerUpdateToOneWithWhereWithoutUserInputSchema: z.ZodType<Prisma.PartnerUpdateToOneWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => PartnerWhereInputSchema).optional(),
  data: z.union([ z.lazy(() => PartnerUpdateWithoutUserInputSchema),z.lazy(() => PartnerUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const PartnerUpdateWithoutUserInputSchema: z.ZodType<Prisma.PartnerUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyName: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => PartnerStatusSchema),z.lazy(() => EnumPartnerStatusFieldUpdateOperationsInputSchema) ]).optional(),
  profession: z.union([ z.lazy(() => PartnerProfessionSchema),z.lazy(() => EnumPartnerProfessionFieldUpdateOperationsInputSchema) ]).optional(),
  otherProfession: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanVolume: z.union([ z.lazy(() => PartnerLoanVolumeSchema),z.lazy(() => EnumPartnerLoanVolumeFieldUpdateOperationsInputSchema) ]).optional(),
  clientTypes: z.union([ z.lazy(() => PartnerUpdateclientTypesInputSchema),z.lazy(() => PartnerClientTypeSchema).array() ]).optional(),
  usageCases: z.union([ z.lazy(() => PartnerUpdateusageCasesInputSchema),z.lazy(() => PartnerUsageCasesSchema).array() ]).optional(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companySize: z.union([ z.lazy(() => PartnerCompanySizeSchema),z.lazy(() => EnumPartnerCompanySizeFieldUpdateOperationsInputSchema) ]).optional(),
  orias: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partnerCollaborators: z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerRates: z.lazy(() => PartnerRateUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCases: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional(),
  partnerCompanyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutPartnerNestedInputSchema).optional(),
  partnerPendingCases: z.lazy(() => PartnerPendingCaseUncheckedUpdateManyWithoutPartnerNestedInputSchema).optional()
}).strict();

export const PartnerCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpsertWithWhereUniqueWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedUpdateWithoutCollaboratorInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerCollaboratorCreateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedCreateWithoutCollaboratorInputSchema) ]),
}).strict();

export const PartnerCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateWithWhereUniqueWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PartnerCollaboratorUpdateWithoutCollaboratorInputSchema),z.lazy(() => PartnerCollaboratorUncheckedUpdateWithoutCollaboratorInputSchema) ]),
}).strict();

export const PartnerCollaboratorUpdateManyWithWhereWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateManyWithWhereWithoutCollaboratorInput> = z.object({
  where: z.lazy(() => PartnerCollaboratorScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PartnerCollaboratorUpdateManyMutationInputSchema),z.lazy(() => PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorInputSchema) ]),
}).strict();

export const PartnerRateUpsertWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUpsertWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  update: z.union([ z.lazy(() => PartnerRateUpdateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutUserInputSchema) ]),
  create: z.union([ z.lazy(() => PartnerRateCreateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedCreateWithoutUserInputSchema) ]),
}).strict();

export const PartnerRateUpdateWithWhereUniqueWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUpdateWithWhereUniqueWithoutUserInput> = z.object({
  where: z.lazy(() => PartnerRateWhereUniqueInputSchema),
  data: z.union([ z.lazy(() => PartnerRateUpdateWithoutUserInputSchema),z.lazy(() => PartnerRateUncheckedUpdateWithoutUserInputSchema) ]),
}).strict();

export const PartnerRateUpdateManyWithWhereWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUpdateManyWithWhereWithoutUserInput> = z.object({
  where: z.lazy(() => PartnerRateScalarWhereInputSchema),
  data: z.union([ z.lazy(() => PartnerRateUpdateManyMutationInputSchema),z.lazy(() => PartnerRateUncheckedUpdateManyWithoutUserInputSchema) ]),
}).strict();

export const AssetJointOwnerCreateManyAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerCreateManyAssetInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  sharePercentage: z.number().int()
}).strict();

export const AssetShareholderCreateManyAssetInputSchema: z.ZodType<Prisma.AssetShareholderCreateManyAssetInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  type: z.lazy(() => AssetShareholderTypeSchema),
  sharePercentage: z.number().int()
}).strict();

export const CustomerRealEstateLoanChargeCreateManyAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyAssetInput> = z.object({
  id: z.string().optional(),
  customerId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema)
}).strict();

export const CompanyRealEstateLoanChargeCreateManyAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyAssetInput> = z.object({
  id: z.string().optional(),
  companyId: z.string(),
  caseId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const AssetJointOwnerUpdateWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerUncheckedUpdateWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetJointOwnerUncheckedUpdateManyWithoutAssetInputSchema: z.ZodType<Prisma.AssetJointOwnerUncheckedUpdateManyWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetShareholderUpdateWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetShareholderUncheckedUpdateWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetShareholderUncheckedUpdateManyWithoutAssetInputSchema: z.ZodType<Prisma.AssetShareholderUncheckedUpdateManyWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => AssetShareholderTypeSchema),z.lazy(() => EnumAssetShareholderTypeFieldUpdateOperationsInputSchema) ]).optional(),
  sharePercentage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneRequiredWithoutRealEstateLoanChargesNestedInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  company: z.lazy(() => CompanyUpdateOneRequiredWithoutCompanyRealEstateLoanChargeNestedInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionCreateManyBankInputSchema: z.ZodType<Prisma.SubmissionCreateManyBankInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  authorId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionUpdateWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUpdateWithoutBankInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional()
}).strict();

export const SubmissionUncheckedUpdateWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateWithoutBankInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyWithoutBankInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyWithoutBankInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetCreateManyCaseInputSchema: z.ZodType<Prisma.AssetCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCollaboratorCreateManyCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionCreateManyCaseInputSchema: z.ZodType<Prisma.SubmissionCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  authorId: z.string(),
  bankId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SuggestionCreateManyCaseInputSchema: z.ZodType<Prisma.SuggestionCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  field: z.lazy(() => SuggestionFieldSchema),
  value: z.string(),
  status: z.lazy(() => SuggestionStatusSchema).optional(),
  source: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SimulationCreateManyCaseInputSchema: z.ZodType<Prisma.SimulationCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  authorId: z.string(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseMessageCreateManyCaseInputSchema: z.ZodType<Prisma.CaseMessageCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  senderId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CasePartnerCreateManyCaseInputSchema: z.ZodType<Prisma.CasePartnerCreateManyCaseInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  rateId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const AssetUpdateWithoutCaseInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  collaborator: z.lazy(() => UserUpdateOneRequiredWithoutCasesAsCollaboratorNestedInputSchema).optional()
}).strict();

export const CaseCollaboratorUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  bank: z.lazy(() => BankUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional()
}).strict();

export const SubmissionUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bankId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bankId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SuggestionUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.SuggestionUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  field: z.union([ z.lazy(() => SuggestionFieldSchema),z.lazy(() => EnumSuggestionFieldFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SuggestionStatusSchema),z.lazy(() => EnumSuggestionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  source: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  author: z.lazy(() => UserUpdateOneRequiredWithoutSimulationNestedInputSchema).optional()
}).strict();

export const SimulationUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  sender: z.lazy(() => UserUpdateOneRequiredWithoutSentCaseMessagesNestedInputSchema).optional()
}).strict();

export const CaseMessageUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerCasesNestedInputSchema).optional(),
  rate: z.lazy(() => PartnerRateUpdateOneRequiredWithoutCasePartnerNestedInputSchema).optional()
}).strict();

export const CasePartnerUncheckedUpdateWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyWithoutCaseInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyWithoutCaseInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCreateManyCompanyInputSchema: z.ZodType<Prisma.CaseCreateManyCompanyInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const AssetCreateManyCompanyInputSchema: z.ZodType<Prisma.AssetCreateManyCompanyInput> = z.object({
  id: z.string().optional(),
  customerId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRealEstateLoanChargeCreateManyCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyCompanyInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  assetId: z.string(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  rate: z.number(),
  endDate: z.coerce.date(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyRentIncomeCreateManyCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeCreateManyCompanyInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  monthlyIncome: z.number().int(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyFinancialDataCreateManyCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataCreateManyCompanyInput> = z.object({
  id: z.string().optional(),
  year: z.number().int(),
  exploitationResult: z.number().optional().nullable(),
  depreciationCharge: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable(),
  loanInterest: z.number().optional().nullable(),
  revenue: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  balanceSheetSize: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  accountValue: z.number().optional().nullable()
}).strict();

export const CaseUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutAssetsNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneRequiredWithoutCompanyRealEstateLoanChargesNestedInputSchema).optional()
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRentIncomeUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneWithoutCompanyRentIncomeNestedInputSchema).optional()
}).strict();

export const CompanyRentIncomeUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyRentIncomeUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyRentIncomeUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyFinancialDataUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CompanyFinancialDataUncheckedUpdateWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedUpdateWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CompanyFinancialDataUncheckedUpdateManyWithoutCompanyInputSchema: z.ZodType<Prisma.CompanyFinancialDataUncheckedUpdateManyWithoutCompanyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  exploitationResult: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  depreciationCharge: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loanInterest: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  balanceSheetSize: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountValue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CaseCreateManyCustomerInputSchema: z.ZodType<Prisma.CaseCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCreateManyCoCustomerInputSchema: z.ZodType<Prisma.CaseCreateManyCoCustomerInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  userId: z.string(),
  customerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CustomerInvestmentCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  amount: z.number().int()
}).strict();

export const CustomerAdditionalIncomeCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CustomerAdditionalIncomeTypeSchema),
  monthlyIncome: z.number().int()
}).strict();

export const CustomerRentIncomeCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  assetId: z.string(),
  monthlyIncome: z.number().int()
}).strict();

export const AssetCreateManyCustomerInputSchema: z.ZodType<Prisma.AssetCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  companyId: z.string().optional().nullable(),
  caseId: z.string().optional().nullable(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  surface: z.number().int(),
  value: z.number().int(),
  rentalValue: z.number().int().optional().nullable(),
  sellValue: z.number().int().optional().nullable(),
  assetType: z.lazy(() => AssetTypeSchema).optional().nullable(),
  usageType: z.lazy(() => AssetUsageTypeSchema),
  ownershipType: z.lazy(() => AssetOwnershipTypeSchema),
  companyType: z.lazy(() => AssetCompanyTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CustomerRealEstateLoanChargeCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  assetId: z.string().optional().nullable(),
  monthlyCharge: z.number().int().optional().nullable(),
  guarantee: z.lazy(() => LoanGuaranteeSchema),
  endDate: z.coerce.date(),
  rate: z.number(),
  loanAmount: z.number().int().optional().nullable(),
  type: z.lazy(() => LoanTypeSchema)
}).strict();

export const CustomerConsumerLoanChargeCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  endDate: z.coerce.date(),
  rate: z.number()
}).strict();

export const CustomerRentChargeCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int()
}).strict();

export const CustomerAdditionalChargeCreateManyCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateManyCustomerInput> = z.object({
  id: z.string().optional(),
  monthlyCharge: z.number().int(),
  type: z.lazy(() => CustomerAdditionalChargeTypeSchema)
}).strict();

export const CaseUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseUpdateWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUpdateWithoutCoCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutCasesNestedInputSchema).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutCoCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateManyWithoutCoCustomerInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutCoCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  userId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerInvestmentUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerInvestmentUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalIncomeTypeSchema),z.lazy(() => EnumCustomerAdditionalIncomeTypeFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneWithoutCustomerRentIncomeNestedInputSchema).optional()
}).strict();

export const CustomerRentIncomeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentIncomeUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentIncomeUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyIncome: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AssetUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutAssetsNestedInputSchema).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutAssetsNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assetJointOwners: z.lazy(() => AssetJointOwnerUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  assetShareholders: z.lazy(() => AssetShareholderUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRealEstateLoanCharges: z.lazy(() => CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  customerRentIncome: z.lazy(() => CustomerRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional(),
  companyRealEstateLoanCharges: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutAssetNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateOneWithoutAssetNestedInputSchema).optional()
}).strict();

export const AssetUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.AssetUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  surface: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  value: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rentalValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sellValue: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  assetType: z.union([ z.lazy(() => AssetTypeSchema),z.lazy(() => NullableEnumAssetTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  usageType: z.union([ z.lazy(() => AssetUsageTypeSchema),z.lazy(() => EnumAssetUsageTypeFieldUpdateOperationsInputSchema) ]).optional(),
  ownershipType: z.union([ z.lazy(() => AssetOwnershipTypeSchema),z.lazy(() => EnumAssetOwnershipTypeFieldUpdateOperationsInputSchema) ]).optional(),
  companyType: z.union([ z.lazy(() => AssetCompanyTypeSchema),z.lazy(() => NullableEnumAssetCompanyTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  asset: z.lazy(() => AssetUpdateOneWithoutCustomerRealEstateLoanChargesNestedInputSchema).optional()
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  assetId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  guarantee: z.union([ z.lazy(() => LoanGuaranteeSchema),z.lazy(() => EnumLoanGuaranteeFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loanAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  type: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  endDate: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerRentChargeUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerRentChargeUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeUncheckedUpdateWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedUpdateWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerInputSchema: z.ZodType<Prisma.CustomerAdditionalChargeUncheckedUpdateManyWithoutCustomerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyCharge: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CustomerAdditionalChargeTypeSchema),z.lazy(() => EnumCustomerAdditionalChargeTypeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PappersRepresentativeCreateManyCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeCreateManyCompanyPappersInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  age: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable()
}).strict();

export const PappersBeneficiaryCreateManyCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryCreateManyCompanyPappersInput> = z.object({
  id: z.string().optional(),
  status: z.string().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  sharesPercentage: z.number().int().optional().nullable(),
  votePercentage: z.number().int().optional().nullable(),
  hasOngoingSanction: z.boolean().optional().nullable()
}).strict();

export const PappersCollectiveConventionCreateManyCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateManyCompanyPappersInput> = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  idcc: z.number().int().optional().nullable()
}).strict();

export const PappersCollectiveProcedureCreateManyCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateManyCompanyPappersInput> = z.object({
  id: z.string().optional(),
  type: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersFinancialDataCreateManyCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataCreateManyCompanyPappersInput> = z.object({
  id: z.string().optional(),
  year: z.number().int().optional().nullable(),
  revenue: z.number().optional().nullable(),
  operatingResults: z.number().optional().nullable(),
  liquidity: z.number().optional().nullable(),
  charges: z.number().optional().nullable(),
  repaymentCapacity: z.number().optional().nullable(),
  equity: z.number().optional().nullable(),
  netProfit: z.number().optional().nullable()
}).strict();

export const PappersRepresentativeUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUpdateManyWithoutPappersRepresentativeNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeNestedInputSchema).optional()
}).strict();

export const PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersRepresentativeUncheckedUpdateManyWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  age: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersBeneficiaryUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUpdateManyWithoutPappersBeneficiaryNestedInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sanction: z.lazy(() => PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryNestedInputSchema).optional()
}).strict();

export const PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersBeneficiaryUncheckedUpdateManyWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  sharesPercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  votePercentage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  hasOngoingSanction: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionUncheckedUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveConventionUncheckedUpdateManyWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  idcc: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureUncheckedUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersCollectiveProcedureUncheckedUpdateManyWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataUncheckedUpdateWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedUpdateWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersInputSchema: z.ZodType<Prisma.PappersFinancialDataUncheckedUpdateManyWithoutCompanyPappersInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  year: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  revenue: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  operatingResults: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  liquidity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  charges: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  repaymentCapacity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  equity: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  netProfit: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionCreateManyPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionCreateManyPappersRepresentativeInput> = z.object({
  id: z.string().optional(),
  pappersBeneficiaryId: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersSanctionUpdateWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUpdateWithoutPappersRepresentativeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  pappersBeneficiary: z.lazy(() => PappersBeneficiaryUpdateOneWithoutSanctionNestedInputSchema).optional()
}).strict();

export const PappersSanctionUncheckedUpdateWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateWithoutPappersRepresentativeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pappersBeneficiaryId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateManyWithoutPappersRepresentativeInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pappersBeneficiaryId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionCreateManyPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionCreateManyPappersBeneficiaryInput> = z.object({
  id: z.string().optional(),
  pappersRepresentativeId: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  autority: z.string().optional().nullable(),
  isOngoing: z.boolean().optional().nullable(),
  startDate: z.string().optional().nullable(),
  endDate: z.string().optional().nullable()
}).strict();

export const PappersSanctionUpdateWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUpdateWithoutPappersBeneficiaryInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  pappersRepresentative: z.lazy(() => PappersRepresentativeUpdateOneWithoutSanctionNestedInputSchema).optional()
}).strict();

export const PappersSanctionUncheckedUpdateWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateWithoutPappersBeneficiaryInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pappersRepresentativeId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryInputSchema: z.ZodType<Prisma.PappersSanctionUncheckedUpdateManyWithoutPappersBeneficiaryInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pappersRepresentativeId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  autority: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOngoing: z.union([ z.boolean(),z.lazy(() => NullableBoolFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  startDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  endDate: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PartnerCollaboratorCreateManyPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyPartnerInput> = z.object({
  id: z.string().optional(),
  collaboratorId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerRateCreateManyPartnerInputSchema: z.ZodType<Prisma.PartnerRateCreateManyPartnerInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  createdBy: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerCreateManyPartnerInputSchema: z.ZodType<Prisma.CasePartnerCreateManyPartnerInput> = z.object({
  id: z.string().optional(),
  rateId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const PartnerPendingCaseCreateManyPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseCreateManyPartnerInput> = z.object({
  id: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  email: z.string(),
  phone: z.string(),
  patrimony: z.lazy(() => ProspectPatrimonySchema),
  product: z.lazy(() => ProspectProductSchema),
  amount: z.lazy(() => ProspectAmountSchema),
  urgency: z.lazy(() => ProspectUrgencySchema),
  rating: z.lazy(() => ProspectRatingSchema),
  description: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerCollaboratorUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  collaborator: z.lazy(() => UserUpdateOneRequiredWithoutPartnerCollaboratorsNestedInputSchema).optional()
}).strict();

export const PartnerCollaboratorUncheckedUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUncheckedUpdateManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateManyWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  collaboratorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerRateUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  user: z.lazy(() => UserUpdateOneRequiredWithoutPartnerRateCreatedByNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutRateNestedInputSchema).optional()
}).strict();

export const PartnerRateUncheckedUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdBy: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutRateNestedInputSchema).optional()
}).strict();

export const PartnerRateUncheckedUpdateManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateManyWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdBy: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.lazy(() => PartnerRateUpdateOneRequiredWithoutCasePartnerNestedInputSchema).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCasePartnerNestedInputSchema).optional()
}).strict();

export const CasePartnerUncheckedUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyWithoutPartnerInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rateId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseUncheckedUpdateWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedUpdateWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerPendingCaseUncheckedUpdateManyWithoutPartnerInputSchema: z.ZodType<Prisma.PartnerPendingCaseUncheckedUpdateManyWithoutPartnerInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  lastname: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phone: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  patrimony: z.union([ z.lazy(() => ProspectPatrimonySchema),z.lazy(() => EnumProspectPatrimonyFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProspectProductSchema),z.lazy(() => EnumProspectProductFieldUpdateOperationsInputSchema) ]).optional(),
  amount: z.union([ z.lazy(() => ProspectAmountSchema),z.lazy(() => EnumProspectAmountFieldUpdateOperationsInputSchema) ]).optional(),
  urgency: z.union([ z.lazy(() => ProspectUrgencySchema),z.lazy(() => EnumProspectUrgencyFieldUpdateOperationsInputSchema) ]).optional(),
  rating: z.union([ z.lazy(() => ProspectRatingSchema),z.lazy(() => EnumProspectRatingFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerCreateManyRateInputSchema: z.ZodType<Prisma.CasePartnerCreateManyRateInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const CasePartnerUpdateWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUpdateWithoutRateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerCasesNestedInputSchema).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCasePartnerNestedInputSchema).optional()
}).strict();

export const CasePartnerUncheckedUpdateWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateWithoutRateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CasePartnerUncheckedUpdateManyWithoutRateInputSchema: z.ZodType<Prisma.CasePartnerUncheckedUpdateManyWithoutRateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const AccountCreateManyUserInputSchema: z.ZodType<Prisma.AccountCreateManyUserInput> = z.object({
  id: z.string().optional(),
  type: z.string(),
  provider: z.string(),
  providerAccountId: z.string(),
  refresh_token: z.string().optional().nullable(),
  access_token: z.string().optional().nullable(),
  expires_at: z.number().int().optional().nullable(),
  token_type: z.string().optional().nullable(),
  scope: z.string().optional().nullable(),
  id_token: z.string().optional().nullable(),
  session_state: z.string().optional().nullable()
}).strict();

export const SessionCreateManyUserInputSchema: z.ZodType<Prisma.SessionCreateManyUserInput> = z.object({
  id: z.string().optional(),
  sessionToken: z.string(),
  expires: z.coerce.date()
}).strict();

export const CaseTraceCreateManyUserInputSchema: z.ZodType<Prisma.CaseTraceCreateManyUserInput> = z.object({
  id: z.string().optional(),
  caseId: z.string().optional().nullable(),
  affiliateId: z.string().optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CompanyCreateManyUserInputSchema: z.ZodType<Prisma.CompanyCreateManyUserInput> = z.object({
  id: z.string().optional(),
  type: z.lazy(() => CompanyTypeSchema),
  caseId: z.string(),
  name: z.string().optional().nullable(),
  siret: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  inseeCode: z.string().optional().nullable(),
  country: z.string(),
  taxType: z.lazy(() => TaxTypeSchema).optional().nullable(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCreateManyUserInputSchema: z.ZodType<Prisma.CaseCreateManyUserInput> = z.object({
  id: z.string().optional(),
  referenceNo: z.number().int().optional(),
  customerId: z.string().optional().nullable(),
  coCustomerId: z.string().optional().nullable(),
  companyId: z.string().optional().nullable(),
  attio_record_id: z.string().optional().nullable(),
  purpose: z.lazy(() => CasePurposeSchema),
  description: z.string(),
  requestedAmount: z.number().int().optional().nullable(),
  caseType: z.lazy(() => CaseTypeSchema).optional(),
  status: z.lazy(() => CaseStatusSchema).optional(),
  priority: z.lazy(() => CasePrioritySchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseCollaboratorCreateManyCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyCollaboratorInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SimulationCreateManyAuthorInputSchema: z.ZodType<Prisma.SimulationCreateManyAuthorInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  loanType: z.lazy(() => LoanTypeSchema),
  borrowedCapital: z.number().int(),
  remainingMonths: z.number().int(),
  rate: z.number(),
  expertFees: z.number(),
  mandate: z.number(),
  bankFees: z.number(),
  guaranteeFees: z.number(),
  loansAmountTakenOver: z.number(),
  monthlyChargesLoansTakenOver: z.number().optional(),
  status: z.lazy(() => SimulationStatusSchema).optional(),
  rejectionMotive: z.lazy(() => RejectionMotiveSchema).optional().nullable(),
  rejectionReason: z.string().optional().nullable(),
  statusUpdatedAt: z.coerce.date().optional().nullable(),
  contribution: z.number().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SubmissionCreateManyUserInputSchema: z.ZodType<Prisma.SubmissionCreateManyUserInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  bankId: z.string(),
  product: z.lazy(() => ProductSchema),
  reason: z.string(),
  comment: z.string(),
  status: z.lazy(() => SubmissionStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const SynthesisCreateManyUserInputSchema: z.ZodType<Prisma.SynthesisCreateManyUserInput> = z.object({
  id: z.string().optional(),
  caseId: z.string(),
  content: z.string(),
  updatedAt: z.coerce.date().optional(),
  createdAt: z.coerce.date().optional()
}).strict();

export const NotificationCreateManySenderInputSchema: z.ZodType<Prisma.NotificationCreateManySenderInput> = z.object({
  id: z.string().optional(),
  receiverId: z.string(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const NotificationCreateManyReceiverInputSchema: z.ZodType<Prisma.NotificationCreateManyReceiverInput> = z.object({
  id: z.string().optional(),
  senderId: z.string(),
  model: z.lazy(() => NotificationModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => NotificationStatusSchema).optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const CaseMessageCreateManySenderInputSchema: z.ZodType<Prisma.CaseMessageCreateManySenderInput> = z.object({
  id: z.string().optional(),
  senderRole: z.lazy(() => UserRoleSchema),
  model: z.lazy(() => CaseMessageModelSchema),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]),
  status: z.lazy(() => CaseMessageStatusSchema).optional(),
  caseId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerCollaboratorCreateManyCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyCollaboratorInput> = z.object({
  id: z.string().optional(),
  partnerId: z.string(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional()
}).strict();

export const PartnerRateCreateManyUserInputSchema: z.ZodType<Prisma.PartnerRateCreateManyUserInput> = z.object({
  id: z.string().optional(),
  rate: z.number(),
  partnerId: z.string(),
  createdAt: z.coerce.date().optional()
}).strict();

export const AccountUpdateWithoutUserInputSchema: z.ZodType<Prisma.AccountUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  provider: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  providerAccountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  refresh_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  access_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  expires_at: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  token_type: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  scope: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  id_token: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  session_state: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const SessionUpdateWithoutUserInputSchema: z.ZodType<Prisma.SessionUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SessionUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.SessionUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  sessionToken: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  expires: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseTraceUpdateWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneWithoutCaseTraceNestedInputSchema).optional()
}).strict();

export const CaseTraceUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseTraceUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.CaseTraceUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  affiliateId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  answersInput: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  contactInput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateOutput: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  dbCreateError: z.union([ z.lazy(() => NullableJsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CompanyUpdateWithoutUserInputSchema: z.ZodType<Prisma.CompanyUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  caseAsCompany: z.lazy(() => CaseUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRealEstateLoanCharge: z.lazy(() => CompanyRealEstateLoanChargeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyRentIncome: z.lazy(() => CompanyRentIncomeUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional(),
  companyPappers: z.lazy(() => CompanyPappersUncheckedUpdateOneWithoutCompanyNestedInputSchema).optional(),
  companyFinancialDatas: z.lazy(() => CompanyFinancialDataUncheckedUpdateManyWithoutCompanyNestedInputSchema).optional()
}).strict();

export const CompanyUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.CompanyUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  type: z.union([ z.lazy(() => CompanyTypeSchema),z.lazy(() => EnumCompanyTypeFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  siret: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  address: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  zipcode: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  city: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  inseeCode: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  country: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  taxType: z.union([ z.lazy(() => TaxTypeSchema),z.lazy(() => NullableEnumTaxTypeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseUpdateWithoutUserInputSchema: z.ZodType<Prisma.CaseUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  customer: z.lazy(() => CustomerUpdateOneWithoutCasesAsMainCustomerNestedInputSchema).optional(),
  coCustomer: z.lazy(() => CustomerUpdateOneWithoutCasesAsCoCustomerNestedInputSchema).optional(),
  company: z.lazy(() => CompanyUpdateOneWithoutCaseAsCompanyNestedInputSchema).optional(),
  assets: z.lazy(() => AssetUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  assets: z.lazy(() => AssetUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseTrace: z.lazy(() => CaseTraceUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  caseCollaborators: z.lazy(() => CaseCollaboratorUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  submissions: z.lazy(() => SubmissionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  synthesis: z.lazy(() => SynthesisUncheckedUpdateOneWithoutCaseNestedInputSchema).optional(),
  suggestions: z.lazy(() => SuggestionUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  Simulation: z.lazy(() => SimulationUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  caseMessages: z.lazy(() => CaseMessageUncheckedUpdateManyWithoutCaseNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutCaseNestedInputSchema).optional()
}).strict();

export const CaseUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.CaseUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  referenceNo: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  customerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  coCustomerId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  companyId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  attio_record_id: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  purpose: z.union([ z.lazy(() => CasePurposeSchema),z.lazy(() => EnumCasePurposeFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  requestedAmount: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  caseType: z.union([ z.lazy(() => CaseTypeSchema),z.lazy(() => EnumCaseTypeFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => CaseStatusSchema),z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputSchema) ]).optional(),
  priority: z.union([ z.lazy(() => CasePrioritySchema),z.lazy(() => EnumCasePriorityFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUpdateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUpdateWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCaseCollaboratorsNestedInputSchema).optional()
}).strict();

export const CaseCollaboratorUncheckedUpdateWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.CaseCollaboratorUncheckedUpdateManyWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationUpdateWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUpdateWithoutAuthorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSimulationNestedInputSchema).optional()
}).strict();

export const SimulationUncheckedUpdateWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateWithoutAuthorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SimulationUncheckedUpdateManyWithoutAuthorInputSchema: z.ZodType<Prisma.SimulationUncheckedUpdateManyWithoutAuthorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  loanType: z.union([ z.lazy(() => LoanTypeSchema),z.lazy(() => EnumLoanTypeFieldUpdateOperationsInputSchema) ]).optional(),
  borrowedCapital: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  remainingMonths: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  expertFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  mandate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  bankFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  guaranteeFees: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  loansAmountTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  monthlyChargesLoansTakenOver: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SimulationStatusSchema),z.lazy(() => EnumSimulationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  rejectionMotive: z.union([ z.lazy(() => RejectionMotiveSchema),z.lazy(() => NullableEnumRejectionMotiveFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  rejectionReason: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  statusUpdatedAt: z.union([ z.coerce.date(),z.lazy(() => NullableDateTimeFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contribution: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionUpdateWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  bank: z.lazy(() => BankUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSubmissionsNestedInputSchema).optional()
}).strict();

export const SubmissionUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bankId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SubmissionUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.SubmissionUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  bankId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  product: z.union([ z.lazy(() => ProductSchema),z.lazy(() => EnumProductFieldUpdateOperationsInputSchema) ]).optional(),
  reason: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  comment: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.lazy(() => SubmissionStatusSchema),z.lazy(() => EnumSubmissionStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SynthesisUpdateWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutSynthesisNestedInputSchema).optional()
}).strict();

export const SynthesisUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const SynthesisUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.SynthesisUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  content: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationUpdateWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUpdateWithoutSenderInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  receiver: z.lazy(() => UserUpdateOneRequiredWithoutReceivedNotificationsNestedInputSchema).optional()
}).strict();

export const NotificationUncheckedUpdateWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateWithoutSenderInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationUncheckedUpdateManyWithoutSenderInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateManyWithoutSenderInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  receiverId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationUpdateWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUpdateWithoutReceiverInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  sender: z.lazy(() => UserUpdateOneRequiredWithoutSentNotificationsNestedInputSchema).optional()
}).strict();

export const NotificationUncheckedUpdateWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateWithoutReceiverInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const NotificationUncheckedUpdateManyWithoutReceiverInputSchema: z.ZodType<Prisma.NotificationUncheckedUpdateManyWithoutReceiverInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => NotificationModelSchema),z.lazy(() => EnumNotificationModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => NotificationStatusSchema),z.lazy(() => EnumNotificationStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageUpdateWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUpdateWithoutSenderInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  case: z.lazy(() => CaseUpdateOneRequiredWithoutCaseMessagesNestedInputSchema).optional()
}).strict();

export const CaseMessageUncheckedUpdateWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateWithoutSenderInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const CaseMessageUncheckedUpdateManyWithoutSenderInputSchema: z.ZodType<Prisma.CaseMessageUncheckedUpdateManyWithoutSenderInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  senderRole: z.union([ z.lazy(() => UserRoleSchema),z.lazy(() => EnumUserRoleFieldUpdateOperationsInputSchema) ]).optional(),
  model: z.union([ z.lazy(() => CaseMessageModelSchema),z.lazy(() => EnumCaseMessageModelFieldUpdateOperationsInputSchema) ]).optional(),
  modelData: z.union([ z.lazy(() => JsonNullValueInputSchema),InputJsonValueSchema ]).optional(),
  status: z.union([ z.lazy(() => CaseMessageStatusSchema),z.lazy(() => EnumCaseMessageStatusFieldUpdateOperationsInputSchema) ]).optional(),
  caseId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUpdateWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerCollaboratorsNestedInputSchema).optional()
}).strict();

export const PartnerCollaboratorUncheckedUpdateWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorInputSchema: z.ZodType<Prisma.PartnerCollaboratorUncheckedUpdateManyWithoutCollaboratorInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  updatedAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PartnerRateUpdateWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  partner: z.lazy(() => PartnerUpdateOneRequiredWithoutPartnerRatesNestedInputSchema).optional(),
  casePartner: z.lazy(() => CasePartnerUpdateManyWithoutRateNestedInputSchema).optional()
}).strict();

export const PartnerRateUncheckedUpdateWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
  casePartner: z.lazy(() => CasePartnerUncheckedUpdateManyWithoutRateNestedInputSchema).optional()
}).strict();

export const PartnerRateUncheckedUpdateManyWithoutUserInputSchema: z.ZodType<Prisma.PartnerRateUncheckedUpdateManyWithoutUserInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  rate: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  partnerId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.coerce.date(),z.lazy(() => DateTimeFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

/////////////////////////////////////////
// ARGS
/////////////////////////////////////////

export const AssetFindFirstArgsSchema: z.ZodType<Prisma.AssetFindFirstArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereInputSchema.optional(),
  orderBy: z.union([ AssetOrderByWithRelationInputSchema.array(),AssetOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetScalarFieldEnumSchema,AssetScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AssetFindFirstOrThrowArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereInputSchema.optional(),
  orderBy: z.union([ AssetOrderByWithRelationInputSchema.array(),AssetOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetScalarFieldEnumSchema,AssetScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetFindManyArgsSchema: z.ZodType<Prisma.AssetFindManyArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereInputSchema.optional(),
  orderBy: z.union([ AssetOrderByWithRelationInputSchema.array(),AssetOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetScalarFieldEnumSchema,AssetScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetAggregateArgsSchema: z.ZodType<Prisma.AssetAggregateArgs> = z.object({
  where: AssetWhereInputSchema.optional(),
  orderBy: z.union([ AssetOrderByWithRelationInputSchema.array(),AssetOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssetGroupByArgsSchema: z.ZodType<Prisma.AssetGroupByArgs> = z.object({
  where: AssetWhereInputSchema.optional(),
  orderBy: z.union([ AssetOrderByWithAggregationInputSchema.array(),AssetOrderByWithAggregationInputSchema ]).optional(),
  by: AssetScalarFieldEnumSchema.array(),
  having: AssetScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssetFindUniqueArgsSchema: z.ZodType<Prisma.AssetFindUniqueArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereUniqueInputSchema,
}).strict() ;

export const AssetFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AssetFindUniqueOrThrowArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereUniqueInputSchema,
}).strict() ;

export const AssetJointOwnerFindFirstArgsSchema: z.ZodType<Prisma.AssetJointOwnerFindFirstArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereInputSchema.optional(),
  orderBy: z.union([ AssetJointOwnerOrderByWithRelationInputSchema.array(),AssetJointOwnerOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetJointOwnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetJointOwnerScalarFieldEnumSchema,AssetJointOwnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetJointOwnerFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AssetJointOwnerFindFirstOrThrowArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereInputSchema.optional(),
  orderBy: z.union([ AssetJointOwnerOrderByWithRelationInputSchema.array(),AssetJointOwnerOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetJointOwnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetJointOwnerScalarFieldEnumSchema,AssetJointOwnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetJointOwnerFindManyArgsSchema: z.ZodType<Prisma.AssetJointOwnerFindManyArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereInputSchema.optional(),
  orderBy: z.union([ AssetJointOwnerOrderByWithRelationInputSchema.array(),AssetJointOwnerOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetJointOwnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetJointOwnerScalarFieldEnumSchema,AssetJointOwnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetJointOwnerAggregateArgsSchema: z.ZodType<Prisma.AssetJointOwnerAggregateArgs> = z.object({
  where: AssetJointOwnerWhereInputSchema.optional(),
  orderBy: z.union([ AssetJointOwnerOrderByWithRelationInputSchema.array(),AssetJointOwnerOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetJointOwnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssetJointOwnerGroupByArgsSchema: z.ZodType<Prisma.AssetJointOwnerGroupByArgs> = z.object({
  where: AssetJointOwnerWhereInputSchema.optional(),
  orderBy: z.union([ AssetJointOwnerOrderByWithAggregationInputSchema.array(),AssetJointOwnerOrderByWithAggregationInputSchema ]).optional(),
  by: AssetJointOwnerScalarFieldEnumSchema.array(),
  having: AssetJointOwnerScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssetJointOwnerFindUniqueArgsSchema: z.ZodType<Prisma.AssetJointOwnerFindUniqueArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereUniqueInputSchema,
}).strict() ;

export const AssetJointOwnerFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AssetJointOwnerFindUniqueOrThrowArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereUniqueInputSchema,
}).strict() ;

export const AssetShareholderFindFirstArgsSchema: z.ZodType<Prisma.AssetShareholderFindFirstArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereInputSchema.optional(),
  orderBy: z.union([ AssetShareholderOrderByWithRelationInputSchema.array(),AssetShareholderOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetShareholderWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetShareholderScalarFieldEnumSchema,AssetShareholderScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetShareholderFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AssetShareholderFindFirstOrThrowArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereInputSchema.optional(),
  orderBy: z.union([ AssetShareholderOrderByWithRelationInputSchema.array(),AssetShareholderOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetShareholderWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetShareholderScalarFieldEnumSchema,AssetShareholderScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetShareholderFindManyArgsSchema: z.ZodType<Prisma.AssetShareholderFindManyArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereInputSchema.optional(),
  orderBy: z.union([ AssetShareholderOrderByWithRelationInputSchema.array(),AssetShareholderOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetShareholderWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssetShareholderScalarFieldEnumSchema,AssetShareholderScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssetShareholderAggregateArgsSchema: z.ZodType<Prisma.AssetShareholderAggregateArgs> = z.object({
  where: AssetShareholderWhereInputSchema.optional(),
  orderBy: z.union([ AssetShareholderOrderByWithRelationInputSchema.array(),AssetShareholderOrderByWithRelationInputSchema ]).optional(),
  cursor: AssetShareholderWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssetShareholderGroupByArgsSchema: z.ZodType<Prisma.AssetShareholderGroupByArgs> = z.object({
  where: AssetShareholderWhereInputSchema.optional(),
  orderBy: z.union([ AssetShareholderOrderByWithAggregationInputSchema.array(),AssetShareholderOrderByWithAggregationInputSchema ]).optional(),
  by: AssetShareholderScalarFieldEnumSchema.array(),
  having: AssetShareholderScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssetShareholderFindUniqueArgsSchema: z.ZodType<Prisma.AssetShareholderFindUniqueArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereUniqueInputSchema,
}).strict() ;

export const AssetShareholderFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AssetShareholderFindUniqueOrThrowArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereUniqueInputSchema,
}).strict() ;

export const AssistantResponseFindFirstArgsSchema: z.ZodType<Prisma.AssistantResponseFindFirstArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereInputSchema.optional(),
  orderBy: z.union([ AssistantResponseOrderByWithRelationInputSchema.array(),AssistantResponseOrderByWithRelationInputSchema ]).optional(),
  cursor: AssistantResponseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssistantResponseScalarFieldEnumSchema,AssistantResponseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssistantResponseFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AssistantResponseFindFirstOrThrowArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereInputSchema.optional(),
  orderBy: z.union([ AssistantResponseOrderByWithRelationInputSchema.array(),AssistantResponseOrderByWithRelationInputSchema ]).optional(),
  cursor: AssistantResponseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssistantResponseScalarFieldEnumSchema,AssistantResponseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssistantResponseFindManyArgsSchema: z.ZodType<Prisma.AssistantResponseFindManyArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereInputSchema.optional(),
  orderBy: z.union([ AssistantResponseOrderByWithRelationInputSchema.array(),AssistantResponseOrderByWithRelationInputSchema ]).optional(),
  cursor: AssistantResponseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AssistantResponseScalarFieldEnumSchema,AssistantResponseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AssistantResponseAggregateArgsSchema: z.ZodType<Prisma.AssistantResponseAggregateArgs> = z.object({
  where: AssistantResponseWhereInputSchema.optional(),
  orderBy: z.union([ AssistantResponseOrderByWithRelationInputSchema.array(),AssistantResponseOrderByWithRelationInputSchema ]).optional(),
  cursor: AssistantResponseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssistantResponseGroupByArgsSchema: z.ZodType<Prisma.AssistantResponseGroupByArgs> = z.object({
  where: AssistantResponseWhereInputSchema.optional(),
  orderBy: z.union([ AssistantResponseOrderByWithAggregationInputSchema.array(),AssistantResponseOrderByWithAggregationInputSchema ]).optional(),
  by: AssistantResponseScalarFieldEnumSchema.array(),
  having: AssistantResponseScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AssistantResponseFindUniqueArgsSchema: z.ZodType<Prisma.AssistantResponseFindUniqueArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereUniqueInputSchema,
}).strict() ;

export const AssistantResponseFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AssistantResponseFindUniqueOrThrowArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereUniqueInputSchema,
}).strict() ;

export const AccountFindFirstArgsSchema: z.ZodType<Prisma.AccountFindFirstArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AccountFindFirstOrThrowArgsSchema: z.ZodType<Prisma.AccountFindFirstOrThrowArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AccountFindManyArgsSchema: z.ZodType<Prisma.AccountFindManyArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ AccountScalarFieldEnumSchema,AccountScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const AccountAggregateArgsSchema: z.ZodType<Prisma.AccountAggregateArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AccountGroupByArgsSchema: z.ZodType<Prisma.AccountGroupByArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithAggregationInputSchema.array(),AccountOrderByWithAggregationInputSchema ]).optional(),
  by: AccountScalarFieldEnumSchema.array(),
  having: AccountScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const AccountFindUniqueArgsSchema: z.ZodType<Prisma.AccountFindUniqueArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const AccountFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.AccountFindUniqueOrThrowArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const SessionFindFirstArgsSchema: z.ZodType<Prisma.SessionFindFirstArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SessionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SessionFindFirstOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SessionFindManyArgsSchema: z.ZodType<Prisma.SessionFindManyArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SessionScalarFieldEnumSchema,SessionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SessionAggregateArgsSchema: z.ZodType<Prisma.SessionAggregateArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithRelationInputSchema.array(),SessionOrderByWithRelationInputSchema ]).optional(),
  cursor: SessionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SessionGroupByArgsSchema: z.ZodType<Prisma.SessionGroupByArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
  orderBy: z.union([ SessionOrderByWithAggregationInputSchema.array(),SessionOrderByWithAggregationInputSchema ]).optional(),
  by: SessionScalarFieldEnumSchema.array(),
  having: SessionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SessionFindUniqueArgsSchema: z.ZodType<Prisma.SessionFindUniqueArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const SessionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SessionFindUniqueOrThrowArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenFindFirstArgsSchema: z.ZodType<Prisma.VerificationTokenFindFirstArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const VerificationTokenFindFirstOrThrowArgsSchema: z.ZodType<Prisma.VerificationTokenFindFirstOrThrowArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const VerificationTokenFindManyArgsSchema: z.ZodType<Prisma.VerificationTokenFindManyArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ VerificationTokenScalarFieldEnumSchema,VerificationTokenScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const VerificationTokenAggregateArgsSchema: z.ZodType<Prisma.VerificationTokenAggregateArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithRelationInputSchema.array(),VerificationTokenOrderByWithRelationInputSchema ]).optional(),
  cursor: VerificationTokenWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const VerificationTokenGroupByArgsSchema: z.ZodType<Prisma.VerificationTokenGroupByArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
  orderBy: z.union([ VerificationTokenOrderByWithAggregationInputSchema.array(),VerificationTokenOrderByWithAggregationInputSchema ]).optional(),
  by: VerificationTokenScalarFieldEnumSchema.array(),
  having: VerificationTokenScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const VerificationTokenFindUniqueArgsSchema: z.ZodType<Prisma.VerificationTokenFindUniqueArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.VerificationTokenFindUniqueOrThrowArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const BankFindFirstArgsSchema: z.ZodType<Prisma.BankFindFirstArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereInputSchema.optional(),
  orderBy: z.union([ BankOrderByWithRelationInputSchema.array(),BankOrderByWithRelationInputSchema ]).optional(),
  cursor: BankWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BankScalarFieldEnumSchema,BankScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BankFindFirstOrThrowArgsSchema: z.ZodType<Prisma.BankFindFirstOrThrowArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereInputSchema.optional(),
  orderBy: z.union([ BankOrderByWithRelationInputSchema.array(),BankOrderByWithRelationInputSchema ]).optional(),
  cursor: BankWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BankScalarFieldEnumSchema,BankScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BankFindManyArgsSchema: z.ZodType<Prisma.BankFindManyArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereInputSchema.optional(),
  orderBy: z.union([ BankOrderByWithRelationInputSchema.array(),BankOrderByWithRelationInputSchema ]).optional(),
  cursor: BankWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ BankScalarFieldEnumSchema,BankScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const BankAggregateArgsSchema: z.ZodType<Prisma.BankAggregateArgs> = z.object({
  where: BankWhereInputSchema.optional(),
  orderBy: z.union([ BankOrderByWithRelationInputSchema.array(),BankOrderByWithRelationInputSchema ]).optional(),
  cursor: BankWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BankGroupByArgsSchema: z.ZodType<Prisma.BankGroupByArgs> = z.object({
  where: BankWhereInputSchema.optional(),
  orderBy: z.union([ BankOrderByWithAggregationInputSchema.array(),BankOrderByWithAggregationInputSchema ]).optional(),
  by: BankScalarFieldEnumSchema.array(),
  having: BankScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const BankFindUniqueArgsSchema: z.ZodType<Prisma.BankFindUniqueArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereUniqueInputSchema,
}).strict() ;

export const BankFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.BankFindUniqueOrThrowArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereUniqueInputSchema,
}).strict() ;

export const CaseFindFirstArgsSchema: z.ZodType<Prisma.CaseFindFirstArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereInputSchema.optional(),
  orderBy: z.union([ CaseOrderByWithRelationInputSchema.array(),CaseOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseScalarFieldEnumSchema,CaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CaseFindFirstOrThrowArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereInputSchema.optional(),
  orderBy: z.union([ CaseOrderByWithRelationInputSchema.array(),CaseOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseScalarFieldEnumSchema,CaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseFindManyArgsSchema: z.ZodType<Prisma.CaseFindManyArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereInputSchema.optional(),
  orderBy: z.union([ CaseOrderByWithRelationInputSchema.array(),CaseOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseScalarFieldEnumSchema,CaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseAggregateArgsSchema: z.ZodType<Prisma.CaseAggregateArgs> = z.object({
  where: CaseWhereInputSchema.optional(),
  orderBy: z.union([ CaseOrderByWithRelationInputSchema.array(),CaseOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseGroupByArgsSchema: z.ZodType<Prisma.CaseGroupByArgs> = z.object({
  where: CaseWhereInputSchema.optional(),
  orderBy: z.union([ CaseOrderByWithAggregationInputSchema.array(),CaseOrderByWithAggregationInputSchema ]).optional(),
  by: CaseScalarFieldEnumSchema.array(),
  having: CaseScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseFindUniqueArgsSchema: z.ZodType<Prisma.CaseFindUniqueArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereUniqueInputSchema,
}).strict() ;

export const CaseFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CaseFindUniqueOrThrowArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereUniqueInputSchema,
}).strict() ;

export const CaseCollaboratorFindFirstArgsSchema: z.ZodType<Prisma.CaseCollaboratorFindFirstArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ CaseCollaboratorOrderByWithRelationInputSchema.array(),CaseCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseCollaboratorScalarFieldEnumSchema,CaseCollaboratorScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseCollaboratorFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CaseCollaboratorFindFirstOrThrowArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ CaseCollaboratorOrderByWithRelationInputSchema.array(),CaseCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseCollaboratorScalarFieldEnumSchema,CaseCollaboratorScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseCollaboratorFindManyArgsSchema: z.ZodType<Prisma.CaseCollaboratorFindManyArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ CaseCollaboratorOrderByWithRelationInputSchema.array(),CaseCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseCollaboratorScalarFieldEnumSchema,CaseCollaboratorScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseCollaboratorAggregateArgsSchema: z.ZodType<Prisma.CaseCollaboratorAggregateArgs> = z.object({
  where: CaseCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ CaseCollaboratorOrderByWithRelationInputSchema.array(),CaseCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseCollaboratorGroupByArgsSchema: z.ZodType<Prisma.CaseCollaboratorGroupByArgs> = z.object({
  where: CaseCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ CaseCollaboratorOrderByWithAggregationInputSchema.array(),CaseCollaboratorOrderByWithAggregationInputSchema ]).optional(),
  by: CaseCollaboratorScalarFieldEnumSchema.array(),
  having: CaseCollaboratorScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseCollaboratorFindUniqueArgsSchema: z.ZodType<Prisma.CaseCollaboratorFindUniqueArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const CaseCollaboratorFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CaseCollaboratorFindUniqueOrThrowArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const CaseTraceFindFirstArgsSchema: z.ZodType<Prisma.CaseTraceFindFirstArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereInputSchema.optional(),
  orderBy: z.union([ CaseTraceOrderByWithRelationInputSchema.array(),CaseTraceOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseTraceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseTraceScalarFieldEnumSchema,CaseTraceScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseTraceFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CaseTraceFindFirstOrThrowArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereInputSchema.optional(),
  orderBy: z.union([ CaseTraceOrderByWithRelationInputSchema.array(),CaseTraceOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseTraceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseTraceScalarFieldEnumSchema,CaseTraceScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseTraceFindManyArgsSchema: z.ZodType<Prisma.CaseTraceFindManyArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereInputSchema.optional(),
  orderBy: z.union([ CaseTraceOrderByWithRelationInputSchema.array(),CaseTraceOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseTraceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseTraceScalarFieldEnumSchema,CaseTraceScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseTraceAggregateArgsSchema: z.ZodType<Prisma.CaseTraceAggregateArgs> = z.object({
  where: CaseTraceWhereInputSchema.optional(),
  orderBy: z.union([ CaseTraceOrderByWithRelationInputSchema.array(),CaseTraceOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseTraceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseTraceGroupByArgsSchema: z.ZodType<Prisma.CaseTraceGroupByArgs> = z.object({
  where: CaseTraceWhereInputSchema.optional(),
  orderBy: z.union([ CaseTraceOrderByWithAggregationInputSchema.array(),CaseTraceOrderByWithAggregationInputSchema ]).optional(),
  by: CaseTraceScalarFieldEnumSchema.array(),
  having: CaseTraceScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseTraceFindUniqueArgsSchema: z.ZodType<Prisma.CaseTraceFindUniqueArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereUniqueInputSchema,
}).strict() ;

export const CaseTraceFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CaseTraceFindUniqueOrThrowArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereUniqueInputSchema,
}).strict() ;

export const CaseMessageFindFirstArgsSchema: z.ZodType<Prisma.CaseMessageFindFirstArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereInputSchema.optional(),
  orderBy: z.union([ CaseMessageOrderByWithRelationInputSchema.array(),CaseMessageOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseMessageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseMessageScalarFieldEnumSchema,CaseMessageScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseMessageFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CaseMessageFindFirstOrThrowArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereInputSchema.optional(),
  orderBy: z.union([ CaseMessageOrderByWithRelationInputSchema.array(),CaseMessageOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseMessageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseMessageScalarFieldEnumSchema,CaseMessageScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseMessageFindManyArgsSchema: z.ZodType<Prisma.CaseMessageFindManyArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereInputSchema.optional(),
  orderBy: z.union([ CaseMessageOrderByWithRelationInputSchema.array(),CaseMessageOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseMessageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CaseMessageScalarFieldEnumSchema,CaseMessageScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CaseMessageAggregateArgsSchema: z.ZodType<Prisma.CaseMessageAggregateArgs> = z.object({
  where: CaseMessageWhereInputSchema.optional(),
  orderBy: z.union([ CaseMessageOrderByWithRelationInputSchema.array(),CaseMessageOrderByWithRelationInputSchema ]).optional(),
  cursor: CaseMessageWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseMessageGroupByArgsSchema: z.ZodType<Prisma.CaseMessageGroupByArgs> = z.object({
  where: CaseMessageWhereInputSchema.optional(),
  orderBy: z.union([ CaseMessageOrderByWithAggregationInputSchema.array(),CaseMessageOrderByWithAggregationInputSchema ]).optional(),
  by: CaseMessageScalarFieldEnumSchema.array(),
  having: CaseMessageScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CaseMessageFindUniqueArgsSchema: z.ZodType<Prisma.CaseMessageFindUniqueArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereUniqueInputSchema,
}).strict() ;

export const CaseMessageFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CaseMessageFindUniqueOrThrowArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereUniqueInputSchema,
}).strict() ;

export const CompanyFindFirstArgsSchema: z.ZodType<Prisma.CompanyFindFirstArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyScalarFieldEnumSchema,CompanyScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CompanyFindFirstOrThrowArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyScalarFieldEnumSchema,CompanyScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyFindManyArgsSchema: z.ZodType<Prisma.CompanyFindManyArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyScalarFieldEnumSchema,CompanyScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyAggregateArgsSchema: z.ZodType<Prisma.CompanyAggregateArgs> = z.object({
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithRelationInputSchema.array(),CompanyOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyGroupByArgsSchema: z.ZodType<Prisma.CompanyGroupByArgs> = z.object({
  where: CompanyWhereInputSchema.optional(),
  orderBy: z.union([ CompanyOrderByWithAggregationInputSchema.array(),CompanyOrderByWithAggregationInputSchema ]).optional(),
  by: CompanyScalarFieldEnumSchema.array(),
  having: CompanyScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyFindUniqueArgsSchema: z.ZodType<Prisma.CompanyFindUniqueArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
}).strict() ;

export const CompanyFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CompanyFindUniqueOrThrowArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
}).strict() ;

export const CompanyRealEstateLoanChargeFindFirstArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeFindFirstArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CompanyRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyRealEstateLoanChargeScalarFieldEnumSchema,CompanyRealEstateLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeFindFirstOrThrowArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CompanyRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyRealEstateLoanChargeScalarFieldEnumSchema,CompanyRealEstateLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeFindManyArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeFindManyArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CompanyRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyRealEstateLoanChargeScalarFieldEnumSchema,CompanyRealEstateLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeAggregateArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeAggregateArgs> = z.object({
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CompanyRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeGroupByArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeGroupByArgs> = z.object({
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRealEstateLoanChargeOrderByWithAggregationInputSchema.array(),CompanyRealEstateLoanChargeOrderByWithAggregationInputSchema ]).optional(),
  by: CompanyRealEstateLoanChargeScalarFieldEnumSchema.array(),
  having: CompanyRealEstateLoanChargeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeFindUniqueArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeFindUniqueArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRealEstateLoanChargeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeFindUniqueOrThrowArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRentIncomeFindFirstArgsSchema: z.ZodType<Prisma.CompanyRentIncomeFindFirstArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRentIncomeOrderByWithRelationInputSchema.array(),CompanyRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyRentIncomeScalarFieldEnumSchema,CompanyRentIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyRentIncomeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CompanyRentIncomeFindFirstOrThrowArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRentIncomeOrderByWithRelationInputSchema.array(),CompanyRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyRentIncomeScalarFieldEnumSchema,CompanyRentIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyRentIncomeFindManyArgsSchema: z.ZodType<Prisma.CompanyRentIncomeFindManyArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRentIncomeOrderByWithRelationInputSchema.array(),CompanyRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyRentIncomeScalarFieldEnumSchema,CompanyRentIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyRentIncomeAggregateArgsSchema: z.ZodType<Prisma.CompanyRentIncomeAggregateArgs> = z.object({
  where: CompanyRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRentIncomeOrderByWithRelationInputSchema.array(),CompanyRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyRentIncomeGroupByArgsSchema: z.ZodType<Prisma.CompanyRentIncomeGroupByArgs> = z.object({
  where: CompanyRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CompanyRentIncomeOrderByWithAggregationInputSchema.array(),CompanyRentIncomeOrderByWithAggregationInputSchema ]).optional(),
  by: CompanyRentIncomeScalarFieldEnumSchema.array(),
  having: CompanyRentIncomeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyRentIncomeFindUniqueArgsSchema: z.ZodType<Prisma.CompanyRentIncomeFindUniqueArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRentIncomeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CompanyRentIncomeFindUniqueOrThrowArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CompanyFinancialDataFindFirstArgsSchema: z.ZodType<Prisma.CompanyFinancialDataFindFirstArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ CompanyFinancialDataOrderByWithRelationInputSchema.array(),CompanyFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyFinancialDataScalarFieldEnumSchema,CompanyFinancialDataScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyFinancialDataFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CompanyFinancialDataFindFirstOrThrowArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ CompanyFinancialDataOrderByWithRelationInputSchema.array(),CompanyFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyFinancialDataScalarFieldEnumSchema,CompanyFinancialDataScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyFinancialDataFindManyArgsSchema: z.ZodType<Prisma.CompanyFinancialDataFindManyArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ CompanyFinancialDataOrderByWithRelationInputSchema.array(),CompanyFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyFinancialDataScalarFieldEnumSchema,CompanyFinancialDataScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyFinancialDataAggregateArgsSchema: z.ZodType<Prisma.CompanyFinancialDataAggregateArgs> = z.object({
  where: CompanyFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ CompanyFinancialDataOrderByWithRelationInputSchema.array(),CompanyFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyFinancialDataGroupByArgsSchema: z.ZodType<Prisma.CompanyFinancialDataGroupByArgs> = z.object({
  where: CompanyFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ CompanyFinancialDataOrderByWithAggregationInputSchema.array(),CompanyFinancialDataOrderByWithAggregationInputSchema ]).optional(),
  by: CompanyFinancialDataScalarFieldEnumSchema.array(),
  having: CompanyFinancialDataScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyFinancialDataFindUniqueArgsSchema: z.ZodType<Prisma.CompanyFinancialDataFindUniqueArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const CompanyFinancialDataFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CompanyFinancialDataFindUniqueOrThrowArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const CustomerFindFirstArgsSchema: z.ZodType<Prisma.CustomerFindFirstArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereInputSchema.optional(),
  orderBy: z.union([ CustomerOrderByWithRelationInputSchema.array(),CustomerOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerScalarFieldEnumSchema,CustomerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerFindFirstOrThrowArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereInputSchema.optional(),
  orderBy: z.union([ CustomerOrderByWithRelationInputSchema.array(),CustomerOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerScalarFieldEnumSchema,CustomerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerFindManyArgsSchema: z.ZodType<Prisma.CustomerFindManyArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereInputSchema.optional(),
  orderBy: z.union([ CustomerOrderByWithRelationInputSchema.array(),CustomerOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerScalarFieldEnumSchema,CustomerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAggregateArgsSchema: z.ZodType<Prisma.CustomerAggregateArgs> = z.object({
  where: CustomerWhereInputSchema.optional(),
  orderBy: z.union([ CustomerOrderByWithRelationInputSchema.array(),CustomerOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerGroupByArgsSchema: z.ZodType<Prisma.CustomerGroupByArgs> = z.object({
  where: CustomerWhereInputSchema.optional(),
  orderBy: z.union([ CustomerOrderByWithAggregationInputSchema.array(),CustomerOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerScalarFieldEnumSchema.array(),
  having: CustomerScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerFindUniqueArgsSchema: z.ZodType<Prisma.CustomerFindUniqueArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereUniqueInputSchema,
}).strict() ;

export const CustomerFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerFindUniqueOrThrowArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereUniqueInputSchema,
}).strict() ;

export const CustomerEmployeeFindFirstArgsSchema: z.ZodType<Prisma.CustomerEmployeeFindFirstArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerEmployeeOrderByWithRelationInputSchema.array(),CustomerEmployeeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerEmployeeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerEmployeeScalarFieldEnumSchema,CustomerEmployeeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerEmployeeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerEmployeeFindFirstOrThrowArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerEmployeeOrderByWithRelationInputSchema.array(),CustomerEmployeeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerEmployeeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerEmployeeScalarFieldEnumSchema,CustomerEmployeeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerEmployeeFindManyArgsSchema: z.ZodType<Prisma.CustomerEmployeeFindManyArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerEmployeeOrderByWithRelationInputSchema.array(),CustomerEmployeeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerEmployeeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerEmployeeScalarFieldEnumSchema,CustomerEmployeeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerEmployeeAggregateArgsSchema: z.ZodType<Prisma.CustomerEmployeeAggregateArgs> = z.object({
  where: CustomerEmployeeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerEmployeeOrderByWithRelationInputSchema.array(),CustomerEmployeeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerEmployeeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerEmployeeGroupByArgsSchema: z.ZodType<Prisma.CustomerEmployeeGroupByArgs> = z.object({
  where: CustomerEmployeeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerEmployeeOrderByWithAggregationInputSchema.array(),CustomerEmployeeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerEmployeeScalarFieldEnumSchema.array(),
  having: CustomerEmployeeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerEmployeeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerEmployeeFindUniqueArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereUniqueInputSchema,
}).strict() ;

export const CustomerEmployeeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerEmployeeFindUniqueOrThrowArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRetiredFindFirstArgsSchema: z.ZodType<Prisma.CustomerRetiredFindFirstArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRetiredOrderByWithRelationInputSchema.array(),CustomerRetiredOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRetiredWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRetiredScalarFieldEnumSchema,CustomerRetiredScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRetiredFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerRetiredFindFirstOrThrowArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRetiredOrderByWithRelationInputSchema.array(),CustomerRetiredOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRetiredWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRetiredScalarFieldEnumSchema,CustomerRetiredScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRetiredFindManyArgsSchema: z.ZodType<Prisma.CustomerRetiredFindManyArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRetiredOrderByWithRelationInputSchema.array(),CustomerRetiredOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRetiredWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRetiredScalarFieldEnumSchema,CustomerRetiredScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRetiredAggregateArgsSchema: z.ZodType<Prisma.CustomerRetiredAggregateArgs> = z.object({
  where: CustomerRetiredWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRetiredOrderByWithRelationInputSchema.array(),CustomerRetiredOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRetiredWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRetiredGroupByArgsSchema: z.ZodType<Prisma.CustomerRetiredGroupByArgs> = z.object({
  where: CustomerRetiredWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRetiredOrderByWithAggregationInputSchema.array(),CustomerRetiredOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerRetiredScalarFieldEnumSchema.array(),
  having: CustomerRetiredScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRetiredFindUniqueArgsSchema: z.ZodType<Prisma.CustomerRetiredFindUniqueArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereUniqueInputSchema,
}).strict() ;

export const CustomerRetiredFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerRetiredFindUniqueOrThrowArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereUniqueInputSchema,
}).strict() ;

export const CustomerSelfEmployedFindFirstArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedFindFirstArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereInputSchema.optional(),
  orderBy: z.union([ CustomerSelfEmployedOrderByWithRelationInputSchema.array(),CustomerSelfEmployedOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerSelfEmployedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerSelfEmployedScalarFieldEnumSchema,CustomerSelfEmployedScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerSelfEmployedFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedFindFirstOrThrowArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereInputSchema.optional(),
  orderBy: z.union([ CustomerSelfEmployedOrderByWithRelationInputSchema.array(),CustomerSelfEmployedOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerSelfEmployedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerSelfEmployedScalarFieldEnumSchema,CustomerSelfEmployedScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerSelfEmployedFindManyArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedFindManyArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereInputSchema.optional(),
  orderBy: z.union([ CustomerSelfEmployedOrderByWithRelationInputSchema.array(),CustomerSelfEmployedOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerSelfEmployedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerSelfEmployedScalarFieldEnumSchema,CustomerSelfEmployedScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerSelfEmployedAggregateArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedAggregateArgs> = z.object({
  where: CustomerSelfEmployedWhereInputSchema.optional(),
  orderBy: z.union([ CustomerSelfEmployedOrderByWithRelationInputSchema.array(),CustomerSelfEmployedOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerSelfEmployedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerSelfEmployedGroupByArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedGroupByArgs> = z.object({
  where: CustomerSelfEmployedWhereInputSchema.optional(),
  orderBy: z.union([ CustomerSelfEmployedOrderByWithAggregationInputSchema.array(),CustomerSelfEmployedOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerSelfEmployedScalarFieldEnumSchema.array(),
  having: CustomerSelfEmployedScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerSelfEmployedFindUniqueArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedFindUniqueArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereUniqueInputSchema,
}).strict() ;

export const CustomerSelfEmployedFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedFindUniqueOrThrowArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereUniqueInputSchema,
}).strict() ;

export const CustomerInvestmentFindFirstArgsSchema: z.ZodType<Prisma.CustomerInvestmentFindFirstArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereInputSchema.optional(),
  orderBy: z.union([ CustomerInvestmentOrderByWithRelationInputSchema.array(),CustomerInvestmentOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerInvestmentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerInvestmentScalarFieldEnumSchema,CustomerInvestmentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerInvestmentFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerInvestmentFindFirstOrThrowArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereInputSchema.optional(),
  orderBy: z.union([ CustomerInvestmentOrderByWithRelationInputSchema.array(),CustomerInvestmentOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerInvestmentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerInvestmentScalarFieldEnumSchema,CustomerInvestmentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerInvestmentFindManyArgsSchema: z.ZodType<Prisma.CustomerInvestmentFindManyArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereInputSchema.optional(),
  orderBy: z.union([ CustomerInvestmentOrderByWithRelationInputSchema.array(),CustomerInvestmentOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerInvestmentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerInvestmentScalarFieldEnumSchema,CustomerInvestmentScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerInvestmentAggregateArgsSchema: z.ZodType<Prisma.CustomerInvestmentAggregateArgs> = z.object({
  where: CustomerInvestmentWhereInputSchema.optional(),
  orderBy: z.union([ CustomerInvestmentOrderByWithRelationInputSchema.array(),CustomerInvestmentOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerInvestmentWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerInvestmentGroupByArgsSchema: z.ZodType<Prisma.CustomerInvestmentGroupByArgs> = z.object({
  where: CustomerInvestmentWhereInputSchema.optional(),
  orderBy: z.union([ CustomerInvestmentOrderByWithAggregationInputSchema.array(),CustomerInvestmentOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerInvestmentScalarFieldEnumSchema.array(),
  having: CustomerInvestmentScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerInvestmentFindUniqueArgsSchema: z.ZodType<Prisma.CustomerInvestmentFindUniqueArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereUniqueInputSchema,
}).strict() ;

export const CustomerInvestmentFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerInvestmentFindUniqueOrThrowArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalIncomeFindFirstArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeFindFirstArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalIncomeOrderByWithRelationInputSchema.array(),CustomerAdditionalIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerAdditionalIncomeScalarFieldEnumSchema,CustomerAdditionalIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAdditionalIncomeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeFindFirstOrThrowArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalIncomeOrderByWithRelationInputSchema.array(),CustomerAdditionalIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerAdditionalIncomeScalarFieldEnumSchema,CustomerAdditionalIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAdditionalIncomeFindManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeFindManyArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalIncomeOrderByWithRelationInputSchema.array(),CustomerAdditionalIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerAdditionalIncomeScalarFieldEnumSchema,CustomerAdditionalIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAdditionalIncomeAggregateArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeAggregateArgs> = z.object({
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalIncomeOrderByWithRelationInputSchema.array(),CustomerAdditionalIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerAdditionalIncomeGroupByArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeGroupByArgs> = z.object({
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalIncomeOrderByWithAggregationInputSchema.array(),CustomerAdditionalIncomeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerAdditionalIncomeScalarFieldEnumSchema.array(),
  having: CustomerAdditionalIncomeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerAdditionalIncomeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeFindUniqueArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalIncomeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeFindUniqueOrThrowArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentIncomeFindFirstArgsSchema: z.ZodType<Prisma.CustomerRentIncomeFindFirstArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentIncomeOrderByWithRelationInputSchema.array(),CustomerRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRentIncomeScalarFieldEnumSchema,CustomerRentIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRentIncomeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerRentIncomeFindFirstOrThrowArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentIncomeOrderByWithRelationInputSchema.array(),CustomerRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRentIncomeScalarFieldEnumSchema,CustomerRentIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRentIncomeFindManyArgsSchema: z.ZodType<Prisma.CustomerRentIncomeFindManyArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentIncomeOrderByWithRelationInputSchema.array(),CustomerRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRentIncomeScalarFieldEnumSchema,CustomerRentIncomeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRentIncomeAggregateArgsSchema: z.ZodType<Prisma.CustomerRentIncomeAggregateArgs> = z.object({
  where: CustomerRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentIncomeOrderByWithRelationInputSchema.array(),CustomerRentIncomeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentIncomeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRentIncomeGroupByArgsSchema: z.ZodType<Prisma.CustomerRentIncomeGroupByArgs> = z.object({
  where: CustomerRentIncomeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentIncomeOrderByWithAggregationInputSchema.array(),CustomerRentIncomeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerRentIncomeScalarFieldEnumSchema.array(),
  having: CustomerRentIncomeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRentIncomeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerRentIncomeFindUniqueArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentIncomeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerRentIncomeFindUniqueOrThrowArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentChargeFindFirstArgsSchema: z.ZodType<Prisma.CustomerRentChargeFindFirstArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentChargeOrderByWithRelationInputSchema.array(),CustomerRentChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRentChargeScalarFieldEnumSchema,CustomerRentChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRentChargeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerRentChargeFindFirstOrThrowArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentChargeOrderByWithRelationInputSchema.array(),CustomerRentChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRentChargeScalarFieldEnumSchema,CustomerRentChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRentChargeFindManyArgsSchema: z.ZodType<Prisma.CustomerRentChargeFindManyArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentChargeOrderByWithRelationInputSchema.array(),CustomerRentChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRentChargeScalarFieldEnumSchema,CustomerRentChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRentChargeAggregateArgsSchema: z.ZodType<Prisma.CustomerRentChargeAggregateArgs> = z.object({
  where: CustomerRentChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentChargeOrderByWithRelationInputSchema.array(),CustomerRentChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRentChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRentChargeGroupByArgsSchema: z.ZodType<Prisma.CustomerRentChargeGroupByArgs> = z.object({
  where: CustomerRentChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRentChargeOrderByWithAggregationInputSchema.array(),CustomerRentChargeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerRentChargeScalarFieldEnumSchema.array(),
  having: CustomerRentChargeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRentChargeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerRentChargeFindUniqueArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentChargeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerRentChargeFindUniqueOrThrowArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerConsumerLoanChargeFindFirstArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeFindFirstArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerConsumerLoanChargeOrderByWithRelationInputSchema.array(),CustomerConsumerLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerConsumerLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerConsumerLoanChargeScalarFieldEnumSchema,CustomerConsumerLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerConsumerLoanChargeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeFindFirstOrThrowArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerConsumerLoanChargeOrderByWithRelationInputSchema.array(),CustomerConsumerLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerConsumerLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerConsumerLoanChargeScalarFieldEnumSchema,CustomerConsumerLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerConsumerLoanChargeFindManyArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeFindManyArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerConsumerLoanChargeOrderByWithRelationInputSchema.array(),CustomerConsumerLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerConsumerLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerConsumerLoanChargeScalarFieldEnumSchema,CustomerConsumerLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerConsumerLoanChargeAggregateArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeAggregateArgs> = z.object({
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerConsumerLoanChargeOrderByWithRelationInputSchema.array(),CustomerConsumerLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerConsumerLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerConsumerLoanChargeGroupByArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeGroupByArgs> = z.object({
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerConsumerLoanChargeOrderByWithAggregationInputSchema.array(),CustomerConsumerLoanChargeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerConsumerLoanChargeScalarFieldEnumSchema.array(),
  having: CustomerConsumerLoanChargeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerConsumerLoanChargeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeFindUniqueArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerConsumerLoanChargeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeFindUniqueOrThrowArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRealEstateLoanChargeFindFirstArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeFindFirstArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CustomerRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRealEstateLoanChargeScalarFieldEnumSchema,CustomerRealEstateLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeFindFirstOrThrowArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CustomerRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRealEstateLoanChargeScalarFieldEnumSchema,CustomerRealEstateLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeFindManyArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeFindManyArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CustomerRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerRealEstateLoanChargeScalarFieldEnumSchema,CustomerRealEstateLoanChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeAggregateArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeAggregateArgs> = z.object({
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRealEstateLoanChargeOrderByWithRelationInputSchema.array(),CustomerRealEstateLoanChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerRealEstateLoanChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeGroupByArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeGroupByArgs> = z.object({
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerRealEstateLoanChargeOrderByWithAggregationInputSchema.array(),CustomerRealEstateLoanChargeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerRealEstateLoanChargeScalarFieldEnumSchema.array(),
  having: CustomerRealEstateLoanChargeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeFindUniqueArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRealEstateLoanChargeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeFindUniqueOrThrowArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalChargeFindFirstArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeFindFirstArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalChargeOrderByWithRelationInputSchema.array(),CustomerAdditionalChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerAdditionalChargeScalarFieldEnumSchema,CustomerAdditionalChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAdditionalChargeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeFindFirstOrThrowArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalChargeOrderByWithRelationInputSchema.array(),CustomerAdditionalChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerAdditionalChargeScalarFieldEnumSchema,CustomerAdditionalChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAdditionalChargeFindManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeFindManyArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalChargeOrderByWithRelationInputSchema.array(),CustomerAdditionalChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CustomerAdditionalChargeScalarFieldEnumSchema,CustomerAdditionalChargeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CustomerAdditionalChargeAggregateArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeAggregateArgs> = z.object({
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalChargeOrderByWithRelationInputSchema.array(),CustomerAdditionalChargeOrderByWithRelationInputSchema ]).optional(),
  cursor: CustomerAdditionalChargeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerAdditionalChargeGroupByArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeGroupByArgs> = z.object({
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
  orderBy: z.union([ CustomerAdditionalChargeOrderByWithAggregationInputSchema.array(),CustomerAdditionalChargeOrderByWithAggregationInputSchema ]).optional(),
  by: CustomerAdditionalChargeScalarFieldEnumSchema.array(),
  having: CustomerAdditionalChargeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CustomerAdditionalChargeFindUniqueArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeFindUniqueArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalChargeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeFindUniqueOrThrowArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereUniqueInputSchema,
}).strict() ;

export const NotificationFindFirstArgsSchema: z.ZodType<Prisma.NotificationFindFirstArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereInputSchema.optional(),
  orderBy: z.union([ NotificationOrderByWithRelationInputSchema.array(),NotificationOrderByWithRelationInputSchema ]).optional(),
  cursor: NotificationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ NotificationScalarFieldEnumSchema,NotificationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const NotificationFindFirstOrThrowArgsSchema: z.ZodType<Prisma.NotificationFindFirstOrThrowArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereInputSchema.optional(),
  orderBy: z.union([ NotificationOrderByWithRelationInputSchema.array(),NotificationOrderByWithRelationInputSchema ]).optional(),
  cursor: NotificationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ NotificationScalarFieldEnumSchema,NotificationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const NotificationFindManyArgsSchema: z.ZodType<Prisma.NotificationFindManyArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereInputSchema.optional(),
  orderBy: z.union([ NotificationOrderByWithRelationInputSchema.array(),NotificationOrderByWithRelationInputSchema ]).optional(),
  cursor: NotificationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ NotificationScalarFieldEnumSchema,NotificationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const NotificationAggregateArgsSchema: z.ZodType<Prisma.NotificationAggregateArgs> = z.object({
  where: NotificationWhereInputSchema.optional(),
  orderBy: z.union([ NotificationOrderByWithRelationInputSchema.array(),NotificationOrderByWithRelationInputSchema ]).optional(),
  cursor: NotificationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const NotificationGroupByArgsSchema: z.ZodType<Prisma.NotificationGroupByArgs> = z.object({
  where: NotificationWhereInputSchema.optional(),
  orderBy: z.union([ NotificationOrderByWithAggregationInputSchema.array(),NotificationOrderByWithAggregationInputSchema ]).optional(),
  by: NotificationScalarFieldEnumSchema.array(),
  having: NotificationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const NotificationFindUniqueArgsSchema: z.ZodType<Prisma.NotificationFindUniqueArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereUniqueInputSchema,
}).strict() ;

export const NotificationFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.NotificationFindUniqueOrThrowArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereUniqueInputSchema,
}).strict() ;

export const CompanyPappersFindFirstArgsSchema: z.ZodType<Prisma.CompanyPappersFindFirstArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereInputSchema.optional(),
  orderBy: z.union([ CompanyPappersOrderByWithRelationInputSchema.array(),CompanyPappersOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyPappersWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyPappersScalarFieldEnumSchema,CompanyPappersScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyPappersFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CompanyPappersFindFirstOrThrowArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereInputSchema.optional(),
  orderBy: z.union([ CompanyPappersOrderByWithRelationInputSchema.array(),CompanyPappersOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyPappersWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyPappersScalarFieldEnumSchema,CompanyPappersScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyPappersFindManyArgsSchema: z.ZodType<Prisma.CompanyPappersFindManyArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereInputSchema.optional(),
  orderBy: z.union([ CompanyPappersOrderByWithRelationInputSchema.array(),CompanyPappersOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyPappersWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CompanyPappersScalarFieldEnumSchema,CompanyPappersScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CompanyPappersAggregateArgsSchema: z.ZodType<Prisma.CompanyPappersAggregateArgs> = z.object({
  where: CompanyPappersWhereInputSchema.optional(),
  orderBy: z.union([ CompanyPappersOrderByWithRelationInputSchema.array(),CompanyPappersOrderByWithRelationInputSchema ]).optional(),
  cursor: CompanyPappersWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyPappersGroupByArgsSchema: z.ZodType<Prisma.CompanyPappersGroupByArgs> = z.object({
  where: CompanyPappersWhereInputSchema.optional(),
  orderBy: z.union([ CompanyPappersOrderByWithAggregationInputSchema.array(),CompanyPappersOrderByWithAggregationInputSchema ]).optional(),
  by: CompanyPappersScalarFieldEnumSchema.array(),
  having: CompanyPappersScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CompanyPappersFindUniqueArgsSchema: z.ZodType<Prisma.CompanyPappersFindUniqueArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereUniqueInputSchema,
}).strict() ;

export const CompanyPappersFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CompanyPappersFindUniqueOrThrowArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereUniqueInputSchema,
}).strict() ;

export const PappersRepresentativeFindFirstArgsSchema: z.ZodType<Prisma.PappersRepresentativeFindFirstArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereInputSchema.optional(),
  orderBy: z.union([ PappersRepresentativeOrderByWithRelationInputSchema.array(),PappersRepresentativeOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersRepresentativeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersRepresentativeScalarFieldEnumSchema,PappersRepresentativeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersRepresentativeFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PappersRepresentativeFindFirstOrThrowArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereInputSchema.optional(),
  orderBy: z.union([ PappersRepresentativeOrderByWithRelationInputSchema.array(),PappersRepresentativeOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersRepresentativeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersRepresentativeScalarFieldEnumSchema,PappersRepresentativeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersRepresentativeFindManyArgsSchema: z.ZodType<Prisma.PappersRepresentativeFindManyArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereInputSchema.optional(),
  orderBy: z.union([ PappersRepresentativeOrderByWithRelationInputSchema.array(),PappersRepresentativeOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersRepresentativeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersRepresentativeScalarFieldEnumSchema,PappersRepresentativeScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersRepresentativeAggregateArgsSchema: z.ZodType<Prisma.PappersRepresentativeAggregateArgs> = z.object({
  where: PappersRepresentativeWhereInputSchema.optional(),
  orderBy: z.union([ PappersRepresentativeOrderByWithRelationInputSchema.array(),PappersRepresentativeOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersRepresentativeWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersRepresentativeGroupByArgsSchema: z.ZodType<Prisma.PappersRepresentativeGroupByArgs> = z.object({
  where: PappersRepresentativeWhereInputSchema.optional(),
  orderBy: z.union([ PappersRepresentativeOrderByWithAggregationInputSchema.array(),PappersRepresentativeOrderByWithAggregationInputSchema ]).optional(),
  by: PappersRepresentativeScalarFieldEnumSchema.array(),
  having: PappersRepresentativeScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersRepresentativeFindUniqueArgsSchema: z.ZodType<Prisma.PappersRepresentativeFindUniqueArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereUniqueInputSchema,
}).strict() ;

export const PappersRepresentativeFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PappersRepresentativeFindUniqueOrThrowArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereUniqueInputSchema,
}).strict() ;

export const PappersBeneficiaryFindFirstArgsSchema: z.ZodType<Prisma.PappersBeneficiaryFindFirstArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereInputSchema.optional(),
  orderBy: z.union([ PappersBeneficiaryOrderByWithRelationInputSchema.array(),PappersBeneficiaryOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersBeneficiaryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersBeneficiaryScalarFieldEnumSchema,PappersBeneficiaryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersBeneficiaryFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PappersBeneficiaryFindFirstOrThrowArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereInputSchema.optional(),
  orderBy: z.union([ PappersBeneficiaryOrderByWithRelationInputSchema.array(),PappersBeneficiaryOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersBeneficiaryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersBeneficiaryScalarFieldEnumSchema,PappersBeneficiaryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersBeneficiaryFindManyArgsSchema: z.ZodType<Prisma.PappersBeneficiaryFindManyArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereInputSchema.optional(),
  orderBy: z.union([ PappersBeneficiaryOrderByWithRelationInputSchema.array(),PappersBeneficiaryOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersBeneficiaryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersBeneficiaryScalarFieldEnumSchema,PappersBeneficiaryScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersBeneficiaryAggregateArgsSchema: z.ZodType<Prisma.PappersBeneficiaryAggregateArgs> = z.object({
  where: PappersBeneficiaryWhereInputSchema.optional(),
  orderBy: z.union([ PappersBeneficiaryOrderByWithRelationInputSchema.array(),PappersBeneficiaryOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersBeneficiaryWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersBeneficiaryGroupByArgsSchema: z.ZodType<Prisma.PappersBeneficiaryGroupByArgs> = z.object({
  where: PappersBeneficiaryWhereInputSchema.optional(),
  orderBy: z.union([ PappersBeneficiaryOrderByWithAggregationInputSchema.array(),PappersBeneficiaryOrderByWithAggregationInputSchema ]).optional(),
  by: PappersBeneficiaryScalarFieldEnumSchema.array(),
  having: PappersBeneficiaryScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersBeneficiaryFindUniqueArgsSchema: z.ZodType<Prisma.PappersBeneficiaryFindUniqueArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereUniqueInputSchema,
}).strict() ;

export const PappersBeneficiaryFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PappersBeneficiaryFindUniqueOrThrowArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveConventionFindFirstArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionFindFirstArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveConventionOrderByWithRelationInputSchema.array(),PappersCollectiveConventionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveConventionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersCollectiveConventionScalarFieldEnumSchema,PappersCollectiveConventionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersCollectiveConventionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionFindFirstOrThrowArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveConventionOrderByWithRelationInputSchema.array(),PappersCollectiveConventionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveConventionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersCollectiveConventionScalarFieldEnumSchema,PappersCollectiveConventionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersCollectiveConventionFindManyArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionFindManyArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveConventionOrderByWithRelationInputSchema.array(),PappersCollectiveConventionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveConventionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersCollectiveConventionScalarFieldEnumSchema,PappersCollectiveConventionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersCollectiveConventionAggregateArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionAggregateArgs> = z.object({
  where: PappersCollectiveConventionWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveConventionOrderByWithRelationInputSchema.array(),PappersCollectiveConventionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveConventionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersCollectiveConventionGroupByArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionGroupByArgs> = z.object({
  where: PappersCollectiveConventionWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveConventionOrderByWithAggregationInputSchema.array(),PappersCollectiveConventionOrderByWithAggregationInputSchema ]).optional(),
  by: PappersCollectiveConventionScalarFieldEnumSchema.array(),
  having: PappersCollectiveConventionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersCollectiveConventionFindUniqueArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionFindUniqueArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveConventionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionFindUniqueOrThrowArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveProcedureFindFirstArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureFindFirstArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveProcedureOrderByWithRelationInputSchema.array(),PappersCollectiveProcedureOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveProcedureWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersCollectiveProcedureScalarFieldEnumSchema,PappersCollectiveProcedureScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersCollectiveProcedureFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureFindFirstOrThrowArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveProcedureOrderByWithRelationInputSchema.array(),PappersCollectiveProcedureOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveProcedureWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersCollectiveProcedureScalarFieldEnumSchema,PappersCollectiveProcedureScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersCollectiveProcedureFindManyArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureFindManyArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveProcedureOrderByWithRelationInputSchema.array(),PappersCollectiveProcedureOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveProcedureWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersCollectiveProcedureScalarFieldEnumSchema,PappersCollectiveProcedureScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersCollectiveProcedureAggregateArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureAggregateArgs> = z.object({
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveProcedureOrderByWithRelationInputSchema.array(),PappersCollectiveProcedureOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersCollectiveProcedureWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersCollectiveProcedureGroupByArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureGroupByArgs> = z.object({
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
  orderBy: z.union([ PappersCollectiveProcedureOrderByWithAggregationInputSchema.array(),PappersCollectiveProcedureOrderByWithAggregationInputSchema ]).optional(),
  by: PappersCollectiveProcedureScalarFieldEnumSchema.array(),
  having: PappersCollectiveProcedureScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersCollectiveProcedureFindUniqueArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureFindUniqueArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveProcedureFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureFindUniqueOrThrowArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereUniqueInputSchema,
}).strict() ;

export const PappersFinancialDataFindFirstArgsSchema: z.ZodType<Prisma.PappersFinancialDataFindFirstArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ PappersFinancialDataOrderByWithRelationInputSchema.array(),PappersFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersFinancialDataScalarFieldEnumSchema,PappersFinancialDataScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersFinancialDataFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PappersFinancialDataFindFirstOrThrowArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ PappersFinancialDataOrderByWithRelationInputSchema.array(),PappersFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersFinancialDataScalarFieldEnumSchema,PappersFinancialDataScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersFinancialDataFindManyArgsSchema: z.ZodType<Prisma.PappersFinancialDataFindManyArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ PappersFinancialDataOrderByWithRelationInputSchema.array(),PappersFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersFinancialDataScalarFieldEnumSchema,PappersFinancialDataScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersFinancialDataAggregateArgsSchema: z.ZodType<Prisma.PappersFinancialDataAggregateArgs> = z.object({
  where: PappersFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ PappersFinancialDataOrderByWithRelationInputSchema.array(),PappersFinancialDataOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersFinancialDataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersFinancialDataGroupByArgsSchema: z.ZodType<Prisma.PappersFinancialDataGroupByArgs> = z.object({
  where: PappersFinancialDataWhereInputSchema.optional(),
  orderBy: z.union([ PappersFinancialDataOrderByWithAggregationInputSchema.array(),PappersFinancialDataOrderByWithAggregationInputSchema ]).optional(),
  by: PappersFinancialDataScalarFieldEnumSchema.array(),
  having: PappersFinancialDataScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersFinancialDataFindUniqueArgsSchema: z.ZodType<Prisma.PappersFinancialDataFindUniqueArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const PappersFinancialDataFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PappersFinancialDataFindUniqueOrThrowArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const PappersSanctionFindFirstArgsSchema: z.ZodType<Prisma.PappersSanctionFindFirstArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereInputSchema.optional(),
  orderBy: z.union([ PappersSanctionOrderByWithRelationInputSchema.array(),PappersSanctionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersSanctionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersSanctionScalarFieldEnumSchema,PappersSanctionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersSanctionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PappersSanctionFindFirstOrThrowArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereInputSchema.optional(),
  orderBy: z.union([ PappersSanctionOrderByWithRelationInputSchema.array(),PappersSanctionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersSanctionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersSanctionScalarFieldEnumSchema,PappersSanctionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersSanctionFindManyArgsSchema: z.ZodType<Prisma.PappersSanctionFindManyArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereInputSchema.optional(),
  orderBy: z.union([ PappersSanctionOrderByWithRelationInputSchema.array(),PappersSanctionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersSanctionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PappersSanctionScalarFieldEnumSchema,PappersSanctionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PappersSanctionAggregateArgsSchema: z.ZodType<Prisma.PappersSanctionAggregateArgs> = z.object({
  where: PappersSanctionWhereInputSchema.optional(),
  orderBy: z.union([ PappersSanctionOrderByWithRelationInputSchema.array(),PappersSanctionOrderByWithRelationInputSchema ]).optional(),
  cursor: PappersSanctionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersSanctionGroupByArgsSchema: z.ZodType<Prisma.PappersSanctionGroupByArgs> = z.object({
  where: PappersSanctionWhereInputSchema.optional(),
  orderBy: z.union([ PappersSanctionOrderByWithAggregationInputSchema.array(),PappersSanctionOrderByWithAggregationInputSchema ]).optional(),
  by: PappersSanctionScalarFieldEnumSchema.array(),
  having: PappersSanctionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PappersSanctionFindUniqueArgsSchema: z.ZodType<Prisma.PappersSanctionFindUniqueArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereUniqueInputSchema,
}).strict() ;

export const PappersSanctionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PappersSanctionFindUniqueOrThrowArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereUniqueInputSchema,
}).strict() ;

export const PartnerFindFirstArgsSchema: z.ZodType<Prisma.PartnerFindFirstArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereInputSchema.optional(),
  orderBy: z.union([ PartnerOrderByWithRelationInputSchema.array(),PartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerScalarFieldEnumSchema,PartnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PartnerFindFirstOrThrowArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereInputSchema.optional(),
  orderBy: z.union([ PartnerOrderByWithRelationInputSchema.array(),PartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerScalarFieldEnumSchema,PartnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerFindManyArgsSchema: z.ZodType<Prisma.PartnerFindManyArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereInputSchema.optional(),
  orderBy: z.union([ PartnerOrderByWithRelationInputSchema.array(),PartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerScalarFieldEnumSchema,PartnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerAggregateArgsSchema: z.ZodType<Prisma.PartnerAggregateArgs> = z.object({
  where: PartnerWhereInputSchema.optional(),
  orderBy: z.union([ PartnerOrderByWithRelationInputSchema.array(),PartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerGroupByArgsSchema: z.ZodType<Prisma.PartnerGroupByArgs> = z.object({
  where: PartnerWhereInputSchema.optional(),
  orderBy: z.union([ PartnerOrderByWithAggregationInputSchema.array(),PartnerOrderByWithAggregationInputSchema ]).optional(),
  by: PartnerScalarFieldEnumSchema.array(),
  having: PartnerScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerFindUniqueArgsSchema: z.ZodType<Prisma.PartnerFindUniqueArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereUniqueInputSchema,
}).strict() ;

export const PartnerFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PartnerFindUniqueOrThrowArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereUniqueInputSchema,
}).strict() ;

export const PartnerCollaboratorFindFirstArgsSchema: z.ZodType<Prisma.PartnerCollaboratorFindFirstArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ PartnerCollaboratorOrderByWithRelationInputSchema.array(),PartnerCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerCollaboratorScalarFieldEnumSchema,PartnerCollaboratorScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerCollaboratorFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PartnerCollaboratorFindFirstOrThrowArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ PartnerCollaboratorOrderByWithRelationInputSchema.array(),PartnerCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerCollaboratorScalarFieldEnumSchema,PartnerCollaboratorScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerCollaboratorFindManyArgsSchema: z.ZodType<Prisma.PartnerCollaboratorFindManyArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ PartnerCollaboratorOrderByWithRelationInputSchema.array(),PartnerCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerCollaboratorScalarFieldEnumSchema,PartnerCollaboratorScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerCollaboratorAggregateArgsSchema: z.ZodType<Prisma.PartnerCollaboratorAggregateArgs> = z.object({
  where: PartnerCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ PartnerCollaboratorOrderByWithRelationInputSchema.array(),PartnerCollaboratorOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerCollaboratorWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerCollaboratorGroupByArgsSchema: z.ZodType<Prisma.PartnerCollaboratorGroupByArgs> = z.object({
  where: PartnerCollaboratorWhereInputSchema.optional(),
  orderBy: z.union([ PartnerCollaboratorOrderByWithAggregationInputSchema.array(),PartnerCollaboratorOrderByWithAggregationInputSchema ]).optional(),
  by: PartnerCollaboratorScalarFieldEnumSchema.array(),
  having: PartnerCollaboratorScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerCollaboratorFindUniqueArgsSchema: z.ZodType<Prisma.PartnerCollaboratorFindUniqueArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const PartnerCollaboratorFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PartnerCollaboratorFindUniqueOrThrowArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const PartnerRateFindFirstArgsSchema: z.ZodType<Prisma.PartnerRateFindFirstArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereInputSchema.optional(),
  orderBy: z.union([ PartnerRateOrderByWithRelationInputSchema.array(),PartnerRateOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerRateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerRateScalarFieldEnumSchema,PartnerRateScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerRateFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PartnerRateFindFirstOrThrowArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereInputSchema.optional(),
  orderBy: z.union([ PartnerRateOrderByWithRelationInputSchema.array(),PartnerRateOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerRateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerRateScalarFieldEnumSchema,PartnerRateScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerRateFindManyArgsSchema: z.ZodType<Prisma.PartnerRateFindManyArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereInputSchema.optional(),
  orderBy: z.union([ PartnerRateOrderByWithRelationInputSchema.array(),PartnerRateOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerRateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerRateScalarFieldEnumSchema,PartnerRateScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerRateAggregateArgsSchema: z.ZodType<Prisma.PartnerRateAggregateArgs> = z.object({
  where: PartnerRateWhereInputSchema.optional(),
  orderBy: z.union([ PartnerRateOrderByWithRelationInputSchema.array(),PartnerRateOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerRateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerRateGroupByArgsSchema: z.ZodType<Prisma.PartnerRateGroupByArgs> = z.object({
  where: PartnerRateWhereInputSchema.optional(),
  orderBy: z.union([ PartnerRateOrderByWithAggregationInputSchema.array(),PartnerRateOrderByWithAggregationInputSchema ]).optional(),
  by: PartnerRateScalarFieldEnumSchema.array(),
  having: PartnerRateScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerRateFindUniqueArgsSchema: z.ZodType<Prisma.PartnerRateFindUniqueArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereUniqueInputSchema,
}).strict() ;

export const PartnerRateFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PartnerRateFindUniqueOrThrowArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereUniqueInputSchema,
}).strict() ;

export const CasePartnerFindFirstArgsSchema: z.ZodType<Prisma.CasePartnerFindFirstArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereInputSchema.optional(),
  orderBy: z.union([ CasePartnerOrderByWithRelationInputSchema.array(),CasePartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: CasePartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CasePartnerScalarFieldEnumSchema,CasePartnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CasePartnerFindFirstOrThrowArgsSchema: z.ZodType<Prisma.CasePartnerFindFirstOrThrowArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereInputSchema.optional(),
  orderBy: z.union([ CasePartnerOrderByWithRelationInputSchema.array(),CasePartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: CasePartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CasePartnerScalarFieldEnumSchema,CasePartnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CasePartnerFindManyArgsSchema: z.ZodType<Prisma.CasePartnerFindManyArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereInputSchema.optional(),
  orderBy: z.union([ CasePartnerOrderByWithRelationInputSchema.array(),CasePartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: CasePartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ CasePartnerScalarFieldEnumSchema,CasePartnerScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const CasePartnerAggregateArgsSchema: z.ZodType<Prisma.CasePartnerAggregateArgs> = z.object({
  where: CasePartnerWhereInputSchema.optional(),
  orderBy: z.union([ CasePartnerOrderByWithRelationInputSchema.array(),CasePartnerOrderByWithRelationInputSchema ]).optional(),
  cursor: CasePartnerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CasePartnerGroupByArgsSchema: z.ZodType<Prisma.CasePartnerGroupByArgs> = z.object({
  where: CasePartnerWhereInputSchema.optional(),
  orderBy: z.union([ CasePartnerOrderByWithAggregationInputSchema.array(),CasePartnerOrderByWithAggregationInputSchema ]).optional(),
  by: CasePartnerScalarFieldEnumSchema.array(),
  having: CasePartnerScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const CasePartnerFindUniqueArgsSchema: z.ZodType<Prisma.CasePartnerFindUniqueArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereUniqueInputSchema,
}).strict() ;

export const CasePartnerFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.CasePartnerFindUniqueOrThrowArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereUniqueInputSchema,
}).strict() ;

export const PartnerPendingCaseFindFirstArgsSchema: z.ZodType<Prisma.PartnerPendingCaseFindFirstArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PartnerPendingCaseOrderByWithRelationInputSchema.array(),PartnerPendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerPendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerPendingCaseScalarFieldEnumSchema,PartnerPendingCaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerPendingCaseFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PartnerPendingCaseFindFirstOrThrowArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PartnerPendingCaseOrderByWithRelationInputSchema.array(),PartnerPendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerPendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerPendingCaseScalarFieldEnumSchema,PartnerPendingCaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerPendingCaseFindManyArgsSchema: z.ZodType<Prisma.PartnerPendingCaseFindManyArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PartnerPendingCaseOrderByWithRelationInputSchema.array(),PartnerPendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerPendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PartnerPendingCaseScalarFieldEnumSchema,PartnerPendingCaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PartnerPendingCaseAggregateArgsSchema: z.ZodType<Prisma.PartnerPendingCaseAggregateArgs> = z.object({
  where: PartnerPendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PartnerPendingCaseOrderByWithRelationInputSchema.array(),PartnerPendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PartnerPendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerPendingCaseGroupByArgsSchema: z.ZodType<Prisma.PartnerPendingCaseGroupByArgs> = z.object({
  where: PartnerPendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PartnerPendingCaseOrderByWithAggregationInputSchema.array(),PartnerPendingCaseOrderByWithAggregationInputSchema ]).optional(),
  by: PartnerPendingCaseScalarFieldEnumSchema.array(),
  having: PartnerPendingCaseScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PartnerPendingCaseFindUniqueArgsSchema: z.ZodType<Prisma.PartnerPendingCaseFindUniqueArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PartnerPendingCaseFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PartnerPendingCaseFindUniqueOrThrowArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PendingCaseFindFirstArgsSchema: z.ZodType<Prisma.PendingCaseFindFirstArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PendingCaseOrderByWithRelationInputSchema.array(),PendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PendingCaseScalarFieldEnumSchema,PendingCaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PendingCaseFindFirstOrThrowArgsSchema: z.ZodType<Prisma.PendingCaseFindFirstOrThrowArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PendingCaseOrderByWithRelationInputSchema.array(),PendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PendingCaseScalarFieldEnumSchema,PendingCaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PendingCaseFindManyArgsSchema: z.ZodType<Prisma.PendingCaseFindManyArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PendingCaseOrderByWithRelationInputSchema.array(),PendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ PendingCaseScalarFieldEnumSchema,PendingCaseScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const PendingCaseAggregateArgsSchema: z.ZodType<Prisma.PendingCaseAggregateArgs> = z.object({
  where: PendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PendingCaseOrderByWithRelationInputSchema.array(),PendingCaseOrderByWithRelationInputSchema ]).optional(),
  cursor: PendingCaseWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PendingCaseGroupByArgsSchema: z.ZodType<Prisma.PendingCaseGroupByArgs> = z.object({
  where: PendingCaseWhereInputSchema.optional(),
  orderBy: z.union([ PendingCaseOrderByWithAggregationInputSchema.array(),PendingCaseOrderByWithAggregationInputSchema ]).optional(),
  by: PendingCaseScalarFieldEnumSchema.array(),
  having: PendingCaseScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const PendingCaseFindUniqueArgsSchema: z.ZodType<Prisma.PendingCaseFindUniqueArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PendingCaseFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.PendingCaseFindUniqueOrThrowArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereUniqueInputSchema,
}).strict() ;

export const SimulationFindFirstArgsSchema: z.ZodType<Prisma.SimulationFindFirstArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereInputSchema.optional(),
  orderBy: z.union([ SimulationOrderByWithRelationInputSchema.array(),SimulationOrderByWithRelationInputSchema ]).optional(),
  cursor: SimulationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SimulationScalarFieldEnumSchema,SimulationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SimulationFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SimulationFindFirstOrThrowArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereInputSchema.optional(),
  orderBy: z.union([ SimulationOrderByWithRelationInputSchema.array(),SimulationOrderByWithRelationInputSchema ]).optional(),
  cursor: SimulationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SimulationScalarFieldEnumSchema,SimulationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SimulationFindManyArgsSchema: z.ZodType<Prisma.SimulationFindManyArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereInputSchema.optional(),
  orderBy: z.union([ SimulationOrderByWithRelationInputSchema.array(),SimulationOrderByWithRelationInputSchema ]).optional(),
  cursor: SimulationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SimulationScalarFieldEnumSchema,SimulationScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SimulationAggregateArgsSchema: z.ZodType<Prisma.SimulationAggregateArgs> = z.object({
  where: SimulationWhereInputSchema.optional(),
  orderBy: z.union([ SimulationOrderByWithRelationInputSchema.array(),SimulationOrderByWithRelationInputSchema ]).optional(),
  cursor: SimulationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SimulationGroupByArgsSchema: z.ZodType<Prisma.SimulationGroupByArgs> = z.object({
  where: SimulationWhereInputSchema.optional(),
  orderBy: z.union([ SimulationOrderByWithAggregationInputSchema.array(),SimulationOrderByWithAggregationInputSchema ]).optional(),
  by: SimulationScalarFieldEnumSchema.array(),
  having: SimulationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SimulationFindUniqueArgsSchema: z.ZodType<Prisma.SimulationFindUniqueArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereUniqueInputSchema,
}).strict() ;

export const SimulationFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SimulationFindUniqueOrThrowArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereUniqueInputSchema,
}).strict() ;

export const SubmissionFindFirstArgsSchema: z.ZodType<Prisma.SubmissionFindFirstArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereInputSchema.optional(),
  orderBy: z.union([ SubmissionOrderByWithRelationInputSchema.array(),SubmissionOrderByWithRelationInputSchema ]).optional(),
  cursor: SubmissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SubmissionScalarFieldEnumSchema,SubmissionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SubmissionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SubmissionFindFirstOrThrowArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereInputSchema.optional(),
  orderBy: z.union([ SubmissionOrderByWithRelationInputSchema.array(),SubmissionOrderByWithRelationInputSchema ]).optional(),
  cursor: SubmissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SubmissionScalarFieldEnumSchema,SubmissionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SubmissionFindManyArgsSchema: z.ZodType<Prisma.SubmissionFindManyArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereInputSchema.optional(),
  orderBy: z.union([ SubmissionOrderByWithRelationInputSchema.array(),SubmissionOrderByWithRelationInputSchema ]).optional(),
  cursor: SubmissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SubmissionScalarFieldEnumSchema,SubmissionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SubmissionAggregateArgsSchema: z.ZodType<Prisma.SubmissionAggregateArgs> = z.object({
  where: SubmissionWhereInputSchema.optional(),
  orderBy: z.union([ SubmissionOrderByWithRelationInputSchema.array(),SubmissionOrderByWithRelationInputSchema ]).optional(),
  cursor: SubmissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SubmissionGroupByArgsSchema: z.ZodType<Prisma.SubmissionGroupByArgs> = z.object({
  where: SubmissionWhereInputSchema.optional(),
  orderBy: z.union([ SubmissionOrderByWithAggregationInputSchema.array(),SubmissionOrderByWithAggregationInputSchema ]).optional(),
  by: SubmissionScalarFieldEnumSchema.array(),
  having: SubmissionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SubmissionFindUniqueArgsSchema: z.ZodType<Prisma.SubmissionFindUniqueArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereUniqueInputSchema,
}).strict() ;

export const SubmissionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SubmissionFindUniqueOrThrowArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereUniqueInputSchema,
}).strict() ;

export const SuggestionFindFirstArgsSchema: z.ZodType<Prisma.SuggestionFindFirstArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereInputSchema.optional(),
  orderBy: z.union([ SuggestionOrderByWithRelationInputSchema.array(),SuggestionOrderByWithRelationInputSchema ]).optional(),
  cursor: SuggestionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SuggestionScalarFieldEnumSchema,SuggestionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SuggestionFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SuggestionFindFirstOrThrowArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereInputSchema.optional(),
  orderBy: z.union([ SuggestionOrderByWithRelationInputSchema.array(),SuggestionOrderByWithRelationInputSchema ]).optional(),
  cursor: SuggestionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SuggestionScalarFieldEnumSchema,SuggestionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SuggestionFindManyArgsSchema: z.ZodType<Prisma.SuggestionFindManyArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereInputSchema.optional(),
  orderBy: z.union([ SuggestionOrderByWithRelationInputSchema.array(),SuggestionOrderByWithRelationInputSchema ]).optional(),
  cursor: SuggestionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SuggestionScalarFieldEnumSchema,SuggestionScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SuggestionAggregateArgsSchema: z.ZodType<Prisma.SuggestionAggregateArgs> = z.object({
  where: SuggestionWhereInputSchema.optional(),
  orderBy: z.union([ SuggestionOrderByWithRelationInputSchema.array(),SuggestionOrderByWithRelationInputSchema ]).optional(),
  cursor: SuggestionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SuggestionGroupByArgsSchema: z.ZodType<Prisma.SuggestionGroupByArgs> = z.object({
  where: SuggestionWhereInputSchema.optional(),
  orderBy: z.union([ SuggestionOrderByWithAggregationInputSchema.array(),SuggestionOrderByWithAggregationInputSchema ]).optional(),
  by: SuggestionScalarFieldEnumSchema.array(),
  having: SuggestionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SuggestionFindUniqueArgsSchema: z.ZodType<Prisma.SuggestionFindUniqueArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereUniqueInputSchema,
}).strict() ;

export const SuggestionFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SuggestionFindUniqueOrThrowArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereUniqueInputSchema,
}).strict() ;

export const SynthesisFindFirstArgsSchema: z.ZodType<Prisma.SynthesisFindFirstArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereInputSchema.optional(),
  orderBy: z.union([ SynthesisOrderByWithRelationInputSchema.array(),SynthesisOrderByWithRelationInputSchema ]).optional(),
  cursor: SynthesisWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SynthesisScalarFieldEnumSchema,SynthesisScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SynthesisFindFirstOrThrowArgsSchema: z.ZodType<Prisma.SynthesisFindFirstOrThrowArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereInputSchema.optional(),
  orderBy: z.union([ SynthesisOrderByWithRelationInputSchema.array(),SynthesisOrderByWithRelationInputSchema ]).optional(),
  cursor: SynthesisWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SynthesisScalarFieldEnumSchema,SynthesisScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SynthesisFindManyArgsSchema: z.ZodType<Prisma.SynthesisFindManyArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereInputSchema.optional(),
  orderBy: z.union([ SynthesisOrderByWithRelationInputSchema.array(),SynthesisOrderByWithRelationInputSchema ]).optional(),
  cursor: SynthesisWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ SynthesisScalarFieldEnumSchema,SynthesisScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const SynthesisAggregateArgsSchema: z.ZodType<Prisma.SynthesisAggregateArgs> = z.object({
  where: SynthesisWhereInputSchema.optional(),
  orderBy: z.union([ SynthesisOrderByWithRelationInputSchema.array(),SynthesisOrderByWithRelationInputSchema ]).optional(),
  cursor: SynthesisWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SynthesisGroupByArgsSchema: z.ZodType<Prisma.SynthesisGroupByArgs> = z.object({
  where: SynthesisWhereInputSchema.optional(),
  orderBy: z.union([ SynthesisOrderByWithAggregationInputSchema.array(),SynthesisOrderByWithAggregationInputSchema ]).optional(),
  by: SynthesisScalarFieldEnumSchema.array(),
  having: SynthesisScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const SynthesisFindUniqueArgsSchema: z.ZodType<Prisma.SynthesisFindUniqueArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereUniqueInputSchema,
}).strict() ;

export const SynthesisFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.SynthesisFindUniqueOrThrowArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereUniqueInputSchema,
}).strict() ;

export const UserFindFirstArgsSchema: z.ZodType<Prisma.UserFindFirstArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserFindFirstOrThrowArgsSchema: z.ZodType<Prisma.UserFindFirstOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserFindManyArgsSchema: z.ZodType<Prisma.UserFindManyArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.union([ UserScalarFieldEnumSchema,UserScalarFieldEnumSchema.array() ]).optional(),
}).strict() ;

export const UserAggregateArgsSchema: z.ZodType<Prisma.UserAggregateArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithRelationInputSchema.array(),UserOrderByWithRelationInputSchema ]).optional(),
  cursor: UserWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const UserGroupByArgsSchema: z.ZodType<Prisma.UserGroupByArgs> = z.object({
  where: UserWhereInputSchema.optional(),
  orderBy: z.union([ UserOrderByWithAggregationInputSchema.array(),UserOrderByWithAggregationInputSchema ]).optional(),
  by: UserScalarFieldEnumSchema.array(),
  having: UserScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict() ;

export const UserFindUniqueArgsSchema: z.ZodType<Prisma.UserFindUniqueArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserFindUniqueOrThrowArgsSchema: z.ZodType<Prisma.UserFindUniqueOrThrowArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const AssetCreateArgsSchema: z.ZodType<Prisma.AssetCreateArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  data: z.union([ AssetCreateInputSchema,AssetUncheckedCreateInputSchema ]),
}).strict() ;

export const AssetUpsertArgsSchema: z.ZodType<Prisma.AssetUpsertArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereUniqueInputSchema,
  create: z.union([ AssetCreateInputSchema,AssetUncheckedCreateInputSchema ]),
  update: z.union([ AssetUpdateInputSchema,AssetUncheckedUpdateInputSchema ]),
}).strict() ;

export const AssetCreateManyArgsSchema: z.ZodType<Prisma.AssetCreateManyArgs> = z.object({
  data: z.union([ AssetCreateManyInputSchema,AssetCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssetCreateManyAndReturnArgsSchema: z.ZodType<Prisma.AssetCreateManyAndReturnArgs> = z.object({
  data: z.union([ AssetCreateManyInputSchema,AssetCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssetDeleteArgsSchema: z.ZodType<Prisma.AssetDeleteArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  where: AssetWhereUniqueInputSchema,
}).strict() ;

export const AssetUpdateArgsSchema: z.ZodType<Prisma.AssetUpdateArgs> = z.object({
  select: AssetSelectSchema.optional(),
  include: AssetIncludeSchema.optional(),
  data: z.union([ AssetUpdateInputSchema,AssetUncheckedUpdateInputSchema ]),
  where: AssetWhereUniqueInputSchema,
}).strict() ;

export const AssetUpdateManyArgsSchema: z.ZodType<Prisma.AssetUpdateManyArgs> = z.object({
  data: z.union([ AssetUpdateManyMutationInputSchema,AssetUncheckedUpdateManyInputSchema ]),
  where: AssetWhereInputSchema.optional(),
}).strict() ;

export const AssetDeleteManyArgsSchema: z.ZodType<Prisma.AssetDeleteManyArgs> = z.object({
  where: AssetWhereInputSchema.optional(),
}).strict() ;

export const AssetJointOwnerCreateArgsSchema: z.ZodType<Prisma.AssetJointOwnerCreateArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  data: z.union([ AssetJointOwnerCreateInputSchema,AssetJointOwnerUncheckedCreateInputSchema ]),
}).strict() ;

export const AssetJointOwnerUpsertArgsSchema: z.ZodType<Prisma.AssetJointOwnerUpsertArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereUniqueInputSchema,
  create: z.union([ AssetJointOwnerCreateInputSchema,AssetJointOwnerUncheckedCreateInputSchema ]),
  update: z.union([ AssetJointOwnerUpdateInputSchema,AssetJointOwnerUncheckedUpdateInputSchema ]),
}).strict() ;

export const AssetJointOwnerCreateManyArgsSchema: z.ZodType<Prisma.AssetJointOwnerCreateManyArgs> = z.object({
  data: z.union([ AssetJointOwnerCreateManyInputSchema,AssetJointOwnerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssetJointOwnerCreateManyAndReturnArgsSchema: z.ZodType<Prisma.AssetJointOwnerCreateManyAndReturnArgs> = z.object({
  data: z.union([ AssetJointOwnerCreateManyInputSchema,AssetJointOwnerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssetJointOwnerDeleteArgsSchema: z.ZodType<Prisma.AssetJointOwnerDeleteArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  where: AssetJointOwnerWhereUniqueInputSchema,
}).strict() ;

export const AssetJointOwnerUpdateArgsSchema: z.ZodType<Prisma.AssetJointOwnerUpdateArgs> = z.object({
  select: AssetJointOwnerSelectSchema.optional(),
  include: AssetJointOwnerIncludeSchema.optional(),
  data: z.union([ AssetJointOwnerUpdateInputSchema,AssetJointOwnerUncheckedUpdateInputSchema ]),
  where: AssetJointOwnerWhereUniqueInputSchema,
}).strict() ;

export const AssetJointOwnerUpdateManyArgsSchema: z.ZodType<Prisma.AssetJointOwnerUpdateManyArgs> = z.object({
  data: z.union([ AssetJointOwnerUpdateManyMutationInputSchema,AssetJointOwnerUncheckedUpdateManyInputSchema ]),
  where: AssetJointOwnerWhereInputSchema.optional(),
}).strict() ;

export const AssetJointOwnerDeleteManyArgsSchema: z.ZodType<Prisma.AssetJointOwnerDeleteManyArgs> = z.object({
  where: AssetJointOwnerWhereInputSchema.optional(),
}).strict() ;

export const AssetShareholderCreateArgsSchema: z.ZodType<Prisma.AssetShareholderCreateArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  data: z.union([ AssetShareholderCreateInputSchema,AssetShareholderUncheckedCreateInputSchema ]),
}).strict() ;

export const AssetShareholderUpsertArgsSchema: z.ZodType<Prisma.AssetShareholderUpsertArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereUniqueInputSchema,
  create: z.union([ AssetShareholderCreateInputSchema,AssetShareholderUncheckedCreateInputSchema ]),
  update: z.union([ AssetShareholderUpdateInputSchema,AssetShareholderUncheckedUpdateInputSchema ]),
}).strict() ;

export const AssetShareholderCreateManyArgsSchema: z.ZodType<Prisma.AssetShareholderCreateManyArgs> = z.object({
  data: z.union([ AssetShareholderCreateManyInputSchema,AssetShareholderCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssetShareholderCreateManyAndReturnArgsSchema: z.ZodType<Prisma.AssetShareholderCreateManyAndReturnArgs> = z.object({
  data: z.union([ AssetShareholderCreateManyInputSchema,AssetShareholderCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssetShareholderDeleteArgsSchema: z.ZodType<Prisma.AssetShareholderDeleteArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  where: AssetShareholderWhereUniqueInputSchema,
}).strict() ;

export const AssetShareholderUpdateArgsSchema: z.ZodType<Prisma.AssetShareholderUpdateArgs> = z.object({
  select: AssetShareholderSelectSchema.optional(),
  include: AssetShareholderIncludeSchema.optional(),
  data: z.union([ AssetShareholderUpdateInputSchema,AssetShareholderUncheckedUpdateInputSchema ]),
  where: AssetShareholderWhereUniqueInputSchema,
}).strict() ;

export const AssetShareholderUpdateManyArgsSchema: z.ZodType<Prisma.AssetShareholderUpdateManyArgs> = z.object({
  data: z.union([ AssetShareholderUpdateManyMutationInputSchema,AssetShareholderUncheckedUpdateManyInputSchema ]),
  where: AssetShareholderWhereInputSchema.optional(),
}).strict() ;

export const AssetShareholderDeleteManyArgsSchema: z.ZodType<Prisma.AssetShareholderDeleteManyArgs> = z.object({
  where: AssetShareholderWhereInputSchema.optional(),
}).strict() ;

export const AssistantResponseCreateArgsSchema: z.ZodType<Prisma.AssistantResponseCreateArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  data: z.union([ AssistantResponseCreateInputSchema,AssistantResponseUncheckedCreateInputSchema ]),
}).strict() ;

export const AssistantResponseUpsertArgsSchema: z.ZodType<Prisma.AssistantResponseUpsertArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereUniqueInputSchema,
  create: z.union([ AssistantResponseCreateInputSchema,AssistantResponseUncheckedCreateInputSchema ]),
  update: z.union([ AssistantResponseUpdateInputSchema,AssistantResponseUncheckedUpdateInputSchema ]),
}).strict() ;

export const AssistantResponseCreateManyArgsSchema: z.ZodType<Prisma.AssistantResponseCreateManyArgs> = z.object({
  data: z.union([ AssistantResponseCreateManyInputSchema,AssistantResponseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssistantResponseCreateManyAndReturnArgsSchema: z.ZodType<Prisma.AssistantResponseCreateManyAndReturnArgs> = z.object({
  data: z.union([ AssistantResponseCreateManyInputSchema,AssistantResponseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AssistantResponseDeleteArgsSchema: z.ZodType<Prisma.AssistantResponseDeleteArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  where: AssistantResponseWhereUniqueInputSchema,
}).strict() ;

export const AssistantResponseUpdateArgsSchema: z.ZodType<Prisma.AssistantResponseUpdateArgs> = z.object({
  select: AssistantResponseSelectSchema.optional(),
  data: z.union([ AssistantResponseUpdateInputSchema,AssistantResponseUncheckedUpdateInputSchema ]),
  where: AssistantResponseWhereUniqueInputSchema,
}).strict() ;

export const AssistantResponseUpdateManyArgsSchema: z.ZodType<Prisma.AssistantResponseUpdateManyArgs> = z.object({
  data: z.union([ AssistantResponseUpdateManyMutationInputSchema,AssistantResponseUncheckedUpdateManyInputSchema ]),
  where: AssistantResponseWhereInputSchema.optional(),
}).strict() ;

export const AssistantResponseDeleteManyArgsSchema: z.ZodType<Prisma.AssistantResponseDeleteManyArgs> = z.object({
  where: AssistantResponseWhereInputSchema.optional(),
}).strict() ;

export const AccountCreateArgsSchema: z.ZodType<Prisma.AccountCreateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  data: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
}).strict() ;

export const AccountUpsertArgsSchema: z.ZodType<Prisma.AccountUpsertArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
  create: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
  update: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
}).strict() ;

export const AccountCreateManyArgsSchema: z.ZodType<Prisma.AccountCreateManyArgs> = z.object({
  data: z.union([ AccountCreateManyInputSchema,AccountCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AccountCreateManyAndReturnArgsSchema: z.ZodType<Prisma.AccountCreateManyAndReturnArgs> = z.object({
  data: z.union([ AccountCreateManyInputSchema,AccountCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const AccountDeleteArgsSchema: z.ZodType<Prisma.AccountDeleteArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const AccountUpdateArgsSchema: z.ZodType<Prisma.AccountUpdateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  include: AccountIncludeSchema.optional(),
  data: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
  where: AccountWhereUniqueInputSchema,
}).strict() ;

export const AccountUpdateManyArgsSchema: z.ZodType<Prisma.AccountUpdateManyArgs> = z.object({
  data: z.union([ AccountUpdateManyMutationInputSchema,AccountUncheckedUpdateManyInputSchema ]),
  where: AccountWhereInputSchema.optional(),
}).strict() ;

export const AccountDeleteManyArgsSchema: z.ZodType<Prisma.AccountDeleteManyArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
}).strict() ;

export const SessionCreateArgsSchema: z.ZodType<Prisma.SessionCreateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
}).strict() ;

export const SessionUpsertArgsSchema: z.ZodType<Prisma.SessionUpsertArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
  create: z.union([ SessionCreateInputSchema,SessionUncheckedCreateInputSchema ]),
  update: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
}).strict() ;

export const SessionCreateManyArgsSchema: z.ZodType<Prisma.SessionCreateManyArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SessionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SessionCreateManyAndReturnArgs> = z.object({
  data: z.union([ SessionCreateManyInputSchema,SessionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SessionDeleteArgsSchema: z.ZodType<Prisma.SessionDeleteArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const SessionUpdateArgsSchema: z.ZodType<Prisma.SessionUpdateArgs> = z.object({
  select: SessionSelectSchema.optional(),
  include: SessionIncludeSchema.optional(),
  data: z.union([ SessionUpdateInputSchema,SessionUncheckedUpdateInputSchema ]),
  where: SessionWhereUniqueInputSchema,
}).strict() ;

export const SessionUpdateManyArgsSchema: z.ZodType<Prisma.SessionUpdateManyArgs> = z.object({
  data: z.union([ SessionUpdateManyMutationInputSchema,SessionUncheckedUpdateManyInputSchema ]),
  where: SessionWhereInputSchema.optional(),
}).strict() ;

export const SessionDeleteManyArgsSchema: z.ZodType<Prisma.SessionDeleteManyArgs> = z.object({
  where: SessionWhereInputSchema.optional(),
}).strict() ;

export const VerificationTokenCreateArgsSchema: z.ZodType<Prisma.VerificationTokenCreateArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  data: z.union([ VerificationTokenCreateInputSchema,VerificationTokenUncheckedCreateInputSchema ]),
}).strict() ;

export const VerificationTokenUpsertArgsSchema: z.ZodType<Prisma.VerificationTokenUpsertArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
  create: z.union([ VerificationTokenCreateInputSchema,VerificationTokenUncheckedCreateInputSchema ]),
  update: z.union([ VerificationTokenUpdateInputSchema,VerificationTokenUncheckedUpdateInputSchema ]),
}).strict() ;

export const VerificationTokenCreateManyArgsSchema: z.ZodType<Prisma.VerificationTokenCreateManyArgs> = z.object({
  data: z.union([ VerificationTokenCreateManyInputSchema,VerificationTokenCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const VerificationTokenCreateManyAndReturnArgsSchema: z.ZodType<Prisma.VerificationTokenCreateManyAndReturnArgs> = z.object({
  data: z.union([ VerificationTokenCreateManyInputSchema,VerificationTokenCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const VerificationTokenDeleteArgsSchema: z.ZodType<Prisma.VerificationTokenDeleteArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenUpdateArgsSchema: z.ZodType<Prisma.VerificationTokenUpdateArgs> = z.object({
  select: VerificationTokenSelectSchema.optional(),
  data: z.union([ VerificationTokenUpdateInputSchema,VerificationTokenUncheckedUpdateInputSchema ]),
  where: VerificationTokenWhereUniqueInputSchema,
}).strict() ;

export const VerificationTokenUpdateManyArgsSchema: z.ZodType<Prisma.VerificationTokenUpdateManyArgs> = z.object({
  data: z.union([ VerificationTokenUpdateManyMutationInputSchema,VerificationTokenUncheckedUpdateManyInputSchema ]),
  where: VerificationTokenWhereInputSchema.optional(),
}).strict() ;

export const VerificationTokenDeleteManyArgsSchema: z.ZodType<Prisma.VerificationTokenDeleteManyArgs> = z.object({
  where: VerificationTokenWhereInputSchema.optional(),
}).strict() ;

export const BankCreateArgsSchema: z.ZodType<Prisma.BankCreateArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  data: z.union([ BankCreateInputSchema,BankUncheckedCreateInputSchema ]),
}).strict() ;

export const BankUpsertArgsSchema: z.ZodType<Prisma.BankUpsertArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereUniqueInputSchema,
  create: z.union([ BankCreateInputSchema,BankUncheckedCreateInputSchema ]),
  update: z.union([ BankUpdateInputSchema,BankUncheckedUpdateInputSchema ]),
}).strict() ;

export const BankCreateManyArgsSchema: z.ZodType<Prisma.BankCreateManyArgs> = z.object({
  data: z.union([ BankCreateManyInputSchema,BankCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BankCreateManyAndReturnArgsSchema: z.ZodType<Prisma.BankCreateManyAndReturnArgs> = z.object({
  data: z.union([ BankCreateManyInputSchema,BankCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const BankDeleteArgsSchema: z.ZodType<Prisma.BankDeleteArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  where: BankWhereUniqueInputSchema,
}).strict() ;

export const BankUpdateArgsSchema: z.ZodType<Prisma.BankUpdateArgs> = z.object({
  select: BankSelectSchema.optional(),
  include: BankIncludeSchema.optional(),
  data: z.union([ BankUpdateInputSchema,BankUncheckedUpdateInputSchema ]),
  where: BankWhereUniqueInputSchema,
}).strict() ;

export const BankUpdateManyArgsSchema: z.ZodType<Prisma.BankUpdateManyArgs> = z.object({
  data: z.union([ BankUpdateManyMutationInputSchema,BankUncheckedUpdateManyInputSchema ]),
  where: BankWhereInputSchema.optional(),
}).strict() ;

export const BankDeleteManyArgsSchema: z.ZodType<Prisma.BankDeleteManyArgs> = z.object({
  where: BankWhereInputSchema.optional(),
}).strict() ;

export const CaseCreateArgsSchema: z.ZodType<Prisma.CaseCreateArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  data: z.union([ CaseCreateInputSchema,CaseUncheckedCreateInputSchema ]),
}).strict() ;

export const CaseUpsertArgsSchema: z.ZodType<Prisma.CaseUpsertArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereUniqueInputSchema,
  create: z.union([ CaseCreateInputSchema,CaseUncheckedCreateInputSchema ]),
  update: z.union([ CaseUpdateInputSchema,CaseUncheckedUpdateInputSchema ]),
}).strict() ;

export const CaseCreateManyArgsSchema: z.ZodType<Prisma.CaseCreateManyArgs> = z.object({
  data: z.union([ CaseCreateManyInputSchema,CaseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CaseCreateManyAndReturnArgs> = z.object({
  data: z.union([ CaseCreateManyInputSchema,CaseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseDeleteArgsSchema: z.ZodType<Prisma.CaseDeleteArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  where: CaseWhereUniqueInputSchema,
}).strict() ;

export const CaseUpdateArgsSchema: z.ZodType<Prisma.CaseUpdateArgs> = z.object({
  select: CaseSelectSchema.optional(),
  include: CaseIncludeSchema.optional(),
  data: z.union([ CaseUpdateInputSchema,CaseUncheckedUpdateInputSchema ]),
  where: CaseWhereUniqueInputSchema,
}).strict() ;

export const CaseUpdateManyArgsSchema: z.ZodType<Prisma.CaseUpdateManyArgs> = z.object({
  data: z.union([ CaseUpdateManyMutationInputSchema,CaseUncheckedUpdateManyInputSchema ]),
  where: CaseWhereInputSchema.optional(),
}).strict() ;

export const CaseDeleteManyArgsSchema: z.ZodType<Prisma.CaseDeleteManyArgs> = z.object({
  where: CaseWhereInputSchema.optional(),
}).strict() ;

export const CaseCollaboratorCreateArgsSchema: z.ZodType<Prisma.CaseCollaboratorCreateArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  data: z.union([ CaseCollaboratorCreateInputSchema,CaseCollaboratorUncheckedCreateInputSchema ]),
}).strict() ;

export const CaseCollaboratorUpsertArgsSchema: z.ZodType<Prisma.CaseCollaboratorUpsertArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereUniqueInputSchema,
  create: z.union([ CaseCollaboratorCreateInputSchema,CaseCollaboratorUncheckedCreateInputSchema ]),
  update: z.union([ CaseCollaboratorUpdateInputSchema,CaseCollaboratorUncheckedUpdateInputSchema ]),
}).strict() ;

export const CaseCollaboratorCreateManyArgsSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyArgs> = z.object({
  data: z.union([ CaseCollaboratorCreateManyInputSchema,CaseCollaboratorCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseCollaboratorCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CaseCollaboratorCreateManyAndReturnArgs> = z.object({
  data: z.union([ CaseCollaboratorCreateManyInputSchema,CaseCollaboratorCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseCollaboratorDeleteArgsSchema: z.ZodType<Prisma.CaseCollaboratorDeleteArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  where: CaseCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const CaseCollaboratorUpdateArgsSchema: z.ZodType<Prisma.CaseCollaboratorUpdateArgs> = z.object({
  select: CaseCollaboratorSelectSchema.optional(),
  include: CaseCollaboratorIncludeSchema.optional(),
  data: z.union([ CaseCollaboratorUpdateInputSchema,CaseCollaboratorUncheckedUpdateInputSchema ]),
  where: CaseCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const CaseCollaboratorUpdateManyArgsSchema: z.ZodType<Prisma.CaseCollaboratorUpdateManyArgs> = z.object({
  data: z.union([ CaseCollaboratorUpdateManyMutationInputSchema,CaseCollaboratorUncheckedUpdateManyInputSchema ]),
  where: CaseCollaboratorWhereInputSchema.optional(),
}).strict() ;

export const CaseCollaboratorDeleteManyArgsSchema: z.ZodType<Prisma.CaseCollaboratorDeleteManyArgs> = z.object({
  where: CaseCollaboratorWhereInputSchema.optional(),
}).strict() ;

export const CaseTraceCreateArgsSchema: z.ZodType<Prisma.CaseTraceCreateArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  data: z.union([ CaseTraceCreateInputSchema,CaseTraceUncheckedCreateInputSchema ]),
}).strict() ;

export const CaseTraceUpsertArgsSchema: z.ZodType<Prisma.CaseTraceUpsertArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereUniqueInputSchema,
  create: z.union([ CaseTraceCreateInputSchema,CaseTraceUncheckedCreateInputSchema ]),
  update: z.union([ CaseTraceUpdateInputSchema,CaseTraceUncheckedUpdateInputSchema ]),
}).strict() ;

export const CaseTraceCreateManyArgsSchema: z.ZodType<Prisma.CaseTraceCreateManyArgs> = z.object({
  data: z.union([ CaseTraceCreateManyInputSchema,CaseTraceCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseTraceCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CaseTraceCreateManyAndReturnArgs> = z.object({
  data: z.union([ CaseTraceCreateManyInputSchema,CaseTraceCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseTraceDeleteArgsSchema: z.ZodType<Prisma.CaseTraceDeleteArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  where: CaseTraceWhereUniqueInputSchema,
}).strict() ;

export const CaseTraceUpdateArgsSchema: z.ZodType<Prisma.CaseTraceUpdateArgs> = z.object({
  select: CaseTraceSelectSchema.optional(),
  include: CaseTraceIncludeSchema.optional(),
  data: z.union([ CaseTraceUpdateInputSchema,CaseTraceUncheckedUpdateInputSchema ]),
  where: CaseTraceWhereUniqueInputSchema,
}).strict() ;

export const CaseTraceUpdateManyArgsSchema: z.ZodType<Prisma.CaseTraceUpdateManyArgs> = z.object({
  data: z.union([ CaseTraceUpdateManyMutationInputSchema,CaseTraceUncheckedUpdateManyInputSchema ]),
  where: CaseTraceWhereInputSchema.optional(),
}).strict() ;

export const CaseTraceDeleteManyArgsSchema: z.ZodType<Prisma.CaseTraceDeleteManyArgs> = z.object({
  where: CaseTraceWhereInputSchema.optional(),
}).strict() ;

export const CaseMessageCreateArgsSchema: z.ZodType<Prisma.CaseMessageCreateArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  data: z.union([ CaseMessageCreateInputSchema,CaseMessageUncheckedCreateInputSchema ]),
}).strict() ;

export const CaseMessageUpsertArgsSchema: z.ZodType<Prisma.CaseMessageUpsertArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereUniqueInputSchema,
  create: z.union([ CaseMessageCreateInputSchema,CaseMessageUncheckedCreateInputSchema ]),
  update: z.union([ CaseMessageUpdateInputSchema,CaseMessageUncheckedUpdateInputSchema ]),
}).strict() ;

export const CaseMessageCreateManyArgsSchema: z.ZodType<Prisma.CaseMessageCreateManyArgs> = z.object({
  data: z.union([ CaseMessageCreateManyInputSchema,CaseMessageCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseMessageCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CaseMessageCreateManyAndReturnArgs> = z.object({
  data: z.union([ CaseMessageCreateManyInputSchema,CaseMessageCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CaseMessageDeleteArgsSchema: z.ZodType<Prisma.CaseMessageDeleteArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  where: CaseMessageWhereUniqueInputSchema,
}).strict() ;

export const CaseMessageUpdateArgsSchema: z.ZodType<Prisma.CaseMessageUpdateArgs> = z.object({
  select: CaseMessageSelectSchema.optional(),
  include: CaseMessageIncludeSchema.optional(),
  data: z.union([ CaseMessageUpdateInputSchema,CaseMessageUncheckedUpdateInputSchema ]),
  where: CaseMessageWhereUniqueInputSchema,
}).strict() ;

export const CaseMessageUpdateManyArgsSchema: z.ZodType<Prisma.CaseMessageUpdateManyArgs> = z.object({
  data: z.union([ CaseMessageUpdateManyMutationInputSchema,CaseMessageUncheckedUpdateManyInputSchema ]),
  where: CaseMessageWhereInputSchema.optional(),
}).strict() ;

export const CaseMessageDeleteManyArgsSchema: z.ZodType<Prisma.CaseMessageDeleteManyArgs> = z.object({
  where: CaseMessageWhereInputSchema.optional(),
}).strict() ;

export const CompanyCreateArgsSchema: z.ZodType<Prisma.CompanyCreateArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  data: z.union([ CompanyCreateInputSchema,CompanyUncheckedCreateInputSchema ]),
}).strict() ;

export const CompanyUpsertArgsSchema: z.ZodType<Prisma.CompanyUpsertArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
  create: z.union([ CompanyCreateInputSchema,CompanyUncheckedCreateInputSchema ]),
  update: z.union([ CompanyUpdateInputSchema,CompanyUncheckedUpdateInputSchema ]),
}).strict() ;

export const CompanyCreateManyArgsSchema: z.ZodType<Prisma.CompanyCreateManyArgs> = z.object({
  data: z.union([ CompanyCreateManyInputSchema,CompanyCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CompanyCreateManyAndReturnArgs> = z.object({
  data: z.union([ CompanyCreateManyInputSchema,CompanyCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyDeleteArgsSchema: z.ZodType<Prisma.CompanyDeleteArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  where: CompanyWhereUniqueInputSchema,
}).strict() ;

export const CompanyUpdateArgsSchema: z.ZodType<Prisma.CompanyUpdateArgs> = z.object({
  select: CompanySelectSchema.optional(),
  include: CompanyIncludeSchema.optional(),
  data: z.union([ CompanyUpdateInputSchema,CompanyUncheckedUpdateInputSchema ]),
  where: CompanyWhereUniqueInputSchema,
}).strict() ;

export const CompanyUpdateManyArgsSchema: z.ZodType<Prisma.CompanyUpdateManyArgs> = z.object({
  data: z.union([ CompanyUpdateManyMutationInputSchema,CompanyUncheckedUpdateManyInputSchema ]),
  where: CompanyWhereInputSchema.optional(),
}).strict() ;

export const CompanyDeleteManyArgsSchema: z.ZodType<Prisma.CompanyDeleteManyArgs> = z.object({
  where: CompanyWhereInputSchema.optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeCreateArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  data: z.union([ CompanyRealEstateLoanChargeCreateInputSchema,CompanyRealEstateLoanChargeUncheckedCreateInputSchema ]),
}).strict() ;

export const CompanyRealEstateLoanChargeUpsertArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpsertArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereUniqueInputSchema,
  create: z.union([ CompanyRealEstateLoanChargeCreateInputSchema,CompanyRealEstateLoanChargeUncheckedCreateInputSchema ]),
  update: z.union([ CompanyRealEstateLoanChargeUpdateInputSchema,CompanyRealEstateLoanChargeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CompanyRealEstateLoanChargeCreateManyArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyArgs> = z.object({
  data: z.union([ CompanyRealEstateLoanChargeCreateManyInputSchema,CompanyRealEstateLoanChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CompanyRealEstateLoanChargeCreateManyInputSchema,CompanyRealEstateLoanChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeDeleteArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeDeleteArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  where: CompanyRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRealEstateLoanChargeUpdateArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateArgs> = z.object({
  select: CompanyRealEstateLoanChargeSelectSchema.optional(),
  include: CompanyRealEstateLoanChargeIncludeSchema.optional(),
  data: z.union([ CompanyRealEstateLoanChargeUpdateInputSchema,CompanyRealEstateLoanChargeUncheckedUpdateInputSchema ]),
  where: CompanyRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRealEstateLoanChargeUpdateManyArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeUpdateManyArgs> = z.object({
  data: z.union([ CompanyRealEstateLoanChargeUpdateManyMutationInputSchema,CompanyRealEstateLoanChargeUncheckedUpdateManyInputSchema ]),
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
}).strict() ;

export const CompanyRealEstateLoanChargeDeleteManyArgsSchema: z.ZodType<Prisma.CompanyRealEstateLoanChargeDeleteManyArgs> = z.object({
  where: CompanyRealEstateLoanChargeWhereInputSchema.optional(),
}).strict() ;

export const CompanyRentIncomeCreateArgsSchema: z.ZodType<Prisma.CompanyRentIncomeCreateArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  data: z.union([ CompanyRentIncomeCreateInputSchema,CompanyRentIncomeUncheckedCreateInputSchema ]),
}).strict() ;

export const CompanyRentIncomeUpsertArgsSchema: z.ZodType<Prisma.CompanyRentIncomeUpsertArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereUniqueInputSchema,
  create: z.union([ CompanyRentIncomeCreateInputSchema,CompanyRentIncomeUncheckedCreateInputSchema ]),
  update: z.union([ CompanyRentIncomeUpdateInputSchema,CompanyRentIncomeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CompanyRentIncomeCreateManyArgsSchema: z.ZodType<Prisma.CompanyRentIncomeCreateManyArgs> = z.object({
  data: z.union([ CompanyRentIncomeCreateManyInputSchema,CompanyRentIncomeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyRentIncomeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CompanyRentIncomeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CompanyRentIncomeCreateManyInputSchema,CompanyRentIncomeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyRentIncomeDeleteArgsSchema: z.ZodType<Prisma.CompanyRentIncomeDeleteArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  where: CompanyRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRentIncomeUpdateArgsSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateArgs> = z.object({
  select: CompanyRentIncomeSelectSchema.optional(),
  include: CompanyRentIncomeIncludeSchema.optional(),
  data: z.union([ CompanyRentIncomeUpdateInputSchema,CompanyRentIncomeUncheckedUpdateInputSchema ]),
  where: CompanyRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CompanyRentIncomeUpdateManyArgsSchema: z.ZodType<Prisma.CompanyRentIncomeUpdateManyArgs> = z.object({
  data: z.union([ CompanyRentIncomeUpdateManyMutationInputSchema,CompanyRentIncomeUncheckedUpdateManyInputSchema ]),
  where: CompanyRentIncomeWhereInputSchema.optional(),
}).strict() ;

export const CompanyRentIncomeDeleteManyArgsSchema: z.ZodType<Prisma.CompanyRentIncomeDeleteManyArgs> = z.object({
  where: CompanyRentIncomeWhereInputSchema.optional(),
}).strict() ;

export const CompanyFinancialDataCreateArgsSchema: z.ZodType<Prisma.CompanyFinancialDataCreateArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  data: z.union([ CompanyFinancialDataCreateInputSchema,CompanyFinancialDataUncheckedCreateInputSchema ]),
}).strict() ;

export const CompanyFinancialDataUpsertArgsSchema: z.ZodType<Prisma.CompanyFinancialDataUpsertArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereUniqueInputSchema,
  create: z.union([ CompanyFinancialDataCreateInputSchema,CompanyFinancialDataUncheckedCreateInputSchema ]),
  update: z.union([ CompanyFinancialDataUpdateInputSchema,CompanyFinancialDataUncheckedUpdateInputSchema ]),
}).strict() ;

export const CompanyFinancialDataCreateManyArgsSchema: z.ZodType<Prisma.CompanyFinancialDataCreateManyArgs> = z.object({
  data: z.union([ CompanyFinancialDataCreateManyInputSchema,CompanyFinancialDataCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyFinancialDataCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CompanyFinancialDataCreateManyAndReturnArgs> = z.object({
  data: z.union([ CompanyFinancialDataCreateManyInputSchema,CompanyFinancialDataCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyFinancialDataDeleteArgsSchema: z.ZodType<Prisma.CompanyFinancialDataDeleteArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  where: CompanyFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const CompanyFinancialDataUpdateArgsSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateArgs> = z.object({
  select: CompanyFinancialDataSelectSchema.optional(),
  include: CompanyFinancialDataIncludeSchema.optional(),
  data: z.union([ CompanyFinancialDataUpdateInputSchema,CompanyFinancialDataUncheckedUpdateInputSchema ]),
  where: CompanyFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const CompanyFinancialDataUpdateManyArgsSchema: z.ZodType<Prisma.CompanyFinancialDataUpdateManyArgs> = z.object({
  data: z.union([ CompanyFinancialDataUpdateManyMutationInputSchema,CompanyFinancialDataUncheckedUpdateManyInputSchema ]),
  where: CompanyFinancialDataWhereInputSchema.optional(),
}).strict() ;

export const CompanyFinancialDataDeleteManyArgsSchema: z.ZodType<Prisma.CompanyFinancialDataDeleteManyArgs> = z.object({
  where: CompanyFinancialDataWhereInputSchema.optional(),
}).strict() ;

export const CustomerCreateArgsSchema: z.ZodType<Prisma.CustomerCreateArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  data: z.union([ CustomerCreateInputSchema,CustomerUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerUpsertArgsSchema: z.ZodType<Prisma.CustomerUpsertArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereUniqueInputSchema,
  create: z.union([ CustomerCreateInputSchema,CustomerUncheckedCreateInputSchema ]),
  update: z.union([ CustomerUpdateInputSchema,CustomerUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerCreateManyArgsSchema: z.ZodType<Prisma.CustomerCreateManyArgs> = z.object({
  data: z.union([ CustomerCreateManyInputSchema,CustomerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerCreateManyInputSchema,CustomerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerDeleteArgsSchema: z.ZodType<Prisma.CustomerDeleteArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  where: CustomerWhereUniqueInputSchema,
}).strict() ;

export const CustomerUpdateArgsSchema: z.ZodType<Prisma.CustomerUpdateArgs> = z.object({
  select: CustomerSelectSchema.optional(),
  include: CustomerIncludeSchema.optional(),
  data: z.union([ CustomerUpdateInputSchema,CustomerUncheckedUpdateInputSchema ]),
  where: CustomerWhereUniqueInputSchema,
}).strict() ;

export const CustomerUpdateManyArgsSchema: z.ZodType<Prisma.CustomerUpdateManyArgs> = z.object({
  data: z.union([ CustomerUpdateManyMutationInputSchema,CustomerUncheckedUpdateManyInputSchema ]),
  where: CustomerWhereInputSchema.optional(),
}).strict() ;

export const CustomerDeleteManyArgsSchema: z.ZodType<Prisma.CustomerDeleteManyArgs> = z.object({
  where: CustomerWhereInputSchema.optional(),
}).strict() ;

export const CustomerEmployeeCreateArgsSchema: z.ZodType<Prisma.CustomerEmployeeCreateArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  data: z.union([ CustomerEmployeeCreateInputSchema,CustomerEmployeeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerEmployeeUpsertArgsSchema: z.ZodType<Prisma.CustomerEmployeeUpsertArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereUniqueInputSchema,
  create: z.union([ CustomerEmployeeCreateInputSchema,CustomerEmployeeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerEmployeeUpdateInputSchema,CustomerEmployeeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerEmployeeCreateManyArgsSchema: z.ZodType<Prisma.CustomerEmployeeCreateManyArgs> = z.object({
  data: z.union([ CustomerEmployeeCreateManyInputSchema,CustomerEmployeeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerEmployeeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerEmployeeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerEmployeeCreateManyInputSchema,CustomerEmployeeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerEmployeeDeleteArgsSchema: z.ZodType<Prisma.CustomerEmployeeDeleteArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  where: CustomerEmployeeWhereUniqueInputSchema,
}).strict() ;

export const CustomerEmployeeUpdateArgsSchema: z.ZodType<Prisma.CustomerEmployeeUpdateArgs> = z.object({
  select: CustomerEmployeeSelectSchema.optional(),
  include: CustomerEmployeeIncludeSchema.optional(),
  data: z.union([ CustomerEmployeeUpdateInputSchema,CustomerEmployeeUncheckedUpdateInputSchema ]),
  where: CustomerEmployeeWhereUniqueInputSchema,
}).strict() ;

export const CustomerEmployeeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerEmployeeUpdateManyArgs> = z.object({
  data: z.union([ CustomerEmployeeUpdateManyMutationInputSchema,CustomerEmployeeUncheckedUpdateManyInputSchema ]),
  where: CustomerEmployeeWhereInputSchema.optional(),
}).strict() ;

export const CustomerEmployeeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerEmployeeDeleteManyArgs> = z.object({
  where: CustomerEmployeeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRetiredCreateArgsSchema: z.ZodType<Prisma.CustomerRetiredCreateArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  data: z.union([ CustomerRetiredCreateInputSchema,CustomerRetiredUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerRetiredUpsertArgsSchema: z.ZodType<Prisma.CustomerRetiredUpsertArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereUniqueInputSchema,
  create: z.union([ CustomerRetiredCreateInputSchema,CustomerRetiredUncheckedCreateInputSchema ]),
  update: z.union([ CustomerRetiredUpdateInputSchema,CustomerRetiredUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerRetiredCreateManyArgsSchema: z.ZodType<Prisma.CustomerRetiredCreateManyArgs> = z.object({
  data: z.union([ CustomerRetiredCreateManyInputSchema,CustomerRetiredCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRetiredCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerRetiredCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerRetiredCreateManyInputSchema,CustomerRetiredCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRetiredDeleteArgsSchema: z.ZodType<Prisma.CustomerRetiredDeleteArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  where: CustomerRetiredWhereUniqueInputSchema,
}).strict() ;

export const CustomerRetiredUpdateArgsSchema: z.ZodType<Prisma.CustomerRetiredUpdateArgs> = z.object({
  select: CustomerRetiredSelectSchema.optional(),
  include: CustomerRetiredIncludeSchema.optional(),
  data: z.union([ CustomerRetiredUpdateInputSchema,CustomerRetiredUncheckedUpdateInputSchema ]),
  where: CustomerRetiredWhereUniqueInputSchema,
}).strict() ;

export const CustomerRetiredUpdateManyArgsSchema: z.ZodType<Prisma.CustomerRetiredUpdateManyArgs> = z.object({
  data: z.union([ CustomerRetiredUpdateManyMutationInputSchema,CustomerRetiredUncheckedUpdateManyInputSchema ]),
  where: CustomerRetiredWhereInputSchema.optional(),
}).strict() ;

export const CustomerRetiredDeleteManyArgsSchema: z.ZodType<Prisma.CustomerRetiredDeleteManyArgs> = z.object({
  where: CustomerRetiredWhereInputSchema.optional(),
}).strict() ;

export const CustomerSelfEmployedCreateArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  data: z.union([ CustomerSelfEmployedCreateInputSchema,CustomerSelfEmployedUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerSelfEmployedUpsertArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedUpsertArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereUniqueInputSchema,
  create: z.union([ CustomerSelfEmployedCreateInputSchema,CustomerSelfEmployedUncheckedCreateInputSchema ]),
  update: z.union([ CustomerSelfEmployedUpdateInputSchema,CustomerSelfEmployedUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerSelfEmployedCreateManyArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateManyArgs> = z.object({
  data: z.union([ CustomerSelfEmployedCreateManyInputSchema,CustomerSelfEmployedCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerSelfEmployedCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerSelfEmployedCreateManyInputSchema,CustomerSelfEmployedCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerSelfEmployedDeleteArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedDeleteArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  where: CustomerSelfEmployedWhereUniqueInputSchema,
}).strict() ;

export const CustomerSelfEmployedUpdateArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateArgs> = z.object({
  select: CustomerSelfEmployedSelectSchema.optional(),
  include: CustomerSelfEmployedIncludeSchema.optional(),
  data: z.union([ CustomerSelfEmployedUpdateInputSchema,CustomerSelfEmployedUncheckedUpdateInputSchema ]),
  where: CustomerSelfEmployedWhereUniqueInputSchema,
}).strict() ;

export const CustomerSelfEmployedUpdateManyArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedUpdateManyArgs> = z.object({
  data: z.union([ CustomerSelfEmployedUpdateManyMutationInputSchema,CustomerSelfEmployedUncheckedUpdateManyInputSchema ]),
  where: CustomerSelfEmployedWhereInputSchema.optional(),
}).strict() ;

export const CustomerSelfEmployedDeleteManyArgsSchema: z.ZodType<Prisma.CustomerSelfEmployedDeleteManyArgs> = z.object({
  where: CustomerSelfEmployedWhereInputSchema.optional(),
}).strict() ;

export const CustomerInvestmentCreateArgsSchema: z.ZodType<Prisma.CustomerInvestmentCreateArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  data: z.union([ CustomerInvestmentCreateInputSchema,CustomerInvestmentUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerInvestmentUpsertArgsSchema: z.ZodType<Prisma.CustomerInvestmentUpsertArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereUniqueInputSchema,
  create: z.union([ CustomerInvestmentCreateInputSchema,CustomerInvestmentUncheckedCreateInputSchema ]),
  update: z.union([ CustomerInvestmentUpdateInputSchema,CustomerInvestmentUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerInvestmentCreateManyArgsSchema: z.ZodType<Prisma.CustomerInvestmentCreateManyArgs> = z.object({
  data: z.union([ CustomerInvestmentCreateManyInputSchema,CustomerInvestmentCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerInvestmentCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerInvestmentCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerInvestmentCreateManyInputSchema,CustomerInvestmentCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerInvestmentDeleteArgsSchema: z.ZodType<Prisma.CustomerInvestmentDeleteArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  where: CustomerInvestmentWhereUniqueInputSchema,
}).strict() ;

export const CustomerInvestmentUpdateArgsSchema: z.ZodType<Prisma.CustomerInvestmentUpdateArgs> = z.object({
  select: CustomerInvestmentSelectSchema.optional(),
  include: CustomerInvestmentIncludeSchema.optional(),
  data: z.union([ CustomerInvestmentUpdateInputSchema,CustomerInvestmentUncheckedUpdateInputSchema ]),
  where: CustomerInvestmentWhereUniqueInputSchema,
}).strict() ;

export const CustomerInvestmentUpdateManyArgsSchema: z.ZodType<Prisma.CustomerInvestmentUpdateManyArgs> = z.object({
  data: z.union([ CustomerInvestmentUpdateManyMutationInputSchema,CustomerInvestmentUncheckedUpdateManyInputSchema ]),
  where: CustomerInvestmentWhereInputSchema.optional(),
}).strict() ;

export const CustomerInvestmentDeleteManyArgsSchema: z.ZodType<Prisma.CustomerInvestmentDeleteManyArgs> = z.object({
  where: CustomerInvestmentWhereInputSchema.optional(),
}).strict() ;

export const CustomerAdditionalIncomeCreateArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  data: z.union([ CustomerAdditionalIncomeCreateInputSchema,CustomerAdditionalIncomeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerAdditionalIncomeUpsertArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpsertArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereUniqueInputSchema,
  create: z.union([ CustomerAdditionalIncomeCreateInputSchema,CustomerAdditionalIncomeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerAdditionalIncomeUpdateInputSchema,CustomerAdditionalIncomeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerAdditionalIncomeCreateManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateManyArgs> = z.object({
  data: z.union([ CustomerAdditionalIncomeCreateManyInputSchema,CustomerAdditionalIncomeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerAdditionalIncomeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerAdditionalIncomeCreateManyInputSchema,CustomerAdditionalIncomeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerAdditionalIncomeDeleteArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeDeleteArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  where: CustomerAdditionalIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalIncomeUpdateArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateArgs> = z.object({
  select: CustomerAdditionalIncomeSelectSchema.optional(),
  include: CustomerAdditionalIncomeIncludeSchema.optional(),
  data: z.union([ CustomerAdditionalIncomeUpdateInputSchema,CustomerAdditionalIncomeUncheckedUpdateInputSchema ]),
  where: CustomerAdditionalIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalIncomeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeUpdateManyArgs> = z.object({
  data: z.union([ CustomerAdditionalIncomeUpdateManyMutationInputSchema,CustomerAdditionalIncomeUncheckedUpdateManyInputSchema ]),
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
}).strict() ;

export const CustomerAdditionalIncomeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalIncomeDeleteManyArgs> = z.object({
  where: CustomerAdditionalIncomeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRentIncomeCreateArgsSchema: z.ZodType<Prisma.CustomerRentIncomeCreateArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  data: z.union([ CustomerRentIncomeCreateInputSchema,CustomerRentIncomeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerRentIncomeUpsertArgsSchema: z.ZodType<Prisma.CustomerRentIncomeUpsertArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereUniqueInputSchema,
  create: z.union([ CustomerRentIncomeCreateInputSchema,CustomerRentIncomeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerRentIncomeUpdateInputSchema,CustomerRentIncomeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerRentIncomeCreateManyArgsSchema: z.ZodType<Prisma.CustomerRentIncomeCreateManyArgs> = z.object({
  data: z.union([ CustomerRentIncomeCreateManyInputSchema,CustomerRentIncomeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRentIncomeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerRentIncomeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerRentIncomeCreateManyInputSchema,CustomerRentIncomeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRentIncomeDeleteArgsSchema: z.ZodType<Prisma.CustomerRentIncomeDeleteArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  where: CustomerRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentIncomeUpdateArgsSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateArgs> = z.object({
  select: CustomerRentIncomeSelectSchema.optional(),
  include: CustomerRentIncomeIncludeSchema.optional(),
  data: z.union([ CustomerRentIncomeUpdateInputSchema,CustomerRentIncomeUncheckedUpdateInputSchema ]),
  where: CustomerRentIncomeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentIncomeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerRentIncomeUpdateManyArgs> = z.object({
  data: z.union([ CustomerRentIncomeUpdateManyMutationInputSchema,CustomerRentIncomeUncheckedUpdateManyInputSchema ]),
  where: CustomerRentIncomeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRentIncomeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerRentIncomeDeleteManyArgs> = z.object({
  where: CustomerRentIncomeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRentChargeCreateArgsSchema: z.ZodType<Prisma.CustomerRentChargeCreateArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  data: z.union([ CustomerRentChargeCreateInputSchema,CustomerRentChargeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerRentChargeUpsertArgsSchema: z.ZodType<Prisma.CustomerRentChargeUpsertArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereUniqueInputSchema,
  create: z.union([ CustomerRentChargeCreateInputSchema,CustomerRentChargeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerRentChargeUpdateInputSchema,CustomerRentChargeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerRentChargeCreateManyArgsSchema: z.ZodType<Prisma.CustomerRentChargeCreateManyArgs> = z.object({
  data: z.union([ CustomerRentChargeCreateManyInputSchema,CustomerRentChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRentChargeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerRentChargeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerRentChargeCreateManyInputSchema,CustomerRentChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRentChargeDeleteArgsSchema: z.ZodType<Prisma.CustomerRentChargeDeleteArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  where: CustomerRentChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentChargeUpdateArgsSchema: z.ZodType<Prisma.CustomerRentChargeUpdateArgs> = z.object({
  select: CustomerRentChargeSelectSchema.optional(),
  include: CustomerRentChargeIncludeSchema.optional(),
  data: z.union([ CustomerRentChargeUpdateInputSchema,CustomerRentChargeUncheckedUpdateInputSchema ]),
  where: CustomerRentChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRentChargeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerRentChargeUpdateManyArgs> = z.object({
  data: z.union([ CustomerRentChargeUpdateManyMutationInputSchema,CustomerRentChargeUncheckedUpdateManyInputSchema ]),
  where: CustomerRentChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRentChargeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerRentChargeDeleteManyArgs> = z.object({
  where: CustomerRentChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerConsumerLoanChargeCreateArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  data: z.union([ CustomerConsumerLoanChargeCreateInputSchema,CustomerConsumerLoanChargeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerConsumerLoanChargeUpsertArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpsertArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereUniqueInputSchema,
  create: z.union([ CustomerConsumerLoanChargeCreateInputSchema,CustomerConsumerLoanChargeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerConsumerLoanChargeUpdateInputSchema,CustomerConsumerLoanChargeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerConsumerLoanChargeCreateManyArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateManyArgs> = z.object({
  data: z.union([ CustomerConsumerLoanChargeCreateManyInputSchema,CustomerConsumerLoanChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerConsumerLoanChargeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerConsumerLoanChargeCreateManyInputSchema,CustomerConsumerLoanChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerConsumerLoanChargeDeleteArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeDeleteArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  where: CustomerConsumerLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerConsumerLoanChargeUpdateArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateArgs> = z.object({
  select: CustomerConsumerLoanChargeSelectSchema.optional(),
  include: CustomerConsumerLoanChargeIncludeSchema.optional(),
  data: z.union([ CustomerConsumerLoanChargeUpdateInputSchema,CustomerConsumerLoanChargeUncheckedUpdateInputSchema ]),
  where: CustomerConsumerLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerConsumerLoanChargeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeUpdateManyArgs> = z.object({
  data: z.union([ CustomerConsumerLoanChargeUpdateManyMutationInputSchema,CustomerConsumerLoanChargeUncheckedUpdateManyInputSchema ]),
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerConsumerLoanChargeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerConsumerLoanChargeDeleteManyArgs> = z.object({
  where: CustomerConsumerLoanChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeCreateArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  data: z.union([ CustomerRealEstateLoanChargeCreateInputSchema,CustomerRealEstateLoanChargeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerRealEstateLoanChargeUpsertArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpsertArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereUniqueInputSchema,
  create: z.union([ CustomerRealEstateLoanChargeCreateInputSchema,CustomerRealEstateLoanChargeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerRealEstateLoanChargeUpdateInputSchema,CustomerRealEstateLoanChargeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerRealEstateLoanChargeCreateManyArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyArgs> = z.object({
  data: z.union([ CustomerRealEstateLoanChargeCreateManyInputSchema,CustomerRealEstateLoanChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerRealEstateLoanChargeCreateManyInputSchema,CustomerRealEstateLoanChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeDeleteArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeDeleteArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  where: CustomerRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRealEstateLoanChargeUpdateArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateArgs> = z.object({
  select: CustomerRealEstateLoanChargeSelectSchema.optional(),
  include: CustomerRealEstateLoanChargeIncludeSchema.optional(),
  data: z.union([ CustomerRealEstateLoanChargeUpdateInputSchema,CustomerRealEstateLoanChargeUncheckedUpdateInputSchema ]),
  where: CustomerRealEstateLoanChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerRealEstateLoanChargeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeUpdateManyArgs> = z.object({
  data: z.union([ CustomerRealEstateLoanChargeUpdateManyMutationInputSchema,CustomerRealEstateLoanChargeUncheckedUpdateManyInputSchema ]),
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerRealEstateLoanChargeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerRealEstateLoanChargeDeleteManyArgs> = z.object({
  where: CustomerRealEstateLoanChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerAdditionalChargeCreateArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  data: z.union([ CustomerAdditionalChargeCreateInputSchema,CustomerAdditionalChargeUncheckedCreateInputSchema ]),
}).strict() ;

export const CustomerAdditionalChargeUpsertArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpsertArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereUniqueInputSchema,
  create: z.union([ CustomerAdditionalChargeCreateInputSchema,CustomerAdditionalChargeUncheckedCreateInputSchema ]),
  update: z.union([ CustomerAdditionalChargeUpdateInputSchema,CustomerAdditionalChargeUncheckedUpdateInputSchema ]),
}).strict() ;

export const CustomerAdditionalChargeCreateManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateManyArgs> = z.object({
  data: z.union([ CustomerAdditionalChargeCreateManyInputSchema,CustomerAdditionalChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerAdditionalChargeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeCreateManyAndReturnArgs> = z.object({
  data: z.union([ CustomerAdditionalChargeCreateManyInputSchema,CustomerAdditionalChargeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CustomerAdditionalChargeDeleteArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeDeleteArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  where: CustomerAdditionalChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalChargeUpdateArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateArgs> = z.object({
  select: CustomerAdditionalChargeSelectSchema.optional(),
  include: CustomerAdditionalChargeIncludeSchema.optional(),
  data: z.union([ CustomerAdditionalChargeUpdateInputSchema,CustomerAdditionalChargeUncheckedUpdateInputSchema ]),
  where: CustomerAdditionalChargeWhereUniqueInputSchema,
}).strict() ;

export const CustomerAdditionalChargeUpdateManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeUpdateManyArgs> = z.object({
  data: z.union([ CustomerAdditionalChargeUpdateManyMutationInputSchema,CustomerAdditionalChargeUncheckedUpdateManyInputSchema ]),
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
}).strict() ;

export const CustomerAdditionalChargeDeleteManyArgsSchema: z.ZodType<Prisma.CustomerAdditionalChargeDeleteManyArgs> = z.object({
  where: CustomerAdditionalChargeWhereInputSchema.optional(),
}).strict() ;

export const NotificationCreateArgsSchema: z.ZodType<Prisma.NotificationCreateArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  data: z.union([ NotificationCreateInputSchema,NotificationUncheckedCreateInputSchema ]),
}).strict() ;

export const NotificationUpsertArgsSchema: z.ZodType<Prisma.NotificationUpsertArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereUniqueInputSchema,
  create: z.union([ NotificationCreateInputSchema,NotificationUncheckedCreateInputSchema ]),
  update: z.union([ NotificationUpdateInputSchema,NotificationUncheckedUpdateInputSchema ]),
}).strict() ;

export const NotificationCreateManyArgsSchema: z.ZodType<Prisma.NotificationCreateManyArgs> = z.object({
  data: z.union([ NotificationCreateManyInputSchema,NotificationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const NotificationCreateManyAndReturnArgsSchema: z.ZodType<Prisma.NotificationCreateManyAndReturnArgs> = z.object({
  data: z.union([ NotificationCreateManyInputSchema,NotificationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const NotificationDeleteArgsSchema: z.ZodType<Prisma.NotificationDeleteArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  where: NotificationWhereUniqueInputSchema,
}).strict() ;

export const NotificationUpdateArgsSchema: z.ZodType<Prisma.NotificationUpdateArgs> = z.object({
  select: NotificationSelectSchema.optional(),
  include: NotificationIncludeSchema.optional(),
  data: z.union([ NotificationUpdateInputSchema,NotificationUncheckedUpdateInputSchema ]),
  where: NotificationWhereUniqueInputSchema,
}).strict() ;

export const NotificationUpdateManyArgsSchema: z.ZodType<Prisma.NotificationUpdateManyArgs> = z.object({
  data: z.union([ NotificationUpdateManyMutationInputSchema,NotificationUncheckedUpdateManyInputSchema ]),
  where: NotificationWhereInputSchema.optional(),
}).strict() ;

export const NotificationDeleteManyArgsSchema: z.ZodType<Prisma.NotificationDeleteManyArgs> = z.object({
  where: NotificationWhereInputSchema.optional(),
}).strict() ;

export const CompanyPappersCreateArgsSchema: z.ZodType<Prisma.CompanyPappersCreateArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  data: z.union([ CompanyPappersCreateInputSchema,CompanyPappersUncheckedCreateInputSchema ]),
}).strict() ;

export const CompanyPappersUpsertArgsSchema: z.ZodType<Prisma.CompanyPappersUpsertArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereUniqueInputSchema,
  create: z.union([ CompanyPappersCreateInputSchema,CompanyPappersUncheckedCreateInputSchema ]),
  update: z.union([ CompanyPappersUpdateInputSchema,CompanyPappersUncheckedUpdateInputSchema ]),
}).strict() ;

export const CompanyPappersCreateManyArgsSchema: z.ZodType<Prisma.CompanyPappersCreateManyArgs> = z.object({
  data: z.union([ CompanyPappersCreateManyInputSchema,CompanyPappersCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyPappersCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CompanyPappersCreateManyAndReturnArgs> = z.object({
  data: z.union([ CompanyPappersCreateManyInputSchema,CompanyPappersCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CompanyPappersDeleteArgsSchema: z.ZodType<Prisma.CompanyPappersDeleteArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  where: CompanyPappersWhereUniqueInputSchema,
}).strict() ;

export const CompanyPappersUpdateArgsSchema: z.ZodType<Prisma.CompanyPappersUpdateArgs> = z.object({
  select: CompanyPappersSelectSchema.optional(),
  include: CompanyPappersIncludeSchema.optional(),
  data: z.union([ CompanyPappersUpdateInputSchema,CompanyPappersUncheckedUpdateInputSchema ]),
  where: CompanyPappersWhereUniqueInputSchema,
}).strict() ;

export const CompanyPappersUpdateManyArgsSchema: z.ZodType<Prisma.CompanyPappersUpdateManyArgs> = z.object({
  data: z.union([ CompanyPappersUpdateManyMutationInputSchema,CompanyPappersUncheckedUpdateManyInputSchema ]),
  where: CompanyPappersWhereInputSchema.optional(),
}).strict() ;

export const CompanyPappersDeleteManyArgsSchema: z.ZodType<Prisma.CompanyPappersDeleteManyArgs> = z.object({
  where: CompanyPappersWhereInputSchema.optional(),
}).strict() ;

export const PappersRepresentativeCreateArgsSchema: z.ZodType<Prisma.PappersRepresentativeCreateArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  data: z.union([ PappersRepresentativeCreateInputSchema,PappersRepresentativeUncheckedCreateInputSchema ]),
}).strict() ;

export const PappersRepresentativeUpsertArgsSchema: z.ZodType<Prisma.PappersRepresentativeUpsertArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereUniqueInputSchema,
  create: z.union([ PappersRepresentativeCreateInputSchema,PappersRepresentativeUncheckedCreateInputSchema ]),
  update: z.union([ PappersRepresentativeUpdateInputSchema,PappersRepresentativeUncheckedUpdateInputSchema ]),
}).strict() ;

export const PappersRepresentativeCreateManyArgsSchema: z.ZodType<Prisma.PappersRepresentativeCreateManyArgs> = z.object({
  data: z.union([ PappersRepresentativeCreateManyInputSchema,PappersRepresentativeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersRepresentativeCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PappersRepresentativeCreateManyAndReturnArgs> = z.object({
  data: z.union([ PappersRepresentativeCreateManyInputSchema,PappersRepresentativeCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersRepresentativeDeleteArgsSchema: z.ZodType<Prisma.PappersRepresentativeDeleteArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  where: PappersRepresentativeWhereUniqueInputSchema,
}).strict() ;

export const PappersRepresentativeUpdateArgsSchema: z.ZodType<Prisma.PappersRepresentativeUpdateArgs> = z.object({
  select: PappersRepresentativeSelectSchema.optional(),
  include: PappersRepresentativeIncludeSchema.optional(),
  data: z.union([ PappersRepresentativeUpdateInputSchema,PappersRepresentativeUncheckedUpdateInputSchema ]),
  where: PappersRepresentativeWhereUniqueInputSchema,
}).strict() ;

export const PappersRepresentativeUpdateManyArgsSchema: z.ZodType<Prisma.PappersRepresentativeUpdateManyArgs> = z.object({
  data: z.union([ PappersRepresentativeUpdateManyMutationInputSchema,PappersRepresentativeUncheckedUpdateManyInputSchema ]),
  where: PappersRepresentativeWhereInputSchema.optional(),
}).strict() ;

export const PappersRepresentativeDeleteManyArgsSchema: z.ZodType<Prisma.PappersRepresentativeDeleteManyArgs> = z.object({
  where: PappersRepresentativeWhereInputSchema.optional(),
}).strict() ;

export const PappersBeneficiaryCreateArgsSchema: z.ZodType<Prisma.PappersBeneficiaryCreateArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  data: z.union([ PappersBeneficiaryCreateInputSchema,PappersBeneficiaryUncheckedCreateInputSchema ]),
}).strict() ;

export const PappersBeneficiaryUpsertArgsSchema: z.ZodType<Prisma.PappersBeneficiaryUpsertArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereUniqueInputSchema,
  create: z.union([ PappersBeneficiaryCreateInputSchema,PappersBeneficiaryUncheckedCreateInputSchema ]),
  update: z.union([ PappersBeneficiaryUpdateInputSchema,PappersBeneficiaryUncheckedUpdateInputSchema ]),
}).strict() ;

export const PappersBeneficiaryCreateManyArgsSchema: z.ZodType<Prisma.PappersBeneficiaryCreateManyArgs> = z.object({
  data: z.union([ PappersBeneficiaryCreateManyInputSchema,PappersBeneficiaryCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersBeneficiaryCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PappersBeneficiaryCreateManyAndReturnArgs> = z.object({
  data: z.union([ PappersBeneficiaryCreateManyInputSchema,PappersBeneficiaryCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersBeneficiaryDeleteArgsSchema: z.ZodType<Prisma.PappersBeneficiaryDeleteArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  where: PappersBeneficiaryWhereUniqueInputSchema,
}).strict() ;

export const PappersBeneficiaryUpdateArgsSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateArgs> = z.object({
  select: PappersBeneficiarySelectSchema.optional(),
  include: PappersBeneficiaryIncludeSchema.optional(),
  data: z.union([ PappersBeneficiaryUpdateInputSchema,PappersBeneficiaryUncheckedUpdateInputSchema ]),
  where: PappersBeneficiaryWhereUniqueInputSchema,
}).strict() ;

export const PappersBeneficiaryUpdateManyArgsSchema: z.ZodType<Prisma.PappersBeneficiaryUpdateManyArgs> = z.object({
  data: z.union([ PappersBeneficiaryUpdateManyMutationInputSchema,PappersBeneficiaryUncheckedUpdateManyInputSchema ]),
  where: PappersBeneficiaryWhereInputSchema.optional(),
}).strict() ;

export const PappersBeneficiaryDeleteManyArgsSchema: z.ZodType<Prisma.PappersBeneficiaryDeleteManyArgs> = z.object({
  where: PappersBeneficiaryWhereInputSchema.optional(),
}).strict() ;

export const PappersCollectiveConventionCreateArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  data: z.union([ PappersCollectiveConventionCreateInputSchema,PappersCollectiveConventionUncheckedCreateInputSchema ]),
}).strict() ;

export const PappersCollectiveConventionUpsertArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionUpsertArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereUniqueInputSchema,
  create: z.union([ PappersCollectiveConventionCreateInputSchema,PappersCollectiveConventionUncheckedCreateInputSchema ]),
  update: z.union([ PappersCollectiveConventionUpdateInputSchema,PappersCollectiveConventionUncheckedUpdateInputSchema ]),
}).strict() ;

export const PappersCollectiveConventionCreateManyArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateManyArgs> = z.object({
  data: z.union([ PappersCollectiveConventionCreateManyInputSchema,PappersCollectiveConventionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersCollectiveConventionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionCreateManyAndReturnArgs> = z.object({
  data: z.union([ PappersCollectiveConventionCreateManyInputSchema,PappersCollectiveConventionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersCollectiveConventionDeleteArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionDeleteArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  where: PappersCollectiveConventionWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveConventionUpdateArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateArgs> = z.object({
  select: PappersCollectiveConventionSelectSchema.optional(),
  include: PappersCollectiveConventionIncludeSchema.optional(),
  data: z.union([ PappersCollectiveConventionUpdateInputSchema,PappersCollectiveConventionUncheckedUpdateInputSchema ]),
  where: PappersCollectiveConventionWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveConventionUpdateManyArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionUpdateManyArgs> = z.object({
  data: z.union([ PappersCollectiveConventionUpdateManyMutationInputSchema,PappersCollectiveConventionUncheckedUpdateManyInputSchema ]),
  where: PappersCollectiveConventionWhereInputSchema.optional(),
}).strict() ;

export const PappersCollectiveConventionDeleteManyArgsSchema: z.ZodType<Prisma.PappersCollectiveConventionDeleteManyArgs> = z.object({
  where: PappersCollectiveConventionWhereInputSchema.optional(),
}).strict() ;

export const PappersCollectiveProcedureCreateArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  data: z.union([ PappersCollectiveProcedureCreateInputSchema,PappersCollectiveProcedureUncheckedCreateInputSchema ]),
}).strict() ;

export const PappersCollectiveProcedureUpsertArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpsertArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereUniqueInputSchema,
  create: z.union([ PappersCollectiveProcedureCreateInputSchema,PappersCollectiveProcedureUncheckedCreateInputSchema ]),
  update: z.union([ PappersCollectiveProcedureUpdateInputSchema,PappersCollectiveProcedureUncheckedUpdateInputSchema ]),
}).strict() ;

export const PappersCollectiveProcedureCreateManyArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateManyArgs> = z.object({
  data: z.union([ PappersCollectiveProcedureCreateManyInputSchema,PappersCollectiveProcedureCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersCollectiveProcedureCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureCreateManyAndReturnArgs> = z.object({
  data: z.union([ PappersCollectiveProcedureCreateManyInputSchema,PappersCollectiveProcedureCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersCollectiveProcedureDeleteArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureDeleteArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  where: PappersCollectiveProcedureWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveProcedureUpdateArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateArgs> = z.object({
  select: PappersCollectiveProcedureSelectSchema.optional(),
  include: PappersCollectiveProcedureIncludeSchema.optional(),
  data: z.union([ PappersCollectiveProcedureUpdateInputSchema,PappersCollectiveProcedureUncheckedUpdateInputSchema ]),
  where: PappersCollectiveProcedureWhereUniqueInputSchema,
}).strict() ;

export const PappersCollectiveProcedureUpdateManyArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureUpdateManyArgs> = z.object({
  data: z.union([ PappersCollectiveProcedureUpdateManyMutationInputSchema,PappersCollectiveProcedureUncheckedUpdateManyInputSchema ]),
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
}).strict() ;

export const PappersCollectiveProcedureDeleteManyArgsSchema: z.ZodType<Prisma.PappersCollectiveProcedureDeleteManyArgs> = z.object({
  where: PappersCollectiveProcedureWhereInputSchema.optional(),
}).strict() ;

export const PappersFinancialDataCreateArgsSchema: z.ZodType<Prisma.PappersFinancialDataCreateArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  data: z.union([ PappersFinancialDataCreateInputSchema,PappersFinancialDataUncheckedCreateInputSchema ]),
}).strict() ;

export const PappersFinancialDataUpsertArgsSchema: z.ZodType<Prisma.PappersFinancialDataUpsertArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereUniqueInputSchema,
  create: z.union([ PappersFinancialDataCreateInputSchema,PappersFinancialDataUncheckedCreateInputSchema ]),
  update: z.union([ PappersFinancialDataUpdateInputSchema,PappersFinancialDataUncheckedUpdateInputSchema ]),
}).strict() ;

export const PappersFinancialDataCreateManyArgsSchema: z.ZodType<Prisma.PappersFinancialDataCreateManyArgs> = z.object({
  data: z.union([ PappersFinancialDataCreateManyInputSchema,PappersFinancialDataCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersFinancialDataCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PappersFinancialDataCreateManyAndReturnArgs> = z.object({
  data: z.union([ PappersFinancialDataCreateManyInputSchema,PappersFinancialDataCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersFinancialDataDeleteArgsSchema: z.ZodType<Prisma.PappersFinancialDataDeleteArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  where: PappersFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const PappersFinancialDataUpdateArgsSchema: z.ZodType<Prisma.PappersFinancialDataUpdateArgs> = z.object({
  select: PappersFinancialDataSelectSchema.optional(),
  include: PappersFinancialDataIncludeSchema.optional(),
  data: z.union([ PappersFinancialDataUpdateInputSchema,PappersFinancialDataUncheckedUpdateInputSchema ]),
  where: PappersFinancialDataWhereUniqueInputSchema,
}).strict() ;

export const PappersFinancialDataUpdateManyArgsSchema: z.ZodType<Prisma.PappersFinancialDataUpdateManyArgs> = z.object({
  data: z.union([ PappersFinancialDataUpdateManyMutationInputSchema,PappersFinancialDataUncheckedUpdateManyInputSchema ]),
  where: PappersFinancialDataWhereInputSchema.optional(),
}).strict() ;

export const PappersFinancialDataDeleteManyArgsSchema: z.ZodType<Prisma.PappersFinancialDataDeleteManyArgs> = z.object({
  where: PappersFinancialDataWhereInputSchema.optional(),
}).strict() ;

export const PappersSanctionCreateArgsSchema: z.ZodType<Prisma.PappersSanctionCreateArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  data: z.union([ PappersSanctionCreateInputSchema,PappersSanctionUncheckedCreateInputSchema ]).optional(),
}).strict() ;

export const PappersSanctionUpsertArgsSchema: z.ZodType<Prisma.PappersSanctionUpsertArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereUniqueInputSchema,
  create: z.union([ PappersSanctionCreateInputSchema,PappersSanctionUncheckedCreateInputSchema ]),
  update: z.union([ PappersSanctionUpdateInputSchema,PappersSanctionUncheckedUpdateInputSchema ]),
}).strict() ;

export const PappersSanctionCreateManyArgsSchema: z.ZodType<Prisma.PappersSanctionCreateManyArgs> = z.object({
  data: z.union([ PappersSanctionCreateManyInputSchema,PappersSanctionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersSanctionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PappersSanctionCreateManyAndReturnArgs> = z.object({
  data: z.union([ PappersSanctionCreateManyInputSchema,PappersSanctionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PappersSanctionDeleteArgsSchema: z.ZodType<Prisma.PappersSanctionDeleteArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  where: PappersSanctionWhereUniqueInputSchema,
}).strict() ;

export const PappersSanctionUpdateArgsSchema: z.ZodType<Prisma.PappersSanctionUpdateArgs> = z.object({
  select: PappersSanctionSelectSchema.optional(),
  include: PappersSanctionIncludeSchema.optional(),
  data: z.union([ PappersSanctionUpdateInputSchema,PappersSanctionUncheckedUpdateInputSchema ]),
  where: PappersSanctionWhereUniqueInputSchema,
}).strict() ;

export const PappersSanctionUpdateManyArgsSchema: z.ZodType<Prisma.PappersSanctionUpdateManyArgs> = z.object({
  data: z.union([ PappersSanctionUpdateManyMutationInputSchema,PappersSanctionUncheckedUpdateManyInputSchema ]),
  where: PappersSanctionWhereInputSchema.optional(),
}).strict() ;

export const PappersSanctionDeleteManyArgsSchema: z.ZodType<Prisma.PappersSanctionDeleteManyArgs> = z.object({
  where: PappersSanctionWhereInputSchema.optional(),
}).strict() ;

export const PartnerCreateArgsSchema: z.ZodType<Prisma.PartnerCreateArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  data: z.union([ PartnerCreateInputSchema,PartnerUncheckedCreateInputSchema ]),
}).strict() ;

export const PartnerUpsertArgsSchema: z.ZodType<Prisma.PartnerUpsertArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereUniqueInputSchema,
  create: z.union([ PartnerCreateInputSchema,PartnerUncheckedCreateInputSchema ]),
  update: z.union([ PartnerUpdateInputSchema,PartnerUncheckedUpdateInputSchema ]),
}).strict() ;

export const PartnerCreateManyArgsSchema: z.ZodType<Prisma.PartnerCreateManyArgs> = z.object({
  data: z.union([ PartnerCreateManyInputSchema,PartnerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PartnerCreateManyAndReturnArgs> = z.object({
  data: z.union([ PartnerCreateManyInputSchema,PartnerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerDeleteArgsSchema: z.ZodType<Prisma.PartnerDeleteArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  where: PartnerWhereUniqueInputSchema,
}).strict() ;

export const PartnerUpdateArgsSchema: z.ZodType<Prisma.PartnerUpdateArgs> = z.object({
  select: PartnerSelectSchema.optional(),
  include: PartnerIncludeSchema.optional(),
  data: z.union([ PartnerUpdateInputSchema,PartnerUncheckedUpdateInputSchema ]),
  where: PartnerWhereUniqueInputSchema,
}).strict() ;

export const PartnerUpdateManyArgsSchema: z.ZodType<Prisma.PartnerUpdateManyArgs> = z.object({
  data: z.union([ PartnerUpdateManyMutationInputSchema,PartnerUncheckedUpdateManyInputSchema ]),
  where: PartnerWhereInputSchema.optional(),
}).strict() ;

export const PartnerDeleteManyArgsSchema: z.ZodType<Prisma.PartnerDeleteManyArgs> = z.object({
  where: PartnerWhereInputSchema.optional(),
}).strict() ;

export const PartnerCollaboratorCreateArgsSchema: z.ZodType<Prisma.PartnerCollaboratorCreateArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  data: z.union([ PartnerCollaboratorCreateInputSchema,PartnerCollaboratorUncheckedCreateInputSchema ]),
}).strict() ;

export const PartnerCollaboratorUpsertArgsSchema: z.ZodType<Prisma.PartnerCollaboratorUpsertArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereUniqueInputSchema,
  create: z.union([ PartnerCollaboratorCreateInputSchema,PartnerCollaboratorUncheckedCreateInputSchema ]),
  update: z.union([ PartnerCollaboratorUpdateInputSchema,PartnerCollaboratorUncheckedUpdateInputSchema ]),
}).strict() ;

export const PartnerCollaboratorCreateManyArgsSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyArgs> = z.object({
  data: z.union([ PartnerCollaboratorCreateManyInputSchema,PartnerCollaboratorCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerCollaboratorCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PartnerCollaboratorCreateManyAndReturnArgs> = z.object({
  data: z.union([ PartnerCollaboratorCreateManyInputSchema,PartnerCollaboratorCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerCollaboratorDeleteArgsSchema: z.ZodType<Prisma.PartnerCollaboratorDeleteArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  where: PartnerCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const PartnerCollaboratorUpdateArgsSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateArgs> = z.object({
  select: PartnerCollaboratorSelectSchema.optional(),
  include: PartnerCollaboratorIncludeSchema.optional(),
  data: z.union([ PartnerCollaboratorUpdateInputSchema,PartnerCollaboratorUncheckedUpdateInputSchema ]),
  where: PartnerCollaboratorWhereUniqueInputSchema,
}).strict() ;

export const PartnerCollaboratorUpdateManyArgsSchema: z.ZodType<Prisma.PartnerCollaboratorUpdateManyArgs> = z.object({
  data: z.union([ PartnerCollaboratorUpdateManyMutationInputSchema,PartnerCollaboratorUncheckedUpdateManyInputSchema ]),
  where: PartnerCollaboratorWhereInputSchema.optional(),
}).strict() ;

export const PartnerCollaboratorDeleteManyArgsSchema: z.ZodType<Prisma.PartnerCollaboratorDeleteManyArgs> = z.object({
  where: PartnerCollaboratorWhereInputSchema.optional(),
}).strict() ;

export const PartnerRateCreateArgsSchema: z.ZodType<Prisma.PartnerRateCreateArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  data: z.union([ PartnerRateCreateInputSchema,PartnerRateUncheckedCreateInputSchema ]),
}).strict() ;

export const PartnerRateUpsertArgsSchema: z.ZodType<Prisma.PartnerRateUpsertArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereUniqueInputSchema,
  create: z.union([ PartnerRateCreateInputSchema,PartnerRateUncheckedCreateInputSchema ]),
  update: z.union([ PartnerRateUpdateInputSchema,PartnerRateUncheckedUpdateInputSchema ]),
}).strict() ;

export const PartnerRateCreateManyArgsSchema: z.ZodType<Prisma.PartnerRateCreateManyArgs> = z.object({
  data: z.union([ PartnerRateCreateManyInputSchema,PartnerRateCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerRateCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PartnerRateCreateManyAndReturnArgs> = z.object({
  data: z.union([ PartnerRateCreateManyInputSchema,PartnerRateCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerRateDeleteArgsSchema: z.ZodType<Prisma.PartnerRateDeleteArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  where: PartnerRateWhereUniqueInputSchema,
}).strict() ;

export const PartnerRateUpdateArgsSchema: z.ZodType<Prisma.PartnerRateUpdateArgs> = z.object({
  select: PartnerRateSelectSchema.optional(),
  include: PartnerRateIncludeSchema.optional(),
  data: z.union([ PartnerRateUpdateInputSchema,PartnerRateUncheckedUpdateInputSchema ]),
  where: PartnerRateWhereUniqueInputSchema,
}).strict() ;

export const PartnerRateUpdateManyArgsSchema: z.ZodType<Prisma.PartnerRateUpdateManyArgs> = z.object({
  data: z.union([ PartnerRateUpdateManyMutationInputSchema,PartnerRateUncheckedUpdateManyInputSchema ]),
  where: PartnerRateWhereInputSchema.optional(),
}).strict() ;

export const PartnerRateDeleteManyArgsSchema: z.ZodType<Prisma.PartnerRateDeleteManyArgs> = z.object({
  where: PartnerRateWhereInputSchema.optional(),
}).strict() ;

export const CasePartnerCreateArgsSchema: z.ZodType<Prisma.CasePartnerCreateArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  data: z.union([ CasePartnerCreateInputSchema,CasePartnerUncheckedCreateInputSchema ]),
}).strict() ;

export const CasePartnerUpsertArgsSchema: z.ZodType<Prisma.CasePartnerUpsertArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereUniqueInputSchema,
  create: z.union([ CasePartnerCreateInputSchema,CasePartnerUncheckedCreateInputSchema ]),
  update: z.union([ CasePartnerUpdateInputSchema,CasePartnerUncheckedUpdateInputSchema ]),
}).strict() ;

export const CasePartnerCreateManyArgsSchema: z.ZodType<Prisma.CasePartnerCreateManyArgs> = z.object({
  data: z.union([ CasePartnerCreateManyInputSchema,CasePartnerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CasePartnerCreateManyAndReturnArgsSchema: z.ZodType<Prisma.CasePartnerCreateManyAndReturnArgs> = z.object({
  data: z.union([ CasePartnerCreateManyInputSchema,CasePartnerCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const CasePartnerDeleteArgsSchema: z.ZodType<Prisma.CasePartnerDeleteArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  where: CasePartnerWhereUniqueInputSchema,
}).strict() ;

export const CasePartnerUpdateArgsSchema: z.ZodType<Prisma.CasePartnerUpdateArgs> = z.object({
  select: CasePartnerSelectSchema.optional(),
  include: CasePartnerIncludeSchema.optional(),
  data: z.union([ CasePartnerUpdateInputSchema,CasePartnerUncheckedUpdateInputSchema ]),
  where: CasePartnerWhereUniqueInputSchema,
}).strict() ;

export const CasePartnerUpdateManyArgsSchema: z.ZodType<Prisma.CasePartnerUpdateManyArgs> = z.object({
  data: z.union([ CasePartnerUpdateManyMutationInputSchema,CasePartnerUncheckedUpdateManyInputSchema ]),
  where: CasePartnerWhereInputSchema.optional(),
}).strict() ;

export const CasePartnerDeleteManyArgsSchema: z.ZodType<Prisma.CasePartnerDeleteManyArgs> = z.object({
  where: CasePartnerWhereInputSchema.optional(),
}).strict() ;

export const PartnerPendingCaseCreateArgsSchema: z.ZodType<Prisma.PartnerPendingCaseCreateArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  data: z.union([ PartnerPendingCaseCreateInputSchema,PartnerPendingCaseUncheckedCreateInputSchema ]),
}).strict() ;

export const PartnerPendingCaseUpsertArgsSchema: z.ZodType<Prisma.PartnerPendingCaseUpsertArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereUniqueInputSchema,
  create: z.union([ PartnerPendingCaseCreateInputSchema,PartnerPendingCaseUncheckedCreateInputSchema ]),
  update: z.union([ PartnerPendingCaseUpdateInputSchema,PartnerPendingCaseUncheckedUpdateInputSchema ]),
}).strict() ;

export const PartnerPendingCaseCreateManyArgsSchema: z.ZodType<Prisma.PartnerPendingCaseCreateManyArgs> = z.object({
  data: z.union([ PartnerPendingCaseCreateManyInputSchema,PartnerPendingCaseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerPendingCaseCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PartnerPendingCaseCreateManyAndReturnArgs> = z.object({
  data: z.union([ PartnerPendingCaseCreateManyInputSchema,PartnerPendingCaseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PartnerPendingCaseDeleteArgsSchema: z.ZodType<Prisma.PartnerPendingCaseDeleteArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  where: PartnerPendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PartnerPendingCaseUpdateArgsSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateArgs> = z.object({
  select: PartnerPendingCaseSelectSchema.optional(),
  include: PartnerPendingCaseIncludeSchema.optional(),
  data: z.union([ PartnerPendingCaseUpdateInputSchema,PartnerPendingCaseUncheckedUpdateInputSchema ]),
  where: PartnerPendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PartnerPendingCaseUpdateManyArgsSchema: z.ZodType<Prisma.PartnerPendingCaseUpdateManyArgs> = z.object({
  data: z.union([ PartnerPendingCaseUpdateManyMutationInputSchema,PartnerPendingCaseUncheckedUpdateManyInputSchema ]),
  where: PartnerPendingCaseWhereInputSchema.optional(),
}).strict() ;

export const PartnerPendingCaseDeleteManyArgsSchema: z.ZodType<Prisma.PartnerPendingCaseDeleteManyArgs> = z.object({
  where: PartnerPendingCaseWhereInputSchema.optional(),
}).strict() ;

export const PendingCaseCreateArgsSchema: z.ZodType<Prisma.PendingCaseCreateArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  data: z.union([ PendingCaseCreateInputSchema,PendingCaseUncheckedCreateInputSchema ]),
}).strict() ;

export const PendingCaseUpsertArgsSchema: z.ZodType<Prisma.PendingCaseUpsertArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereUniqueInputSchema,
  create: z.union([ PendingCaseCreateInputSchema,PendingCaseUncheckedCreateInputSchema ]),
  update: z.union([ PendingCaseUpdateInputSchema,PendingCaseUncheckedUpdateInputSchema ]),
}).strict() ;

export const PendingCaseCreateManyArgsSchema: z.ZodType<Prisma.PendingCaseCreateManyArgs> = z.object({
  data: z.union([ PendingCaseCreateManyInputSchema,PendingCaseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PendingCaseCreateManyAndReturnArgsSchema: z.ZodType<Prisma.PendingCaseCreateManyAndReturnArgs> = z.object({
  data: z.union([ PendingCaseCreateManyInputSchema,PendingCaseCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const PendingCaseDeleteArgsSchema: z.ZodType<Prisma.PendingCaseDeleteArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  where: PendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PendingCaseUpdateArgsSchema: z.ZodType<Prisma.PendingCaseUpdateArgs> = z.object({
  select: PendingCaseSelectSchema.optional(),
  data: z.union([ PendingCaseUpdateInputSchema,PendingCaseUncheckedUpdateInputSchema ]),
  where: PendingCaseWhereUniqueInputSchema,
}).strict() ;

export const PendingCaseUpdateManyArgsSchema: z.ZodType<Prisma.PendingCaseUpdateManyArgs> = z.object({
  data: z.union([ PendingCaseUpdateManyMutationInputSchema,PendingCaseUncheckedUpdateManyInputSchema ]),
  where: PendingCaseWhereInputSchema.optional(),
}).strict() ;

export const PendingCaseDeleteManyArgsSchema: z.ZodType<Prisma.PendingCaseDeleteManyArgs> = z.object({
  where: PendingCaseWhereInputSchema.optional(),
}).strict() ;

export const SimulationCreateArgsSchema: z.ZodType<Prisma.SimulationCreateArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  data: z.union([ SimulationCreateInputSchema,SimulationUncheckedCreateInputSchema ]),
}).strict() ;

export const SimulationUpsertArgsSchema: z.ZodType<Prisma.SimulationUpsertArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereUniqueInputSchema,
  create: z.union([ SimulationCreateInputSchema,SimulationUncheckedCreateInputSchema ]),
  update: z.union([ SimulationUpdateInputSchema,SimulationUncheckedUpdateInputSchema ]),
}).strict() ;

export const SimulationCreateManyArgsSchema: z.ZodType<Prisma.SimulationCreateManyArgs> = z.object({
  data: z.union([ SimulationCreateManyInputSchema,SimulationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SimulationCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SimulationCreateManyAndReturnArgs> = z.object({
  data: z.union([ SimulationCreateManyInputSchema,SimulationCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SimulationDeleteArgsSchema: z.ZodType<Prisma.SimulationDeleteArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  where: SimulationWhereUniqueInputSchema,
}).strict() ;

export const SimulationUpdateArgsSchema: z.ZodType<Prisma.SimulationUpdateArgs> = z.object({
  select: SimulationSelectSchema.optional(),
  include: SimulationIncludeSchema.optional(),
  data: z.union([ SimulationUpdateInputSchema,SimulationUncheckedUpdateInputSchema ]),
  where: SimulationWhereUniqueInputSchema,
}).strict() ;

export const SimulationUpdateManyArgsSchema: z.ZodType<Prisma.SimulationUpdateManyArgs> = z.object({
  data: z.union([ SimulationUpdateManyMutationInputSchema,SimulationUncheckedUpdateManyInputSchema ]),
  where: SimulationWhereInputSchema.optional(),
}).strict() ;

export const SimulationDeleteManyArgsSchema: z.ZodType<Prisma.SimulationDeleteManyArgs> = z.object({
  where: SimulationWhereInputSchema.optional(),
}).strict() ;

export const SubmissionCreateArgsSchema: z.ZodType<Prisma.SubmissionCreateArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  data: z.union([ SubmissionCreateInputSchema,SubmissionUncheckedCreateInputSchema ]),
}).strict() ;

export const SubmissionUpsertArgsSchema: z.ZodType<Prisma.SubmissionUpsertArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereUniqueInputSchema,
  create: z.union([ SubmissionCreateInputSchema,SubmissionUncheckedCreateInputSchema ]),
  update: z.union([ SubmissionUpdateInputSchema,SubmissionUncheckedUpdateInputSchema ]),
}).strict() ;

export const SubmissionCreateManyArgsSchema: z.ZodType<Prisma.SubmissionCreateManyArgs> = z.object({
  data: z.union([ SubmissionCreateManyInputSchema,SubmissionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SubmissionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SubmissionCreateManyAndReturnArgs> = z.object({
  data: z.union([ SubmissionCreateManyInputSchema,SubmissionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SubmissionDeleteArgsSchema: z.ZodType<Prisma.SubmissionDeleteArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  where: SubmissionWhereUniqueInputSchema,
}).strict() ;

export const SubmissionUpdateArgsSchema: z.ZodType<Prisma.SubmissionUpdateArgs> = z.object({
  select: SubmissionSelectSchema.optional(),
  include: SubmissionIncludeSchema.optional(),
  data: z.union([ SubmissionUpdateInputSchema,SubmissionUncheckedUpdateInputSchema ]),
  where: SubmissionWhereUniqueInputSchema,
}).strict() ;

export const SubmissionUpdateManyArgsSchema: z.ZodType<Prisma.SubmissionUpdateManyArgs> = z.object({
  data: z.union([ SubmissionUpdateManyMutationInputSchema,SubmissionUncheckedUpdateManyInputSchema ]),
  where: SubmissionWhereInputSchema.optional(),
}).strict() ;

export const SubmissionDeleteManyArgsSchema: z.ZodType<Prisma.SubmissionDeleteManyArgs> = z.object({
  where: SubmissionWhereInputSchema.optional(),
}).strict() ;

export const SuggestionCreateArgsSchema: z.ZodType<Prisma.SuggestionCreateArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  data: z.union([ SuggestionCreateInputSchema,SuggestionUncheckedCreateInputSchema ]),
}).strict() ;

export const SuggestionUpsertArgsSchema: z.ZodType<Prisma.SuggestionUpsertArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereUniqueInputSchema,
  create: z.union([ SuggestionCreateInputSchema,SuggestionUncheckedCreateInputSchema ]),
  update: z.union([ SuggestionUpdateInputSchema,SuggestionUncheckedUpdateInputSchema ]),
}).strict() ;

export const SuggestionCreateManyArgsSchema: z.ZodType<Prisma.SuggestionCreateManyArgs> = z.object({
  data: z.union([ SuggestionCreateManyInputSchema,SuggestionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SuggestionCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SuggestionCreateManyAndReturnArgs> = z.object({
  data: z.union([ SuggestionCreateManyInputSchema,SuggestionCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SuggestionDeleteArgsSchema: z.ZodType<Prisma.SuggestionDeleteArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  where: SuggestionWhereUniqueInputSchema,
}).strict() ;

export const SuggestionUpdateArgsSchema: z.ZodType<Prisma.SuggestionUpdateArgs> = z.object({
  select: SuggestionSelectSchema.optional(),
  include: SuggestionIncludeSchema.optional(),
  data: z.union([ SuggestionUpdateInputSchema,SuggestionUncheckedUpdateInputSchema ]),
  where: SuggestionWhereUniqueInputSchema,
}).strict() ;

export const SuggestionUpdateManyArgsSchema: z.ZodType<Prisma.SuggestionUpdateManyArgs> = z.object({
  data: z.union([ SuggestionUpdateManyMutationInputSchema,SuggestionUncheckedUpdateManyInputSchema ]),
  where: SuggestionWhereInputSchema.optional(),
}).strict() ;

export const SuggestionDeleteManyArgsSchema: z.ZodType<Prisma.SuggestionDeleteManyArgs> = z.object({
  where: SuggestionWhereInputSchema.optional(),
}).strict() ;

export const SynthesisCreateArgsSchema: z.ZodType<Prisma.SynthesisCreateArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  data: z.union([ SynthesisCreateInputSchema,SynthesisUncheckedCreateInputSchema ]),
}).strict() ;

export const SynthesisUpsertArgsSchema: z.ZodType<Prisma.SynthesisUpsertArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereUniqueInputSchema,
  create: z.union([ SynthesisCreateInputSchema,SynthesisUncheckedCreateInputSchema ]),
  update: z.union([ SynthesisUpdateInputSchema,SynthesisUncheckedUpdateInputSchema ]),
}).strict() ;

export const SynthesisCreateManyArgsSchema: z.ZodType<Prisma.SynthesisCreateManyArgs> = z.object({
  data: z.union([ SynthesisCreateManyInputSchema,SynthesisCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SynthesisCreateManyAndReturnArgsSchema: z.ZodType<Prisma.SynthesisCreateManyAndReturnArgs> = z.object({
  data: z.union([ SynthesisCreateManyInputSchema,SynthesisCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const SynthesisDeleteArgsSchema: z.ZodType<Prisma.SynthesisDeleteArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  where: SynthesisWhereUniqueInputSchema,
}).strict() ;

export const SynthesisUpdateArgsSchema: z.ZodType<Prisma.SynthesisUpdateArgs> = z.object({
  select: SynthesisSelectSchema.optional(),
  include: SynthesisIncludeSchema.optional(),
  data: z.union([ SynthesisUpdateInputSchema,SynthesisUncheckedUpdateInputSchema ]),
  where: SynthesisWhereUniqueInputSchema,
}).strict() ;

export const SynthesisUpdateManyArgsSchema: z.ZodType<Prisma.SynthesisUpdateManyArgs> = z.object({
  data: z.union([ SynthesisUpdateManyMutationInputSchema,SynthesisUncheckedUpdateManyInputSchema ]),
  where: SynthesisWhereInputSchema.optional(),
}).strict() ;

export const SynthesisDeleteManyArgsSchema: z.ZodType<Prisma.SynthesisDeleteManyArgs> = z.object({
  where: SynthesisWhereInputSchema.optional(),
}).strict() ;

export const UserCreateArgsSchema: z.ZodType<Prisma.UserCreateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
}).strict() ;

export const UserUpsertArgsSchema: z.ZodType<Prisma.UserUpsertArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
  create: z.union([ UserCreateInputSchema,UserUncheckedCreateInputSchema ]),
  update: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
}).strict() ;

export const UserCreateManyArgsSchema: z.ZodType<Prisma.UserCreateManyArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const UserCreateManyAndReturnArgsSchema: z.ZodType<Prisma.UserCreateManyAndReturnArgs> = z.object({
  data: z.union([ UserCreateManyInputSchema,UserCreateManyInputSchema.array() ]),
  skipDuplicates: z.boolean().optional(),
}).strict() ;

export const UserDeleteArgsSchema: z.ZodType<Prisma.UserDeleteArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserUpdateArgsSchema: z.ZodType<Prisma.UserUpdateArgs> = z.object({
  select: UserSelectSchema.optional(),
  include: UserIncludeSchema.optional(),
  data: z.union([ UserUpdateInputSchema,UserUncheckedUpdateInputSchema ]),
  where: UserWhereUniqueInputSchema,
}).strict() ;

export const UserUpdateManyArgsSchema: z.ZodType<Prisma.UserUpdateManyArgs> = z.object({
  data: z.union([ UserUpdateManyMutationInputSchema,UserUncheckedUpdateManyInputSchema ]),
  where: UserWhereInputSchema.optional(),
}).strict() ;

export const UserDeleteManyArgsSchema: z.ZodType<Prisma.UserDeleteManyArgs> = z.object({
  where: UserWhereInputSchema.optional(),
}).strict() ;